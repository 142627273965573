import LodashGet from 'lodash/get'
import LodashHas from 'lodash/has'
import {
  isFunction,
  isJsonString,
  isNullOrEmpty,
  isObject,
  isString
} from './Is'
import { json2Obj } from './Json'
import { logOne } from './Log'
import { RGSquareBracketReplace } from './Regex'
import HFormat from './Format'
import HComponent from './Component'
export const get = LodashGet
export const has = LodashHas

export const mergeObjIfExist = (srcObj: any, mergeObj: any) => {
  if (srcObj && mergeObj) {
    let _keys = Object.keys(mergeObj)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        srcObj[k] = mergeObj[k]
      }
    }
  }
}

export const mergeObjIfNotExist = (srcObj: any, mergeObj: any) => {
  if (srcObj && mergeObj) {
    let _keys = Object.keys(mergeObj)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        if (!has(srcObj, k)) {
          srcObj[k] = mergeObj[k]
        }
      }
    }
  }
}

export const runFuntion = (fnList: object, name?: string, args: any[] = []) => {
  if (fnList && name) {
    if (isFunction(fnList[name])) {
      return fnList[name].apply(null, args)
    }
  }
  logOne(`runFuntion failed: ${name}`, fnList)
}

const getArrayFieldFromSquareBracketOfText = (s: string) => {
  var _result = []
  if (s != null) {
    var _m
    while ((_m = RGSquareBracketReplace.exec(s)) !== null) {
      if (_m.length > 1) {
        _result.push(_m[1])
      }
    }
  }
  return _result
}
//Helo [name] data: {name:"Hung"} -> Helo Hung
export const getCustomData = (sourceData: any, customData: any) => {
  let _customData = {}
  if (customData) {
    let _keys = Object.keys(customData)
    for (let k of _keys) {
      let _item = customData[k]
      if (isObject(_item)) {
        if (_item.fieldName && _item.configFormat) {
          _customData[k] = HFormat.format(
            sourceData[_item.fieldName],
            _item.configFormat,
            {
              data: sourceData
            }
          )
        } else if (_item.myIcon) {
          _customData[k] = HComponent.getHtmlFromConfigComponent(_item)
        }
      } else if (isString(_item)) {
        _customData[k] = getTextWithData(_item, sourceData, {
          squareBracket: true,
          hideshowWhenNull: true
        })
      } else {
        _customData[k] = customData[k]
      }
    }
  }
  return _customData
}
export const getTextWithData = (
  text: string,
  data: any,
  opts: any = { squareBracket: true, hideshowWhenNull: true }
) => {
  if (data && text) {
    if (text.indexOf('[') > -1) {
      //co dau hieu cua squareBracket
      let _fields = getArrayFieldFromSquareBracketOfText(text)
      if (_fields && _fields.length > 0) {
        var newS = text
        for (let f of _fields) {
          let _fieldName = f
          let _value = data[_fieldName]

          if (isJsonString(f)) {
            let _obj = json2Obj(f)
            if (_obj.fieldName) {
              _fieldName = _obj.fieldName
              if (_obj.configFormat) {
                _value = HFormat.format(data[_fieldName], _obj.configFormat, {
                  data: data
                })
              }
            }
            if (!isNullOrEmpty(_value)) {
              var rg = new RegExp(`\\[${f}\\]`, 'g')
              newS = newS.replace(rg, _value)
            } else if (
              opts.hideshowWhenNull === true &&
              isNullOrEmpty(_value)
            ) {
              var rg = new RegExp(`\\[${f}\\]`, 'g')
              newS = newS.replace(rg, '')
            }
          } else if (!isNullOrEmpty(_value)) {
            var rg = new RegExp(`\\[${_fieldName}\\]`, 'g')
            newS = newS.replace(rg, _value)
          } else if (opts.hideshowWhenNull === true && isNullOrEmpty(_value)) {
            ////Bo sung hideshowWhenNull, de ko muon [] trong chuoi sau khi replace
            var rg = new RegExp(`\\[${_fieldName}\\]`, 'g')
            newS = newS.replace(rg, '')
          }
        }
        return newS
      }
    }
  }
  return text
}

// export function getFuncName() {//Not working
//   return getFuncName.caller.name
// }

export function test() {
  console.warn('test HUtils')
}
