import * as React from 'react';
import MyPageTypes from '../MyPageTypes'
import CSuppense from '../../MyCore/CSuspense'
const Dev_Test  = React.lazy(() => import('./dev_test'));
const Dev_Mode  = React.lazy(() => import('./devmode'));

const Helpers = {
  initDevPage(){
    MyPageTypes.addTypes('test',(props)=>{ return CSuppense(props,Dev_Test) }); 
    MyPageTypes.addTypes('devmode',(props)=>{ return CSuppense(props,Dev_Mode) }); 
  }
}

export default Helpers;