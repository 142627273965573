import React from 'react'
import MyLibTypes from './MyLibTypes';
import HUtils from '../../helpers/Utils'
class MyLib extends React.Component {    
  render(){
    const {type} = this.props;
    // console.warn("render MyUI:",this.props,type,MyUITypes.Types);
    if(type && MyLibTypes.Types[type]){
      return MyLibTypes.Types[type](this.props);
    }
    HUtils.logExistType(type);
    return (
      <div></div>
    )
  } 
}

export default MyLib