export function updateListInList(
  listInList: string[],
  options: any,
  listData?: any[],
  listFieldTextByFieldOptionsInList?: any
) {
  if (listInList && listInList.length > 0 && options && listData) {
    let _listOnly = {}
    for (let k of listInList) {
      let _optionKey = k + '_InList'
      options[_optionKey] = []
      _listOnly[k] = []
    }
    if (listData != null) {
      for (let i = 0; i < listData.length; i++) {
        let _item = listData[i]
        for (let j = 0; j < listInList.length; j++) {
          let _fieldName = listInList[j]
          let _optionKey = _fieldName + '_InList'
          let _value = _item[_fieldName]
          if (_listOnly[_fieldName].indexOf(_value) == -1) {
            let _text = _value
            if (
              listFieldTextByFieldOptionsInList &&
              listFieldTextByFieldOptionsInList.hasOwnProperty(_fieldName)
            ) {
              _text = _item[listFieldTextByFieldOptionsInList[_fieldName]] || ''
            }
            if (options[_optionKey]) {
              options[_optionKey].push({
                label: _text,
                Text: _text,
                value: _value,
                Value: _value
              })
            }
            _listOnly[_fieldName].push(_value)
          }
        }
      }
    }
  }
}

export const getTextDisplayOfValueFromOptions = (
  sourceList: any,
  value: any
) => {
  let _text = ''
  if (sourceList != null) {
    for (let i = 0; i < sourceList.length; i++) {
      if (sourceList[i].Value == value) {
        _text = sourceList[i].Text_Display || sourceList[i].Text
        break
      }
    }
  }
  return _text
}
