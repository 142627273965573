import React from 'react';
import MyLoading from '../MyLoading/MyLoading';

const All = React.lazy(() => import('./Types/_all'));
const Search = React.lazy(() => import('./Types/search'));
const Select2 = React.lazy(() => import('./Types/select2'));
const Select = React.lazy(() => import('./Types/select'));
const Checkbox = React.lazy(() => import('./Types/checkbox'));
const Text = React.lazy(() => import('./Types/text'));
const Custom = React.lazy(() => import('./Types/custom'));
const FilterRow = React.lazy(() => import('./Types/filter_row'));
const FilterRowSelect = React.lazy(() => import('./Types/filter_row_select'));
const DateFromTo = React.lazy(() => import('./Types/datefromto'));
const Select2Quality = React.lazy(() => import('./Types/select2quality'));
const Switch = React.lazy(() => import('./Types/switch'));
const FilterRange = React.lazy(() => import('./Types/filter_range'));
const FilterCheckbox = React.lazy(() => import('./Types/filter_checkbox'));

// const Select2Content = React.lazy(() => import('./Types/select2content'));

const MyTableFilter = {
  addType(key,fnComponent){
    if(MyTableFilter.Types[key]==null){
      MyTableFilter.Types[key] = fnComponent;
    }
  },
  Types:{    
    search(props){ return( <React.Suspense fallback={<MyLoading />}> <Search {...props}/> </React.Suspense> ) },
    select2(props){ return( <React.Suspense fallback={<MyLoading />}> <Select2 {...props}/> </React.Suspense> ) },
    select(props){ return( <React.Suspense fallback={<MyLoading />}> <Select {...props}/> </React.Suspense> ) },
    checkbox(props){ return( <React.Suspense fallback={<MyLoading />}> <Checkbox {...props}/> </React.Suspense> ) },
    text(props){ return( <Text {...props}/>) },
    custom(props){ return( <Custom {...props}/>) },
    filter_row(props){ return( <FilterRow {...props}/>) },
    filter_row_select(props){ return( <FilterRowSelect {...props}/>) },
    datefromto(props){ return( <DateFromTo {...props}/>) },
    datefrom(props){ return( <DateFromTo {...props} customConfig={{daterange:{singleDatePicker:true,isFromDate:true}}}/>) },
    dateto(props){ return( <DateFromTo {...props} customConfig={{daterange:{singleDatePicker:true,isToDate:true}}}/>) },
    select2quality(props){ return( <Select2Quality {...props}/>) },
    switch(props){ return( <Switch {...props}/>) },
    filter_range(props){ return( <FilterRange {...props}/>) },
    filter_checkbox(props){ return( <FilterCheckbox {...props}/>) },
    // select2content(props){ return( <Select2Content {...props}/>) },
  }
}

export default MyTableFilter;