import React, { Component } from 'react';
import {
  Button,
  TabContent, TabPane
} from '../../_importComponent';
import {HeaderWithExpandV1} from '../../_importV1'
import {MyChart} from '../../_importMyComponent';
class CTable extends Component {
  constructor(props) {
    super(props);
    this.state={
    };
    this.state.loadedTab = ['tab_0'];
    this.state.activeTab = 'tab_0';
  }

  componentDidMount(){
  }

  onChangeData=(res)=>{
    const {filter,data} = res || {};
    console.log('onChangeData:',filter,data);
    this.forceUpdate();
  }
  _onClickTab=(tabId,index)=>{
    let _loadedTab = this.state.loadedTab;
    if(_loadedTab.indexOf(tabId)==-1){
      _loadedTab.push(tabId);
    }
    this.setState({
      activeTab: tabId,
      loadedTab: _loadedTab,
    })
  }
  _renderChart=()=>{
    const {options, configExpandChart, getDataTable,getOptions,getDataTableFilterd,getExtraDataTable,screenCode} = this.props; 
    let _chartConfig = options.ChartConfig;  
    let _config = _chartConfig.Config || {};
    let _configExpandChart = configExpandChart || _config.configExpand || {};
    let _defaultShowContent = false; 
    // let _chartConfig = {
    //   fX: 'AssignToId_Name',
    //   fY: 'Total_OnChart;[num]40;[num]45',
    //   titleChart: 'Chart',
    //   titleY: 'Total Hour;40;45',
    //   options: {},
    // }
    let _arrChart = _chartConfig.Charts;
    if(_config.DefaultShowContent!=null){
      _defaultShowContent = _config.DefaultShowContent;
    }

    if(_arrChart && _arrChart.length>0){
      let _ui = [];
      let _uiHeader = [];      
      for(let i =0;i<_arrChart.length;i++){
        let _chart = _arrChart[i];
        let _tabId = `tab_${i}`;
        if(_arrChart.length>1){
          _uiHeader.push(
            <Button key={i} outline={this.state.activeTab==_tabId?false:true} color="primary" 
              onClick={() => {             
                this._onClickTab(_tabId,i) 
              }}>
              {_chart.titleChart || _chart.type }
            </Button>
          )
        }
        _ui.push(
          <TabPane key={i} tabId={_tabId} style={{ padding: '0px' }}>
            {
              this.state.loadedTab.indexOf(_tabId)>-1 &&
              <MyChart 
                type={_chart.type} 
                // New Props
                chartConfig={_chart}
                fnList={{
                  fnGetDataTable: _chartConfig.WithFilter===false ? getDataTable: getDataTableFilterd,
                  fnGetDataTableFilterd: getDataTableFilterd,
                  fnGetExtraDataTable: getExtraDataTable,
                  fnGetOptions: getOptions
                }}
                morePropsChart={{
                  graph_id: `${this.props.prefixId||""}${screenCode}${_chart.type}_${i}`
                }}
              />
            }            
          </TabPane>
        )
      }
      return (
        <HeaderWithExpandV1 title={"Chart"} defaultShowContent={_defaultShowContent} {..._configExpandChart}>
          <div style={{width:'100%'}}>
            <div style={{width:'100%',marginBottom:'5px',textAlign:'center'}}>
              {_uiHeader}
            </div>
            <div style={_config.styleWrapTabContent||{overflowX:'auto',padding:"10px"}}>
              <TabContent activeTab={this.state.activeTab} style={{ border: '0px', background:'transparent' }}>
                {
                  _ui
                }
              </TabContent>
            </div>
          </div>          
        </HeaderWithExpandV1>
      )
    }
    return (
      <div></div>
    )
  }
  render() {
    const {options} = this.props;
    // console.warn('render chart:',options);
    return (
      <div className="header-chart"> 
        {
          options && options.ChartConfig &&
          this._renderChart()
        }     
      </div>
    )
  }
}

export default CTable;