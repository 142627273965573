import React, { Component } from 'react';
import { HColor, HConfig, HText, HUI, HUtils, HConstant, HNumber } from '../../_import';
import {ApiGeneric} from '../../_importService';
import {TableHelperV1} from '../../_importV1';
import { MyTableV1} from '../../_importV1mt'
import TableDetails from './TableDetails';
import TableDetailsApi from './TableDetailsApi';
import TableDetailsApiMulti from './TableDetailsApiMulti';

/**
 * JIRA: WEBAPI-6086
 */
class CTable extends Component {
  constructor(props) {
    super(props);

    this.state={
      data: [],
      extraData: null,
      extraDataSever: null,
      cols:[],
      error: null,
      isLoading: true,
      listHideCols: [],
      listRemoveCols: [],
      currentPage: 1,
      sizePerPage: 20,
      dataTotalSize: 0,
      controlSizePerPageInClient: false,
      sort: null,
    };

    this._query = {};
    this._customQuery = {};
    this._filterQuery = {};
    this._cTableExpand = {};

    this.state.listHideCols = this._getTableConfig('listHideCols',[]);
    // console.warn("_listHideColsFromParent 1:",this.props.fnList);
    if(this.props.fnList && this.props.fnList.fntGetCurrentListHideCols){
      let _listHideColsFromParent = this.props.fnList.fntGetCurrentListHideCols() || [];
      // console.warn("_listHideColsFromParent:",_listHideColsFromParent);
      this.state.listHideCols = [...this.state.listHideCols,..._listHideColsFromParent];
    }
  }

  componentDidMount(){
  }

  _getTableConfig=(parram,defaultValue)=>{
    const {tableConfig} = this.props
    // console.warn('_getTableConfig:',tableConfig);
    if(tableConfig && tableConfig[parram]!=null){
      return tableConfig[parram];
    }
    return defaultValue;
  }

  _getTableConfigV2=(key,df)=>{
    const {tableConfig} = this.props
    return HConstant.getConfig(tableConfig,"TableConfig",key,df);
  }

  _getOptions=()=>{
    return this.options;
  }

  _mixExtraData=({exLocal,exSever})=>{
    console.warn('[OLD 23/11/2018] _mixExtraData:',exLocal,exSever);
    if(exSever && exLocal && exLocal.Columns){
      let _keys = Object.keys(exLocal.Columns);
      let _colLocal = exLocal.Columns;
      let _colSever = exSever.Columns;
      if(_colSever && _keys.length>0){
        for(let _k of _keys){
          if(_colLocal[_k].IsNotMix==true){
            continue;
          }
          let _colLocalCanEdit = _colLocal[_k].CanEdit;
          if(_colLocal && _colSever && _colLocal[_k] && _colSever[_k]){
            // console.log('_mixExtraData ce:',_k,_colSever[_k].CanEdit);
            if(_colSever[_k].CanEdit!=null){
              _colLocal[_k].CanEdit = _colSever[_k].CanEdit;
            }
          }

          //For WorkDate
          if(_k.startsWith('WorkDate_')==true){
            // console.log(_ce);
            for(let _kOfO of Object.keys(_colSever[_k])){
              _colLocal[_k][_kOfO] = _colSever[_k][_kOfO];
            }
            if(_colLocalCanEdit==false){
              _colLocal[_k].CanEdit=_colLocalCanEdit;
            }
          }
        }
      }
    }
    // console.log('_mixExtraData:',exLocal,exSever);
    return exLocal;
  }

  reBuildTableWithExtraData=(extraData)=>{
    let _cols = this._buildCols({extraData});
    this.setState({
      cols:_cols,
    })
  }

  _buildCols=({extraData})=>{
    const {fnList} = this.props;
    const me = this;
    let _cols = [];
    let _addExtraColumns = this._getTableConfig('addExtraColumns',{});
    let _showDetails = this._getTableConfig("showDetails",false);
    let _showDetailsMulti = this._getTableConfig("showDetailsMulti",false);
    if(_showDetails==true){
      _addExtraColumns["_ColExpand"] = { Type: { type: 'expand', }, Index: 0, CanShow: true, CanEdit: false, CanSort: false, Header: " ", Width: '30px', };      
    }
    if(_showDetailsMulti==true){
      let _detailConfig = this.props.detailConfig;
      if(_detailConfig){
        try {
          _detailConfig = JSON.stringify(_detailConfig);
          _addExtraColumns["_ColExpandMulti"] = { Type: { type: 'expand_multi', more:_detailConfig }, Index: 0, CanShow: true, CanEdit: false, CanSort: false, Header: " ", Width: '30px', };
        } catch (error) {          
        }
      }      
    }
    // console.warn("_buildCols:",fnList);
    _cols = TableHelperV1.buildCols({
      component: this,
      extraData,
      fieldId: this._getTableConfigV2(HConstant.TableConfig.fieldId),
      sourceList: this.options,
      fnList: Object.assign(this._getTableConfig('fnList',{}),fnList,{        
        fnReloadTable: this.reload,
        fnReloadOptionsAndList: this.fnReloadOptionsAndList,
        fnReloadOptions: this.fnReloadOptions,
        fnAddOptions: this.fnAddOptions,
        fnShowExpand: this.fnShowExpand,
        fnIsShowExpand: this.fnIsShowExpand,
        fnRequestDelete: this.fnRequestDelete,
        fnRequestUpdate: this._onRequestUpdate,
        fnRequestUpdateFields: this._onRequestUpdateFields,
        fnForceUpdateTable: this.fnForceUpdateTable,
        fnClickShowModalControl: this.fnClickShowModalControl,// con su dung cho crmopty
        fnClickPhotoSite: this.props.onClickPhotoSite,// con su dung cho files cu
        fnGetFilterQuery: this.fnGetFilterQuery,
        fnGetColumnShowQuery: this.fnGetColumnShowQuery,
        fnGetRequestQuery: this.fnGetRequestQuery,
        // fnClickSelectWithList: this.fnClickSelectWithList,
        fnGetProjectId: this.fnGetProjectId,
        fnGetScreenCode: this.fnGetScreenCode,
        fnGetOptions: this.props.getOptions,
        fnGetParentData: this.props.getParentData,
        fnGetTableConfig: this._getTableConfig,
        fnGetApiPath: ()=>{
          return me.props.apiPath;
        },
        fnGetExpandList: ()=>{
          return this._cTableExpand;
        },
        fnGetConfigController: ()=>{
          // console.warn(`fnGetConfigController:`,me.props);
          return me.props.configController;
        },
        fntdGetExpandLevel: ()=>{
          return HConstant.rf(fnList,HConstant.UIFnTableDetail.fntdGetExpandLevel,[]) || 0;
        }
      }),
      options: {
        canDelete: this._getTableConfigV2(HConstant.TableConfig.canDelete),
        hideColDelete: this._getTableConfigV2(HConstant.TableConfig.hideColDelete),
        hideColDeleteOnSmallWidth: this._getTableConfigV2(HConstant.TableConfig.hideColDeleteOnSmallWidth),
        showID: this._getTableConfigV2(HConstant.TableConfig.showID),
        customProps: this._getTableConfig('customProps',{}),
        customDelete: this._getTableConfig('customDelete',null),
        // {
          // ProjectName:{
          //   width:'100px', dataSort: true, dataField:'ProjectName', header: 'Project Name',
          //   dataFormat: this.projectFormater,
          // },
          // _Uncover:{
          //   width:'30px', dataSort: true, dataField:'_Uncover', header: 'Uncover Needs',
          //   dataFormat: this.uncoverFormater,
          // }
        // },
        addExtraColumns: _addExtraColumns,
        customAction:this._getTableConfig('customAction',null),//khong xài this.actionFormater (03/10/2019)
      },
      fnRequestUpdate: this._onRequestUpdate,
      fnRequestDelete: this._onRequestDelete,
    })
    return _cols;
  }

  loadWithOptions(options,opts = {}){
    this.options = options;   
    if(opts.notNeedRequestList!=true){
      this._onRequestList({
        firstLoad: opts.firstLoad!=null?opts.firstLoad:true,
      });
    }           
  }

  reload=()=>{
    console.warn("reload");
    let _staticReload = this._getTableConfig('staticDataReload',null);
    if(_staticReload!=null){
      _staticReload({
        cb:()=>{
          this._onRequestList();
        }
      });
    }
    else{
      this._onRequestList();
    }
  }

  cleanSort=()=>{
    if(this._cTable){
      this._cTable.cleanSort();
    }
  }
  getListComponentExtraTable=()=>{
    return this._cTableExpand;
  }
  getListColumnsShow=()=>{
    let _cols = [];
    let _extraData = this.state.extraData;
    if(_extraData && _extraData.Columns){
      let _keys = Object.keys(_extraData.Columns);
      for(let k of _keys){
        if(_extraData.Columns[k].CanShow==true){
          _cols.push(k);
        }
      }
    }
    return _cols;
  }
  getExpanding=()=>{
    let _hasExpand = this._getTableConfigV2(HConstant.TableConfig.hasExpand,false);
    if(_hasExpand==true){
      let _data = this.state.data;
      let _newExpanding = [];
      if(_data){
        for(let i=0;i<_data.length;i++){
          if(_data[i].Id){
            _newExpanding.push(_data[i].Id);
          }
        }
      }
      return _newExpanding;     
    }    
  }
  fnClickShowModalControl=({row,opts})=>{
    if(this.props.onClickShowModalControl){
      this.props.onClickShowModalControl(opts);
    }
  }
  fnGetProjectId=({row,opts}={})=>{
    if(this.props.projectId){
      return this.props.projectId;
    }
    if(row && row.ProjectId){
      return row.ProjectId;
    }
  }
  fnReloadOptionsAndList=({row,opts}={})=>{
    const {onRequestOptions} = this.props;
    if(onRequestOptions){
      onRequestOptions({
        optsLoadOptionsInList: {
          notNeedRequestList: false,
          firstLoad: false,
        }
      })
    }
  }
  fnReloadOptions=({row,opts}={})=>{
    const {onRequestOptions} = this.props;
    if(onRequestOptions){
      onRequestOptions({
        optsLoadOptionsInList: {
          notNeedRequestList: true,
          firstLoad: false,
        }
      })
    }
  }
  fnGetScreenCode=(opts)=>{
    const {screenCode} = this.props;
    return screenCode;
  }
  fnIsShowExpand=({row,item})=>{
    if(row && row.Id && this._cTableExpand){      
      if(row['_isExpand']!=null){
        // console.log('fnIsShowExpand1:',row,this._cTableExpand);
        return row['_isExpand'];
      }
      if(this._cTableExpand[row.Id]!=null && this._cTableExpand[row.Id].isShowed!=null){
        // console.log('fnIsShowExpand2:',row,this._cTableExpand);
        return this._cTableExpand[row.Id].isShowed({row,item});
      }
    }
    // if(row.ReleaseNote && row.ReleaseNote.indexOf('22')){
    //   return true;
    // }
    return false;
  }

  fnAddOptions=({source,value,text})=>{
    // this.options = 
    console.log('fnAddOptions:',source,value,text,this.options);
    this.options[source].push({
      Value: value,
      Text: text
    });
    console.log('fnAddOptions:',this.options);
  }
  fnShowExpand=({row,cc,item})=>{
    // console.log('fnShowExpand:',row,this._cTableExpand);
    if(row && row.Id && this._cTableExpand){
      if(this._cTableExpand[row.Id]!=null){
        this._cTableExpand[row.Id].show({row,cc,item});
      }
    }
    else{
      console.warn("Cannot show expand, maybe Id null",row,this._cTableExpand);
    }
  }
  fnForceUpdateTable=()=>{
    this.forceUpdate();
  }
  fnRequestDelete=({row,extra})=>{
    this._onRequestDelete(row);
  }
  fnGetColumnShowQuery=()=>{
    let _query = {};
    let _cols = this.getListColumnsShow();
    if(_cols && _cols.length>0){
      // _query.ColumnShowQuery = _cols.join(",");
      _query.ColumnShowQuery = _cols;
    }
    return _query;
  }
  fnGetFilterQuery=()=>{
    // console.warn('fnGetFilterQuery:',this._filterQuery);
    return this._filterQuery;
  }
  fnGetSortQuery=()=>{
    return this._sortQuery;
  }
  fnGetCustomQuery=()=>{
    return this._customQuery;
  }
  fnGetRequestQuery=()=>{
    // console.warn(`fnGetRequestQuery`,this.props);
    let _requestQuery = {};
    if(this.props.getSharedQueryData){
      _requestQuery = this.props.getSharedQueryData();
    }
    else{
      if(this.props.projectId){
        _requestQuery = {
          ProjectId: this.props.projectId
        };
      }
    }      
    let _query = Object.assign(_requestQuery,this._customQuery)||{};
    if(this.props.queryConfig){
      _query = Object.assign(_query,this.props.queryConfig);
    }
    // console.log(`_query request:`,_query);
    return _query;
  }
  handleSearch=(text)=>{
    const {onAfterSearch}  = this.props;
    console.log('handleSearch:',text);
    if(this._cTable!=null){
      this._cTable.handleSearch(text);
      if (onAfterSearch != null) {
        let _data = this._cTable.getTableDataIgnorePaging();        
        onAfterSearch({ search: text, data: _data });
      }
    }
  }
  handleFilterData=(obj)=>{
    const {filterConfig,onAfterFilter}  = this.props;
    console.log('handleFilterData:',obj,filterConfig,this.state.data,this._customQuery);
    if(obj){
      this._filterQuery = {};
      let _keys = Object.keys(obj);
      if(_keys){
        for(let k of _keys){
          if(typeof obj[k]=='boolean'){
            this._filterQuery[k] = obj[k];
          }
          else{
            if(obj[k].start!=null && obj[k].end!=null && obj[k].start>0){//Dung cho kieu date from,to
              this._filterQuery[k] = `${obj[k].start},${obj[k].end}`;
            }
            else{
              this._filterQuery[k] = obj[k].value;
            }            
          }          
          // if(obj[k].value && typeof obj[k].value == 'object' &&  obj[k].value.callbackParameters!=null 
          // && obj[k].value.callbackParameters.selectValue!=null
          // ){
          //   this._filterQuery[k] = obj[k].value.callbackParameters.selectValue;
          // }//Se lam filtter bi sai
        }
      }
      // console.warn('handleFilterData:',this._filterQuery);
    }
    // return;
    if(filterConfig){
      if(filterConfig.customQueryFromSever){
        this._lastCustomQueryString = JSON.stringify(Object.assign({},this._customQuery));
        this._customQuery = filterConfig.customQueryFromSever({
          obj: obj
        });        
        let _lengthCustomQuery = Object.keys(this._customQuery).length;
        let _compareOldNew = JSON.stringify(this._customQuery)!=this._lastCustomQueryString;
        console.log('handleFilterData _customQuery:',this._customQuery,obj,this._lastCustomQueryString,_lengthCustomQuery,_compareOldNew);
        if(_lengthCustomQuery>=0){//call request list always after change filter
          if(_compareOldNew){
            if(obj){
              let _obj = {};
              for(let _k in obj){
                if(_k.indexOf('_OnServer')==-1){
                  _obj[_k] = obj[_k];
                }
              }
              console.log('handleFilterData _obj1:',_obj);
              this._cTable.handleFilterData(_obj);
            }
            return this._onRequestList(
              {
                notShowLoadingApp: this._getTableConfig("showLoadingWhenFilter",null)===true?false:true,
              }
            );
          }
        }        
      }
    }
    if(this._cTable!=null){
      if(obj){
        let _obj = {};
        for(let _k in obj){
          if(_k.indexOf('_OnServer')==-1){
            _obj[_k] = obj[_k];
          }
        }
        console.log('handleFilterData _obj2:',_obj);
        this._cTable.handleFilterData(_obj);
      }
      // this._cTable.handleFilterData(obj);
      if (onAfterFilter != null) {
        let _data = this._cTable.getTableDataIgnorePaging();
        // console.log('handleFilterData after filter _data:', _data);
        onAfterFilter({ filter: obj, data: _data });
      }
    }
  }
  handleHideCol=(listCols,opts)=>{
    console.log('handleHideCol:',listCols);
    if(listCols!=null){
      if(opts && opts.isRemove==true){
        this.setState({listRemoveCols:listCols});
      }
      else{
        this.setState({listHideCols:listCols});
      }
      if(opts && opts.applyDetail===true && this._cTableExpand){
        let _keys = Object.keys(this._cTableExpand);
        if(_keys && _keys.length>0){
          for(let k of _keys){
            if(this._cTableExpand[k] && this._cTableExpand[k].handleHideCol){
              this._cTableExpand[k].handleHideCol(listCols,opts);
            }
          }
        }        
      }
    }
  }

  _checkInvalidProjectList=(data)=>{
    let _data = [];
    // console.warn('Check Invalid',this.props, data );
    let _isCheckInvalidProject = this._getTableConfig('isCheckInvalidProject',HConfig.fnList.getConfig("isCheckInvalidProjectId"));
    if(_isCheckInvalidProject!=true){
      return data;
    }
    if(data){
      let _isHasProblem = false;
      let _ignoreNoProjectId = this._getTableConfig('ignoreNoProjectId',HConfig.fnList.getConfig("ignoreNoProjectId"));
      for(let i=0;i<data.length;i++){
        let _item = data[i];
        if(_ignoreNoProjectId==true && !_item.hasOwnProperty("ProjectId")){
          _data.push(_item);
        }
        else{
          if(_item.ProjectId==this.props.projectId){
            _data.push(_item);
          }
          else{
            _isHasProblem = true;
          }
        }        
      }
      if(_isHasProblem){
        HUI.Toast.showError(HText.get('msg_error_data_need_feedback'));
        ApiGeneric.generic({
          request:{
            method: 'POST',
            path: "Exception",
            name: "Save"
          },
          data:{
            "Data":{
              ProjectId: this.props.projectId,
              ScreenCode: this.props.screenCode,
              Url: window.location.href,
            }
          },
          successCallBack:(response)=>{
          },
          errorCallBack:(error,response)=>{
          }
        })
      }
    }        
    return _data;
  }

  _onRequestUpdate=(row, cellName, cellValue, opts)=>{
    const {onAfterUpdateField}=this.props;
    // console.log('_onRequestUpdate:',row,this.props);
    ApiGeneric.generic({
      request:{
        method: 'POST',
        path: this.props.apiPath,
        name: ApiGeneric.NAME.UpdateFields
      },
      data:{
        ProjectId: row.ProjectId,
        Id: row.Id,
        Url: window.location.href,
        RequestData: this.fnGetRequestQuery(),
        Values:[{
          FieldName: cellName,
          NewValue: cellValue,
        }]
      },
      successCallBack:(response)=>{
        if(response.Data!=null && response.Data.Id==row.Id){
          let _data = this.state.data;
          // console.log('_data:',_data);
          //  chuyen sang su dung HUtils
          // for(let i=0;i<_data.length;i++){
          //   if(_data[i].Id == response.Data.Id){
          //     // _data[i] = response.Data;//Update new
          //     // Update attr of obj
          //     for(let _key of Object.keys(_data[i])){
          //       if(_key.startsWith('_')==false){
          //         _data[i][_key] = response.Data[_key];
          //       }
          //     }
          //     break;
          //   }
          // }
          HUtils.updateDataWithNewData(row,response.Data);

          let _isAlwaysUpdateCellAfterUpdate = this._getTableConfig('isAlwaysUpdateCellAfterUpdate',false);
          if(_isAlwaysUpdateCellAfterUpdate!=false){            
            if(_isAlwaysUpdateCellAfterUpdate=='all'){
              row._UI_NeedUpdate = {...response.Data};
            }
            else{
              let _obj = {};            
              _obj[cellName] = response.Data[cellName];
              row._UI_NeedUpdate = _obj;
            }            
          }                    

          if(onAfterUpdateField){
            onAfterUpdateField({data:_data})
          }          
        }

        if(opts){
          if(opts.fnUpdateUILoading){
            opts.fnUpdateUILoading(false,{...opts});
          }
          if(opts.fnUpdateUIError){
            opts.fnUpdateUIError(null,{...opts});
          }
          if(opts.fnSuccessCallBack){
            opts.fnSuccessCallBack(response,{...opts});
          }
        }
        
        let _isHideMsgUpdateConfig = this._getTableConfig('hideMsgUpdate',false);
        if(_isHideMsgUpdateConfig==true || (opts && opts.hideMsgUpdate==true)){
          console.log("Update success");
        }
        else{
          HUI.Toast.showSuccess(response.Msg);
        }        
        TableHelperV1.Updating.remove(row,cellName);        
        this.forceUpdate();

        if(this._getTableConfigV2(HConstant.TableConfig.needReloadAfterUpdate,false)){
          this.reload();
        }
        let _needReloadAfterUpdateFields = this._getTableConfigV2(HConstant.TableConfig.needReloadAfterUpdateFields,null);
        if(_needReloadAfterUpdateFields!=null){
          if(Array.isArray(_needReloadAfterUpdateFields)){
            if(_needReloadAfterUpdateFields.indexOf(cellName)>-1){
              this.reload();
            }
          }
          else if(typeof _needReloadAfterUpdateFields=="string"){
            if(cellName==_needReloadAfterUpdateFields){
              this.reload();
            }
          }
        }
      },
      errorCallBack:(error,response)=>{
        // this.forceUpdate();
        // console.warn("errorCallBack:",error,response);
        if(response && response.Msg){
          if(opts){
            if(opts.fnUpdateUILoading){
              opts.fnUpdateUILoading(false,{...opts});
            }
            if(opts.fnUpdateUIError){
              opts.fnUpdateUIError(response.Msg,{...opts});            
            }
          }          
        }
      }
    })
  }

  _onRequestUpdateFields=(row, values, opts = {})=>{
    const {onAfterUpdateField}=this.props;
    const {componentCell,updateSuccess,updateFailed} = opts;
    // console.log('_onRequestUpdateFields:',values,this.props);
    ApiGeneric.generic({
      request:{
        method: 'POST',
        path: this.props.apiPath,
        name: ApiGeneric.NAME.UpdateFields
      },
      data:{
        ProjectId: row.ProjectId,
        Id: row.Id,
        Url: window.location.href,
        RequestData: this.fnGetRequestQuery(),
        Values: values
      },
      successCallBack:(response)=>{
        if(response.Data!=null && response.Data.Id==row.Id){
          let _data = this.state.data;
          // console.log('_data:',_data);
          for(let i=0;i<_data.length;i++){
            if(_data[i].Id == response.Data.Id){
              for(let _key of Object.keys(_data[i])){
                if(_key.startsWith('_')==false){
                  _data[i][_key] = response.Data[_key];
                }                
              }
              break;
            }
          }
          if(onAfterUpdateField){
            onAfterUpdateField({data:_data})
          }
        }
        HUI.Toast.showSuccess(response.Msg);
        if(updateSuccess){
          updateSuccess();
        }
        this.forceUpdate();
      },
      errorCallBack:(error,response)=>{
        if(updateFailed){
          updateFailed();
        }
      }
    })
  }

  _onRequestUpdateMaster=(row)=>{
    ApiGeneric.generic({
      request:{
        method: 'POST',
        path: this.props.apiPath,
        name: `GetOneItemModel/${row.Id}`
      },
      data: {},
      successCallBack:(response)=>{
        let _data = this.state.data;
        if(row!=null && response.Data){
          if(row.Id==response.Data.Id){
            let _keys = Object.keys(row);
            if(_keys && _keys.length>0){
              for(let k of _keys){
                if(k && k.startsWith("_")==false){
                  row[k] = response.Data[k];
                }                
              }
            }
          }
        }
        this.setState({
          data: _data,
        })
      },
      errorCallBack:(error,response)=>{
      }
    });
  }

  _onRequestDelete=(row)=>{
    const {onAfterDeleteItem} = this.props;
    let _isDeleteWithData = this._getTableConfigV2(HConstant.TableConfig.deleteWithData);
    let _pathName = `${ApiGeneric.NAME.Delete}/${row.Id}`;
    let _query = {
      Id: row.Id,
    }
    if(_isDeleteWithData==true){
      _pathName = ApiGeneric.NAME.Delete;
      _query.projectId = this.props.projectId;//cu
      _query.ProjectId = this.props.projectId;//viet hoa cho giong cac request khac
      _query.RequestData = this.fnGetRequestQuery();
    }
    ApiGeneric.generic({
      request:{
        method: 'POST',
        path: this.props.apiPath,
        name: _pathName
      },
      data: _query,
      successCallBack:(response)=>{
        let _msg = response.Msg || HText.get('msg_success_remove');
        HUI.Toast.showSuccess(_msg);
        let _data = this.state.data;

        if(_data!=null){
          for(let i=0;i<_data.length;i++){
            if(_data[i].Id==row.Id){
              _data[i]._isDeleted = true;
              _data.splice(i,1);
              break;
            }
          }
        }
        this.setState({
          data: _data,
        },()=>{
          if(onAfterDeleteItem){
            onAfterDeleteItem();
          }
          if(this._getTableConfigV2(HConstant.TableConfig.needReloadAfterDelete,false)){
            this.reload();
          }
        })
      },
      errorCallBack:(error,response)=>{
      }
    });
  }

  _onRequestList=(opts={})=>{
    const {onLoadListFinish}=this.props;
    if(this.props.tableConfig && this.props.tableConfig.staticData){
      let _data = [];
      if(typeof this.props.tableConfig.staticData === 'function'){
        _data = this.props.tableConfig.staticData();
      }
      else{
        _data = this.props.tableConfig.staticData;
      }
      let _usingExtraData = null;
      let _localExtraData = this._getTableConfig('localExtraData',null);
      let _extraData = this._getTableConfig('extraData',null);

      if(_localExtraData){
        _usingExtraData = _localExtraData;
      }
      if(_extraData){
        if(_usingExtraData!=null){
          let _isMergeExtraData = this._getTableConfig('isMergeExtraData',false);
          if(_isMergeExtraData==true){
            _usingExtraData = TableHelperV1.ExtraData.mergeLocalAndServer(_localExtraData,_extraData);
          }
          else{          
            _usingExtraData = this._mixExtraData({exLocal:_localExtraData,exSever:_extraData});
          } 
        }
        else{
          _usingExtraData = _extraData;
        }
      }
      
      let _cols = this._buildCols({extraData: _usingExtraData});
      console.log('_onRequestList _cols:',_cols,_usingExtraData,_extraData);
      this.setState({
        data: _data,
        extraData: _usingExtraData,
        cols: _cols,
        error:null,isLoading:false
      },()=>{
      });
      if(onLoadListFinish!=null){
        onLoadListFinish(_data,null,{Data:{Data:_data}});
      }
      return;
    }
    /** An di vi gom lai qua ham fnGetRequestQuery */
    // this._query = {};
    // if(this.props.getSharedQueryData){
    //   this._query = this.props.getSharedQueryData();
    // }
    // else{
    //   if(this.props.projectId){
    //     this._query = {
    //       ProjectId: this.props.projectId
    //     };
    //   }
    // }      
    // let _query = Object.assign(this._query,this._customQuery)||{};
    // if(this.props.queryConfig){
    //   _query = Object.assign(_query,this.props.queryConfig);
    // }
    let _query = this.fnGetRequestQuery();
    let _hasPagingRemote = this._getTableConfigV2(HConstant.TableConfig.hasPagingRemote);
    if(_hasPagingRemote){
      _query = Object.assign(_query,{
        Page: this.state.currentPage,
        PageSize: this.state.sizePerPage,
        Sorts: this.state.sort,
        UI_Page: this.state.currentPage,
        UI_PageSize: this.state.sizePerPage,
        UI_Sorts: this.state.sort,
        UI_TextSearch: this._searchQuery
      })
      // let _optionsTable = this._getTableConfig("optionsTable",{});
      // console.warn("aaaaa",_optionsTable);
      // if(_optionsTable.sizePerPage){
      //   _query.PageSize = _optionsTable.sizePerPage;
      //   this.state.sizePerPage = _optionsTable.sizePerPage;
      // }
      if(opts && opts.isFirstPage){
        _query.Page = 1;
        this.state.currentPage = 1;
      }
    }
    if(opts.customRequest){
      _query = Object.assign(_query,opts.customRequest);
    }   
    if(opts.notShowLoadingApp===false){
      HUI.LoadingScreen.show();
    } 
    ApiGeneric.generic({
      request:{
        method: 'POST',
        path: this.props.apiPath,
        name: this._getTableConfig('apiList','List')
      },
      data: _query,
      successCallBack:(response)=>{
        let _localExtraData = this._getTableConfig('extraData',null); 
        
        let _extraData = response.Data.ExtraData;
        
        if(_localExtraData){
          let _isMergeExtraData = this._getTableConfig('isMergeExtraData',false);
          if(_isMergeExtraData==true){
            _extraData = TableHelperV1.ExtraData.mergeLocalAndServer(_localExtraData,_extraData);
          }
          else{          
            _extraData = this._mixExtraData({exLocal:_localExtraData,exSever:response.Data.ExtraData});
          }   
        }
             
        console.log('_extraData:',_extraData,response);
        let _cols = this._buildCols({extraData: _extraData});
        let _data = response.Data.Data;
        let _rebuildData = this._getTableConfig('rebuildData',null);
        if(_rebuildData){
          _data = _rebuildData({data:_data,getOptions:this._getOptions});
        }
        if(this.props.projectId){
          _data = this._checkInvalidProjectList(_data);
        }

        let _waitingFilterOnServer = this._getTableConfig('waitingFilterOnServer',null);
        let _needCleanSortAfterList = this._getTableConfig('needCleanSortAfterList',null);
        if(_waitingFilterOnServer==true && opts.firstLoad==true){//neu co thi se doi goi list o 
          _data = null;
        } 
        if(_needCleanSortAfterList){
          if(this._cTable && this._cTable.cleanSort){
            this._cTable.cleanSort();
          }
        }
        
        this.setState({
          data: _data,
          dataTotal: response.Data.DataTotal,
          extraData: _extraData,
          extraDataSever: response.Data.ExtraData,
          cols: _cols,
          error:null,isLoading:false,
          dataTotalSize: response.Data.Total
        },()=>{
        });
        if(onLoadListFinish!=null){
          console.log('onLoadListFinish call:',response.Data.ExtraData);
          // return;       
          onLoadListFinish(response.Data.Data,null,response,opts);
        }
      },
      errorCallBack:(error,response)=>{
        // console.log('errorCallBack:',error,response,error.toString());
        this.setState({
          error:error!=null?error:response,isLoading:false
        });        
        if(onLoadListFinish!=null && response && response.Data && response.Data.Data){onLoadListFinish(response.Data.Data,error,response,opts);}
        if(response==null && error && error.toString){
          let _msgError = error.toString();
          if(_msgError.indexOf('timeout of')>-1){
            if(onLoadListFinish!=null){
              onLoadListFinish(null,error,null,opts);
            }
          }
        }        
      }
    });
  }

  _renderExpand=(row)=>{
    // console.warn('_renderExpand:',row,this.props.tableConfig);
    const {detailConfig,getOptions,fnList} = this.props;
    let _expandConfig = this._getTableConfig("Expand",{});
    let _expandProps = {};
    if(_expandConfig && _expandConfig.props){
      _expandConfig = _expandConfig.props;
    }
    let _c;    
    let _showDetails = this._getTableConfig("showDetails",false);
    if(_showDetails){
      _c = TableDetails;
    }
    if(detailConfig && detailConfig.controller){
      _c = TableDetailsApi;
    }
    else if(detailConfig && detailConfig.multi){
      _c = TableDetailsApiMulti;
    }
    if(this.props.tableConfig && this.props.tableConfig.componentExpand){
      _c = this.props.tableConfig.componentExpand;
    }
    if(_c){
      return (
        <_c 
          ref={r => { this._cTableExpand[row.Id] = r; }} 
          options={this.options} 
          getOptions={getOptions}
          row={row} 
          extraData={this.state.extraData} 
          extraDataSever={this.state.extraDataSever}
          onClickPhotoSite={this.props.onClickPhotoSite}
          detailConfig={this.props.detailConfig}
          pageMatch={this.props.pageMatch}
          projectId={this.props.projectId}
          isWithProjectId={this.props.pageConfig.isWithProjectId}
          configController={this.props.configController}
          requestUpdateMaster={this._onRequestUpdateMaster}   
          fnList={fnList}       
          {..._expandConfig}
        />
      )
    }    
    return <div></div>
  }

  //để truyền qua Category
  getData=()=>{
    return this.state.data;
  }

  getExtraData=()=>{
    return this.state.extraData;
  }

  getDataFiltered=()=>{
    if(this._cTable){
      return this._cTable.getTableDataIgnorePaging();
    }
    return this.state.data;
  }

  //lấy cols
  getCols=()=>{
    return this.state.cols;
  }

  getFooterData=()=>{
    if(this.state.cols && this.state.cols.length>0){
      // console.warn("cols:",this.state.cols);
      let _configTotal = this._getTableConfig("configTotal",{});
      let _defaultAlign = 'right';
      let _defaultFormatNumber = HConfig.numberFormat_ReadonlyNumber;
      let _configColTotal = {};
      
      if(_configTotal.defaultAlign){
        _defaultAlign = _configTotal.defaultAlign;
      }
      if(_configTotal.defaultFormatNumber){
        _defaultFormatNumber = _configTotal.defaultFormatNumber;
      }
      if(_configTotal.configColTotal){
        _configColTotal = _configTotal.configColTotal;
      }
      let _styleAllCol = {}
      if(_configTotal.styleAllCol){
        _styleAllCol = _configTotal.styleAllCol;
      }

      let _data = [];
      let _dataTotal = this.state.dataTotal;
      if(_dataTotal && _dataTotal.length>0){
        for(let i in _dataTotal){
          let _item = _dataTotal[i];
          let _dataItem = [];        
          let _keyOfItem = Object.keys(_item);
          for(let ki=0;ki<_keyOfItem.length;ki++){
            let _keyKI = _keyOfItem[ki];
            let _index = 0;
            
            for(let kc=0;kc<this.state.cols.length;kc++){
              let _keyKC = this.state.cols[kc].dataField;
              if(_keyKI==_keyKC){
                _index = kc;
                break;
              }
            }
            let _colConfig = _configColTotal[_keyKI] || {};
            let _styleOfCol = _colConfig.style || {};
            _dataItem.push({
              label: _item[_keyKI],
              columnIndex: _index,
              align: _colConfig.align!=null?_colConfig.align:_defaultAlign,
              formatter: (tableData) => {   
                let _value = _item[_keyKI]; 
                if(_colConfig.fShow){
                  _value = _item[_colConfig.fShow];
                }         
                if(typeof _item[_keyKI]=='number'){                  
                  let _format = _colConfig.format || _defaultFormatNumber;
                  _value = HNumber.format(_value,_format);               
                }
                if(typeof _value==="string"){
                  return <div title={_value} style={{..._styleAllCol,..._styleOfCol}}>{_value}</div>;
                }
                return <div></div>
              }
            })
          }
          _data.push(_dataItem);        
        }
      }      
      return _data;
    }
  }

  _onSortChange=(sortField, order,p)=>{
    console.warn("_onSortChange:",sortField,order,p);
    this._sortQuery = [
      {
        Field: sortField,
        Dir: order
      }
    ];
    let _hasPagingRemote = this._getTableConfigV2(HConstant.TableConfig.hasPagingRemote);
    if(_hasPagingRemote){
      this.setState({
        controlSizePerPageInClient: true,
        sort: this._sortQuery
      },()=>{
        this.reload();
      });
    }   
  }
  _onSearchChange=(searchText, colInfos, multiColumnSearch)=>{
    // console.warn("_onSearchChange:", searchText, colInfos, multiColumnSearch)
    this._searchQuery = searchText;
    let _hasPagingRemote = this._getTableConfigV2(HConstant.TableConfig.hasPagingRemote);
    if(_hasPagingRemote){
      this.reload();
    }
  }
  _onPageChange=(page, sizePerPage)=> {
    const {fnList} = this.props;    
    // console.warn("_onPageChange:",page,sizePerPage,fnList);    
    this.setState({
      currentPage: page,
      sizePerPage: sizePerPage,
      controlSizePerPageInClient: true
    },()=>{
      this.reload();
      if(fnList && fnList.onTablePageChange){
        fnList.onTablePageChange({
          page, sizePerPage
        })
      }
    });
  }

  render() {
    //Hide table if show === false
    let _showTable = this._getTableConfig("show",true) ? true: false;
    if(_showTable===false){
      return (<div></div>)
    }

    let _className = this._getTableConfig("showHeader",true) ? "": "s_table_hideheader";
    _className += this._getTableConfig("hasCustomHeader",false) ? " s_table_customheader": "";
    _className += this._getTableConfig("hasWrapHeader",false) ? " s_table_wrapheader": "";
    _className += this._getTableConfig("noBorder",false) ? " s_table_noborder": "";
    _className += this._getTableConfigV2(HConstant.TableConfig.noBorderCol) ? " s_table_noborder_col": "";
    _className += this._getTableConfig("borderLight",false) ? " s_table_borderlight": "";
    _className += this._getTableConfig("hasBigBorderBottom",false) ? " has-big-borderbottom": "";    
    // _className += this._getTableConfig("hasStickyHeader",false) ? " has-stickyheader": "";//Neu ko an, can kiem tra overflow cac parent, app-body, app // ko dung nua vi dung overlow .app
    _className += " " + this._getTableConfig("classNameTable","");
    let _tableProps = this._getTableConfig("tableProps",{});
    
    let _hasExpand = this._getTableConfigV2(HConstant.TableConfig.hasExpand);
    let _hasPagingRemote = this._getTableConfigV2(HConstant.TableConfig.hasPagingRemote);

    let _optionsTable = this._getTableConfig("optionsTable",{});
    _optionsTable.onSortChange = this._onSortChange;
    _optionsTable.onSearchChange = this._onSearchChange;

    if(_hasExpand===true){
      _tableProps = Object.assign(_tableProps,{
        expandableRow: ()=>{return true},
        expandComponent: this._renderExpand
      });
      _optionsTable = Object.assign(_optionsTable,{
        expandAllChilds: true,
        expandRowBgColor: HColor.bgExpand,
        expandBy: 'column',
        expandBodyClass : this._getTableConfig("expandBodyClass",'react-bs-container-row-expand'),
        expanding: this.getExpanding(),
      });  
    }
    if(_hasPagingRemote){
      _tableProps = Object.assign(_tableProps,{
        remote: true,
        fetchInfo: {
          dataTotalSize: this.state.dataTotalSize
        }
      });
      let _sizePerPage = _optionsTable.sizePerPage || this.state.sizePerPage;
      if(this.state.controlSizePerPageInClient==true){
        _sizePerPage = this.state.sizePerPage;
      }else{
        if(_optionsTable.sizePerPage){
          this.state.sizePerPage = _optionsTable.sizePerPage;
        }
      }
      _optionsTable = Object.assign(_optionsTable,{
        sizePerPage: _sizePerPage,
        onPageChange: this._onPageChange,        
        page: this.state.currentPage
      });      
    }
    let _maxHeight = this._getTableConfig("maxHeight","");//example : calc(100vh - 350px)
    if(_maxHeight){
      _tableProps.containerStyle = {
        maxHeight: _maxHeight,
        overflow: 'auto'
      }
    }
    if(this._getTableConfig("showTotal",false)){
      let _footerData = this.getFooterData();
      // console.warn("footerData:",_footerData);
      if(_footerData && this.state.data && this.state.data.length>0){//chi show khi co data
        _tableProps.footer = true;
        _tableProps.footerData = _footerData;
      }
    }
    // console.warn("render MyTable:",_tableProps);
    return (
      <MyTableV1 
        ref={r=>{this._cTable=r;}}  
        cols={this.state.cols}
        data={this.state.data}
        colsHidden={this.state.listHideCols}
        colsRemove={this.state.listRemoveCols}
        optionsTable={_optionsTable}
        tableProps={_tableProps}
        className={_className}
      />    
    )
  }
}

export default CTable;