export function xoaDauInText(s: string) {
  let str = s + '' //phai chac chan la chuoi
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}

export function validSearchTextTV(sourceText: string, searchText: string) {
  if (sourceText && searchText != null) {
    let _source = xoaDauInText(sourceText).toLocaleLowerCase()
    let _search = xoaDauInText(searchText).toLocaleLowerCase()
    if (_source.indexOf(_search) > -1) {
      return true
    }
  }
  return false
}

export function validSearchText(
  sourceText: string,
  searchText: string,
  configSearch?: any
) {
  if (configSearch && configSearch.noSymbol) {
    return validSearchTextTV(sourceText, searchText)
  }
  if (sourceText && searchText != null) {
    let _source = sourceText.toLocaleLowerCase()
    let _search = searchText.toLocaleLowerCase()
    if (_source.indexOf(_search) > -1) {
      return true
    }
  }
  return false
}

export function test() {
  console.warn('')
}
