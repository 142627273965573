import React from "react";
import ReactDOM from "react-dom"
import HLocalStorage from "../../../helpers/LocalStorage"
import {Tooltip} from "../../../v1/_importLibs"
const LocalKeyAS = "V3_AuthorizationSecond";
class View extends React.Component {
  constructor() {
    super();       
  }

  componentDidMount = () => {
  };

  componentWillUnmount = () => {
  };

  render() {
    const {item} = this.props;
    if(item && item.getUserName){
      return (
        <div style={{background:"#222958",border:"1px solid white",height:"40px",borderRadius:"20px",padding:"10px",color:"white",display:"flex",alignItems:"center"}}>
          <div>Đang xem qua: <span style={{fontWeight:"bold"}}>{item.getUserName()}</span></div>
          <Tooltip placement="top" overlay={"Bỏ chế độ xem qua tài khoản khác"}>
            <div style={{width:"26px",height:"26px",
              borderRadius:"30px",background:"white",color:"red",display:"flex",alignItems:"center",
              marginLeft:"5px",fontSize:"25px",cursor:"pointer",
              justifyContent:"center"}} onClick={()=>{
              AuthorizationSecond.clear();
            }}>
              <i className="fa fa-times-circle"></i>
            </div>
          </Tooltip>
          
        </div>
      )
    }
    return (
      <div></div>
    )
  }
}

const Config = {

}
class AuthorizationSecond {
  constructor(obj={}) {
    this._token = obj.token || "";
    this._user = obj.user || {};
    this._component = (
      <View item={this}/>
    );
  }
  getUserName(){
    if(this._user && this._user.DisplayName){
      return this._user.DisplayName
    }
  }
  getToken(){
    return this._token;
  }

  static get Config(){
    return Config;
  }
  static get KConfigApp() {
    return "_authorizationSecond";
  }
  static get KHeader() {
    return "Authorization-Second";
  }
  static get KCanRunAsOtherUser(){
    return "CanRunAsOtherUser"
  }
  static getConfigByKey(key,df){
    if(Config[key]!=null){
      return Config[key];
    }
    return df;
  }
  static updateConfig(key,value){
    Config[key] = value;
  }
  static clear(){
    localStorage.removeItem(LocalKeyAS);
    window.location.reload();
  }
  static saveLocalStorage(user,token){
    HLocalStorage.setObject(LocalKeyAS,{
      token,
      user
    })    
  }
  static loadFromLocalStorage(HConfig){
    let _obj = HLocalStorage.getObject(LocalKeyAS);
    if(_obj){
      let _objAS = new AuthorizationSecond(_obj);
      if(HConfig){
        HConfig[AuthorizationSecond.KConfigApp] = _objAS;
      }
      return _objAS;
    }
  }
  static renderAreaNotify(objAS){
    let div = document.createElement("div");
    div.style.position = "fixed";
    div.style.left = "10px";
    div.style.bottom = "10px";
    div.style.zIndex = 1069;
    ReactDOM.render(
      <View item={objAS}/>,
      document.body.appendChild(div)
   );
  }
}
export default AuthorizationSecond