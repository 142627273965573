import moment from 'moment';
import React, { Component } from 'react';
import {
  Button,
  ButtonGroup, Card,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon, InputGroupText, Row
} from '../../../_importComponent';
import { Global } from '../../../helpers';
import { CellCustom } from './_CellFilter';
import {HConstant, HUtils, HText, HLocalStorage,HDate} from '../../../_import'
import {Select} from '../../../_importLibs'
import {MyLib} from '../../../_importMyComponent'
const Type_DateFromTo = function(_fromTime,_toTime){
  return {
    type: 'CustomFilter', start: _fromTime,end: _toTime,value: {
      callbackParameters: '',
      callback:(targetVal)=>{
        if(targetVal>=_fromTime && targetVal<=_toTime){
          return true;
        }
        return false;
      }
    }, 
  }  
}
const Type_DateFrom = function(_fromTime,_toTime){
  return {
    type: 'CustomFilter', start: _fromTime,value: {
      callbackParameters: '',
      callback:(targetVal)=>{
        if(targetVal>=_fromTime){
          return true;
        }
        return false;
      }
    }, 
  }  
}
const Type_DateTo = function(_fromTime,_toTime){
  return {
    type: 'CustomFilter', end: _toTime,value: {
      callbackParameters: '',
      callback:(targetVal)=>{
        if(targetVal<=_toTime){
          return true;
        }
        return false;
      }
    }, 
  }  
}
const ColFilter = (props) =>{
  let _classNameItem = "col-12 col-sm-6 col-md-4";
  // console.warn("col filter:",props);
  let _configItem = props.configItem || {};
  if(_configItem.isFull ==true || (_configItem.moreObj && _configItem.moreObj.isFull===true) ){
    _classNameItem = "col-12"
    return (
      <div className={_classNameItem}>{props.component}</div>
    )
  }
  // console.warn("configFilter:",props,props.configFilter);
  if(props.configFilter && props.configFilter.classNameItem){
    _classNameItem = props.configFilter.classNameItem;
  }
  return (
    <div className={_classNameItem}>{props.component}</div>
  )
}
class TableFilter extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      options: null,
      value:{},
      currentFilter: {},
      searchText: '',
    };
  }

  updateOptions=(options)=>{
    this.setState({
      options: options,
    });
    this._checkOptionsAndRemoveFieldMissingInOptions();
  }

  _checkOptionsAndRemoveFieldMissingInOptions=()=>{
    let _dataConfig = this.props.data;
    let _value = this.state.value;
    let _currentFilter = this.state.currentFilter;
    let _options = this.state.options;
    // console.warn('_checkOptionsAndRemoveFieldMissingInOptions:',this.state,_value,_dataConfig);
    let _needCheck = {};
    let _isHaveIssue = false;
    if(_dataConfig && _dataConfig.length>0){
      for(let i in _dataConfig){
        let _item = _dataConfig[i];
        if(_item.optionKey){
          _needCheck[_item.optionKey] = _item.fieldName;
        }
      }
    }
    // console.warn('_checkOptionsAndRemoveFieldMissingInOptions _needCheck:',_needCheck);
    if(Object.keys(_needCheck).length>0){
      for(let _k in _needCheck){
        let _v = _value[_needCheck[_k]];
        // console.warn('_v:',_v,_k);
        if(_v && _v.indexOf!=null){//truong hop _v ko phai string se bi loi, bo sung _v.indexOf
          let _listOpts = _options[_k];
          let _isExist = false;
          // console.warn('_listOpts:',_listOpts);
          let _countExist = 0;
          if(_listOpts && _listOpts.length>0){
            for(let ii in _listOpts){  
              if(_k=="StaffList"){
                // console.warn("test:",_v,_listOpts[ii]);
              }  
              if(_v.indexOf(',')>-1 || HUtils.isArray(_v)){
                let _arrV = HUtils.isArray(_v)?_v:_v.split(',');                
                for(let j of _arrV){
                  if(_listOpts[ii].value==j || _listOpts[ii].Value ==j){
                    _countExist++;
                  }
                }
                // console.warn('count:',_countExist,_arrV)
                if(_countExist==_arrV.length){
                  _isExist = true;break;
                }
              } 
              else{
                if(_listOpts[ii].value==_v || _listOpts[ii].Value ==_v){
                  _isExist = true;break;
                }
              }                       
            }
          }
          if(_isExist==false){
            console.warn('not exist!!',_v,_k);
            delete _value[_needCheck[_k]];
            delete _currentFilter[_needCheck[_k]];
            _isHaveIssue = true;
          }
        }
      }
    }
    if(_isHaveIssue){
      this._onChangeFilter(_value,_currentFilter);
      this.forceUpdate();
    }
    console.log('_needCheck:',_needCheck,_isHaveIssue);
  }

  _getTypeOfKey=(fieldName)=>{
    const {data} = this.props;
    let _type;
    if(this._typeOfField && this._typeOfField[fieldName]){
      _type = this._typeOfField[fieldName];
    }
    else if(data && data.length>0){
      let _list = {};
      for(let i in data){
        let _item = data[i];
        _list[_item.fieldName] = _item.type;
        if(_item.fieldName == fieldName){
          _type = _item.type;
        }
      }
      this._typeOfField = _list;
    }
    return _type;
  }

  loadWithOptions=(options,defaultFilter)=>{
    const {onChangeFilter,onChangeSearch,screenCode,require4ShowData} = this.props;
    //chi loadWithOptions khi options null, chay 1 lan
    if(this.state.options!=null){ 
      console.warn("loadWithOptions chi chay 1 lan",this.state.options,options)     
      return
    }
    // if(this.state.options!= null)
    //   return;
    // console.log('loadWithOptions defaultFilter:',defaultFilter);
    console.log('loadWithOptions savedFilter:',options);
    if(defaultFilter==null && screenCode!=null && this._getHasSaveFilterLocal()){
      let _savedFilter = HLocalStorage.ConfigScreen.getSavedFilter(screenCode);
      console.log('savedFilter:',_savedFilter,screenCode);
      if(_savedFilter!=null){
        let _value = _savedFilter.value;
        let _filter = _savedFilter.filter;
        if(_value!=null){
          let _keys = Object.keys(_value);
          for(let e of _keys){
            // if(typeof _value[e] === 'object' && _filter!=null && _filter[e]!=null && _filter[e].start && _filter[e].end){//date from to, chua test nen tam an
            //   _filter[e] = Type_DateFromTo(_value[e].startDate,_value[e].endDate);
            //   continue;
            // }
            if(typeof _value[e] === 'object'){//remove object value
              delete _value[e];
              if(_filter!=null && _filter[e]!=null){
                delete _filter[e];
              }
            }
            if(_filter!=null){ //remove type CustomFilter
              if(_filter[e]!=null && _filter[e].type!=null && _filter[e].type=='CustomFilter'){                
                delete _filter[e];
                if(_value!=null && _value[e]!=null){
                  delete _value[e];
                }
              }
            }            
          }
        }
        // console.warn('savedFilter after check:',_savedFilter);
        defaultFilter = _savedFilter;
      }
    }

    this.setState({
      options: options,
      value: defaultFilter!=null?defaultFilter.value:{},
      currentFilter: defaultFilter!=null?defaultFilter.filter:{},
    },()=>{
      console.log('Filter loadWithOptions finish:',this.state);
      if(this.state.currentFilter!=null){
        // console.log('Filter loadWithOptions currentFilter:',this.state,this.state.currentFilter);
        if(this.state.currentFilter){
          this._checkOptionsAndRemoveFieldMissingInOptions();
          if(onChangeFilter!=null){onChangeFilter(this.state.currentFilter)}
        }
        if(require4ShowData==true){
          if(onChangeSearch){
            var _text = 'nodata-' + (new Date().toISOString());
            // console.warn('onChangeSearch:',_text);
            onChangeSearch(_text);
          }
        }
      }
    })
    console.log('TableFilter loadWithOptions:',options,defaultFilter);
  }

  _canShowPrefix=(item)=>{
    let _rs = true;
    // console.warn("item",item);
    if(item && item.more && item.more.ui){
      if(item.more.ui.showPre===false){
        _rs = false;
      }
    }
    return _rs;
  }
  _renderPrefix=(item)=>{
    if(this._canShowPrefix(item) && item.title){
      return (
        <InputGroupAddon addonType="prepend">{item.title}</InputGroupAddon>
      )
    }
  }
  _getPlaceHolder=(item,df)=>{
    let _rs = df || HText.get('text_enter_keyword');
    // console.warn("item",item);
    let _moreObj = this._getMoreObj(item);
    if(_moreObj && _moreObj.placeholder!=null){
      _rs = _moreObj.placeholder;
    }
    // console.warn("placeholder:",_rs);
    return _rs;
  }
  _getMoreObj=(item)=>{
    let _moreObj = {};
    if(item && item.more){
      if(typeof item.more=="object"){
        _moreObj = item.more;
      }
      else if(typeof item.more=="string"){
        try {
          _moreObj = JSON.parse(item.more);
        } catch (error) {
          console.warn("error parse json:",item.more)
        }
      }
    }
    return _moreObj;
  }
  _getObjectInMoreObj=(item,key,df)=>{
    let _moreObj = this._getMoreObj(item);
    let _value = df;
    if(_moreObj.hasOwnProperty(key)){
      _value = _moreObj[key];
    }
    return _value;
  }
  _onClear=(fieldName,item)=>{
    const {screenCode} = this.props;
    let _value=this.state.value;
    let _filterObj = this.state.currentFilter;
    // _value[fieldName] = null;
    delete _value[fieldName];
    // _filterObj[fieldName] = '';
    delete _filterObj[fieldName];
    this.setState({
      value: _value,
      currentFilter: _filterObj,
    },()=>{
      this._onChangeFilter(_value,_filterObj,item);
    })
  }
  _getHasSaveFilterLocal=()=>{
    let _has = true;
    const {hasSaveFilterLocal} = this.props;
    if(hasSaveFilterLocal===false){
      _has = false;
    }
    return _has;
  }
  _onChangeFilter=(value,filterObj,item)=>{
    const {onChangeFilter, screenCode} = this.props;
    console.log('Filter _onChangeFilter:',value,filterObj,screenCode,this.state);
    if(screenCode!=null && this._getHasSaveFilterLocal()){
      let _canSaveFilter = true;
      if(item && item.canSaveFilter!=null){
        _canSaveFilter = item.canSaveFilter;
      }
      if(_canSaveFilter){
        HLocalStorage.ConfigScreen.setSavedFilter(screenCode,{
          value: value,
          filter: filterObj,
        });
      }      
    }

    if(onChangeFilter!=null){
      onChangeFilter(filterObj);
    }
  }

  _onChangeSearch=({item,text})=>{
    const {onChangeSearch,require4ShowData}=this.props;
    //nosymbol
    if(onChangeSearch!=null){
      let _text = text;
      if(_text!=null && _text.length>0){
        _text = _text.trim();
      }
      if(item && item.nosymbol==true && _text){
        _text = HText.xoaDauInText(_text);
        console.log('search: ',_text);
      }
      /*
      Nếu bắt buộc phải có dữ liệu mới show data ở lưới thì cố tình đổi giá trị của text đi, sao cho ko thể tìm được
       */
      if(item && require4ShowData==true && (!_text)){
        _text = 'nodata-' + (new Date().toISOString());
        console.log('search: ',_text);
      }
      // console.warn("_onChangeSearch:",_text,item,this.props);
      onChangeSearch(_text);
    }
  }
  _renderSearch=(item)=>{
    // console.log('TableFilter _renderSearch:');
    const {onChangeSearch}=this.props;
    let _inputProps = this._getObjectInMoreObj(item,'input',{});
    let _uiConfig = this._getObjectInMoreObj(item,'ui',{});
    return (
      <FormGroup>
        <InputGroup>
          {
            _uiConfig.showPre == true && (
              <InputGroupAddon addonType="prepend">
                <InputGroupText><i className="fa fa-search"></i></InputGroupText> 
              </InputGroupAddon>
            )
          }
          <Input 
            type="text" 
            id="input-search" 
            name="input-search" 
            placeholder={this._getPlaceHolder(item)} 
            onKeyPress={(ev)=>{
              if(ev.key == 'Enter'){
                this._onChangeSearch({item,text:ev.currentTarget.value})
              }
            }}
            onChange={(ev)=> {
              this.setState({searchText:ev.currentTarget.value});
              if(item.instant==true){
                this._onChangeSearch({item,text:ev.currentTarget.value})
              }
            }}
            value={this.state.searchText}
            {..._inputProps}
          />
          <div>
            {
              _uiConfig.showSuffix != false &&(
                <Button style={{zIndex:0}} color="primary" onClick={()=>{
                  this._onChangeSearch({item,text:this.state.searchText})
                }}><i className="fa fa-search"></i></Button>
              )
            }
          </div>
        </InputGroup>
      </FormGroup>
    )
  }
  _renderSelect=(title,options,fieldName)=>{
    const {onChangeFilter} = this.props;
    let _ui = Global.Helper.createOptions(options,'Tất cả');
    return (
      <FormGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{title}</InputGroupText> 
          </InputGroupAddon>
          <Input type="select" onChange={(e)=>{
            // this._onChangeTrangThai(e,ProjectsFilter.Type.trangthai)
            let _value = e.currentTarget.value;
            let _filterObj = this.state.currentFilter;
            _filterObj[fieldName] = _value;
            this.setState({currentFilter:_filterObj},()=>{
              if(onChangeFilter!=null){onChangeFilter(_filterObj)}
            }); 
          }}>
            { _ui }
          </Input>
        </InputGroup>
      </FormGroup>
    )
  }
  _renderSelect2=(title,options,fieldName,item)=>{
    var moreProps = {
    };
    moreProps = Object.assign(moreProps,item.selectProps);
    let _value=this.state.value;
    let _filterObj = this.state.currentFilter;    
    // let _select2Props = {};
    // if(item.more && item.more.select2){
    //   _select2Props = item.more.select2;
    // }
    let _select2Props = this._getObjectInMoreObj(item,'select2',{});
    // console.warn('_renderSelect2:',item,options,_select2Props);
    return (
      <FormGroup>
        <InputGroup>
          {/* <InputGroupAddon addonType="prepend">{title}</InputGroupAddon> */}
          {this._renderPrefix(item)}
          <div style={{flex:1}}>
            <Select
              id="select2"
              onBlurResetsInput={false}
              onSelectResetsInput={false}
              autoFocus={false}
              options={options!=null?options:[]}
              value={this.state.value[fieldName]}
              placeholder={this._getPlaceHolder(item)} 
              simpleValue
              multi={true}
              closeOnSelect={false}
              clearable={false}
              name="selected-state"
              // value={this.state.WPGGroupId}
              onChange={(newValue)=>{
                console.log('onChange:',newValue,_value,_filterObj);
                _value[fieldName] = newValue;
                if(newValue!=null&&newValue.length==0){
                  _filterObj[fieldName] = newValue;
                  if(newValue.indexOf(';')>-1){
                    let _arrValue = newValue.replace(HConstant.EmptyOption.value,'').replace(/;/g,',').split(',');
                    _filterObj[fieldName] = { type: 'ArrayFilter', value: _arrValue};
                  }
                }
                else if(item.customFilter!=null){
                  _filterObj[fieldName] = item.customFilter(newValue);
                }
                else{
                  if(newValue===true || newValue===false){
                    _filterObj[fieldName] = newValue;
                  }
                  else{
                    let _arrValue = newValue.replace(HConstant.EmptyOption.value,'').replace(/;/g,',').split(',');
                    if(item.isNumber==true){
                      _arrValue = _arrValue.map((v)=> {if(v!=null&v.length>0){ return Number(v)}return null; });
                      
                    }
                    _filterObj[fieldName] = { type: 'ArrayFilter', value: _arrValue};
                  }
                  
                }
                this.setState({
                  value: _value,
                  currentFilter: _filterObj
                });
                console.log('select change:',newValue,_value,_filterObj);
                this._onChangeFilter(_value,_filterObj,item);
              }}
              searchable={true}
              {...moreProps}
              {..._select2Props}
            />
          </div>
          {
            _value && _value[fieldName] &&
            <div>
              <Button color="danger" onClick={()=>{
                this._onClear(fieldName,item);
              }}><i className="fa fa-ban"></i></Button>
            </div>
          }
          
        </InputGroup>
      </FormGroup>
    )
  }
  _cSelect2Color=(option)=>{
    let _bg = option.value||'transparent';
    return <div className="s2color_value" style={{backgroundColor:_bg}}>{option.label}</div>;
  }
  _renderSelect2Color=(title,options,fieldName,item)=>{
    const {onChangeFilter} = this.props;

    var moreProps = {
    };
    moreProps = Object.assign(moreProps,item.selectProps);
    return (
      <FormGroup>
        <InputGroup>
          {this._renderPrefix(item)}
          <div style={{flex:1}}>
            <Select
              id="select2"
              onBlurResetsInput={false}
              onSelectResetsInput={false}
              autoFocus={false}
              options={options!=null?options:[]}
              valueRenderer={this._cSelect2Color}
              optionRenderer={this._cSelect2Color}
              value={this.state.value[fieldName]}
              simpleValue
              multi={true}
              closeOnSelect={false}
              clearable={true}
              name="selected-state"
              // value={this.state.WPGGroupId}
              onChange={(newValue)=>{
                let _value=this.state.value;
                let _filterObj = this.state.currentFilter;

                _value[fieldName] = newValue;
                if(newValue!=null&&newValue.length==0){
                  _filterObj[fieldName] = newValue;
                }
                else{
                  _filterObj[fieldName] = { type: 'ArrayFilter', value: newValue.replace(HConstant.EmptyOption.value,'').split(',') }
                }
                this.setState({
                  value: _value,
                  currentFilter: _filterObj
                });
                console.log('select change:',newValue,_value);
                // if(onChangeFilter!=null){onChangeFilter(_filterObj)};
                this._onChangeFilter(_value,_filterObj,item);
              }}
              searchable={true}
              {...moreProps}
            />
          </div>
          <div>
            <Button color="danger" onClick={()=>{
              this._onClear(fieldName,item);
            }}><i className="fa fa-ban"></i></Button>
          </div>
        </InputGroup>
      </FormGroup>
    )
  }
  _cSelect2Date=(option)=>{
    let _bg = 'transparent';
    let _date = HDate.dateStringWithFormat(option.value,'DD/MM/YYYY');
    return <div className="s2color_value" style={{backgroundColor:_bg}}>{_date}</div>;
  }
  _renderSelect2Date=(title,options,fieldName,item)=>{
    const {onChangeFilter} = this.props;

    var moreProps = {
    };
    moreProps = Object.assign(moreProps,item.selectProps);
    return (
      <FormGroup>
        <InputGroup>
          {this._renderPrefix(item)}
          <div style={{flex:1}}>
            <Select
              id="select2"
              onBlurResetsInput={false}
              onSelectResetsInput={false}
              autoFocus={false}
              options={options!=null?options:[]}
              valueRenderer={this._cSelect2Date}
              optionRenderer={this._cSelect2Date}
              value={this.state.value[fieldName]}
              simpleValue
              multi={true}
              closeOnSelect={false}
              clearable={true}
              name="selected-state"
              // value={this.state.WPGGroupId}
              onChange={(newValue)=>{
                let _value=this.state.value;
                let _filterObj = this.state.currentFilter;

                _value[fieldName] = newValue;
                if(newValue!=null&&newValue.length==0){
                  // _filterObj[fieldName] = newValue;
                  _filterObj[fieldName] = Number(newValue);
                }
                else{
                  let _v = newValue.replace(HConstant.EmptyOption.value,'').split(',');
                  let _v2 = _v.map((v,i)=>{
                    return Number(v);
                  });
                  _filterObj[fieldName] = { type: 'ArrayFilter', value: _v2 };
                }
                this.setState({
                  value: _value,
                  currentFilter: _filterObj
                });
                console.log('select change:',newValue,_value);
                // if(onChangeFilter!=null){onChangeFilter(_filterObj)};
                this._onChangeFilter(_value,_filterObj,item);
              }}
              searchable={true}
              {...moreProps}
            />
          </div>
          <div>
            <Button color="danger" onClick={()=>{
              this._onClear(fieldName,item);
            }}><i className="fa fa-ban"></i></Button>
          </div>
        </InputGroup>
      </FormGroup>
    )
  }
  _renderDateFromTo=(title,options,fieldName,item,{isFrom,isTo}={})=>{
    const {onChangeFilter} = this.props;
    let _valueOfState=this.state.value;
    let _value = _valueOfState[fieldName];
    let _customProps = {};
    let _typeFilter = Type_DateFromTo;
    if(isFrom===true){
      _typeFilter = Type_DateFrom;
      _customProps.singleDatePicker = true;
    }
    else if(isTo===true){
      _typeFilter = Type_DateTo;
      _customProps.singleDatePicker = true;
    }
    if(_value==null){
      _value = {
        startDate: null,
        endDate: null,
      }

      if(item.defaultValue){
        _value.startDate = item.defaultValue.startDate;
        _value.endDate = item.defaultValue.endDate;
        let _filterObj = this.state.currentFilter;
        _valueOfState[fieldName]=_value;
        _filterObj[fieldName] = _typeFilter(_value.startDate,_value.endDate);
        
        this.setState({
          value: _valueOfState,
          currentFilter: _filterObj,
        },()=>{
          // if(onChangeFilter!=null){onChangeFilter(_filterObj)}
          this._onChangeFilter(_valueOfState,_filterObj,item);
        })
      }
    }

    let _title = "";
    if(_value.startDate && _value.endDate){
      _title = `${new moment(_value.startDate).format('DD/MM/YYYY')} - ${new moment(_value.endDate).format('DD/MM/YYYY')}`;
      if(isFrom===true || isTo===true){
        _title = `${new moment(_value.startDate).format('DD/MM/YYYY')}`;
      }
    }

    
    let _showRanges = HUtils.Obj.get(item,"more.showRanges");
    let _defaultShowRange = HConstant.gc(HConstant.AppConfig.mtf_datefromto_showRange)
    if(_showRanges!==false && (_showRanges!=null || _defaultShowRange)){
      _customProps.alwaysShowCalendars = true;
      _customProps.ranges = HDate.getRanges(_showRanges||true);
    }
    if(HUtils.Obj.get(item,"more.moreDateRangePicker")){
      _customProps = {
        ..._customProps,
        ...HUtils.Obj.get(item,"more.moreDateRangePicker")
      }
    }
    // console.warn("item:",item,_showRanges,_customProps);
    return (
      <FormGroup>
        <InputGroup>
          {this._renderPrefix(item)}
          <div style={{flex:1}} className="filter_datefromto">
            <MyLib type="daterangepicker" containerStyles={{width:'100%'}} 
              libConfig={{
                lang: HUtils.Obj.get(item,"more.lang") || "en"
              }}
              locale={{
                format: 'DD/MM/YYYY',
              }}                             
              showDropdowns 
              onApply={(event,picker)=>{              
                _value.startDate = picker.startDate._d.getTime();
                _value.endDate = picker.endDate._d.getTime();
                _valueOfState[fieldName]=_value;
                // console.warn("date apply:",picker,_value);

                let _filterObj = this.state.currentFilter;
                _filterObj[fieldName] = _typeFilter(_value.startDate,_value.endDate);
                this.setState({
                  value: _valueOfState,
                  currentFilter: _filterObj,
                },()=>{
                  this._onChangeFilter(_valueOfState,_filterObj,item);
                  // if(onChangeFilter!=null){onChangeFilter(_filterObj)}
                })
              }} {..._customProps}>
              {
                _value.startDate!=null ?
                <button>{_title}</button>
                :
                <button>{this._getPlaceHolder(item,HText.get('btn_pickdatefromto'))}</button>
              }
            </MyLib>
          </div>
          {
            _value && (_value.startDate || _value.endDate) &&
            <div>
              <Button color="danger" onClick={()=>{
                this._onClear(fieldName,item);
              }}><i className="fa fa-ban"></i></Button>
            </div>
          }
          
        </InputGroup>
      </FormGroup>
    )
  }
  _renderDisplayButton=(item)=>{
    if(item.icon){
      return (
        <i className={item.icon}/>
      )
    }
    else{
      return item.text;
    }
  }
  _renderCheckbox=(title,fieldName,item)=>{
    const {onChangeFilter} = this.props;
    let _valueOfState = this.state.value || {};
    let _value = _valueOfState[fieldName];
    // console.warn("_renderCheckbox",fieldName,item);
    if((_value!==true && _value!==false) && item.defaultValue!=null){
      _value = item.defaultValue;
      let _filterObj = this.state.currentFilter;
      _valueOfState[fieldName] = _value;
      _filterObj[fieldName] = (_value!=true && _value!=false)?'':_value;
      this.setState({
        value: _valueOfState,
        currentFilter: _filterObj
      },()=>{
        // if(onChangeFilter!=null){onChangeFilter(_filterObj)}
        this._onChangeFilter(_valueOfState,_filterObj,item);
      });
    }

    let _onClick=(value)=>{
      let _value=this.state.value;
      let _filterObj = this.state.currentFilter;

      _value[fieldName]=value;
      _filterObj[fieldName] = (value=="all")?'':value;

      this.setState({
        value: _value,
        currentFilter: _filterObj
      },()=>{
        // if(onChangeFilter!=null){onChangeFilter(_filterObj)}
        this._onChangeFilter(_value,_filterObj,item);
      });
    }
    // console.warn("all:",_value,item);

    let _textAll = HUtils.Obj.get(item,"more.all.text") || HText.get('text_all');
    let _itemPositive = {
      text: "True",
      color: "primary",
      ...HUtils.Obj.get(item,"more.positive")
    }
    let _itemNegative = {
      text: "False",
      color: "primary",
      ...HUtils.Obj.get(item,"more.negative")
    }
    return (
      <FormGroup>
        <InputGroup>
          {this._renderPrefix(item)}
          <ButtonGroup>
            {
              item.hideOptionAll !== true &&
              <Button onClick={()=>{_onClick("all")}} outline={(_value!==true && _value!==false)?false:true} color="primary">{_textAll}</Button>
            }            
            <Button onClick={()=>{_onClick(true)}} outline={_value!=true?true:false} color={_itemPositive.color}>{this._renderDisplayButton(_itemPositive)}</Button>
            <Button onClick={()=>{_onClick(false)}} outline={_value!=false?true:false} color={_itemNegative.color}>{this._renderDisplayButton(_itemNegative)}</Button>
          </ButtonGroup>
          {/* <div>
            <Button color="danger" onClick={()=>{
              this._onClear(fieldName);
            }}><i className="fa fa-ban"></i></Button>
          </div> */}
        </InputGroup>
      </FormGroup>
    )
  }
  _renderText=({title,fieldName,item})=>{
    var moreProps = {
    };
    moreProps = Object.assign(moreProps,item.selectProps);
    let _value = this.state.value || {};
    let _filterObj = this.state.currentFilter;
    let _fnAddFilter = (text,{runFilter}={})=>{
      _filterObj[fieldName] = { type: 'TextFilter', value: text };
      _value[fieldName] = text;
      if(item.instant==true || runFilter===true){
        this._onChangeFilter(_value,_filterObj,item);
      }
    }
    // console.warn(`_renderText _value:`,_value,fieldName,_value[fieldName]);
    return (
      <FormGroup>
        <InputGroup>
          {this._renderPrefix(item)}          
          <Input 
            type="text" 
            placeholder={this._getPlaceHolder(item)} 
            // defaultValue={_value[fieldName]}
            value={_value[fieldName]||""}
            onKeyPress={(ev)=>{
              if(ev.key == 'Enter'){
                _fnAddFilter(ev.currentTarget.value,{runFilter:true});
              }
            }}
            onChange={(ev)=> {
              let _text = ev.currentTarget.value;              
              if(_text==""){
                this._onClear(fieldName,item);
              }
              else{
                // if(item.instant==true){
                  _fnAddFilter(ev.currentTarget.value);
                // }  
              }     
              _value[fieldName] = _text;
              this.setState({
                value: _value
              })            
            }}
          />
          {
            _value && _value[fieldName] &&
            <div>
              <Button color="danger" onClick={()=>{
                this._onClear(fieldName,item);
              }}><i className="fa fa-ban"></i></Button>
            </div>
          }
          
        </InputGroup>
      </FormGroup>
    )
  }
  _renderContent=()=>{
    const {data,customType,componentLastInRow,configFilter}=this.props;
    // console.log('TableFilter _renderContent:',data);
    let _ui = [];
    let _optionsAll = this.state.options||this.props.options;
    if(data!=null && data.length>0){
      for(let i=0;i<data.length;i++){
        let _item = data[i];
        let _type = _item.type;
        let _title = _item.title;
        let _optionKey = _item.optionKey;
        let _fieldName = _item.fieldName;
        //parse more
        if(_item.more && _item.moreObj==null){
          _item.moreObj = this._getMoreObj(_item);
        }

        // console.log('TableFilter _renderContent:',_type,_item,_optionsAll,_optionKey);
        if(CellCustom[_type]){
          _ui.push(
            CellCustom[_type]({
              index: i,
              item: _item,
              cFilter: this,
              onClear: this._onClear,
              options: _optionsAll,
              values: this.state.value,
              onChange: ({value,filter})=>{
                let _value = this.state.value;
                let _filter = this.state.currentFilter;    
                _value[_fieldName] = value;
                _filter[_fieldName] = filter;   
                //Bo sung xoa filter neu rong, chua test ky
                if(value==null || value=="" || (Array.isArray(value) && value.length==0)){
                  delete _value[_fieldName];
                  delete _filter[_fieldName];
                  // console.warn('delete',_value,_filter,_fieldName,value);
                }                         
                this._onChangeFilter(_value,_filter,_item);
              },
            })
          );
          continue;
        }
        else if(customType && customType[_type]){
          _ui.push(
            customType[_type]({
              index: i,
              item: _item,
              cFilter: this,
              onClear: this._onClear,
              options: _optionsAll,
              values: this.state.value,
              onChange: ({value,filter})=>{
                let _value = this.state.value;
                let _filter = this.state.currentFilter;    
                _value[_fieldName] = value;
                _filter[_fieldName] = filter;
                //Bo sung xoa filter neu rong, chua test ky
                if(value==null || value=="" || (Array.isArray(value) && value.length==0)){
                  delete _value[_fieldName];
                  delete _filter[_fieldName];
                  // console.warn('delete',_value,_filter,_fieldName,value);
                }  
                this._onChangeFilter(_value,_filter,_item);
              },
            })
          );
          continue;
        }
        if(_type==TableFilter.Type.search){
          _ui.push(
            <ColFilter key={i} component={this._renderSearch(_item)} configItem={_item} configFilter={configFilter} {..._item.col}/>
          )
        }
        else if(_type==TableFilter.Type.select){
          if(_optionsAll!=null){
            let _options = _optionsAll[_optionKey];
            _ui.push(
              <ColFilter key={i} component={this._renderSelect(_title,_options,_fieldName)} configItem={_item} configFilter={configFilter} {..._item.col}/>
            )
          }
          else{
            console.log('missing options!');
          }
        }
        else if(_type==TableFilter.Type.select2){
          if(_optionsAll!=null || _item.options!=null){
            let _options = [];
            if(_item.options!=null){
              _options = _item.options;
            }
            else if(_optionsAll!=null && _optionKey!=null )
            {
              _options = _optionsAll[_optionKey];
              
            }
            if(_item.needConvert==true){
              _options = Global.Helper.createOptionsForSelect2(_options);
            }
            if(_item.isFilterEmpty==true){
              if(_options!=null && _options.length>0 && _options[0].value != HConstant.EmptyOption.value){
                _options.unshift({
                  value: HConstant.EmptyOption.value, label: HConstant.EmptyOption.label
                })
              }
            }
            _ui.push(
              <ColFilter key={i} component={this._renderSelect2(_title,_options,_fieldName,_item)} configItem={_item} configFilter={configFilter} {..._item.col}/>
            )
          }
          else{
            console.log('missing options!:',_optionsAll);
          }
        }
        else if(_type==TableFilter.Type.select2color){
          if(_optionsAll!=null){
            let _options = _optionsAll[_optionKey];
            _ui.push(
              <ColFilter key={i} component={this._renderSelect2Color(_title,_options,_fieldName,_item)} configItem={_item} configFilter={configFilter} {..._item.col}/>
            )
          }
          else{
            console.log('missing options!');
          }
        }
        else if(_type==TableFilter.Type.select2date){
          if(_optionsAll!=null){
            let _options = _optionsAll[_optionKey];
            _ui.push(
              <ColFilter key={i} component={this._renderSelect2Date(_title,_options,_fieldName,_item)} configItem={_item} configFilter={configFilter} {..._item.col}/>
            )
          }
          else{
            console.log('missing options!');
          }
        }
        else if(_type==TableFilter.Type.datefromto){
          _ui.push(
            <ColFilter key={i} component={this._renderDateFromTo(_title,{},_fieldName,_item)} configItem={_item} configFilter={configFilter} {..._item.col}/>
          )
        }
        else if(_type==TableFilter.Type.datefrom){
          _ui.push(
            <ColFilter key={i} component={this._renderDateFromTo(_title,{},_fieldName,_item,{isFrom:true})} configItem={_item} configFilter={configFilter} {..._item.col}/>
          )
        }
        else if(_type==TableFilter.Type.dateto){
          _ui.push(
            <ColFilter key={i} component={this._renderDateFromTo(_title,{},_fieldName,_item,{isTo:true})} configItem={_item} configFilter={configFilter} {..._item.col}/>
          )
        }
        else if(_type==TableFilter.Type.checkbox){
          _ui.push(
            <ColFilter key={i} component={this._renderCheckbox(_title,_fieldName,_item)} configItem={_item} configFilter={configFilter} {..._item.col}/>
            )
        }
        else if(_type==TableFilter.Type.switch){
          _ui.push(
            <ColFilter key={i} component={this._renderSwitch(_title,_fieldName,_item)} configItem={_item} configFilter={configFilter} {..._item.col}/>
            )
        }
        else if(_type==TableFilter.Type.custom){
          _ui.push(
            <ColFilter key={i} component={_item.component(this.state.currentFilter,this.props,this.state)} configItem={_item} configFilter={configFilter} {..._item.col}/>
          )
        }
        else if(_type==TableFilter.Type.hidecol){
          _ui.push(
            <ColFilter key={i} component={this._renderHideCols({title:_title,cols:_item.cols,item:_item})} configItem={_item} configFilter={configFilter} {..._item.col}/>
          )
        }
        else if(_type==TableFilter.Type.text){
          _ui.push(
            <ColFilter key={i} component={this._renderText({title:_title,fieldName:_fieldName,item:_item})} configItem={_item} configFilter={configFilter} {..._item.col}/>
          )
        }
      }
    }
    if(componentLastInRow){
      _ui.push(
        <ColFilter key={'last'} component={componentLastInRow} configFilter={configFilter}/>
      )
    }
    return (
      <Row>
        { _ui }
      </Row>
    )
  }

  _renderSwitch=(title,fieldName,item)=>{
    console.warn("_item",fieldName,item);
    let _valueOfState = this.state.value || {};
    let _value = _valueOfState[fieldName];
    // console.warn(" _renderSwitch",_value,this.state.value);
    if((_value!==true && _value!==false) && item.defaultValue!=null){
      _value = item.defaultValue;
      let _filterObj = this.state.currentFilter;
      _valueOfState[fieldName] = _value;
      _filterObj[fieldName] = (_value!=true && _value!=false)?'':_value;
      this.setState({
        value: _valueOfState,
        currentFilter: _filterObj
      },()=>{
        // if(onChangeFilter!=null){onChangeFilter(_filterObj)}
        this._onChangeFilter(_valueOfState,_filterObj,item);
      });
    }

    let _onClick=(value)=>{
      // console.warn(" value",value);

      let _value=this.state.value;
      let _filterObj = this.state.currentFilter;

      _value[fieldName]=value;
      _filterObj[fieldName] = value;

      this.setState({
        value: _value,
        currentFilter: _filterObj
      },()=>{
        // if(onChangeFilter!=null){onChangeFilter(_filterObj)}
        this._onChangeFilter(_value,_filterObj,item);
      });
    }
    return(
      <FormGroup>
      <InputGroup>
       {
           title!=null &&
           <InputGroupAddon addonType="prepend">
               <InputGroupText>{title}</InputGroupText> 
           </InputGroupAddon>
       }
       <div className="dFfdRjcSB" style={{alignItems:'center',marginLeft:'10px',paddingLeft:'10px'}} onClick={()=>{
          //  console.warn('on Click',_value);
           _onClick(!_value)
       }}>
           <label className="cui-sw cui-sw-green">
           <input type="checkbox" className="cui-sw-input" checked={_value} disabled/>
           <span className="cui-sw-label" data-on="On" data-off="Off"></span>
           <span className="cui-sw-handle"></span>
           </label>
       </div>
      </InputGroup>
  </FormGroup>
    )
  }
  _renderHideCols=({title,cols,item})=>{
    const {onHideCol}=this.props;
    var moreProps = {
    };
    let _fieldName = '_HideCols';
    moreProps = Object.assign(moreProps,item.selectProps);
    let _options = [];
    if(cols && Object.keys(cols).length>0){
      let _allF = Object.keys(cols);
      for(let i in _allF){
        let _k = _allF[i];
        let _n = cols[_k];
        _options.push({
          value: _k,
          label: _n
        })
      }
    }
    return (
      <FormGroup>
        <InputGroup>
          {this._renderPrefix(item)}
          <div style={{flex:1}}>
            <Select
              id="select2hidecol"
              onBlurResetsInput={false}
              onSelectResetsInput={false}
              autoFocus={false}
              options={_options}
              value={this.state.value[_fieldName]}
              simpleValue
              multi={true}
              closeOnSelect={false}
              clearable={false}
              name="selected-state"
              // value={this.state.WPGGroupId}
              onChange={(newValue)=>{
                let _value=this.state.value;
                console.log('onChange:',newValue,_value);
                _value[_fieldName] = newValue;
                this.setState({
                  value: _value,
                },()=>{
                  console.log('select change:',newValue,_value);
                  // this._onChangeFilter(_value,_filterObj);
                  let _arrValue = newValue.split(',');
                  if(onHideCol && _arrValue){
                    let _opts = {};
                    if(item.isRemove==true){
                      _opts.isRemove = true;
                    }
                    onHideCol(_arrValue,_opts);
                  }
                });
              }}
              searchable={true}
              {...moreProps}
            />
          </div>
          <div>
            <Button color="danger" onClick={()=>{
              // this._onClear(_fieldName);
              let _value=this.state.value;
              _value[_fieldName] = "";
              this.setState({
                value: _value,
              },()=>{
                if(onHideCol){
                  let _opts = {};
                  if(item.isRemove==true){
                    _opts.isRemove = true;
                  }
                  onHideCol([],_opts);
                }
              })
            }}><i className="fa fa-ban"></i></Button>
          </div>
        </InputGroup>
      </FormGroup>
    )
  }

  clearCurrentFilter=()=>{
    const {onChangeFilter,screenCode} = this.props;
    this.setState({
      value: {},
      currentFilter: {},
    },()=>{
      HLocalStorage.ConfigScreen.clearSavedFilter(screenCode);
      if(onChangeFilter!=null){
        onChangeFilter({});
      }
    })
  }
  clearFilterWithKeep=(listKeep)=>{
    const {onChangeFilter,screenCode} = this.props;
    console.warn("clearFilterWithKeep:",listKeep,this.state.value,this.state.currentFilter);
    let _value = this.state.value || {};
    let _currentFilter = this.state.currentFilter || {};
    Object.keys(_value).map((v,i)=>{
      if(listKeep.indexOf(v)==-1){
        delete _value[v];
        delete _currentFilter[v];
      }
    })    
    this.setState({
      value: _value,
      currentFilter: _currentFilter
    })
    // this.setState({
    //   value: {},
    //   currentFilter: {},
    // },()=>{
    //   HLocalStorage.ConfigScreen.clearSavedFilter(screenCode);
    //   if(onChangeFilter!=null){
    //     onChangeFilter({});
    //   }
    // })
  }

  render() {
    const {onChangeFilter,screenCode,noShadow,styleCard,componentBottom} = this.props;
    //bat buoc phai co options moi show filter
    if(this.state.options==null){
      return <div></div>
    }
    let _classNameCard = 's_boxshadow no-print tbfilter';
    if(noShadow==true){
      _classNameCard = 'no-print tbfilter';
    }
    return (
      <Card className={_classNameCard} style={styleCard}>
        <CardBody className="" style={{padding:'5px'}}>
          {this._renderContent()}
          {
            this.state.value!=null && Object.keys(this.state.value).length>0 &&
            <div className="tf-tclear no-print" onClick={()=>{
              this.clearCurrentFilter();                  
            }}>Clear and refresh data</div>
          }
          {
            componentBottom!=null && componentBottom
          }
        </CardBody>
      </Card>
    )
  }
}

TableFilter.Type = {
  search: 'search',
  select: 'select',
  select2: 'select2',
  select2color: 'select2color',
  select2date: 'select2date',
  select2content: 'select2content',
  checkbox: 'checkbox',
  datefromto: 'datefromto',
  datefrom: 'datefrom',
  dateto: 'dateto',
  custom: 'custom',
  hidecol: 'hidecol',
  text: 'text',
  switch: 'switch',
}
export default TableFilter;