import React from "react";
import { Button, HC } from '../_import'
class MyUISearchGeneral extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      textSearch: ''
    }    
  }
  _onSearch=()=>{
    const {fnList} = this.props;
    HC.rf(fnList,'onSearch',[this.state.textSearch]);
  }
  render() {
    const {openFullSearch} = this.props;
    return (
      <div className={openFullSearch ? "full-search open" : "full-search"}>
        <div>
          <input autoFocus={openFullSearch==true?true:false} style={{ width: "100%" }} value={this.state.textSearch} onChange={(ev)=>{
            this.setState({
              textSearch: ev.currentTarget.value
            })
          }} onKeyPress={(ev)=>{
            if(ev.key === 'Enter'){
              this._onSearch();
            }
          }}/>
          <button onClick={this._onSearch}>Search</button>
        </div>
      </div>
    )
  }
}
export default MyUISearchGeneral;
