import ConfigFilter from '../configs/ConfigFilter'
import { getObj, saveObj } from '../helpers/LocalStorage'
import { mergeObjIfExist } from '../helpers/Utils'
import HFilter from '../helpers/Filter'
import MyTableFilterControlHelpers from 'src/components/MyTableFilterControl/Helpers'
import OneHelpers from './OneHelpers'
interface IOneTableFilterControl {}

interface IOneTableFilterControlOpts {
  type?: string
  title?: string
  optionKey?: string
  fieldName?: string
  needConvert?: boolean
  fieldQuery?: string
  more?: any
  screenCode?: string
  onePage?: IOnePage
}

interface IOnePage {
  getApiController: () => string | any
  getQueryShared: () => any
  getSourceList: () => any
  onChangeFilter: (oneFilterControl: OneTableFilterControl) => any
}

class OneTableFilterControl implements IOneTableFilterControl {
  type?: string
  title?: string
  optionKey?: string
  fieldName?: string
  needConvert?: boolean
  fieldQuery?: string
  more: any = {}
  screenCode?: string = ''
  currentValue?: any
  currentFilter?: any
  prefixKey?: 'pf_filter'
  configFilter?: ConfigFilter
  _fromComponent: any
  _onePage?: IOnePage
  _fnGetFilterOutFromValue?: any
  _typeWrap?: any
  constructor(opts: IOneTableFilterControlOpts) {
    mergeObjIfExist(this, opts)
    if (this.type && HFilter.Types[this.type]) {
      this.configFilter = HFilter.Types[this.type]
    }
    if (opts.onePage) {
      this._onePage = opts.onePage
    }
  }
  setFromComponent(component: any) {
    if (component) {
      this._fromComponent = component
    }
  }
  setOnePage(onePage: IOnePage) {
    this._onePage = onePage
  }
  setFnGetFilterOutFromValue(fn: any) {
    this._fnGetFilterOutFromValue = fn
  }
  getFilterOutFromValue(value: any) {
    if (this._fnGetFilterOutFromValue) {
      return this._fnGetFilterOutFromValue(value, this)
    }
  }
  updateCurrentValue(currentvalue: any) {
    this.currentValue = currentvalue
    this.updateCurrentFilter(this.getFilterOutFromValue(this.currentValue))
  }
  updateCurrentFilter(currentFilter: any) {
    this.currentFilter = currentFilter
    if (this._onePage) {
      this._onePage.onChangeFilter(this)
    }
  }
  getKeyLocal() {
    if (this.screenCode) {
      return `${this.screenCode}_${this.prefixKey}`
    }
    return null
  }
  getType() {
    return this.type
  }
  getTypeWrap() {
    return this._typeWrap
  }
  clear() {
    this.currentValue = null
    this.currentFilter = null
    this.forceUpdate()
  }
  parseFilterFromValue(value?: any) {
    if (value) {
    } else {
      this.currentFilter = null
    }
  }
  loadLocal() {
    let _key = this.getKeyLocal()
    if (_key) {
      let _obj = getObj(_key, {})
      if (_obj) {
        if (_obj.value) {
          this.currentValue = _obj.value
        }
        if (_obj.filter) {
          this.currentValue = _obj.filter
        }
      }
    }
  }
  saveLocal() {
    let _key = this.getKeyLocal()
    if (_key) {
      let _value = this.currentValue
      let _filter = this.currentFilter
      saveObj(_key, {
        value: _value,
        type: this.type,
        filter: _filter
      })
    }
  }
  //UI
  getSourceData() {
    let _sourceList = {}
    if (this._onePage) {
      _sourceList = this._onePage.getSourceList()
    }
    if (_sourceList && this.optionKey && _sourceList[this.optionKey]) {
      return _sourceList[this.optionKey]
    }
    return []
  }
  getUIFn() {
    return OneHelpers.getUIFn(
      this.getType(),
      MyTableFilterControlHelpers,
      'MyFilterControl'
    )
  }
  getUIWrapFn() {
    return OneHelpers.getUIWrapFn(
      this.getTypeWrap(),
      MyTableFilterControlHelpers,
      'MyFilterControlWrap'
    )
  }
  getUI(props: any) {
    let _myFn = this.getUIFn()
    return _myFn({ oneFilterControl: this, ...props })
  }
  render(props: any) {
    return OneHelpers.render(props, this, 'oneFilterControl')
    // const ComponentWrap = this.getMyFilterControlWrapFn()
    // const Component = this.getMyFilterControlFn()
    // if (ComponentWrap) {
    //   return (
    //     <ComponentWrap oneFilterControl={this} {...props}>
    //       <Component oneFilterControl={this} {...props} />
    //     </ComponentWrap>
    //   )
    // }
    // return <Component oneFilterControl={this} {...props} />
  }
  forceUpdate() {
    if (this._fromComponent && this._fromComponent.forceUpdate) {
      this._fromComponent.forceUpdate()
    }
  }
  // static createFromExtraColumn(
  //   extraData: any,
  //   fieldName: string,
  //   options: any
  // ) {
  //   console.warn('createFromExtraColumn:', extraData, fieldName, options)
  //   if (extraData && extraData.Columns && extraData.Columns[fieldName]) {
  //     let _configCol = extraData.Columns[fieldName]
  //   }
  //   return null
  // }
  static getAllFilterOutFromListFilterControls(arr?: OneTableFilterControl[]) {
    let _filterouts = {}
    if (arr && arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        let _one = arr[i]
        let _fieldName = _one.fieldName
        if (_fieldName && _one.currentFilter) {
          _filterouts[_fieldName] = _one.currentFilter
        }
      }
    }
    return _filterouts
  }
  static clearAllFilter(arr?: OneTableFilterControl[]) {
    if (arr && arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        let _one = arr[i]
        _one.clear()
      }
    }
  }
}

export default OneTableFilterControl
