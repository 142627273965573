//khac voi source mobile
import React from 'react'
import MyLoadingApp from '../../../components2/MyLoading/MyLoadingApp'
import SApiAxios from '../../../services/generic'
import HUtils from '../../../helpers/Utils'
import HUI from '../../../helpers/UI'
import HText from '../../../helpers/Text'
import A from '../../../helpers/A'
import HConstant from '../../../helpers/Constant'
import HFilter from '../../../helpers/Filter';
import MyUIHelpers from '../../../components/MyUI/MyUIHelpers'

import debounce from '../../../libs/Lodash/debounce';
import ResizeObserver from '../../../libs/ResizeObserverPolyfill/ResizeObserver';

class PageExt extends React.Component {  
  constructor(props){
    super(props);
    this.state={
      response: null,
      options: null,
      list: null,
      screenConfig: null,
      dataFiltered: null,
      defaultExpand: true,
    };
    this.initPage();
    this._sharedQueryData = {};
    this._cContents = {};
    this._sharedFnList = {
      fnGetProjectId: this.getProjectId,
      fnGetParamsURL: this.getParamsURL,
      fnGetScreenCode: this.getScreenCode,
      fnGetOptions: this.getOptions,
      fnGetApiPath: this.getApiController,
      fnGetApiController: this.getApiController,
      fnGetRequestData: this.getRequestData,
      fnGetListObj: this.getListObject,
      fnGetListData: this.getListData,
      fnGetDataTable: this.getListData,
      fnGetListExtraData: this.getListExtraData,
      fnGetExtraDataTable: this.getListExtraData,
      fnGetConfigFilter: this.getConfigFilter,
      fnRequestList: this.requestList,
      fnReloadList: this.reloadList,
      fnReloadTable: this.reloadList,
      fnReloadOptions: this.fnReloadOptions,
      fnReloadOptionsAndList: this.fnReloadOptionsAndList,
      fnAddOptions: this.fnAddOptions,
      fnHeaderChangeExpand: this.fnHeaderChangeExpand,
      fnGetConfigFromScreenConfig: this.getConfigFromScreenConfig,
      fnRequestUpdate: (row, fieldName, cellValue, opts)=>{//fnUpdateCell tu version solid
        console.warn("fnRequestUpdate:",row,fieldName,cellValue);
        this.requestUpdateField({
          row: row,
          requestData:{
            ProjectId: row.ProjectId,
            Id: row.Id,
            Values: [
              {FieldName: fieldName, NewValue: cellValue}
            ]
          },
          opts: opts
        })                              
      },
      fnGetCanEditOfField: this.fnGetCanEditOfField,
      fnGetCanShowOfField: this.fnGetCanShowOfField,
      fnExtRequestDelete: this.requestDelete,//dat khac vi Category dang co fnRequestDelete khac
      fnRequestDelete: ({row,extra,customApi,cbSuccess}={})=>{
        return this.requestDelete(row,{extra,customApi,cbSuccess});
      },
      fnGetConfigPage: this.getConfigPage,
      fnGetTableConfig: (key,df)=>{
        return A.Options.getTableConfigInScreenConfigByKey(this.getOptions(),key,df);
      },
      fnRequestUpdateFields: (row, values, opts)=>{
        console.warn("fnRequestUpdates:",row,values);
        this.requestUpdateField({
          row: row,
          requestData:{
            ProjectId: row.ProjectId,
            Id: row.Id,
            Values: values
          },
          opts: opts
        })
      },
      fnForceUpdateTable: ()=>{
        this.forceUpdate();
      },
      fntdGetExpandLevel: ()=>{
        return HConstant.rf(this.props.fnList,HConstant.UIFnTableDetail.fntdGetExpandLevel,[],{disabledWarn:true}) || 0;
      },
      fnGetParentData: ()=>{
        return HConstant.rf(this.props.fnList,"fnGetParentData",[],{});
      },
      fnUpdateComponentContents: this.updateComponentContents,
      fnGetSharedQueryData: this.getSharedQueryData,
      fnMyPage: (name, args=[])=>{
        return HUtils.runFnList(this,name,this,args);
      }
    }
  }
  initPage=()=>{
    this.state.pageLoading = true;
    this.initAutoReload();
    if(this.fnGetIsDetailPage()!==true){
      this.setTitle();
    }    
  }
  componentWillUnmount(){
    if(this._ro){
      this._ro.disconnect();
    }
  }
  initAutoReload=()=>{
    const configPage = this.getConfigPage();
    // console.warn("initAutoReload",configPage)
    let _autoReload = this.getConfigFromPageConfig(HConstant.ConfigPage.autoReload);
    if(_autoReload!=null && typeof _autoReload=='number' && _autoReload>1000){
      if(this.isReloadting!==true){
        setTimeout(()=>{
          console.warn("reeeeeloaaddddd");
          this.isReloadting = false;
          this.reloadAutoReload();
          this.initAutoReload();
        },_autoReload)
      }
      this.isReloadting = true;      
    }
  } 
  reloadAutoReload=()=>{
    window.location.reload();
  }
  reloadContents=({justActive}={})=>{
    if(justActive){
      console.warn("reloadContents:",this._cContents);
      let _keys = Object.keys(this._cContents);
      for(let k of _keys){
        let _c = this._cContents[k];
        if(_c && _c.reload){
          _c.reload();
        }
      }
    }
  }
  initObserver=()=>{
    this._ro = new ResizeObserver((entries) => {
      // console.warn("rorororo",entries);
      // for (let entry of entries) {
      //   const cr = entry.contentRect;
      //   console.log('Element:', entry.target);
      //   console.log(`Element size: ${cr.width}px x ${cr.height}px`);
      //   console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);
      // }
      this.onWindowResized();
    });
    this._ro.observe(document.body);
  }
  onWindowResized = () => {
    if (this._debouncedResize) this._debouncedResize.cancel();
    this._debouncedResize = debounce(() => {
      this.resizeWindow()
    },200);
    this._debouncedResize();
  };
  resizeWindow=()=>{
    this.forceUpdate();
  }
  getParamsURL=()=>{
    const {match} = this.props;
    return HUtils.Obj.get(match,'params');
  }
  setTitle=(value)=>{   
    const {setTitle}  = this.props;
    let _newTitle = this.getTitle(value);    
    // console.warn("setTitle:",value,this.getConfigPage());
    if(_newTitle){
      if(setTitle){
        setTitle(_newTitle)
      }
      if(HConstant.getConfig(this.getConfigFromScreenConfig(HConstant.ScreenConfig.Page),HConstant.PageConfig._key,HConstant.PageConfig.isNotChangePageTitle)===false){
        window.document.title = `${_newTitle} - ${HConstant.gc(HConstant.AppConfig.title)}`
      } 
    }
  }
  getTitle=(value)=>{
    const configPage = this.getConfigPage();
    const _header = this.getConfigFromScreenConfig(HConstant.ScreenConfig.Header);
    const _page = this.getConfigFromScreenConfig(HConstant.ScreenConfig.Page);
    const _titleHeader = HUtils.Obj.get(_header,HConstant.HeaderConfig.title);
    const _titlePage = HUtils.Obj.get(_page,HConstant.PageConfig.title);
    let _newTitle = "";
    if(value){
      _newTitle = value;
    }
    else if(_titleHeader){
      _newTitle = _titleHeader
    }
    else if(_titlePage){
      _newTitle = _titlePage
    }
    else if(configPage && configPage.Title){
      _newTitle = configPage.Title
    }
    return _newTitle;
  }
  fnGetIsDetailPage=()=>{
    const {fnList} = this.props;
    return HUtils.runFnList(fnList,"fntdIsDetailPage",this,[],{disabledWarn:true});
  }
  getOptions=()=>{
    return this.state.options;
  }
  getListObject=()=>{
    return this.state.list;
  }
  getListData=()=>{
    const list = this.getListObject();
    if(list && list.Data){
      return  list.Data;
    }
    return null;
  }
  getListExtraData=()=>{
    const list = this.getListObject();
    if(list && list.ExtraData){
      return  list.ExtraData;
    }
    return null;
  }
  getFromResponseByKey=(key)=>{
    const {response} = this.state;
    if(response && response[key]){
      return response[key]
    }
    return null;
  }
  getDataFiltered=()=>{
    return this.state.dataFiltered
  }
  getExtraOfColumn=(field)=>{
    const _extraData = this.getListExtraData();
    const _columns = _extraData && _extraData.Columns;
    if(_columns && _columns[field]){
      return _columns[field]
    }
    return null;
  }
  getExtraOfFieldByKey=(fieldName, key)=>{
    const _extraData = this.getListExtraData();
    const _columns = _extraData && _extraData.Columns;
    if(_columns && _columns[fieldName]){
      const _fieldConfig = _columns[fieldName];
      if(_fieldConfig[key]!=null){
        return _fieldConfig[key]
      }
    }
    return null;
  }
  getPageContents=()=>{
    const {configPage} = this.props;
    const contents = HUtils.Obj.get(configPage,"Contents");
    if(contents && contents.length>0){
      for(let i=0;i<contents.length;i++){
        let _item = contents[i];
        if(!_item.hasOwnProperty("ConfigObj")){
          try {
            if(typeof _item.Config=="string"){
              _item.ConfigObj = JSON.parse(_item.Config);
            }
            else{
              _item.ConfigObj = _item.Config;
            }          
          } catch (error) {
            console.warn("parse config error:",_item);
          }
        }
      }
      return contents;
    }
    return [];    
  }
  fnGetCanEditOfField=(fieldName)=>{
    let _extraValue = this.getExtraOfFieldByKey(fieldName,'CanEdit');
    if(_extraValue==true){
      return true;
    }
    return false;
  }
  fnGetCanShowOfField=(fieldName)=>{
    let _extraValue = this.getExtraOfFieldByKey(fieldName,'CanShow');
    if(_extraValue==true){
      return true;
    }
    return false;
  }
  // getScreenConfigs=(options)=>{
  //   let _screenConfigs = {};
  //   try {
  //     if(options && options.Configs && options.Configs.ScreenConfig){
  //       _screenConfigs = JSON.parse(options.Configs.ScreenConfig)
  //     }
  //   } catch (error) {
  //     console.warn('Parse JSON error:',error,options);
  //   }    
  //   return _screenConfigs
  // }
  getScreenCode=()=>{
    const configPage = this.getConfigPage();
    let _screenCode;
    if(configPage.ScreenCode){
      _screenCode = configPage.ScreenCode;
    }
    return _screenCode;
  }
  getProjectId=({row}={})=>{//dung cho trang su dung projectId
    const {match,fnList} = this.props;
    let _projectId;
    if(match){
      _projectId = HUtils.Obj.get(match,'params.projectId');
    }
    if(fnList && fnList[HConstant.UIFnPage.fnGetCurrentProjectId]){
      _projectId = fnList[HConstant.UIFnPage.fnGetCurrentProjectId]();
    }
    // console.warn("getProjectId:",this.props,_projectId);
    if(_projectId==null){
      let _queryShared = HConstant.runMyPageFnList(fnList,this,HConstant.MyPage.FnList.getSharedQueryData,[]);
      if(_queryShared && _queryShared.ProjectId){
        _projectId = _queryShared.ProjectId;
      }
    }
    //get projectid tu row
    if(_projectId==null && row && row.ProjectId){
      _projectId = row.ProjectId;
    }
    return _projectId;
  }
  getConfigPage=()=>{
    const configPage = this.props.configPage || {};
    return configPage;
  }
  getConfigPageByKey=(key,df)=>{
    const configPage = this.getConfigPage();
    return HUtils.Obj.get(configPage,key,df);
  }
  getSharedQueryData=()=>{
    const {fnList,match} = this.props;
    let _queryFromMatch = {};
    if(match && match.params){
      _queryFromMatch = match.params; 
    }
    
    let _query = Object.assign({},this._sharedQueryData,_queryFromMatch,
      fnList!=null?HConstant.runMyPageFnList(fnList,this,HConstant.MyPage.FnList.getSharedQueryData):{});
    // console.warn("shared:",this,_query);
    return _query;
  }
  updateSharedQueryData=(obj)=>{
    this._sharedQueryData = Object.assign(this._sharedQueryData,obj);
    // console.warn("updateSharedQueryData:",this._sharedQueryData,this.getMyKey());
  }
  updateComponentContents=({key,component}={})=>{
    if(key && component){
      this._cContents[key] = component;
    }
  }
  getMyKey=()=>{
    const {configPage} = this.props;
    // console.warn("getMyKey:",configPage);
    if(configPage){
      let _key = configPage.Id || configPage.ScreenCode;
      if(_key){
        return _key;
      }
    }
    return 'nokey';
  }
  updateMyComponentToParent=()=>{
    const {fnList} = this.props;
    if(fnList && fnList.fnUpdateComponentContents){
      fnList.fnUpdateComponentContents({
        key: this.getMyKey(),
        component: this
      })
    }
  }
  getDefaultScreenConfig=()=>{
    const {defaultConfig} = this.props;
    let _default = {
      Filter:{},
      Header:{},
    }
    if(defaultConfig){
      HUtils.Obj.addDataToObj(_default,defaultConfig);
    }
    if(this._getMyDefaultScreenConfig){
      HUtils.Obj.addDataToObj(_default,this._getMyDefaultScreenConfig());
    }
    return _default;
  }
  getScreenConfig=()=>{
    return A.Options.getScreenConfig(this.getOptions());
  }
  getConfigFromScreenConfig=(key,{sc}={})=>{//Config from ScreenConfig: from Options: Table, Header, Filter ...
    const screenConfig = sc || this.getScreenConfig();    
    let config = {}
    if(screenConfig && screenConfig[key]){
      config = screenConfig[key]
    }
    // console.warn("getConfigFromScreenConfig:",key,config,sc,screenConfig && screenConfig.hasOwnProperty(key));
    return config;
  }
  getConfigFromCustomConfig=(key)=>{//Config from customConfig
    const {customConfig} = this.props;
    let config = {}
    if(customConfig && customConfig[key]){
      config = customConfig[key]
    }
    return config;
  }
  getConfigFromPageConfig=(key,df)=>{//Config from PageConfig: Config,ConfigObj
    const configPage = this.getConfigPage();
    let _config = configPage.ConfigObj;
    if(_config && _config[key]!=null){
      return _config[key];
    }
    return df;
  }
  getApiController=({customApi}={})=>{
    const configPage = this.getConfigPage();
    return customApi || configPage.APIName;
  }
  getConfigFilter=()=>{
    let _configFilter = {
      show: true, 
      showSearch: true,       //Show search
      searchNoSymbol: false,  //Search ko dau
      require4ShowData: false,//Ko nho dung lam gi
      listInList: [],
      //new
      className: "",          //className MyFitler
      classNameItem: "col-12 col-sm-6 col-md-4 col-lg-3", //className item Myfilter
    };
    let _configFromOptions = this.getConfigFromScreenConfig('Filter');
    if(_configFromOptions){
      _configFilter = Object.assign(_configFilter,_configFromOptions);
    }
    return _configFilter;
  }
  getConfigHeader=()=>{
    let _configHeader = {
      show: true, 
    };
    let _configFromOptions = this.getConfigFromScreenConfig('Header');
    if(_configFromOptions){
      _configHeader = Object.assign(_configHeader,_configFromOptions);
    }
    return _configHeader;
  }
  getConfigExpander=({sc}={})=>{
    let _configExpander = {
    };
    let _configFromOptions = this.getConfigFromScreenConfig('Expander',{sc});
    if(_configFromOptions){
      _configExpander = Object.assign(_configExpander,_configFromOptions);
    }
    // console.warn("getConfigExpander:",_configExpander,sc);
    return _configExpander;
  }
  fnHeaderChangeExpand=(stateExpand)=>{
    this.setState({
      defaultExpand: stateExpand
    })
  }
  hideLoadingApp=()=>{ MyLoadingApp.Helpers.hide(); }
  showLoadingApp=(opts)=>{ MyLoadingApp.Helpers.show(opts); }
  renderPageLoading=()=>{
    return (
      <div style={{padding:"2rem",textAlign:"center",color:"#222958",borderRadius:"4px"}}>
        <div><i className="fa fa-spinner fa-pulse"/></div>
        {HText.get("msg_loading")}
      </div>
    )
  } 
  renderPageError=()=>{
    if(this.state.errorList || this.state.errorOptions){
      let _msg = HUtils.getInObj(this.state.errorOptions,"Msg") || HUtils.getInObj(this.state.errorList,"Msg") || HText.get("msg_error")
      return (
        <div style={{padding:"2rem",textAlign:"center",color:"#f86c6b",background:"#ffeeed",borderRadius:"4px"}}>{_msg}</div>
      )
    }
    return (<div></div>)
  } 
  getScreenId=()=>{
    const configPage = this.getConfigPage();
    console.warn("getScreenId:",configPage)
    if(configPage){
      return configPage.Id;
    }
    return "NoId";
  }
  getRequestData=(obj,{fromApiName}={})=>{
    const configPage = this.getConfigPage();
    let _requestData = {
      ScreenGUID: this.getScreenId()
    };
    console.warn("_requestData:",_requestData)
    let _projectId = this.getProjectId();
    if(_projectId){
      _requestData.ProjectId = _projectId;
    }

    let _sharedQueryData = this.getSharedQueryData();
    // console.warn("_sharedQueryData:",_sharedQueryData)
    if(_sharedQueryData){
      _requestData = {..._requestData, ..._sharedQueryData}
    }

    if(configPage && configPage.RequestData){
      _requestData = {..._requestData,...configPage.RequestData}
    }
    //chuyen match.params qua getSharedQueryData
    // if(match && match.params){
    //   _requestData = {..._requestData, ...match.params}
    // }
    if(obj){
      _requestData = {..._requestData, ...obj}
    }

    if(this._moreRequestDataInMyPage){
      _requestData = {..._requestData, ...this._moreRequestDataInMyPage({fromApiName})}
    }
    
    console.warn("requestData:",this,_requestData,obj)
    return _requestData;
  } 
  requestOptions=({customApi,notShowLoadingApp,customQuery,cbSuccess}={})=>{
    this.showLoadingApp({notShowLoadingApp});
    this.setState({
      isLoadingOptions: true,
      errorOptions: null,
    },()=>{
      SApiAxios.generic({
        request:{
          method: 'POST',
          path: this.getApiController({customApi}),
          name: HConstant.ApiName.Options
        },
        data:this.getRequestData(customQuery,{fromApiName:HConstant.ApiName.Options}),
        successCallBack:(response)=>{
          // console.warn('res:',response);  
          A.Options.parseOptions(response.Data,{customConfig: this.props.customConfig, defaultConfig: this.getDefaultScreenConfig()});
          let _screenConfigs = A.Options.getScreenConfig(response.Data);
          this.setState({
            pageLoading: false,
            options: response.Data,
            screenConfig: _screenConfigs,
            defaultExpand: this.getConfigExpander({sc:_screenConfigs}).defaultExpand,
            isLoadingOptions: false,
            errorOptions: null,
          },()=>{    
            const pageConfigs = this.getConfigFromScreenConfig('Page');
            // console.warn("Page:",pageConfigs);
            if(pageConfigs && pageConfigs.updateUIWhenResize==true){
              this.initObserver();
            }        
            if(cbSuccess){
              cbSuccess(response);
            }
            this.hideLoadingApp({notShowLoadingApp});
          })
        },
        errorCallBack:(error,response)=>{
          console.warn('err:',error,response);
          this.setState({
            isLoadingOptions: false,
            errorOptions: error || response,
          },()=>{
            this.hideLoadingApp({notShowLoadingApp});
          })          
        }
      })
    });    
  }
  fnReloadOptions=()=>{
    this.requestOptions({})
  }
  fnReloadOptionsAndList=()=>{
    this.requestOptions({
      cbSuccess: ()=>{
        this.requestList()
      }
    })
  }
  reloadList=()=>{
    this.requestList();
  }
  requestList=({customApi,customApiName,notShowLoadingApp,customQuery,cbSuccess}={})=>{
    this.showLoadingApp({notShowLoadingApp});
    this.setState({
      isLoadingList: true,
      errorList: null,
    },()=>{
      SApiAxios.generic({
        request:{
          method: 'POST',
          path: this.getApiController({customApi}),
          name: customApiName || HConstant.ApiName.List,
        },
        data: this.getRequestData(customQuery,{fromApiName:HConstant.ApiName.List}),
        successCallBack:(response)=>{
          // console.warn('res:',response);
          this.setState({
            response: response,
            pageLoading: false,
            list: response.Data,
            isLoadingList: false,
            msgInUI: response.MsgShowInUI,
            errorList: null,
            lastGetData: new Date().getTime()
          },()=>{
            this.hideLoadingApp({notShowLoadingApp});
            if(cbSuccess){
              cbSuccess(response);
            }            
          })
        },
        errorCallBack:(error,response)=>{
          console.warn('err:',error,response);
          this.setState({
            isLoadingList: false,
            errorList: error || response,
          },()=>{
            this.hideLoadingApp({notShowLoadingApp});
          })          
        }
      })
    })    
  }
  requestUpdateField=({customApi,row,requestData,optsUpdateData,opts,cbSuccess,cbError}={})=>{
    const {component,hideMsgUpdate,fnUpdateUILoading,fnUpdateUIError,fnSuccessCallBack,fnErrorCallBack} = opts || {};
    const _tableConfig = this.getConfigFromScreenConfig('Table')
    // this.showLoadingApp();
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: this.getApiController({customApi}),
        name: 'UpdateFields'
      },
      data:requestData,
      successCallBack:(response)=>{
        console.warn('res:',response, opts);
        if(cbSuccess){
          cbSuccess(response);
        }
        HUtils.updateDataWithNewData(row,response.Data,{...optsUpdateData});

        if(fnUpdateUILoading){
          fnUpdateUILoading(false,{...opts});
        }
        if(fnUpdateUIError){
          fnUpdateUIError(null,{...opts});
        }
        if(fnSuccessCallBack){
          fnSuccessCallBack(response,{...opts});
        }

        let _isHideMsgUpdateConfig = _tableConfig && _tableConfig.hideMsgUpdate || false;
        if(_isHideMsgUpdateConfig ==true || (hideMsgUpdate == true)){
          console.log("Update success");
        }
        else{
          let _msg = response.Msg;
          _msg && HUI.Toast.showSuccess(_msg);
        }        


        this.forceUpdate();
        //
        // this.setState({
        //   pageLoading: false,
        //   list: response.Data
        // })
        // this.hideLoadingApp();
      },
      errorCallBack:(error,response)=>{
        console.warn('err:',error,response);
        if(cbError){
          cbError()
        }
        else if(fnErrorCallBack){
          fnErrorCallBack(error || response.Msg)
        }
        // this.hideLoadingApp();
      }
    })
  }
  requestAddNew=({requestData,customApi,cbSuccess})=>{
    console.warn('this._cTable', this._cTable)
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: this.getApiController({customApi}),
        name: 'Add'
      },
      data: this.getRequestData(requestData),
      successCallBack:(response)=>{
        console.warn('res:',response);
        cbSuccess && cbSuccess(response);
        this.requestList({notShowLoadingApp: true});
        this.forceUpdate();
        //
        // this.setState({
        //   pageLoading: false,
        //   list: response.Data
        // })
        // this.hideLoadingApp();
      },
      errorCallBack:(error,response)=>{
        console.warn('err:',error,response);
        // this.hideLoadingApp();
      }
    })
  }
  requestAddMulti=(obj,{customApi}={})=>{
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: this.getApiController({customApi}),
        name: 'AddMulti'
      },
      data: this.getRequestData(obj),
      successCallBack:(response)=>{
        console.warn('res:',response);
        this.requestList({notShowLoadingApp: true});
        this.forceUpdate();
      },
      errorCallBack:(error,response)=>{
        console.warn('err:',error,response);
      }
    })
  }
  requestDetail=({method,customApi,detailId,customApiName}={})=>{
    this.setState({
      isLoadingDetail: true,
      errorDetail: null,
    },()=>{
      SApiAxios.generic({
        request:{
          method: method || 'GET',
          path: this.getApiController({customApi}),
          name: customApiName || `Detail/${detailId}`
        },
        data: this.getRequestData(),
        successCallBack:(response)=>{
          console.warn('res:',response);
          this.setState({
            dataDetail: response.Data,
            isLoadingDetail: false,
            errorDetail: null
          })
        },
        errorCallBack:(error,response)=>{
          console.warn('err:',error,response);
          this.setState({
            isLoadingDetail: false,
            errorDetail: null
          })
        }
      })
    })    
  }
  requestDelete=(row,{customApi,cbSuccess}={})=>{
    let _query = {Id: row.Id};
    let _pathName = `Delete/${row.Id}`;
    
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: this.getApiController({customApi}),
        name: _pathName
      },
      data: _query,
      successCallBack:(response)=>{
        HUI.Toast.showSuccess(response.Msg);
        let data = this.state.list;
        if(data && data.Data){
          for(let i=0;i<data.Data.length;i++){
            if(data.Data[i].Id===row.Id){
              data.Data[i]._isDeleted = true;
              data.Data.splice(i,1);
              break;
            }
          }
        }
        this.setState({
          list: data
        },()=>{
          if(cbSuccess){
            cbSuccess(response);
          }
        })
        this.forceUpdate()
      },
      errorCallBack:(error,response)=>{
        HUI.Toast.showError(error)
      }
    })    
  }
  requestAddNewLink=(Id, fieldName, link, name, {cb}={})=>{
    const configPage = this.getConfigPage();
    SApiAxios.generic({
      request:{
          method: 'POST',
          path: 'ProjectPhoto',
          name: 'AddFiles',
      },
      data:{
          ProjectId: '',
          ScreenCode: configPage && configPage.ScreenCode,
          Id: Id,
          FieldName: fieldName,
          Files: [{
            Url: link,
            Name: name,
          }],
      },
      successCallBack:(response)=>{
          if(response.Msg){
           HUI.Toast.showSuccess(response.Msg);
          }
          if(cb){
            cb(response)
          }
        },
        errorCallBack:(error,response)=>{
          if(cb){
            cb(response)
          }
      }
    })
  }
  requestGetPhoto=(Id, fieldName, {cbSuccess, cbError})=>{
    const configPage = this.getConfigPage();
    SApiAxios.generic({
      request:{
          method: 'POST',
          type: /*name_prefix*/ 'project_photo_' + 'get_photos',
          url: '/api/v1/ProjectPhoto/GetPhotos',
      },
      data:{
          ProjectId: '',
          ScreenCode: configPage && configPage.ScreenCode,
          Id: Id,
          FieldName: fieldName,
      },
      successCallBack:(response)=>{
          if(response.Msg){
           HUI.Toast.showSuccess(response.Msg);
          }
          if(cbSuccess){
            cbSuccess(response)
          }
        },
        errorCallBack:(error,response)=>{
          if(cbError){
            cbError(response)
          }
      }
    })
  }
  requestSaveAvatar=(Id, fieldName, Item, {cbSuccess})=>{
    const configPage = this.getConfigPage();
    SApiAxios.generic({
      request:{
          method: 'POST',
          type: /*name_prefix*/ 'project_photo_' + 'save_avatar',
          url: '/api/v1/ProjectPhoto/SaveAvatar',
        },
      data:{
          ProjectId: '',
          ScreenCode: configPage && configPage.ScreenCode,
          Id: Id,
          FieldName: fieldName,
          FileUrl: Item.FileUrl,
        },
      successCallBack:(response)=>{
          if(response.Msg){
           HUI.Toast.showSuccess(response.Msg);
          }
          if(cbSuccess){
            cbSuccess(response)
          }
        },
        errorCallBack:(error,response)=>{
      }
    })
  }
  requestUpdateMaster=(row)=>{
    SApiAxios.generic({
      request:{
        method: 'POST',
        path: this.getApiController(),
        name: `GetOneItemModel/${row.Id}`
      },
      data: {},
      successCallBack:(response)=>{
        let _data = this.state.data;
        if(row!=null && response.Data){
          if(row.Id==response.Data.Id){
            let _keys = Object.keys(row);
            if(_keys && _keys.length>0){
              for(let k of _keys){
                if(k && k.startsWith("_")==false){
                  row[k] = response.Data[k];
                }                
              }
            }
          }
        }
        this.setState({
          list: _data,
        })
      },
      errorCallBack:(error,response)=>{
      }
    });
  }
  uploadMultifiles=(Id, fieldName, Files, {cbSuccess, cbError})=>{
    const configPage = this.getConfigPage();
    SApiAxios.generic({
      request:{
        method: 'UPLOAD',
        type: /*name_prefix*/ 'project_photo_' + 'upload',
        url: '/api/v1/ProjectPhoto/Upload',
      },
      files: Files,
      customHeader: {
        ProjectId: '',
        ScreenCode: configPage && configPage.ScreenCode,
        Id: Id,
        FieldName: fieldName,
      },
      successCallBack:(response)=>{
        if(response.Msg){
          HUI.Toast.showSuccess(response.Msg);
        }
        if(cbSuccess){
          cbSuccess(response)
        }
      },
      errorCallBack:(error,response)=>{
        if(cbError){
          cbError(error || response.Msg);
        }
      }
    })
  }
  fnAddOptions=({source,value,text})=>{
    const {options} = this.state;
    // console.warn('fnAddOptions:',source,value,text,this.options);
    if(options){
      options[source].push({
        Value: value,
        Text: text
      });
    }    
    console.warn('fnAddOptions:',options);
  }
  _onSearchText=(searchText,dataList)=>{
    let _arrFiltered = null;
    let _dataList = dataList || this.state.dataList;
    if (searchText && _dataList){
      _arrFiltered = _dataList.filter((v,i)=>{
        return HFilter.hasTextInObj(v,searchText,this._optsSearch);
      });
    }
    if(dataList){
      return _arrFiltered;
    }
  }
  render(){
    if(this.state.pageLoading){
      return this.renderPageLoading();
    }    
    if(this.renderPage){
      return this.renderPage();
    }
    return (
      <div>PageExt</div>
    )
  } 
}

export default PageExt