import React from 'react'
import PropTypes from 'prop-types'
import {CellHelper} from '../Helpers';
import classnames from 'classnames';
import { OneTableCell, MyUI } from "@macashipo/mlib"
/**
 * Cell Type: readonly_html.
 *
 * @class readonly_html
 * @static
 * @namespace readonly_html
 * @memberof CellType
 * 
 */
class CellTypeReadonlyHTML extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };
    this._oneTableCell = new OneTableCell(props)
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof CellType.readonly_html
   * @param {Object} [style] style của Component
   * @param {Object} [className] class của Component
   * 
  */
  more() {
  }

  render() {
    const {cell,row,extra,index} = this.props;
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    let _className = classnames("idiv-ro has-wrap",CellHelper.getExtraClassName({extra:extra,row:row,more:_more}));
    let _isHasExpand = CellHelper.getIsHasExpandInCell({cell,row,extra,more:_more});
    let _onClick = this._oneTableCell.getOnClick();
    let _configHtml = this._oneTableCell.getConfigHtml()
    if(_isHasExpand){
      return (
        <div className={CellHelper.getClassNameContainer({...this.props,more:_more})} style={CellHelper.getStyleContainer({...this.props,more:_more})}>
          {
            CellHelper.renderExpandArea({extra,row,more:_more,component:this})
          }
          <MyUI type="html" 
            className={_className}
            style={_style}
            html={_configHtml.template}
            data={{ ...row, ..._configHtml.customData }}
            onClick={_onClick}
          />
        </div>
      )
    }
    
    return (
      <MyUI type="html" 
        className={_className}
        style={_style}
        html={_configHtml.template}
        data={{ ...row, ..._configHtml.customData }}
      />
    )
  }
}

export default CellTypeReadonlyHTML;