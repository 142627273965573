import Global from './Global';/** Khac */
import ApiGeneric from '../services/generic';/** Khac */

import Config from './Config';/** Khac */
import Text from './Text';
import Options from './Options';
import Paste from './Paste';
import Utils from './Utils';

import MyTableBootstrap from '../components/MyTable/MyTableBootstrap';
import MyDialog from '../components/MyDialog/index';
import MyForm from '../components/MyForm/MyForm';
import MyModal from '../components/MyModal/MyModal';

const HUpdate = {
  // History: Global.History,
  // Toast: Global.Toast,
  // Alert: Global.Alert,
  // LoadingScreen: Global.LoadingScreen,
  Options: Options,
  MyTableBootstrap: MyTableBootstrap,
  Text: Text,
};
function HH(){
  if(Global && HUpdate.History==null){
    HUpdate.History= Global.History;
    HUpdate.Toast= Global.Toast;
    HUpdate.Alert= Global.Alert;
    HUpdate.LoadingScreen= Global.LoadingScreen;
    HUpdate.changePageTitle= Global.Helper.changePageTitle;
  }
  if(HUpdate.ApiGeneric==null && ApiGeneric){
    HUpdate.ApiGeneric= ApiGeneric;
  }
  if(HUpdate.MyDialog==null && MyDialog){
    HUpdate.MyDialog = MyDialog;
  }
  if(HUpdate.Config==null && Config){
    HUpdate.Config = Config;
  }
  if(HUpdate.Text==null && Text){
    HUpdate.Text = Text;
  }
  if(HUpdate.MyModal==null && MyModal){
    HUpdate.MyModal = MyModal;
  }
  if(HUpdate.MyForm==null && MyForm){
    HUpdate.MyForm = MyForm;
  }
  if(HUpdate.MyTableBootstrap==null && MyTableBootstrap){
    HUpdate.MyTableBootstrap = MyTableBootstrap;
  }
  if(HUpdate.Options==null && Options){
    HUpdate.Options = Options;
  }
  if(HUpdate.Paste==null && Paste){
    HUpdate.Paste = Paste;
  }
  if(HUpdate.Utils==null && Utils){
    HUpdate.Utils = Utils;
  }
  return HUpdate;
}

window.HH = HH;
export default HH;