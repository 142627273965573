import classnames from 'classnames';
import React, { Component } from 'react';
import MyFormHelpers from './MyFormHelpers';
import MyFormTypes from './MyFormTypes';
import MyForm_FormTypes from './MyForm_FormTypes';
import './style.css';

class MyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledForm: false,
    };
    this._currentValues = {}; 
    this._currentFiles = {};
    this._currentFieldNames = {};
    this._relateControl = {};
    this._cControl = {};
    this._invalid = {};    
  }

  componentDidMount(){
  }

  getValues=()=>{
    // console.warn('getValues:',this.props,this._currentValues);
    return this._currentValues;
  }

  getValuesAllFields=()=>{
    let _values = this.getValuesWithDefaultValues();
    let _keys = Object.keys(this._currentFieldNames);
    let _newValues = Object.keys(_values).reduce((newObj,c)=>{
      if(_keys.indexOf(c)!== -1){
        newObj[c]=_values[c];
      }
      return newObj;
    },{});
    return _newValues;
  }
  _getCurrentValues=()=>{//Ham cua Form_v1
    return this._currentValues;
  }

  getValuesWithDefaultValues=()=>{
    const {config} = this.props;
    let _values = {};
    // console.warn("getValuesWithDefaultValues:",this.props,config);
    let _defaultValues = Object.assign({},config.DefaultValues||{});
    // let _fieldNames = Object.keys(this._currentFieldNames);
    // if(_fieldNames && _fieldNames.length>0){
    //   for(let k of _fieldNames){
    //     if(_defaultValues[k]){
    //       _values[k] = _defaultValues[k];
    //     }
    //   }
    // }
    _values = Object.assign({},_defaultValues,this._currentValues);
    return _values;
  }

  getIsSubmiting=()=>{
    const {isSubmiting} = this.props;
    return isSubmiting;
  }

  getValidOfField=(fieldName)=>{
    // console.warn("getValidOfField:",fieldName,this._invalid);
    if(this._invalid && this._invalid[fieldName]){
      return this._invalid[fieldName].valid;
    }
    return true;
  }

  getFilesNeedToUpload=()=>{
    return this._currentFiles;
  }

  turnOnDisabled=(isDisabled)=>{
    let _isDisabledForm = true;
    if(isDisabled!=null){
      _isDisabledForm = isDisabled;
    }
    this.setState({
      disabledForm: _isDisabledForm
    })
  }

  forceUpdateControl=({fieldName}={})=>{
    // console.warn(`forceUpdateControl1:`,fieldName,this._cControl);
    if(fieldName && this._cControl && this._cControl[fieldName] && this._cControl[fieldName].forceUpdate){
      // console.warn(`forceUpdateControl2:`,fieldName);
      this._cControl[fieldName].forceUpdate();
    }
  }

  forceUpdateControlRelates=({fieldName}={})=>{    
    // console.warn(`forceUpdateControlRelates1:`,fieldName,this._relateControl);
    if(fieldName && this._relateControl && this._relateControl[fieldName]){
      let _relates = this._relateControl[fieldName];
      // console.warn(`forceUpdateControlRelates2:`,fieldName,_relates);
      if(_relates && Array.isArray(_relates) && _relates.length>0){
        for(let k of _relates){
          if(k=="Form"){//update form khi trong relative co Form
            this.forceUpdate();
          }
          this.forceUpdateControl({fieldName: k});
        }
      }
    }
  }

  isValid=()=>{
    if(this._invalid){
      let _keys = Object.keys(this._invalid);
      for(let k of _keys){
        if(this._invalid[k].valid===false){
          return false;
        }
      }
    }    
    return true;
  }

  checkValid=()=>{
    let _fieldNames = Object.keys(this._cControl);
    let _allValues = this.getValuesWithDefaultValues();
    let _isValid = true;
    for(let k of _fieldNames){
      if(this._cControl[k] && this._cControl[k].checkValid!=null){
        let _validObj = this._cControl[k].checkValid(_allValues[k]);
        this._onUpdateInvalid({
          fieldName:k,
          invalidObj: _validObj,
          fromControl: this._cControl[k],
        })
        if(_validObj.valid===false){          
          _isValid = false;
        }
      }
    }
    return _isValid;
  }

  _onUpdateRelateControl=({fieldName,relates} = {})=>{
    // console.warn(`_onUpdateRelateControl:`,fieldName,relates);
    if(relates){
      this._relateControl[fieldName] = relates;
    }  
  }
  _onUpdateRefControl=({fieldName,control} = {})=>{
    // console.warn(`_onUpdateRefControl:`,fieldName,control);
    if(control){
      this._cControl[fieldName] = control;
    }  
  }

  _onUpdateValueControl=({fieldName,value} = {})=>{
    let _control = this._cControl[fieldName];
    if(_control && _control.updateValueFromForm){
      _control.updateValueFromForm({value:value});
    }
  }

  _onUpdateFilesToUpload=({files,fieldName} = {})=>{
    if(files){
      this._currentFiles[fieldName] = files;
    }  
  }

  _onUpdateValueOfField=({fieldName,newValue})=>{
    const {isGroup,groupOpts} = this.props;
    if(isGroup){
      const {onUpdateValueOfField} = groupOpts;
      onUpdateValueOfField({fieldName,newValue});
    }
    else{
      this._currentValues[fieldName] = newValue;
    }    
    // console.warn("_onUpdateValueOfField:",fieldName,newValue,this._currentValues,this.props);
  }
  _onUpdateValuesAndField=({obj})=>{
    const {isGroup,groupOpts} = this.props;
    if(isGroup){
      const {onUpdateValuesAndField} = groupOpts;
      onUpdateValuesAndField({obj});
    }
    else{
      if(obj){
        this._currentValues = Object.assign(this._currentValues,obj);
      }
    }    
  }

  _onUpdateInvalid=({fieldName,invalidObj,fromControl}={})=>{
    // console.warn("_onUpdateInvalid:",fieldName,invalidObj);
    if(invalidObj){
      if(invalidObj.valid==false){
        this._invalid[fieldName] = invalidObj;
        if(fromControl){
          fromControl.forceUpdate();
        }
      }
      else{
        if(this._invalid[fieldName]!=null){
          delete this._invalid[fieldName];
          if(fromControl){
            fromControl.forceUpdate();
          }
        }
      }
    }
  }

  _onSubmit=(opts={})=>{
    const {onSubmit} = this.props;
    if(onSubmit){
      onSubmit({
        values: this.getValues(),
        valuesWithDefaultValues: this.getValuesWithDefaultValues(),
        currentField: opts.currentField,
        currentValue: opts.currentValue,
      });
    }
  }

  _renderControl=(item,opts={})=>{
    let _props = MyFormHelpers.getPropsControl(item,opts);
    let _canShow = MyFormHelpers.getCanShowCell({item,opts,props:_props,cForm:this});
    let _styleCell = {};
    if(_canShow===false){
      _styleCell.display = 'none';
      // return null;
    }
    if(item.FieldName){//Luu tru danh sach fieldname
      this._currentFieldNames[item.FieldName] = true;
    }
    
    let _uiHint = null;    
    let _hint = MyFormHelpers.getHintOfCell({item,opts:{},props:_props,df:null});    
    if(_hint){ _uiHint = ( <div className='mfc-hint'>{_props.hint}</div> ) }

    let _uiSubLabel = null;
    let _subLabel = MyFormHelpers.getSubLabelOfCell({item,opts:{},props:_props,df:null});
    if(_subLabel){ _uiSubLabel = ( <div className='mfc-sublabel'>{_props.sublabel}</div> ) }

    return (
      <div className={classnames(`mf-control mfc-wrap-${item.Type}`,MyFormHelpers.getClassOfCell({item,opts,props:_props}),MyFormHelpers.getClassDirectionOfCell({item,opts}))} 
        key={opts.key} 
        style={{...MyFormHelpers.getStyleOfCell(item,opts),..._styleCell}}>
        {/* {
          item.Label &&
          <div className='mfc-left' style={MyFormHelpers.getStyleOfLabel(item,opts)}>
            {item.Label}
          </div>
        }   */}
        {
          item.Label &&
          <div className='mfc-left' style={MyFormHelpers.getStyleOfLabel(item,opts)}>
            {item.Label}
            {
              item.Required==true && 
              <span style={{color:'red',display:'contents'}}>(*)</span>
            }
            { _uiSubLabel }
          </div>
        }  
        <div className='mfc-right'>    
        {
          MyFormTypes.Types[item.Type]!=null?
          MyFormTypes.Types[item.Type](item,opts,{
            fnList: opts.fnList
          })
          :
          <div>{`Type ${item.Type} not exist!`}</div>
        }    
        { _uiHint }
        </div>                
      </div>
    )
  }

  _getOptionOfKey=(key)=>{
    const {getOptionOfKey} = this.props;
    if(getOptionOfKey){
      return getOptionOfKey(key);
    }    
  }
  _getOptions=()=>{
    const {getOptions} = this.props;
    if(getOptions){
      return getOptions();
    }
  }
  _forceUpdateForm=()=>{
    this.forceUpdate();
  }

  _renderAllControl=()=>{
    const {config,groupOpts, fnList} = this.props;
    let _ui = [];
    let _fnList = {
      ...fnList,
      getOptionOfKey: this._getOptionOfKey,
      getOptions: this._getOptions,
      onUpdateValueOfField: this._onUpdateValueOfField,
      onUpdateValuesAndField: this._onUpdateValuesAndField,
      onUpdateValueControl: this._onUpdateValueControl,
      onUpdateInvalid: this._onUpdateInvalid,
      onUpdateFilesToUpload: this._onUpdateFilesToUpload,
      onUpdateRelateControl: this._onUpdateRelateControl,
      onUpdateRefControl: this._onUpdateRefControl,
      onSubmit: this._onSubmit,
      forceUpdateControlRelates: this.forceUpdateControlRelates,
      forceUpdateForm: this._forceUpdateForm,
      getValues: this.getValues,
      getValuesWithDefaultValues: this.getValuesWithDefaultValues,
      getIsSubmiting: this.getIsSubmiting,
      getValidOfField: this.getValidOfField,
    }
    if(groupOpts){
      _fnList.getOptionOfKey = groupOpts.getOptionOfKey;
      _fnList.getOptions = groupOpts.getOptions;
      _fnList.onUpdateValueOfField = groupOpts.onUpdateValueOfField;
      _fnList.onUpdateValuesAndField = groupOpts.onUpdateValuesAndField;
      _fnList.onUpdateValueControl = groupOpts.onUpdateValueControl;
      _fnList.onUpdateInvalid = groupOpts.onUpdateInvalid;
      _fnList.onUpdateFilesToUpload = groupOpts.onUpdateFilesToUpload;
      _fnList.onUpdateRelateControl = groupOpts.onUpdateRelateControl;
      _fnList.onUpdateRefControl = groupOpts.onUpdateRefControl;
      _fnList.forceUpdateControlRelates = groupOpts.forceUpdateControlRelates;
      _fnList.forceUpdateForm = groupOpts.forceUpdateForm;
      _fnList.getValues = groupOpts.getValues;
      _fnList.getValuesWithDefaultValues = groupOpts.getValuesWithDefaultValues;
    }

    if (config.Type){
      let _config = MyForm_FormTypes.Types[config.Type];
      let _defaultValues = Object.assign({},_config.DefaultValues||{},config.DefaultValues||{});
      for(let i in _config.Data){
        let _item = _config.Data[i];
        if(_item.Data){
          _ui.push(
            this._renderGroup(_item,{
              key: i,
              configForm: _config,
              defaultValues: _defaultValues,
              fnList:_fnList,
              ..._fnList
            })
          )
        }
        else if(_item.Type){
          _ui.push(
            this._renderControl(_item,{
              key: i,
              configForm: _config,
              defaultValues: _defaultValues,
              fnList:_fnList,
              ..._fnList,
            })
          )
        }
        
      }
    }else{
      if(config && config.Data){
        for(let i in config.Data){
          let _item = config.Data[i];
          if(_item.Data){
            _ui.push(
              this._renderGroup(_item,{
                key: i,
                configForm: config,
                defaultValues: config.DefaultValues,
                fnList:_fnList,
                ..._fnList,
              })
            )
          }
          else if(_item.Type){
            _ui.push(
              this._renderControl(_item,{
                key: i,
                configForm: config,
                defaultValues: config.DefaultValues,
                fnList:_fnList,
                ..._fnList,
              })
            )
          }
          
        }
      }
    }
    
    return _ui;
  }

  _renderGroup=(group,opts)=>{
    if(group && group.Data){
      let _styles = MyFormHelpers.getStyleOfGroup(group,opts)      
      let _config = {
        DefaultValues: opts.defaultValues,
        Styles: _styles,
        Title: group.Title,
        Data: group.Data,
        More: group.More
      };
      return (
        <MyForm key={opts.key} config={_config} isGroup={true} getOptionOfKey={opts.getOptionOfKey} groupOpts={opts}/>
      )
    }
    return null;
  }

  render() {
    const {config,hideTitle,renderFooter} = this.props;
    if(config==null){
      return (<div></div>)
    }
    return (
      <div className={classnames('myform',MyFormHelpers.getClassOfForm({configForm:config}))} style={MyFormHelpers.getStyleOfForm(config)}>
        <div className='mf-container mf-d-col' style={MyFormHelpers.getStyleOfFormContainer(config)}>
          {
            this._renderAllControl()
          }
        </div>
        {
          renderFooter!=null && renderFooter()
        }
        {
          this.state.disabledForm === true &&
          <div style={{"position":"absolute","top":"0","right":"0","left":"0","bottom":"0","backgroundColor":"rgb(247, 247, 247, 0.4)"}}>
          </div>
        }
      </div>
    )
  }
}

export default MyForm;