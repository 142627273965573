import React, { Component } from 'react';
import { CFade } from '../components/MyCore';
import { Route } from 'react-router-dom';
import MyPageFromConfig from '../components/MyPage/MyPageFromConfig';

const Router = {
  listRoutesClient: [],
  listRoutesServer: [],
  listRoutesServerPublic: [],
  listRoutesSimulate: [],
  listRoutesSimulatePublic: [],
  listRoutesPrivate: [],
  HConfig: null,
  initRouter(HConfig){
    Router.HConfig = HConfig;
    console.warn("initRouter:",HConfig);
    if(HConfig.configBuild && HConfig.configBuild.Routers){
      Router.addRoutesToList(HConfig.configBuild.Routers.routes,Router.listRoutesClient)
      Router.addRoutesToList(HConfig.configBuild.Routers.routes_simulate,Router.listRoutesSimulate)
      Router.addRoutesToList(HConfig.configBuild.Routers.routes_simulate_public,Router.listRoutesSimulatePublic)
      Router.addRoutesToList(HConfig.configBuild.Routers.routes_private,Router.listRoutesPrivate)   
    }
    else if(HConfig.Routers){
      Router.addRoutesToList(HConfig.Routers.routes,Router.listRoutesClient)
      Router.addRoutesToList(HConfig.Routers.routes_simulate,Router.listRoutesSimulate)
      Router.addRoutesToList(HConfig.Routers.routes_simulate_public,Router.listRoutesSimulatePublic)
      Router.addRoutesToList(HConfig.Routers.routes_private,Router.listRoutesPrivate)    
    }
    console.warn("initRouter 2:",Router,HConfig.Routers);
  },
  renderRouter(){
    let _ui = [];    
    if(Router.listRoutesSimulatePublic){
      for(let i=0;i<Router.listRoutesSimulatePublic.length;i++){
        let _item = Router.listRoutesSimulatePublic[i];
        _ui.push(
          <Route key={`simulatepublic_${i}`} 
            exact={true} 
            path={_item.UIUrl} name={_item.Title} render={(props) => {
              return (
                <CFade>
                  <MyPageFromConfig configPage={_item} {...props}/>
                </CFade>                
              )
            }} 
          />
        )
      }
    }
    if(Router.listRoutesClient){
      for(let i=0;i<Router.listRoutesClient.length;i++){
        let _item = Router.listRoutesClient[i];
        _ui.push(
          <Route key={i} 
            exact={_item.exact!=null?_item.exact:false} 
            path={_item.path} name={_item.name} render={props => <_item.component {...props}/>} 
          />
        )
      }
    }
    
    console.warn("renderRouter:",Router,_ui);
    return _ui;
  },
  renderRouterInLayout(){
    let _ui = [];
    if(Router.listRoutesPrivate){
      for(let i=0;i<Router.listRoutesPrivate.length;i++){
        let _item = Router.listRoutesPrivate[i];
        _ui.push(
          <Route
            key={`private_${i}`}
            path={_item.path} exact={_item.exact} name={_item.name}
            render={props => (
              <CFade>
                <_item.component {...props} />
              </CFade>
            )} />
        )
      }
    }
    if(Router.listRoutesSimulate){
      for(let i=0;i<Router.listRoutesSimulate.length;i++){
        let _item = Router.listRoutesSimulate[i];        
        _ui.push(
          <Route key={`simulate_${i}`} 
            exact={true} 
            path={_item.UIUrl} name={_item.Title} render={(props) => {
              return (
                <CFade>
                  <MyPageFromConfig configPage={_item} {...props}/>
                </CFade>                
              )
            }} 
          />
        )
      }
    }
    if(Router.listRoutesServerPublic){
      for(let i=0;i<Router.listRoutesServerPublic.length;i++){
        let _item = Router.listRoutesServerPublic[i];
        _ui.push(
          <Route key={`serverpublic_${i}`} 
            exact={true} 
            path={_item.UIUrl} name={_item.Title} render={(props) => {
              return (
                <CFade>
                  <MyPageFromConfig configPage={_item} {...props} isRoot={true} levelFromRoot={0}/>
                </CFade>                
              )
            }} 
          />
        )
      }
    }
    if(Router.listRoutesServer){
      for(let i=0;i<Router.listRoutesServer.length;i++){
        let _item = Router.listRoutesServer[i];
        _ui.push(
          <Route key={`server_${i}`} 
            exact={true} 
            path={_item.UIUrl} name={_item.Title} render={(props) => {
              return (
                <CFade>
                  <MyPageFromConfig configPage={_item} {...props} isRoot={true} levelFromRoot={0}/>
                </CFade>                
              )
            }} 
          />
        )
      }
    }
    console.warn("renderRouterInLayout:",_ui)
    return _ui;
  },
  addRoutesToList(routes = [],list = []){
    for(let i=0;i<routes.length;i++){
      list.push(routes[i])
    }
  },
  addPublicRoutes(routes){
    console.warn("add addPublicRoutes:",routes,Router.listRoutesSimulatePublic)
    Router.addRoutesToList(routes,Router.listRoutesSimulatePublic)
  },
  addPrivateRoutes(routes){
    Router.addRoutesToList(routes,Router.listRoutesPrivate)
  }
}
if(window._M){
  window._M.HRouter = Router;
}
export default Router;