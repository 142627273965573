import React from 'react';
/**
 * ChartTypes - Kiểu định dạng cho một loại chart
 * **Tiến độ thực hiện**
 * - [ ] gg_column **Tested**
 * - [ ] gg_bar
 * - [ ] gg_pie **Tested**
 * - [ ] gg_orgchart 
 * 
 * 
 * @class ChartTypes
 * @static
 * @namespace ChartTypes
 * 
 */

function ComponentLoading(props) {
  return (
    <div></div>
  )
}

const Default = React.lazy(() => import('./Types/_default'));
const Test = React.lazy(() => import('./Types/_test'));
const GG_Bar = React.lazy(() => import('./Types/gg_bar')); //done
const GG_Pie = React.lazy(() => import('./Types/gg_pie')); //done
const GG_OrgChart = React.lazy(() => import('./Types/gg_orgchart'));
const GG_OrgChart_Old = React.lazy(() => import('./Types/gg_orgchart_old'));

const GG_Column = React.lazy(() => import('./Types/gg_column')); //done
const GG_Combo = React.lazy(() => import('./Types/gg_combo')); //done
const GG_Line = React.lazy(() => import('./Types/gg_line')); //done
const GG_Area = React.lazy(() => import('./Types/gg_area')); //done
const GG_TimeLine = React.lazy(() => import('./Types/gg_timeline')); //done
const GG_Normal_Distribution = React.lazy(() => import('./Types/gg_normal_distribution'));
const GG_Combo_Normal_Distribution = React.lazy(() => import('./Types/gg_combo_normal_distribution'));
const GG_Bubble = React.lazy(() => import('./Types/gg_bubble')); //done

const MilestoneV2 = React.lazy(() => import('./Types/milestone_v2')); 

const MyChartTypes = {
  addTypes(key,fnUI){
    if(MyChartTypes.UI[key]==null){
      MyChartTypes.UI[key] = fnUI;
    }
    else{
      console.warn('addTypes exist:',key)
    }
  },
  UI:{
    _default:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <Default {...props}/>
        </React.Suspense>
      )
    },
    _test:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <Test {...props}/>
        </React.Suspense>
      )
    },
    gg_column:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_Column {...props}/>
        </React.Suspense>
      )
    },
    gg_bar:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_Bar {...props}/>
        </React.Suspense>
      )
    },
    gg_pie:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_Pie {...props}/>
        </React.Suspense>
      )
    },
    gg_orgchart:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_OrgChart {...props}/>
        </React.Suspense>
      )
    },
    gg_orgchart_old:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_OrgChart_Old {...props}/>
        </React.Suspense>
      )
    },
    gg_combo:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_Combo {...props}/>
        </React.Suspense>
      )
    },
    gg_line:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_Line {...props}/>
        </React.Suspense>
      )
    },
    gg_area:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_Area {...props}/>
        </React.Suspense>
      )
    },
    gg_timeline:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_TimeLine {...props}/>
        </React.Suspense>
      )
    },
    milestone_v2:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <MilestoneV2 {...props}/>
        </React.Suspense>
      )
    },
    gg_normal_distribution:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_Normal_Distribution {...props}/>
        </React.Suspense>
      )
    },
    gg_combo_normal_distribution:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_Combo_Normal_Distribution {...props}/>
        </React.Suspense>
      )
    },
    gg_bubble:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <GG_Bubble {...props}/>
        </React.Suspense>
      )
    },
  }
}
window.MyChartTypes = MyChartTypes;
export default MyChartTypes;