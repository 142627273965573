import ConfigSolid from "../solid/_config"
import * as Routers from './_routes';
import AppMyPage from "./components/MyPage"
import AppMyUI from "./components/MyUI"
import AppMyCard from "./components/MyCard"
import AppMyLib from "./components/MyLib"
import AppMyLayout from './components/MyLayout'
import HRouter from '../../helpers/Route'
import HStore from '../../helpers/StoreRedux'
import HC from '../../helpers/Constant'
import HUtils from '../../helpers/Utils'
import HLocalStorage from '../../helpers/LocalStorage'
import HGlobalSetting from '../../helpers/GlobalSetting'
import HelperV3 from './_helpers'
import * as CustomStore from './_store';
import MyPageInitDev from '../../components/MyPage/Types/init_dev'
import AuthorizationSecond from './class/AuthorizationSecond'
import ModuleQuiz from "../../modules/quiz"
import ModuleV1Page from "../../modules/v1_page"
import ModuleV3Page from "../../modules/v3_page"
import ModuleSetting from "../../modules/setting"
import ModuleSetting2FA from "../../modules/setting2fa"

//test mytable v2
// import MyTableCellTypesV2 from '../../v1/components/CellType/TypesV2/_initV2'
import "./stylesv3.css"
const ConfigBuild = {
  ...ConfigSolid,
  Routers: Routers,
  ConfigApp:{
    ...ConfigSolid.ConfigApp,
    initClassBody: "header-hidden footer-hidden container-full theme-solid",
    home_url: '/v3',
    dateFormatRDP: 'dd-MMMyy',//khac voi v1, vi version su dung format khac,
    baseUrl: 'https://soliddevapi.allianceitsc.com',
    title: "Solid App",
  },
  modules: [
    ModuleV1Page,
    ModuleV3Page,
    ModuleQuiz,
    ModuleSetting,
    ModuleSetting2FA,
  ],
  fnList:{
    ...ConfigSolid.fnList,    
    afterInit(HConfig){
      ConfigBuild.HConfig = HConfig;
      console.warn("RunAfterInit:",Routers);            
      /** GA */
      // HGA.initialize();

      HRouter.initRouter(HConfig);
      if(HGlobalSetting.PublicLinks && HUtils.isArray(HGlobalSetting.PublicLinks)){
        HRouter.listRoutesServerPublic = HGlobalSetting.PublicLinks;
      }
      HC.initConstant(HConfig);
      //init MyPage
      AppMyPage.init();
      AppMyUI.init();
      AppMyCard.init();
      AppMyLayout.init();
      AppMyLib.init();
      HelperV3.initLocalRoute();
      MyPageInitDev.initDevPage();

      HStore.initStore(HConfig,CustomStore);

      //
      let _objAS = AuthorizationSecond.loadFromLocalStorage(HConfig);
      if(_objAS){
        AuthorizationSecond.renderAreaNotify(_objAS);
      }
    },
    afterRequestFirstSetting(response){
      // console.warn("afterRequestFirstSetting:",response);
      let _publicApiScreenList = HUtils.Obj.get(response,"Data.PublicApiScreenList");
      if(_publicApiScreenList && HUtils.isArray(_publicApiScreenList)){
        HRouter.listRoutesServerPublic = _publicApiScreenList;
      }
    },
    convertConfigPage(configPage){
      HelperV3.convertConfigPage(configPage);
    },
    beforeRequest({options,header}={}){
      //Authorization-Second
      // console.warn("beforeRequest:",options,header,HC.gc(AuthorizationSecond.KConfigApp))
      if(HC.gc(AuthorizationSecond.KConfigApp)){
        let _objAS = HC.gc(AuthorizationSecond.KConfigApp);
        if(_objAS && _objAS.getToken && header){
          header[AuthorizationSecond.KHeader] = _objAS.getToken();
        }      
      }
    }
  }
}

export default ConfigBuild;