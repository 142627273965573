import React, { Component } from 'react';
import {
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader
} from '../../_importComponent';
import MyPageFromConfig from '../../components2/MyPage/MyPageFromConfig';
import HConstant from '../../helpers/Constant';
import HText from '../../helpers/Text';
import HUtils from '../../helpers/Utils';
import SApiGeneric from '../../services/generic';
import MyUI from '../MyUI/MyUI';
import MyModalTypes from './MyModalTypes';
import {LaddaButton, EXPAND_RIGHT} from '../../_importLibs';

class MyModal extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      isShow: false,
      error: null,
      component: null,
      componentFunction: null,
      componentClass: null,
      componentType: null,
      isShowLoading: false,
      config: {},
      dataObj:{},
    };

    this._fnFromModal = {
      // fnClose: ()=>{//an di ko xai nua -> fnmHide
      //   this.hide();
      // },
      fnmShowLoading: ()=>{
        this.setState({
          showLoading: true
        })
      },
      fnmHideLoading: ()=>{
        this.setState({
          showLoading: false
        })
      },
      fnmHide: this.hide,
      fnmGetConfigByKeyInMore: (key)=>{
        // console.warn("fnmGetConfigByKeyInMore:",key,this.state);
        return HUtils.Obj.get(this.state.config,`configMore.${key}`);
      },
      fnmGetModalData: ()=>{
        // console.warn("fnmGetModalData:",this.state);
        return HUtils.Obj.get(this.state.config,`modalData`);
      },
    }

    if(this.props.type){
      MyModal.Helpers.components[this.props.type] = this;
    }
    else{
      MyModal.Helper.component = this;
    }  
  }

  componentDidMount(){      
  }

  getIsShowLoading=()=>{
    return this.state.isShowLoading;
  }

  show=(component,opts)=>{
    this.setState({
      isShow:true,
      component,
      config: opts,
    })
  }

  showFromComponent=(component,opts)=>{
    console.warn("show:::",component)
    this.setState({
      isShow:true,
      component,
      config: opts,
    })
  }

  showFromFunction=(componentFunction,opts)=>{
    this.setState({
      isShow:true,
      componentFunction,
      config: opts,
    })
  }

  showFromClass=(componentClass,opts)=>{
    this.setState({
      isShow:true,
      componentClass,
      config: opts,
    })
  }

  showFromType=(componentType,opts)=>{
    this.setState({
      isShow:true,
      componentType,
      config: opts,
    })
  }

  //New version
  clearTimeOutHide=()=>{
    if(this._timerHide){
      clearTimeout(this._timerHide);
    }
  }
  showFromMyUI=(type,props,opts)=>{
    // console.warn('type', props)
    this.clearTimeOutHide();
    this.setState({
      isShow: true,
      component: (
        <MyUI type={type} 
          ref={(r)=>{
            this._cContent = r;
          }} {...props} 
          fnList={{
            ...props.fnList,
            ...this._fnFromModal,
            fnFomModal: this._fnFromModal
          }}
          fnFomModal={this._fnFromModal}
          inMyModal={this}
        />
      ),
      config: opts,
    })
  }
  showFromMyPage=(type,props,opts)=>{
    // console.warn("showFromMyPage:",type,props,opts);
    this.clearTimeOutHide();
    this.setState({
      isShow: true,
      component: (
        <MyPageFromConfig type={type} 
          ref={(r)=>{
            this._cContent = r;
          }} {...props} 
          inMyModal={this}
          fnList={{
          ...props.fnList,
          ...this._fnFromModal,
          fnFomModal: this._fnFromModal          
        }}/>
      ),
      config: opts,
    },()=>{
      // console.warn("aaa:",this.state);
    })
  }

  updateDataObj=(dataObj,opts={})=>{
    const {notSetState} = opts;
    // console.warn("dataObj:",dataObj,opts);
    if(notSetState){
      this.state.dataObj = dataObj;
    }
    else{
      this.setState({
        dataObj: dataObj
      })
    }    
  }

  getDataObj=()=>{
    // console.warn("state:",this.state);
    return this.state.dataObj;
  }

  showLoading=()=>{
    this.setState({
      isShowLoading: true
    });
  }
  hideLoading=()=>{
    this.setState({
      isShowLoading: false
    });
  }

  hide=()=>{
    const {isShow, isShowLoading, component, config, componentFunction} = this.state;
    const {className, title, showFooter, loadingMsg, componentFooter, showSubmit, onClickSubmit, hideHeader,fnAfterClose} = config;
    this.setState({
      isShow: false
    },()=>{
      // setTimeout(()=>{
        
      // },200)
      if (fnAfterClose){  
        fnAfterClose();
      }
    })    
  }

  _onClickOK=()=>{
    if(this._onOK){
      this._onOK();
    }
  }

  _renderContent=()=>{
    const {isShow, isShowLoading, component, config, componentFunction,componentClass,componentType} = this.state;    
    // console.warn('this.state', this.state);
    let _modalProps = config.modalProps || {};
    const propsPass = {
      config,          
      // fnList:{
      //   hideModal: this.hide,         
      // },
      fnListFromModal:{
        hideModal: this.hide,
        updateDataObj: this.updateDataObj
      },
      ..._modalProps,
      inMyModal:this,
      fnList:{
        ..._modalProps.fnList,
        hideModal: this.hide, //old
        ...this._fnFromModal,
      }
    }
    if(component!=null){
      if(typeof component == 'object'){
        return component;
      }
      else if(typeof component == 'function'){
        return component(propsPass);
      }
    }
    else if(componentFunction!=null){
      return componentFunction(propsPass);
    }
    else if(componentClass!=null){
      const C = componentClass;
      return <C />
    }
    else if(componentType!=null){   
      // console.warn('props componenttype',this.props);
      if(MyModalTypes.UI[componentType]!=null){
        return MyModalTypes.UI[componentType](propsPass);
      }
      else{
        console.warn("MyModal missing type:",componentType);
      }
    }
  }

  render() {
    const {isShow, isShowLoading, component, config, componentFunction} = this.state;
    const {
      fade, modalTransition, backdropTransition, className, title, backdrop, size,//mac dinh cua Modal
      showFooter, loadingMsg, componentFooter, showSubmit, onClickSubmit, hideHeader,fnAfterClose,styleBody,styleModal,
      titleSubmit,showMiniBtnClose
    } = (config || {});
    // console.warn('MyModal render:',this.props,this.state);
    let _styleBody = {backgroundColor:'#f5f6f7'};
    if (styleBody){
      _styleBody = styleBody;
    }
    return (
      <Modal 
        fade={fade!=null?fade:true} 
        modalTransition={modalTransition||{ timeout: 100 }} 
        backdropTransition={backdropTransition || { timeout: 0 }}
        className={className||''} 
        isOpen={isShow} 
        size={size}
        onClosed={()=>{
          console.warn("onClosed");//de ko bi mat hieu ung faded
          this.setState({
            isShow:false,
            isShowLoading: false,
            component: null,
            componentFunction: null,
            componentClass: null,
            componentType: null,
            config: {},
          },()=>{        
          })
        }}
        toggle={()=>{this.hide();}} 
        backdrop={backdrop||"static"} //static: bấm ra ngoài không close modal, true: bấm ra ngoài close modal
        style={styleModal}
        >
        {
          hideHeader!=true?
            <ModalHeader toggle={()=>{this.hide();}} style={{wordBreak:'break-word'}}>            
              {title||'Modal'}
            </ModalHeader>
          :
          showMiniBtnClose!==false ?
          <Button 
            style={{position:'absolute', right:0, top:0, padding:'0px', height:'25px', width:'25px', color:'#bbafbb',zIndex:1}}
            color="link"
            onClick={()=>{
              this.hide();
            }}><i className="fa fa-close"/></Button>
          :
          <Button 
            style={{position:'absolute', display:'flex',flexDirection:'row',alignItems:'center',justifyContent:"center", right:"-10px", top:"-10px", padding:'0px', border:'1px solid white', borderRadius:'10px',height:'20px', width:'20px', color:'white',backgroundColor:"black",zIndex:1}}
            color="link"
            onClick={()=>{
              this.hide();
            }}><i className="fa fa-close"/></Button>      
        }
        <ModalBody style={_styleBody}>
          {/* {component} */}
          {/* {componentFunction!=null && componentFunction()}    */}
          {
            this._renderContent()
          }
          {
            isShowLoading ==true &&
            <div className="fm-loading">
              <i className="fa fa-spin fa-refresh" style={{marginRight: 5}}/> {loadingMsg||'Loading...'}
            </div>
          }
        </ModalBody>
        {
          componentFooter!=null? componentFooter(this) :
          (
            showFooter==true &&
            <ModalFooter>
              {
                showSubmit == true && 
                <Button color="success" 
                  disabled={isShowLoading}
                  onClick={()=>{
                    if(onClickSubmit){
                      onClickSubmit(this);
                    }
                  }}
                >{titleSubmit||HText.get('btn_submit')}</Button>
              }
              <Button color="secondary" onClick={()=>{
                this.hide();
              }}>{HText.get('btn_close')}</Button>
            </ModalFooter>
          )
        }
      </Modal>
    )
  }
}
MyModal.Helper = {
  component: null,
  currentKey: null,
  show(component,opts){
    if(MyModal.Helper.component){
      MyModal.Helper.component.show(component,opts);
      if(opts && opts.key){
        MyModal.Helper.currentKey = opts.key;
      }
    }
  },  
  showFromFunction(componentFunction,opts){
    if(MyModal.Helper.component){
      MyModal.Helper.component.showFromFunction(componentFunction,opts);
      if(opts && opts.key){
        MyModal.Helper.currentKey = opts.key;
      }
    }
  },
  showFromClass(componentClass,opts){
    if(MyModal.Helper.component){
      MyModal.Helper.component.showFromClass(componentClass,opts);
      if(opts && opts.key){
        MyModal.Helper.currentKey = opts.key;
      }
    }
  },
  showFromType(componentType,opts){
    if(MyModal.Helper.component){
      MyModal.Helper.component.showFromType(componentType,opts);
      if(opts && opts.key){
        MyModal.Helper.currentKey = opts.key;
      }
    }
  },
  hide(){
    if(MyModal.Helper.component){
      MyModal.Helper.component.hide();
      MyModal.Helper.currentKey = '';
    }
  },
  showLoading(){
    if(MyModal.Helper.component){
      MyModal.Helper.component.showLoading();
    }
  },
  hideLoading(){
    if(MyModal.Helper.component){
      MyModal.Helper.component.hideLoading();
    }
  },
  forceUpdateWithKey(key){
    // console.warn('update: ', key, MyModal.Helper.component, MyModal.Helper.currentKey);
    if(MyModal.Helper.component && key==MyModal.Helper.currentKey){
      MyModal.Helper.component.forceUpdate();
    }
  },    
}

MyModal.Helpers = {
  components: {},
  types: {
    component: "component",
    myui: "myui",
    myform: "myform",
    mypage: "mypage",
    lightbox: "lightbox",
    mydialog: "mydialog",
    fromComponent: "fromComponent"
  },
  //newVersion
  showMyUI(type,propsMyUI,opts){
    if(MyModal.Helper.component){
      MyModal.Helper.component.showFromMyUI(type,{
        ...propsMyUI,
      },{isFullContent:false,size:'xl',title:'Modal',className:'',showHeader:true,showFooter:false,...opts});
    }
  },
  showMyPage(type,propsMyPage,opts){
    if(MyModal.Helper.component){
      MyModal.Helper.component.showFromMyPage(type,{
        ...propsMyPage,
      },{isFullContent:false,size:'xl',title:'Modal',className:'',showHeader:true,showFooter:false,...opts});
    }    
  },
  showFromComponent(component,opts){
    console.warn("showFromComponent:",component,opts, MyModal.Helpers.components)
    if(MyModal.Helpers.components[MyModal.Helpers.types.fromComponent]){
      MyModal.Helpers.components[MyModal.Helpers.types.fromComponent].showFromComponent(component,opts);
    }
  },
  checkShowModal({canShowModal,configApiCheckShowModal,apiRequest,apiQuery,row,fnList,fromComponent,cb}={}){
    if(canShowModal==true){
      if(configApiCheckShowModal && apiRequest){
        fromComponent && fromComponent.setState({
          isLoading: true
        },()=>{
          SApiGeneric.generic({
            request: apiRequest,
            data: apiQuery,
            successCallBack:(response)=>{
              let _data = response.Data;
              if(_data && _data.Config && _data.Config.IsShow==true){
                cb && cb({
                  canShow: true,
                  config: _data.Config
                })
              }
              else{
                let _resultModel = HUtils.Obj.get(response,"Data.Config.ResultModel");
                if(_resultModel && row){//update model vao row
                  HUtils.updateDataWithNewData(row,_resultModel,{fieldId:'Id'});
                  HUtils.runFnList(fnList,HConstant.UIFnPage.fnForceUpdateTable,this,[]);//forceupdate Table
                }
                cb && cb({
                  canShow: false,
                  response: response
                })
              }    
              fromComponent && fromComponent.setState({
                isLoading: false
              });                     
            },
            errorCallBack:(error,response)=>{
              fromComponent && fromComponent.setState({
                isLoading: false
              });
              cb && cb({
                canShow: false
              })
            }
          })
        });      
      }
      else{
        cb && cb({
          canShow: true
        })
      }
    }
    else{
      cb && cb({
        canShow: false
      })
    }    
  }
}

MyModal.FooterSave = (modal,onSubmit) => {
  return (
    <ModalFooter>
      <LaddaButton
        className="btn btn-success btn-ladda"
        loading={modal.getIsShowLoading()}
        onClick={()=>{if(onSubmit){onSubmit(modal)};}}
        data-color="green"
        data-style={EXPAND_RIGHT}
      >
        {HText.get('btn_save')}
      </LaddaButton>
      {
        modal.state.isShowLoading == false &&
        <Button color="secondary" onClick={()=>{modal.hide()}}>{HText.get('btn_close')}</Button>
      }
      
    </ModalFooter>
  )
}

MyModal.MyModalTypes = MyModalTypes;
export default MyModal;
