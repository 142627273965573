import React from 'react'
import CSuppense from '../MyCore/CSuspense'
import HUtils from '../../helpers/Utils'

// const MLRater = React.lazy(() => import('./Types/rater'));
const MLCode_mirror = React.lazy(() => import('./Types/code_mirror'));
const MLReactquill = React.lazy(() => import('./Types/reactquill'));
const MLReactmuticarousel = React.lazy(() => import('./Types/reactmuticarousel'));
const MLReactdraggable = React.lazy(() => import('./Types/reactdraggable'));
const MLReactslick = React.lazy(() => import('./Types/reactslick'));
const MLChromepicker = React.lazy(() => import('./Types/chromepicker'));
const MLDaterangepicker = React.lazy(() => import('./Types/daterangepicker'));
const MLReactdatepicker = React.lazy(() => import('./Types/reactdatepicker'));
const MLSelect2 = React.lazy(() => import('./Types/select2'));

const MyLibTypes = {
  addType(key,fnUI){
    if(MyLibTypes.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
      MyLibTypes.Types[key]=fnUI;
    }
  },
  Types:{   
    // rater: (props)=>{ return CSuppense(props,MLRater) },
    code_mirror: (props)=>{ return CSuppense(props,MLCode_mirror) },
    reactquill: (props)=>{ return CSuppense(props,MLReactquill) },
    reactmuticarousel: (props)=>{ return CSuppense(props,MLReactmuticarousel) },
    reactdraggable: (props)=>{ return CSuppense(props,MLReactdraggable) },
    reactslick: (props)=>{ return CSuppense(props,MLReactslick) },
    chromepicker: (props)=>{ return CSuppense(props,MLChromepicker) },
    daterangepicker: (props)=>{ return CSuppense(props,MLDaterangepicker) },
    reactdatepicker: (props)=>{ return CSuppense(props,MLReactdatepicker) },
    select2: (props)=>{ return CSuppense(props,MLSelect2) },
  }
}

export default MyLibTypes;