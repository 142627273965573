import SelectColor from './views/Components/Select/SelectColor'
import {MyUI} from "@macashipo/mlib"
import MyModal from './components/MyModal/MyModal'
import MyChart from './components/MyChart/MyChart'
import MyForm from './components/MyForm/MyForm'
import MyTableFilterHub from './components/MyTableFilter/MyTableFilterHub'
import MyLib from './components2/MyLib/MyLib'
import MyCard from './components2/MyCard/MyCard'
import MyLayout from './components2/MyLayout/MyLayout'
import MyUIButton from './components2/MyUIButton/MyUIButton';
import MyIcon from './components2/MyIcon/MyIcon';
import MySVG from './components/MySVG/MySVG'
import CSuppense from './components2/MyCore/CSuspense'
import MyUIHelpers from './components/MyUI/MyUIHelpers'
import MyPageFromConfig from './components2/MyPage/MyPageFromConfig'
export {
    SelectColor,
    MyUI, MyModal,MyChart,MyForm,MyTableFilterHub,
    MyUIButton,MyLib,MyCard,MyLayout,MyIcon,MySVG,
    CSuppense,MyUIHelpers,MyPageFromConfig,
}