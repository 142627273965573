import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./styles/common.css";
import {
  Button,
  Card,
  CardHeader,
  TabContent,
  TabPane,
  PageExtV2,
  MyPageFromConfig,
  MyModal,
  HUtils,
  HC,
} from "../_import";
import AvatarV3 from "../../MyUI/Types/avatarv3";
import Tabv3_ext from "./tabv3_ext";
import V3SearchGeneral from "../../MyUI/Types/v3_search_general";
import ConfigTabV3 from "../../../model/ConfigTabV3"

class PageTab3L3 extends Tabv3_ext {
  constructor(props) {
    super(props);
    this.state.openFullSearch = false;
    // this.state.loadedTab = ["tab_0"];
  }
  componentDidMount() {
    if (this._isNeedRequestOptions()) {
      this.requestOptions({
        cbSuccess: () => {},
      });
    }
  }
  getContents = () => {
    const { configPage, fnList } = this.props;
    const configPageCalendar = HUtils.Obj.get(HC.rf(fnList,"getPageConfigExt",[]),"Calendar");
    // console.warn("configPageCalendar:",configPageCalendar)
    if (configPage && configPage.Contents) {
      let _contents = [...configPage.Contents];
      if(configPageCalendar){
        _contents.push(configPageCalendar);
      }
      this.parseContent(_contents);
      return _contents;
    } 
    return [];
  };
  _isNeedRequestOptions = () => {
    const { configPage } = this.props;
    if (configPage && configPage.Contents) {
      return false;
    }
    return true;
  };
  openModalLogin = () => {
    let _uiType = "";
    let _title = "";
    let _hideHeader = false;
    let _closeButton = true;
    if (HC.rc(HC.AppFnList.getAuthIsLogged, []) !== true) {
      _uiType = "loginv3";
      _title = "Đăng nhập";
    } else {
      _uiType = "modal_accountv3";
      _title = `Xin chào, ${HC.rc(HC.AppFnList.getCurrentUserName, [])}!`;
      _hideHeader = true;
      _closeButton = false;
    }
    MyModal.Helpers.showMyUI(
      _uiType,
      {},
      {
        title: _title,
        closeButton: _closeButton,
        showMiniBtnClose: false,
        size: "md",
        hideHeader: _hideHeader,
        backdrop: true,
        className: "modalv3-users"
      }
    );
  };
  renderTab = () => {
    const { openFullSearch } = this.state;
    const opts = {
      keyRight: "Tab_Right",
      keyIcon: "Tab_Icons"
    }
    const _leftMenu = this.getMenuByPosition("left", opts);
    const _rightMenu = this.getMenuByPosition("right", opts);
    const _calendarMenu = this.getMenuByPosition("calendar", opts);
    // console.warn("_calendarMenu:",_leftMenu,_rightMenu,_calendarMenu);
    return (
      <div id="tab_lv3" className="no-print">
        <div className="tab_lv3_wrapper">
          {_leftMenu.length > 0 && (
            <div className="tab_lv3">
              {this.renderPreLeftMenu && this.renderPreLeftMenu()}
              {_leftMenu.map((v, i) => {
                return (
                  <Button
                    key={i}
                    onClick={() => {
                      this.changeTab({ 
                        index: i, item: v,
                      });
                    }}
                    className={`btn-tab-lv3 ${
                      this.getTabIsActive({ index: i }) ? "active" : ""
                    }`}
                  >
                    {v.Title}
                  </Button>
                );
              })}
            </div>
          )}
          {
            <div className="tab_lv3">
              {_rightMenu.length > 0 &&
                _rightMenu.map((v, i) => {
                  return (
                    <Button
                      key={i + _leftMenu.length}
                      onClick={() => {
                        this.changeTab({
                          index: i + _leftMenu.length,
                          item: v,
                        });
                      }}
                      className={`btn-tab-lv3 ${
                        this.getTabIsActive({ index: i + _leftMenu.length })
                          ? "active"
                          : ""
                      }`}
                    >
                      {v.Title}
                    </Button>
                  );
                })}
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  className="btn-icon"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    this.setState({ openFullSearch: !openFullSearch });
                  }}
                >
                  <i
                    className="fa fa-search"
                    style={openFullSearch ? { color: "#c74634" } : {}}
                  ></i>
                </Button>
                <Button className="btn-icon" onClick={()=>{
                  if(_calendarMenu && _calendarMenu.length>0){
                    this.changeTab({
                      index: _leftMenu.length + _rightMenu.length,
                      item: _calendarMenu[0],
                    });
                  }                  
                }}>
                  <i className="fa fa-calendar-o"></i>
                </Button>
                <Button className="btn-icon">
                  <i className="fa fa-bell"></i>
                </Button>
                <div
                  style={{
                    background: "#c74634",
                    color: "#fff",
                    width: "25px",
                    fontSize: "12px",
                    height: "25px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    this.openModalLogin();
                  }}
                >
                  <AvatarV3 />
                </div>
              </div>
            </div>
          }
        </div>
        {this.renderFullSearch()}
      </div>
    );
  };
  renderFullSearch = () => {
    const { openFullSearch } = this.state;
    return (
      <V3SearchGeneral openFullSearch={openFullSearch} fnList={{
        onSearch: (textSearch)=>{
          this.updateSharedQueryData({
            TextSearch: textSearch
          });
          this.reloadContents({justActive:true});
        }
      }}/>
    );
  };
  renderContent = () => {
    const { configPage, fnList } = this.props;
    const contents = this.getContents();
    let _ui = [];
    console.warn("renderContent",contents);
    if(ConfigTabV3.checkUrlWithContent(ConfigTabV3.getParamWithLevelTab(this.getCurrentLevelTabIndex()),contents)==false){
      return (
        <MyPageFromConfig 
          key="pagenotfound"
          configPage={{
            Id: "notfound",
            UIType: "pagenotfound"
          }}
        />
      )
    }
    let _fnList = {
      ...this._sharedFnList,
      getParentLevelTabIndex: this.getCurrentLevelTabIndex,
      getParentConfig: () => {
        return configPage;
      },
      getIsHasProject: ()=>{
        if(this.getHasProject){
          return this.getHasProject();
        }
      },
      fnGetCurrentProject: () => {
        return this.getCurrentProject();
      },
    };
    return (
      <div className="content_lv3">
        <TabContent
          activeTab={this.getTabActive({ })}
          style={{ border: "0px", background: "transparent" }}
        >
          {contents.map((v, i) => {
            let _tabId = this.getTabId({
              index: i
            });
            let _isLoaded = this.getTabIsLoaded({ index: i });
            // console.warn("render tablev3:", _tabId, _isLoaded, this.state);
            if (_isLoaded) {
              if(_tabId==this.getTabActive({})){
                ConfigTabV3.setTabByLev(3,v.Id)
              }
              return (
                <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
                  <MyPageFromConfig configPage={v} fnList={_fnList} />
                </TabPane>
              );
            } else {
              // console.warn("_isLoaded:",_isLoaded,_tabId,i,v,this.props,this.state);
              return (
                <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
                  <div>........</div>
                </TabPane>
              );
            }
          })}
        </TabContent>
      </div>
    );
  };
  render() {
    return (
      <div className="app">
        {this.renderTab()}
        {this.renderContent()}
      </div>
    );
  }
}
export default PageTab3L3;
