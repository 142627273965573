import React from 'react'
import {
  Input,
} from '../../../_importComponent';
import classnames from 'classnames';
import PropTypes from 'prop-types'
import {CellHelper} from '../Helpers';

/**
 * Cell Type: number.
 *
 * @class number
 * @static
 * @namespace number
 * @memberof CellType
 * 
 * @example 
 * Url: 
 * 
 */
class Z extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    const {cell,row,extra} = this.props;
    this.state={
        value:'',
    };
    if (cell){
      this.state.value = cell
    }
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof CellType.text
   * @param {Object} [style] style của Component
   * @param {Object} [className] class của Component
   * @param {String} [placeHolder] placeHolder của Input
   * @param {Boolean} isNegative co ho tro so am hay khong, mac dinh la co
   *  
  */
  more() {
  }

  componentWillReceiveProps(nextProps){
    let _needUpdate = CellHelper.isNeedUpdateValueAfterWillReceiveProps(this.props,nextProps,"");
    if(_needUpdate.needUpdate==true){
      this.setState({
        value: _needUpdate.value
      })
    }
  }

  render() {
    const {cell,row,extra} = this.props;
    let _more = CellHelper.getMoreInType({extra:extra,row:row});
    let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    let _className = classnames("idiv-ro has-wrap celltype-number",CellHelper.getExtraClassName({extra:extra,row:row,more:_more}));
    let _fnList = CellHelper.getFnList({extra,row});
    let _patern = /[^(\d-)]/ig;
    if(_more.isNegative===false){
      _patern = /[^(\d)]/ig;
    }
    let _styleInput = Object.assign({height:'100%'},_style);
    let _placeHolder = _more.placeHolder;
    let _disabled = !CellHelper.getCanEdit({extra,row});
    let _input = _more.input || {};
    return (
      <div>
        <Input 
          className={_className}
          style={_styleInput}
          type='number'
          value={this.state.value} 
          placeholder = {_placeHolder}
          disabled={_disabled}
          onBlur={(ev) => {
            // this._onChange(ev, this.state.value);
            CellHelper.callRequestUpdate({extra:extra,row:row,fieldName:extra.fieldName,newValue:this.state.value});
          }}
          onChange={ (ev) => { 
            let _newValue = ev.currentTarget.value.replace(_patern, '');
            this.setState(
              { 
                value: _newValue
              });
            }}
            {..._input}>
        </Input>
      </div>
    )
  }
}

export default Z;