import React from "react"
import { H3Utils,HUtils } from "./_import"
import i18next from "i18next"
import HModule from "src/helpers/Module"
import MyModal from "src/components/MyModal/MyModal"
const Setting2FAHelpers = {
  Auth2FAEnabled: false,
  UniqueId: "",
  fnUpdateSetting2FAPart: null,
  FA2Methods: [],
  seletedMethod: null,
  currentRessponseAuth: {},
}

export const myKey = "setting2fa"
export const constVariable = {
  mykey: "",
  //key info
  fa2_token: "fa2_token",
  fa2_method: "fa2_method",
  fa2_needenable: "fa2_needenable",
  fa2_needverify: "fa2_needverify",
  user_uniqueid: "user_uniqueid",
  user_id: "user_id",
  //fnList
  dispatchLoginSuccess: "dispatchLoginSuccess",
  onUpdateAfterOTPRequest: "onUpdateAfterOTPRequest",
  //relative Module
  moduleSetting: "setting"
}

export const updateSettingByResponse = res => {
  if (res && res.Data) {
    Setting2FAHelpers.Auth2FAEnabled = res.Data.Auth2FAEnabled
    Setting2FAHelpers.UniqueId = res.Data.UniqueId
    Setting2FAHelpers.FA2Methods = res.Data.FA2Methods
  }
  forceUpdateSetting2FAPart()
}
export const updateSettingOneMethodByResponse = res => {
  if (res && res.Data && Setting2FAHelpers.FA2Methods) {
    for (let i = 0; i < Setting2FAHelpers.FA2Methods.length; i++) {
      let _method = Setting2FAHelpers.FA2Methods[i]
      if (res.Data.Id === _method.Id) {
        H3Utils.mergeObjIfExist(_method, res.Data)
      }
    }
  }
  forceUpdateSetting2FAPart()
}
export const updateFnUpdateSetting2FAPart = fnUpdate => {
  if (fnUpdate) {
    Setting2FAHelpers.fnUpdateSetting2FAPart = fnUpdate
  }
}
export const getCurrentMethod = () => {
  return Setting2FAHelpers.seletedMethod
}

export const getCurrentMethodId = () => {
  return HUtils.get(Setting2FAHelpers.seletedMethod, "FA2MethodId")
}

export const getCurrentOtpRecipientRequired = () => {
  return HUtils.get(
    Setting2FAHelpers.seletedMethod,
    "Configs.OtpRecipientRequired"
  )
}
export const getCurrentDisplayQRCode = () => {
  return HUtils.get(Setting2FAHelpers.seletedMethod, "Configs.DisplayQRCode")
}
export const getCurrentOTPDescription = () => {
  return HUtils.get(Setting2FAHelpers.seletedMethod, "Configs.OTPDescription")
}
export const getCurrentFA2MethodName = () => {
  return HUtils.get(Setting2FAHelpers.seletedMethod, "FA2MethodName")
}
export const forceUpdateSetting2FAPart = () => {
  if (Setting2FAHelpers.fnUpdateSetting2FAPart) {
    HUtils.runFuntion(Setting2FAHelpers, "fnUpdateSetting2FAPart", [])
  }
}

export const getInfo2FA = ()=>{
  return Setting2FAHelpers.currentRessponseAuth
}

export const getInfo2FANeedEnable = ()=>{
  return Setting2FAHelpers.currentRessponseAuth[constVariable.fa2_needenable]
}
export const getInfo2FANeedVerify = ()=>{
  return Setting2FAHelpers.currentRessponseAuth[constVariable.fa2_needverify]
}
export const updateInfo2FA = (responseAuth)=>{
  Setting2FAHelpers.currentRessponseAuth = responseAuth || {}
}

export const addMenuToSettingMenu = ()=>{
  HModule.runFunctionInModuleFnList(constVariable.moduleSetting,"addOneMenuSetting",[
    {title:"Authentication 2FA", icon: null, path: "/setting/2fa", subTitle:"null", onClick:null, configPage: {
      Id: "setting2fa",
      UIUrl: "/setting/2fa",
      UIType: "setting2fa",
    }}
  ]);
}

export const showGenerate2FA = (fromOneMethod)=>{
  // console.warn("showGenerate2FA",fromOneMethod)
  MyModal.Helpers.showMyUI(
    fromOneMethod.IsEnable===true?"setting2fa_disable2FA":"setting2fa_generation2fa",
    // props => {
    //   if(fromOneMethod.IsEnable===true){
    //     return (
    //       <div>IsEnable</div>
    //     )
    //   }
    //   else{
    //     return (
    //       <div>Not IsEnable</div>
    //     )
    //   }
    // },
    {},{
      title:
        fromOneMethod.IsEnable === true
          ? i18next.t("setting2fa:title.disable2fa")
          : i18next.t("setting2fa:title.enable2fa"),
      backdrop: "static",
      size: "md",
      modalCentered: true,
    }
  )
}
export default Setting2FAHelpers
