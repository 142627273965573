const Form={       
  Title: '',
  Styles:{
    Form:{
      padding: '0px',
      c_padding: '0px',
      border:'0px solid',
    },
    Label:{ 
    },
    Cell:{
      marginBottom: '0px', 
    }
  },
  Data: [
    {
      Type: 'readonly',
      Label: 'Minute Meeting',
      Props: {
      },
      FieldName: 'row1',    
      Styles:{
        Label:{
          width:'100%',
          display:'flex',
          textAlign:'left',
          fontWeight:'bold',
          fontSize:'x-large',
        },
      },                      
    },
    {
      Type: 'readonly',
      Label: 'HCM, 30 August 2019',
      FieldName: 'row2',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'100%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%%'
        }
      },                    
    },
    {
      Type: 'readonly',
      Label: 'Ref No:',
      FieldName: 'row3',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%%'
        }
      },                    
    },
    {
      Type: 'readonly',
      Label: '',
      FieldName: 'space1',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%%',
          borderBottom:'1px solid black',
          marginTop:'10px',
          marginBottom:'5px',
        }
      },                    
    },
    {
      Type: 'text',
      Label: 'CLIENT NAME:',
      FieldName: 'Attendants_Client',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
          fontWeight:'bold',
        },
        Cell:{
          width:'100%%',
        }
      },                    
    },
    {
      Type: 'datepicker',
      Label: 'DATE:',
      FieldName: 'MeetingDate',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%',
        }
      },                    
    },
    {
      Type: 'text',
      Label: 'LOCATION:',
      FieldName: 'Location',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%'
        }
      }, 
      // SourceList: [
      //   {value:'1',text:'SDI OFFICE'},
      //   {value:'2',text:'SDI OFFICE 2'},
      //   {value:'3',text:'SDI OFFICE 3'},
      // ],                      
    },
    {
      Type: 'text',
      Label: 'ATTENDANTS: ',
      FieldName: 'Attendants',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%'
        }
      },                    
    },
    {
      Type: 'text',
      Label: ' ',
      FieldName: 'Attendants_Client',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%'
        }
      },                    
    },
    {
      Type: 'text',
      Label: 'SUBJECT:',
      FieldName: 'Subject',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%',
        }
      },                    
    },
    {
      Type: 'textarea',
      Label: '',
      FieldName: 'SubjectAgreement',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%',
          borderTop:'1px solid black',
          borderBottom:'1px solid black',
          paddingTop:'10px',
          paddingBottom:'10px',
        }
      },                    
    },
    {
      Type: 'readonly',
      Label: 'Part 01: Meeting Contents',
      FieldName: 'row11',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'100%',
          marginLeft:'5px',
          fontSize:'large'
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%',
        }
      },                    
    },
    {
      Type: 'html_editor',
      Label: '',
      FieldName: 'Content',  
      Styles:{
        Label:{
          fontWeight:'inherit',
          textAlign:'left',
          width:'12%',
          marginLeft:'5px',
        },
        Control:{
          width: '100%',
        },
        Cell:{
          width:'100%',
        }
      },                    
    },
  ]
};

export default Form