import MyComponentTypes from '../MyComponent/Types'
const MyPageLayoutHelpers = {
  setDefaultMyPageLayout(fnUI: any) {
    MyPageLayoutHelpers.Types['_default'] = fnUI
  },
  setEmptyMyPageLayout(fnUI: any) {
    MyPageLayoutHelpers.Types['_empty'] = fnUI
  },
  addTypes(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyPageLayoutHelpers.Types, key, fnUI)
  },
  Types: {
    _empty: MyComponentTypes.Types._empty
  }
}

export default MyPageLayoutHelpers
