import MyComponentTypes from '../MyComponent/Types'
const MyCardHelpers = {
  addTypes(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyCardHelpers.Types, key, fnUI)
  },
  addTypesFromListLazy(listWithKey: any) {
    MyComponentTypes.addTypesGenericFromListLazy(
      MyCardHelpers.Types,
      listWithKey
    )
  },
  Types: {
    _empty: MyComponentTypes.Types._empty
  }
}

export default MyCardHelpers
