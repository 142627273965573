import React, { Component } from 'react';
// import {ToastContainer, toast} from 'react-toastify';
// import createHistory from "history/createBrowserHistory";
// import createHistory from "history/createHashHistory";
import {createHashHistory as createHistory} from "../_importLibs"
// import {HashRouter, BrowserRouter, Route, Switch, Redirect, } from 'react-router-dom';
import Moment from "moment";
import _ from 'lodash';
import TinyColor from 'tinycolor2';
// import TableHelper2 from './App/TableHelper';
import HColor from './Color';
import HUI from './UI';
import HFormat from './Format';
import HUtils from './Utils';
import HEncode from './Encode'
const Global = {
  HConfig: null,
  TableHelper2: null,
  getHConfig(){
    if(Global.HConfig){
      return Global.HConfig;
    }
  },
  initGlobal(Config){
    Global.HConfig = Config;
    import('./App/TableHelper').then((v)=>{
      if(v && v.default){
        console.warn("import TableHelper:",v.default);
        Global.TableHelper2 = v.default;
        Global.TableHelper.Updating = Global.TableHelper2.Updating;
      }
      else{
        console.error("import missing");
      }      
    })
  },
  Format: {
    percent: HFormat.percent,
    currency: HFormat.currency,
  },
  Color: HColor,
  getIconClassFile(type){//Ko xai nua, chuyen sang Utils.getIconClasFile
    return HUtils.getIconClassFile(type);
  },
  LoadingScreen: HUI.LoadingScreen,
  Toast: HUI.Toast,
  Alert: HUI.Alert,
  History:{
    history: null,
    log(){
      console.log(this.history);
    },
    createHistory(){
      return createHistory();
    },
    updateHistory(history){
      console.log('updateHistory:',history);
      this.history = history;
    },
    getInstance(){
      if(this.history==null){
        this.history = createHistory();
      }
      return this.history;
    },
    goHome(){
      window.location.href = '/';
    },
    openExternalUrl(url,target='_blank'){
      window.open(url,target);
    },
    push(path,isNew=false){
      console.log('History push:',path,this.history);
      if(path!=null&&path.startsWith('http')==true){
        if(isNew==true){
          this.openExternalUrl(path);
        }
        else{
          this.openExternalUrl(path,'_self');
        }
      }
      else{
        if(isNew==true){
          this.openExternalUrl('/#'+path);
        }
        else{
          this.history.push(path);
        }
      }
      
      // this.history.push('/#'+path);
      // window.location.href=path;
      // window.location.href = window.location.href;
    },
    refresh(){ 
      console.log(this.history);
    },
    pushFromHistory(path,history){
      console.log('pushFromHistory push:',path,history);
      history.push(path);
    },
  },

  Helper:{
    changePageTitle(title){
      const HConfig = Global.getHConfig();
      if(HConfig){
        document.title = title + ` - ${HConfig.title}`;  
      }      
    },
    arrayToObject(array,keyField){
      let _obj;
      if(array!=null && array.length>0){
        _obj={};
        array.map((item,index)=>{
          let _newItem = item;
          let _key = index;
          if(keyField!=null){
            if(item[keyField]!=null){
              _key = item[keyField];
            }
          }
          _obj[_key]=_newItem;
        })
      }
      return _obj;
    },
    addValueToOptions(options,newValue){
      if(options!=null){
        let _isExist = false;
        for(let i=0;i<options.length;i++){
          if(options[i].Value==newValue){
            _isExist=true;break;
          }
        }
        if(_isExist==false){
          let _options = options;
          _options.push({
            Value: newValue,
            Text: newValue,
          })
          return _options;
        }
      }
      return options;
    },
    createOptions(options,msgEmpty){
      let _ui = [];
      if(options!=null){
        if(msgEmpty!=null){
          _ui.push(
            <option key={-1} value={""}>{msgEmpty}</option>
          );
        }
        for(let i=0;i<options.length;i++){
          _ui.push(
            <option key={i} value={options[i].Value}>{options[i].Text}</option>
          );
        }
      }
      return _ui;
    },
    createOptionsDTC(options,WPGId){
      let _ui = [];
      if(options!=null){
        _ui.push(
          <option key={-1} value={""}></option>
        );
        for(let i=0;i<options.length;i++){
          let _item = options[i];
          if(_item.WPGId==WPGId){
            _ui.push(
              <option key={i} value={options[i].Value}>{options[i].CompanyName}</option>
            );
          }
        }
      }
      return _ui;
    },
    createOptionsWithBG(options,msgEmpty){
      let _ui = [];
      if(options!=null){
        if(msgEmpty!=null){
          _ui.push(
            <option key={-1} value={""}>{msgEmpty}</option>
          );
        }
        for(let i=0;i<options.length;i++){
          let _color = TinyColor(options[i].ColorCode).isDark()==true?'white':'black';
          _ui.push(
            <option key={i} style={{backgroundColor:options[i].ColorCode,color:_color}} value={options[i].ColorCode}>{options[i].Name}</option>
          );
        }
      }
      return _ui;
    },
    createOptionsForSelect2(options,msgEmpty){
      let _newOptions = [];
      // console.warn('createOptionsForSelect2:',options);
      if(options!=null){
        if(msgEmpty!=null){
          _newOptions.push({
            value: "",
            label: msgEmpty,
          });
        }
        for(let i=0;i<options.length;i++){          
          let _item = options[i];
          if(_item.Value){
            let _newItem = {
              value: _item.Value,
              label: _item.Text,
            };
            if(_item.ColorCode){
              _newItem.ColorCode = _item.ColorCode;
            }
            _newOptions.push(_newItem);
          }          
        }
      }
      return _newOptions;
    },
    getValuesFromOptions(options){
      let _values = [];
      if(options!=null){
        for(let i=0;i<options.length;i++){
          _values.push(options[i].Value);
        }
      }
      return _values;
    },
    getTextFromOptions(options){
      let _values = [];
      if(options!=null){
        for(let i=0;i<options.length;i++){
          _values.push(options[i].Text);
        }
      }
      return _values;
    },
    getValueAndTextFromOptions(options){
      let _values = [];
      if(options!=null){
        for(let i=0;i<options.length;i++){
          _values.push({
            text: options[i].Text,
            value: options[i].Value
          });
        }
      }
      return _values;
    },
    getTextOfValueFromOptions(options,value){
      let _text = '';
      if(options!=null){
        for(let i=0;i<options.length;i++){
          if(options[i].Value==value){
            _text = options[i].Text;
            break;
          }
        }
      }
      return _text;
    },
  },

  Date:{
    dateStringWithFormat(time,format){
      if(time==null || time==0 || time == ''){
        return "";
      }
      let _d = new Date(time);
      if(format!=null && format.length>0){
        return Moment(time).format(format);
      }
      return _d.toISOString();
    },
    fromNow(time){
      if(time==null || time==0 || time == ''){
        return "";
      }
      return Moment(time).fromNow(); 
    },
  },

  Hash: HEncode.Hash,
  OptionsHelper:{
    UpdateOptionsInList(options={},listFieldName,listData){
      let _options = options;
      let _newFieldName = {};
      let _listOnly = {};
      let _listOptions = {};
      for(let i=0;i<listFieldName.length;i++){
        _listOnly[listFieldName[i]]=[];
        _listOptions[listFieldName[i]]=[];
        _newFieldName[listFieldName[i]]= listFieldName[i]+'_InList';
      }
      if(listData!=null){
        for(let i=0;i<listData.length;i++){
          let _item = listData[i];
          for(let j=0;j<listFieldName.length;j++){
            let _fieldName = listFieldName[j];
            let _value = _item[_fieldName];
            if(_listOnly[_fieldName].indexOf(_value)==-1){
              _listOptions[_fieldName].push({
                label: _value, value: _value,
              })
              _listOnly[_fieldName].push(_value);
            }
          }
        }
      }
      for(let i=0;i<listFieldName.length;i++){
        _options[_newFieldName[listFieldName[i]]]= _listOptions[listFieldName[i]];
      }
      return _options;
    },
  },
  PageHelper:{
    componentDidMount(Title,fnCall){
      Global.Helper.changePageTitle(Title);
      if(fnCall!=null){
        Global.LoadingScreen.show();
        fnCall();
      }
    },
    onLoadListFinish(data,error,response,callbackSuccess){
      if(data!=null){
        Global.LoadingScreen.hide();
        if(callbackSuccess!=null){
          callbackSuccess(data);
        }
      }
      else{
        let _msg = 'Có lỗi xảy ra';
        console.warn('onLoadListFinish',data,response);
        if(response!=null && response.StatusCode==0 && response.Msg!=null && response.Msg.length>0){
          _msg = response.Msg;
        }
        
        Global.LoadingScreen.showError(_msg,()=>{
          Global.LoadingScreen.show();
          if(this._cTable!=null){
            this._cTable.reload();
          }
        });
      }
    },
  },
  TableHelper:{
    Expanding:{
      isShow(row,item,listExpanding={}){
        if(listExpanding!=null && listExpanding[row.Id]!=null && listExpanding[row.Id].indexOf(item)>-1){
          return true;
        }
        return false;
      },
      onClick(row,item,listExpanding={},listIdExpanding=[],cTableDetail,cCell){
        if(listExpanding[row.Id]==null){
          listExpanding[row.Id] = [item];
        }
        else{
          if(listExpanding[row.Id].indexOf(item)>-1){
            listExpanding[row.Id] = _.without(listExpanding[row.Id],item);
          }
          else{
            listExpanding[row.Id].push(item);
          }
        }
        if(listExpanding!=null){
          for(let i=0;i<Object.keys(listExpanding).length;i++){
            let _key = Object.keys(listExpanding)[i];
            if(listExpanding[_key].length>0){
              listIdExpanding.push(_key);
            }
          }
        }
        if(cTableDetail!=null && cTableDetail[row.Id]!=null){
          cTableDetail[row.Id].forceUpdate();
          if(cCell!=null){cCell.forceUpdate()};
        }
      },
    },
    Updating: null,
    onUpdateField(row,fieldName,newValue,oldValue,fnCallback,fnRequestUpdate,component,options={}){
      console.log('onUpdateField:',row,fieldName,newValue,fnRequestUpdate);
      if(fnRequestUpdate!=null){
        if(newValue!=oldValue && !(newValue=="" && oldValue==null)){
          this.Updating.add(row,fieldName,component);
          fnRequestUpdate(row,fieldName,newValue,{
            component: component,
            fnUpdateUILoading: (loading)=>{
              this.Updating.remove(row,fieldName);
            }
          });
        }
        else{
          console.log('Not change!');
        }
      }
      else{
        console.warn('fnRequestUpdate is null');
      }
    },
    canEdit(fieldName,extraData,canEdit){
      if(typeof canEdit == 'boolean' && canEdit===false){
        return false;
      }
      if(extraData!=null && extraData.Columns!=null && extraData.Columns[fieldName]!=null){
        if(extraData.Columns[fieldName].CanEdit==true){
          // console.log('canEdit:',fieldName,extraData,true);
          return true;
        }
      }
      else if(extraData==null || extraData.Columns==null){
        // console.log('canEdit:',fieldName,extraData,true);
        return true;
      }
      // console.log('canEdit:',fieldName,extraData,false);
      return false;
    },
    getHeader(fieldName,extraData){
      if(extraData!=null && extraData.Columns!=null && extraData.Columns[fieldName]!=null){
        if(extraData.Columns[fieldName].Header!=null){
          // console.log('canEdit:',fieldName,extraData,true);
          return extraData.Columns[fieldName].Header;
        }
      }
      else if(extraData==null){
        // console.log('canEdit:',fieldName,extraData,true);
        return '';
      }
      // console.log('canEdit:',fieldName,extraData,false);
      return '';
    },
    getExtData(fieldName,extraData){
      console.log('getExtData:',fieldName,extraData);
      if(extraData!=null && extraData.Columns!=null && extraData.Columns[fieldName]!=null){
        if(extraData.Columns[fieldName].ExtData!=null){
          // console.log('canEdit:',fieldName,extraData,true);
          return extraData.Columns[fieldName].ExtData;
        }
      }
      else if(extraData==null){
        return '';
      }
      return '';
    },
    getColorBG(fieldName,extraData){
      if(extraData!=null && extraData.Columns!=null && extraData.Columns[fieldName]!=null){
        if(extraData.Columns[fieldName].Color_BG!=null){
          // console.log('canEdit:',fieldName,extraData,true);
          // return '#f2f2f2'
          return extraData.Columns[fieldName].Color_BG;
        }
      }
      else if(extraData==null){
        return '#ffffff';
      }
      return '#ffffff';
    },
    getColorText(fieldName,extraData){
      // console.log('getColorText:',fieldName,extraData);
      if(extraData!=null && extraData.Columns!=null && extraData.Columns[fieldName]!=null){
        if(extraData.Columns[fieldName].Color_Text!=null){
          return extraData.Columns[fieldName].Color_Text;
        }
      }
      else if(extraData==null){
        return '#000000';
      }
      return '#000000';
    },
  },
}

export default Global;