import React from 'react';
import MyButton from './MyButton';
import HH from '../../helpers/H';
import HLink from '../../helpers/Link';
import HEE from '../../helpers/EventEmitter';
import HUtils from '../../helpers/Utils';
import HConstant from '../../helpers/Constant';
import MyModal from '../MyModal/MyModal';
const MyButtonHelpers = {
  sTitleEmpty: "_E_",
  getConfigFromSolidOptions(config={}, opts={}){
    // console.warn('getConfigFromSolidOptions:',config);
    let _obj = {
      title : config.Name,
      color: 'primary'
    };
    if(config.Type==null){
      config.Type = 'Link';     
      if (config.APIName){
        if (config.Form){
          config.Type = 'Form';          
        }
        else{
          config.Type = 'Api';          
        }
      }
      else{
        config.Type = 'Link';
      }
    }
    
    _obj.typeProps = Object.assign({},config);            
    if (config.Type == 'Form'){
      _obj.formConfig = config.Form;
      _obj.color = 'success';
      _obj.type = 'form';

      _obj.formType = config.Form.Type;

      // if (config.APIName){
      //   _obj.typeProps.apiPathAndName = config.APIName;
      // }
      // if(config.NeedReload){
      //   _obj.typeProps.reloadAfterRequest = config.NeedReload;
      // }
      // if(config.NeedReloadList){
      //   _obj.typeProps.reloadTableAfterRequest = config.NeedReloadList;
      // }
    }
    else if(config.Type=="Link"){
      let _url = config.Url;
      let _isShowNewWindow = config.OpenMode == 'NewWindow' ? true : false;
      _obj.typeProps.url = _url;
      _obj.typeProps.isShowNewWindow = _isShowNewWindow;
      // console.warn('typeProps: ',_url,_isShowNewWindow);
      _obj.type = 'link';
    }
    else if (config.Type == "Api"){
      _obj.type = 'api';
      _obj.color = 'success';
      // if (config.APIName){
      //   _obj.typeProps.apiPathAndName = config.APIName;
      // }
      // if(config.RequestData){
      //   _obj.typeProps.queryData = config.RequestData;
      // }

      // if(config.NeedReload){
      //   _obj.typeProps.reloadAfterRequest = config.NeedReload;
      // }
      // if(config.NeedReloadList){
      //   _obj.typeProps.reloadTableAfterRequest = config.NeedReloadList;
      // }
    }
    else if (config.Type == "Upload"){
      _obj.type = 'upload';
      _obj.color = 'success';
    }
    else{
      _obj.type = config.Type!=null?config.Type.toLowerCase():null;
      _obj.color = 'success';
    }

    if (config.APIName){
      _obj.typeProps.apiPathAndName = config.APIName;
    }
    if(config.RequestData){
      _obj.typeProps.queryData = config.RequestData;
    }
    if(config.NeedReload){
      _obj.typeProps.reloadAfterRequest = config.NeedReload;
    }
    if(config.NeedReloadList){
      _obj.typeProps.reloadTableAfterRequest = config.NeedReloadList;
    }

    if (opts.queryData){
      _obj.typeProps.queryData = Object.assign({},_obj.typeProps.queryData,opts.queryData);
    }
    // Khong biet de lam gi, can an di vi se thay doi type cua kieu action, expand,collapse
    // if(config.More && config.More.type){
    //   _obj.type = config.More.type;      
    // }
    let _styleBtn = MyButtonHelpers.getStyleBtn(config);
    if(_styleBtn){
      _obj.styleBtn = _styleBtn;
    }
    // console.warn('getConfigFromSolidOptions _obj:',_obj);

    //Them config = chinh config => han che su dung cac kieu khac
    _obj.config = config;//
    return _obj;

  },
  // getMoreInButtonList(obj){
  //   if(obj._MoreObj){
  //     return obj._MoreObj;
  //   }
  //   if(obj.More){//Dùng style trong More của ButtonList
  //     if(typeof obj.More == "string"){
  //       try {
  //         obj._MoreObj = JSON.parse(obj.More);    
  //         return obj._MoreObj;      
  //       } catch (error) {
  //         console.warn("Parse json getMoreInButtonList error:",error);
  //       }          
  //     }
  //     else if(typeof obj.More == "object"){
  //       return obj.More;
  //     }
  //   }
  // },
  getStyleBtn(obj){
    let _objStyle = {};
    if (obj!=null){
      if (obj.Color && obj.Color!=null){
        _objStyle.color = obj.Color;
      }
      if (obj.Color_BG && obj.Color_BG!=null){
        _objStyle.backgroundColor = obj.Color_BG;
      }
      if (obj.ConfigStyle!=null){
        if (obj.ConfigStyle.color && obj.ConfigStyle.color!=null){
          _objStyle.color = obj.ConfigStyle.color;
        }
        if (obj.ConfigStyle.bgColor && obj.ConfigStyle.bgColor!=null){
          _objStyle.backgroundColor = obj.ConfigStyle.bgColor;
        }
        if (obj.ConfigStyle.style && obj.ConfigStyle.style!=null){
          if (typeof(obj.ConfigStyle.style)==="string"){
            try {
              let _style = JSON.parse(obj.ConfigStyle.style);
              _objStyle = Object.assign(_objStyle,_style);
            } catch (error) {
              console.warn("Parse json _style error:",error);
            }
          }
          if (typeof(obj.ConfigStyle.style)==="object"){
            _objStyle = Object.assign(_objStyle,obj.ConfigStyle.style);
          }
        }
      }  
      // let _moreButtonList = MyButtonHelpers.getMoreInButtonList(obj);//Dùng style trong More của ButtonList
      // if(_moreButtonList && _moreButtonList.style && typeof _moreButtonList.style == "object"){
      //   _objStyle = Object.assign(_objStyle,_moreButtonList.style);
      // }
    }
    return _objStyle;
  },
  // getClassColorBtn(obj){
  //   let _classColorBtn = "primary";
  //   if (obj!=null && obj.ConfigStyle && obj.ConfigStyle!=null && obj.ConfigStyle.classColor && obj.ConfigStyle.classColor!=null){
  //     _classColorBtn = obj.ConfigStyle.classColor;
  //   }
  //   let _moreButtonList = MyButtonHelpers.getMoreInButtonList(obj);//Dùng style trong More của ButtonList
  //   if(_moreButtonList && _moreButtonList.classColor){
  //     return _moreButtonList.classColor;
  //   }
  //   return _classColorBtn;
  // },
  // getClassBtn(obj){
  //   let _classBtn = "";
  //   if (obj!=null && obj.ConfigStyle && obj.ConfigStyle!=null && obj.ConfigStyle.className && obj.ConfigStyle.className!=null){
  //     _classBtn = obj.ConfigStyle.className;
  //   }
  //   let _moreButtonList = MyButtonHelpers.getMoreInButtonList(obj);//Dùng style trong More của ButtonList
  //   if(_moreButtonList && _moreButtonList.className){
  //     return _moreButtonList.className;
  //   }
  //   return _classBtn;
  // },
  parseResponse(props,response){
    if(response){
      let _msg = response.Msg;
      if(_msg){
        HH().Toast.showSuccess(_msg); 
      }
      let _data = response.Data;      
      if(_data){
        //se lay tu getFullPath, chua doi vi chua co dieu kien test
        if(_data.FullPath){
          let _newLinkType = MyButtonHelpers.getNewLinkType(props);
          let _autoOpenLink = MyButtonHelpers.getAutoOpenLink(props,false);
          if(_autoOpenLink==true){
            HLink.openExternalUrl(_data.FullPath,_newLinkType)
          }
          else{
            HH().MyDialog.Helper.show({
              title: _data.FullPathTitle || 'Link',
              msg: (
                <div style={{wordWrap:'break-word'}}>
                  <div>{_data.FullPathInfo}</div>
                  <a href={_data.FullPath} target={_newLinkType} download={_data.FullPathDownload===true?true:false}>{_data.FullPath}</a>
                </div>
              )});
          }          
        }
      }   
    }    
  },
  needReloadOrNot(props){
    if(props && props.config && props.config.NeedReload==true){
      window.location.reload();
      return true;
    }
  },
  needReloadTableOrnot(props){
    const {reloadTable,reloadOptionsAndList} = MyButtonHelpers.getFnList(props);
    let _moreProps = MyButtonHelpers.getMoreInConfig(props);
    console.log("needReloadTableOrnot:",_moreProps,props);
    if(_moreProps && _moreProps.needReloadOptionsAndList===true){
      if(reloadOptionsAndList){
        reloadOptionsAndList();
      }
    }
    else if(props && props.config && props.config.NeedReloadList==true){      
      if(reloadTable){
        reloadTable();
      }
      else{
        HConstant.runMyPageFnList(MyButtonHelpers.getFnList(props),this,HConstant.MyPage.FnList.reloadList,[]);
      }
    }
  },
  needReloadMaster(props){
    let _needReloadMaster = false;
    if(props && props.config && props.config.NeedReloadMaster==true){
      _needReloadMaster = true;      
    }
    if(_needReloadMaster==false && props){
      let _moreProps = MyButtonHelpers.getMoreInConfig(props);
      if(_moreProps && _moreProps.needReloadMaster){
        _needReloadMaster = true;
      }
    }
    if(_needReloadMaster){
      const {fnRequestUpdateMaster} = MyButtonHelpers.getFnList(props);
      if(fnRequestUpdateMaster){
        fnRequestUpdateMaster();
      }
    }
  },
  getAllConfigInCategory(props){
    let _config = {};
    const {getAllConfig} = MyButtonHelpers.getFnList(props);
    if(getAllConfig){
      _config = getAllConfig();
    }
    return _config;
  },
  needClearFilter(props){
    const {onClearFilter} = MyButtonHelpers.getFnList(props);
    let _needClearFilter = false;
    let _moreProps = MyButtonHelpers.getMoreInConfig(props);
    if(_moreProps && _moreProps.needClearFilter){
      _needClearFilter = true;
    }
    if(_needClearFilter && onClearFilter){
      onClearFilter();
    }
  },
  checkNeedReload(props, {response}={}){
    MyButtonHelpers.needClearFilter(props);
    if(props && props.config && props.config.NeedReload==true){
      window.location.reload();
      return;
    }
    const _moreProps = MyButtonHelpers.getMoreInConfig(props);
    const _needSendMsg = HUtils.Obj.get(_moreProps,HConstant.ConfigInMore.needSendMsg);
    if(_needSendMsg){
      if(typeof(_needSendMsg) == "string"){
        HEE.emit(_needSendMsg, {response})
      }
      else if(Array.isArray(_needSendMsg)){
        for(let i=0; i<_needSendMsg.length; i++){
          HEE.emit(_needSendMsg[i], {response})
        }
      }
    }
    if(HUtils.Obj.get(_moreProps,HConstant.ConfigInMore.needCloseModal)===true){
      MyModal.Helper.hide()
    }
    MyButtonHelpers.needReloadTableOrnot(props);
    MyButtonHelpers.needReloadMaster(props);
  },
  getConfirmMsg(props){
    if(props && props.config && props.config.ConfirmMsg){
      return props.config.ConfirmMsg;
    }
  },
  getPlaceholder(props,df){
    let _more = MyButtonHelpers.getMoreInConfig(props);
    if(_more && _more.placeholder){
      return _more.placeholder;
    }
    return df;
  },
  getAutoOpenLink(props,df){
    let _more = MyButtonHelpers.getMoreInConfig(props);
    if(_more && _more.autoOpenLink!=null){
      return _more.autoOpenLink;
    }
    return df;
  },
  getRequest({configApi}={}){
    if(configApi){
      let _request = {
        method: configApi.method || 'POST',
        url: configApi.url,
        path: configApi.path,
        name: configApi.name 
      };    
      return _request;
    }
  },
  getApiRequest(props,opts={}){
    let _request = {
      method: 'POST',
    }
    let _apiName = HUtils.Obj.get(props, "config.APIName");
    if(opts.method){
      _request.method = opts.method;
    }
    if(_apiName){
      if(_apiName.indexOf('/')>-1){
        _request.url = `/api/v1/${_apiName}`
      }
      else{
        const {getApiController} = MyButtonHelpers.getFnList(props);
        const {fnGetApiController} = MyButtonHelpers.getFnList(props);
        if(getApiController){
          _request.path = getApiController();
          _request.name = _apiName
        }
        else if(fnGetApiController){
          _request.path = fnGetApiController();
          _request.name = _apiName
        }
      }
    }
    console.warn(`getApiRequest:`,_request);
    return _request;
  },
  getApiQuery(props,df,opts={}){
    let _query = {};  
    if(df){
      _query = Object.assign(_query,df);
    }
    if(props){
      if(props.projectId){
        _query.ProjectId = props.projectId;
      }

      if(props.config && props.config.RequestData && typeof props.config.RequestData=="object"){
        _query = Object.assign(_query,props.config.RequestData);
      }

      const {getQuery,fnGetSharedQueryData} = MyButtonHelpers.getFnList(props);
      // if(HUtils.isFunction(fnGetSharedQueryData)){
      //   let _moreQuery = fnGetSharedQueryData() || {};
      //   _query = Object.assign(_query,_moreQuery);
      // }
      if(getQuery){
        let _getQueryResult = getQuery() || {};
        _query = Object.assign(_query,_getQueryResult);
      }            
    }
    console.log(`getApiQuery:`,opts,_query);
    return _query;
  },
  getMoreInConfig(props){
    let _obj = {};
    if(props && props.config){
      if(props.config.More && typeof props.config.More == "object"){
        return props.config.More;
      }
      if(props.config._MoreObj){
        return props.config._MoreObj;
      }
      try {
        if(props.config.More){
          let _more = JSON.parse(props.config.More);
          props.config._MoreObj = _more;//Gán váo props.config._MoreObj
          return props.config._MoreObj;
        }
      } catch (error) {
        console.warn("getMore error:",error);
      }
    }
    return _obj;
  },
  getConfigModal(props,{moreObj}={}){
    let _configModal = {};    
    if (moreObj && moreObj.modal){
      if(typeof moreObj.modal==="object"){
        _configModal = moreObj.modal;
      }
      else if(typeof moreObj.modal==="string"){
        try {
          moreObj._ConfigModal = JSON.parse(moreObj.modal);
          _configModal = moreObj._ConfigModal;
        } catch (error) {
          console.warn(`Parse getConfigModal error:`,error);
        }        
      }    
    }
    // console.warn(`getConfigModal:`,_configModal);
    return _configModal;
  },
  getConfigFile(cButton,{moreObj}={}){
    return MyButtonHelpers.getConfigInMore(cButton,{moreObj,key:"file"});
  },
  getConfigInMore(cButton={},{moreObj,key}={}){
    let _configObj = {};
    let _moreObj = moreObj || MyButtonHelpers.getMoreInConfig(cButton.props);
    if (_moreObj && _moreObj[key]){
      if(typeof _moreObj[key]==="object"){
        _configObj = _moreObj[key];
      }
      else if(typeof _moreObj[key]==="string"){
        try {
          _configObj = JSON.parse(_moreObj[key]);
        } catch (error) {
          console.warn(`Parse getConfigInMore error:`,error);
        }        
      }    
    }
    return _configObj;
  },
  getConfigForm(props,{moreObj}={}){
    let _configForm = {};    
    // return {
    //   ConfirmMsg: null,
    //   Data: [
    //     {
    //       Disabled: null,
    //       FieldName: "Check",
    //       Hint: null,
    //       Label: "",
    //       Props: '{"rightLabel":"Tao moi","RelateComponents":["Form","WPGCodeL4Id","WPGCodeL4Id2"]}',
    //       Required: null,
    //       Source: "",
    //       Style: null,
    //       SubmitAfterChange: null,
    //       Type: "checkbox",
    //     },
    //     {
    //       Disabled: null,
    //       FieldName: "WPGCodeL4Id",
    //       Hint: null,
    //       Label: "",
    //       Props: '{"placeholder":"Subject Test","hideCondition":{"obj":"[Check]","opr":"==","value":true}}',
    //       Required: null,
    //       Source: "WPGCodeL4List",
    //       Style: null,
    //       SubmitAfterChange: null,
    //       Type: "select2",
    //     },
    //     {
    //       Disabled: null,
    //       FieldName: "WPGCodeL4Id2",
    //       Hint: null,
    //       Label: "",
    //       Props: '{"placeholder":"Subject Test 2","hideCondition":{"obj":"[Check]","opr":"!=","value":true}}',
    //       Required: null,
    //       Source: "WPGCodeL4List",
    //       Style: null,
    //       SubmitAfterChange: null,
    //       Type: "select2",
    //     },
    //     {
    //       Disabled: null,
    //       FieldName: "StandardTemplateId",
    //       Hint: null,
    //       Label: "",
    //       Props: '{"placeholder":"Key element Test"}',
    //       Required: null,
    //       Source: "StandardTemplateList",
    //       Style: null,
    //       SubmitAfterChange: null,
    //       Type: "select2",
    //     },
    //   ],
    //   Direction: "Column",
    //   Title: "Add New",
    // }
    if(props && props.config && props.config.Form){
      _configForm = props.config.Form;
    }
    return _configForm;
  },
  getMoreProps(props,opts={},df={}){
    let _color = "primary";
    let _outline = false;
    let _moreProps = {
      color: _color,
      outline: _outline,   
      style: {},   
    }
    //Get Style trong styleBtn
    if(props.styleBtn){
      _moreProps.style = Object.assign(_moreProps.style,props.styleBtn);
    }
    if(df){
      _moreProps = Object.assign(_moreProps,df);
    }

    //Lay tu props
    if(props.color){
      _moreProps.color = props.color;
    }
    if(props.outline){
      _moreProps.outline = props.outline;
    }

    //Lay tu config button list
    if(props && props.config){
      if(props.config.Color){
        if(["success","danger","primary","secondary","link","warning"].indexOf(props.config.Color)>-1){
          _moreProps.color = props.config.Color;
        }
      }

      let _more = MyButtonHelpers.getMoreInConfig(props);
      // console.warn(`_more:`,_more);
      if(_more && Object.keys(_more).length>0){
        if(_more.color!=null){
          _moreProps.color = _more.color;
        }
        if(_more.outline!=null){
          _moreProps.outline = _more.outline;
        }
        if(_more.title){
          _moreProps.title = _more.title;
        }
        if(_more.style!=null){
          _moreProps.style = Object.assign(_moreProps.style,_more.style);
        }
        if(_more.disabled===true){
          _moreProps.disabled = true;
        }
      }      
    }
    return _moreProps;
  },
  getFnList(props){
    let _fnList={};
    if(props){
      if(props.fnList){
        _fnList = props.fnList;
      }
    }
    // console.warn("MyButton getFnList:",_fnList);
    return _fnList;
  },
  getNewLinkType(props){
    let _type = '_blank';
    if(props && props.config && props.config.OpenMode!=null){
      if(props.config.OpenMode.toLowerCase().indexOf('self')>-1){
        return '_self';
      }
    }
    return _type;
  },
  getFullPath(props,response){
    let _link;
    if(response && response.Data && response.Data.FullPath){
      _link = response.Data.FullPath;
    }
    else{
      let _more = MyButtonHelpers.getMoreInConfig(props);
      if(_more && _more.fullPath!=null){
        _link = _more.fullPath;
      }
    }
    return _link;
  },
  getTitle(props,df="",{showLoading,forceName}={}){
    if(props && props.config){
      let _ui = [];
      let _icon = props.config.Icon;
      let _name = props.config.Name;
      if(showLoading==true){
        _ui.push(
          <i key="iconloading" className={"fa fa-spinner fa-spin"}/> 
        )
      }
      else{
        if(_icon){
          _ui.push(
            <i key="icon" className={_icon}/> 
          )
        }
      }
      if(forceName!=null){
        _name = forceName;
      } 
      if(_name){
        _ui.push(
          _name 
        )
      }      
      return _ui;
    }
    if(props.title){
      return props.title;
    }
    return df;
  },
  renderListButton({listData,fnList,moreProps}){
    if(listData && listData.length>0 && fnList){
      let _fnGetQuery = fnList.fnGetQuery;
      let _fnGetController = fnList.fnGetController;
      let _fnGetOptions = fnList.fnGetOptions;
      let _fnReloadTable = fnList.fnReloadTable;
      let _ui = ( 
        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
        { 
          listData.map((e,i)=>{
            let _query = {};
            // if(projectId){ _query.ProjectId = projectId;// }//Gom vao getQuery
            if(e.RequestData!=null && typeof e.RequestData=="object"){
              _query = Object.assign(_query,e.RequestData);
            }
            if(_fnGetQuery!=null){
              let _customQuery = _fnGetQuery();
              if(_customQuery!=null){
                _query = Object.assign(_query,_customQuery);
              }
            }
            let _apiController = "";
            if(_fnGetController){
              _apiController = _fnGetController();
            }
            if(e.APIName){              
              if(e.APIName.indexOf("/")==-1 && _apiController){                
                e.APIName = `${_apiController}/${e.APIName}`
              }
            }
            return(
              <MyButton key={i} 
                style = {{marginLeft:'2px', marginRight:'2px'}}
                apiController={_apiController}
                getOptions={_fnGetOptions}
                reloadTable={_fnReloadTable}
                // projectId={projectId}
                // screenCode={screenCode}
                fnList={fnList}
                getQuery={_fnGetQuery}
                {...moreProps}
                {...MyButtonHelpers.getConfigFromSolidOptions(e, {queryData:_query})}
              />
            )
          })
        }
        </div>
      )
      return _ui;
    }
  },
  getRequestData(cButton){
    let _request = {};    
    if(cButton && cButton.props){
      _request = HUtils.Obj.get(cButton,"props.config.RequestData");
    }
    return _request;
  },
  getOptions(cButton){
    if(cButton){
      const {props} = cButton;
      console.warn("getOptions:",props);
      if(props){        
        if(props.getOptions){
          return props.getOptions();
        }
        else if(props.fnList && props.fnList.fnGetOptions){
          return props.fnList.fnGetOptions();
        }        
      }      
    }
    return [];
  }
}

export default MyButtonHelpers;