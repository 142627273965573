import React, { Component } from 'react';
import {
  Button,
} from '../../_importComponent';
import M from '../../libs/M';
import Global from '../Global';
import Text from '../Text';
import App from './App';
import MyButton from '../../components/MyButton';
import ModalForm from "../../views/Components/Form/FormModal";

const PageHelper = {
  componentDidMount(Title,fnCallback,opts){
    if(!opts || (opts && opts.isNotChangePageTitle!=true)){
      App.changePageTitle(Title);
    }    
    if(fnCallback!=null){
      let _isShowLoading = true;
      if(opts && opts.isNotShowLoading==true){
        _isShowLoading = false;
      } 
      if(_isShowLoading){
        Global.LoadingScreen.show();
      }      
      fnCallback();
    }
  },
  buildListBtnFromOptions({opts,projectId,getOptions,getCustomQuery,reloadTable}){
    console.log('buildListBtnFromOptions:',opts,projectId);
    if(opts && opts.length>0){
      
      let _ui = ( 
        <div style={{display:'inline-block'}}>
        { 
          opts.map((e,i)=>{
            let _query = {};
            if(projectId){
              _query.ProjectId = projectId;
            }
            if(e.RequestData!=null && typeof e.RequestData=="object"){
              _query = Object.assign(_query,e.RequestData);
            }
            if(getCustomQuery!=null){
              let _customQuery = getCustomQuery();
              if(_customQuery!=null){
                _query = Object.assign(_query,_customQuery);
              }
            }
            if(e.APIName){
              if(e.Form){
                return (
                  <ModalForm key={i} queryData={_query} config={e} getCustomQuery={getCustomQuery} getOptions={getOptions} reloadTable={reloadTable}/>
                )
              }
              return (
                <MyButton.Api key={i} queryData={_query} apiPathAndName={e.APIName} config={e} reloadTable={reloadTable}>{e.Name}</MyButton.Api>                
              )
            }
            return (
              <Button style={{marginRight:'5px'}} key={i} color="primary" onClick={()=>{Global.History.push(e.Url,e.OpenMode=="NewWindow")}}>{e.Name}</Button>
            )
          })
        }
        </div>
      )
      console.log('buildListBtnFromOptions:',opts,_ui);
      return _ui;
    }
  },
  onLoadListFinish(data,error,response,callbackSuccess,cTable,opts){
    if(data!=null){
      Global.LoadingScreen.hide();
      if(callbackSuccess!=null){
        callbackSuccess(data,error,response,opts);
      }
    }
    else{
      let _msg = 'Có lỗi xảy ra';
      console.warn('onLoadListFinish',data,response);
      if(response!=null && response.StatusCode==0 && response.Msg!=null && response.Msg.length>0){
        _msg = response.Msg;
      }
      
      Global.LoadingScreen.showError(_msg,()=>{
        Global.LoadingScreen.show();
        if(cTable!=null){
          cTable.reload();
        }
      });
    }
  },
  onRequestOptions({api,data,fnTryAgain,fnSuccess,fnRebuildOptions,fnLoadOptionsFinish}){
    if(api!=null && api.get_options!=null){
      api.get_options({
        data: data,
        successCallBack:(response)=>{
          if(fnSuccess!=null){fnSuccess(response);}
          let _options = response.Data;
          if(fnRebuildOptions!=null){
            _options = fnRebuildOptions(_options);
          }
          if(fnLoadOptionsFinish!=null){
            fnLoadOptionsFinish(_options);
          }
        },
        errorCallBack:(error,response)=>{
          Global.LoadingScreen.showError(response!=null?response.Msg:Text.get('msg_error_get_options'),()=>{
            Global.LoadingScreen.show();
            if(fnTryAgain!=null){fnTryAgain();}
          })
          // this.setState({error:error!=null?error:response});
        }
      })
    } else { console.warn('onRequestList failed no api or api get_options')}
  },
  ConfigScreen: {
    key:{
      prefix: 'config_',
      filter: 'filter',
    },
    getConfig(key){
      if(key!=null){
        // console.log('getConfig:',this.key.prefix + key);
        return M.LocalStorage.getObject(this.key.prefix + key,{});
      }
    },
    setConfig(key,obj){
      if(key!=null){
        // console.log('ConfigScreen setConfig:',this.key.prefix + key,obj);
        return M.LocalStorage.setObject(this.key.prefix + key,obj);
      }
    },
    clearAllConfig(){
      if(localStorage!=null && Object.keys(localStorage)!=null){
        let _arrKeys = Object.keys(localStorage);
        for(let e of _arrKeys){
          if(e.startsWith(PageHelper.ConfigScreen.key.prefix)==true){
            delete localStorage[e];
          }
        }
      }
    },
    getSavedFilter(key){
      if(key!=null){
        let _config = this.getConfig(key);
        if(_config!=null && _config[this.key.filter]!=null){
          console.log('getSavedFilter:',_config[this.key.filter]);
          return _config[this.key.filter];
        }
      }
    },
    clearSavedFilter(key){
      if(key!=null){
        let _config = this.getConfig(key);
        delete _config[this.key.filter];
        this.setConfig(key,_config);
      }
    },
    setSavedFilter(key,data){
      if(key!=null){
        let _config = this.getConfig(key);
        _config[this.key.filter] = data;
        this.setConfig(key,_config);
      }
    },
    setCustomField({screenCode,key,data}){
      if(key!=null){
        let _config = this.getConfig(screenCode)||{};
        if(_config){
          _config[key] = data;
        }
        this.setConfig(screenCode,_config);
      }
    },
    getCustomField({screenCode,key}){
      if(screenCode!=null){
        let _config = this.getConfig(screenCode);
        if(_config!=null && _config[key]!=null){
          return _config[key];
        }
      }
    },
  },
}

export default PageHelper;