import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./styles/common.css";
import {
  Button,
  Card,
  CardHeader,
  TabContent,
  TabPane,
  PageExtV2,
  MyPageFromConfig, MyButton,
  HC,
  HUtils,
  MyUI
} from "../_import";
import Tabv3_ext from "./tabv3_ext";
import ConfigTabV3 from "../../../model/ConfigTabV3"
class PageTab3L7 extends Tabv3_ext {
  constructor(props) {
    super(props);
    this.keyRightPositon = "";
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    if (this._isNeedRequestOptions()) {
      this.requestOptions({
        cbSuccess: () => {},
      });
    }
  }
  getHasProject=()=>{
    const {fnList} = this.props;
    if(fnList && fnList.getIsHasProject){
      return fnList.getIsHasProject();
    }
    return false;
  }
  _isNeedRequestOptions = () => {
    const { configPage } = this.props;
    if (configPage && configPage.Contents) {
      return false;
    }
    return true;
  };
  
  renderTab = () => {
    const { fnList, configPage } = this.props;
    const contents = this.getContents();
    const buttonsOnTab = HUtils.Obj.get(this.getConfigPage(),`ConfigObj.${HC.ConfigPage.buttonsOnTab}`);
    // console.warn("buttonsOnTab:",buttonsOnTab);
    return (
      <div className="tab_lv7_wrapper no-print">
        <div className="tab_lv5">
          <div className="title">
            {configPage.Title} <i className="fa fa-chevron-right" />
          </div>
          {contents.map((v, i) => {
            return (
              <Button
                key={i}
                onClick={() => {
                  this.changeTab({ index: i, item: v });
                }}
                className={`btn-tab-lv5 ${
                  this.getTabIsActive({ index: i }) ? "active" : ""
                }`}
              >
                {v.Title}
              </Button>
            );
          })}
          {
            buttonsOnTab!=null &&
            <div className="btns-ontab" style={{display:'flex',flexDirection:'row'}}>
            {
              buttonsOnTab.map((v,i)=>{
                return (
                  <MyButton type={v.Type} config={v} key={i} fnList={this._sharedFnList}/>
                )                
              })
            }
            </div>
          }
          
        </div>
        <div className="tab_lv5-right">               
        </div>
      </div>
    );
  };
  renderContent = () => {
    const { fnList, currentProject } = this.props;
    const contents = this.getContents();
    let _ui = [];
    return (
      <div className="content_lv7">
        <TabContent
          activeTab={this.getTabActive({ currentProject })}
          style={{ border: "0px", background: "transparent" }}
        >
          {contents.map((v, i) => {
            let _tabId = this.getTabId({
              index: i,
              currentProject: currentProject,
            });
            let _isLoaded = this.getTabIsLoaded({ index: i, currentProject });
            // console.warn("render tablev7:", _tabId, _isLoaded, this.state);
            if (_isLoaded) {
              if(_tabId==this.getTabActive({})){
                ConfigTabV3.setTabByLev(7,v.Id)
              }
              return (
                <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
                  <MyPageFromConfig
                    key={_tabId}
                    configPage={v}
                    fnList={{
                      ...fnList,           
                      ...this._sharedFnList,
                      getParentLevelTabIndex: this.getCurrentLevelTabIndex,           
                      updateConfigSearchBar:(configPage,configSearchBar)=>{
                        if(configPage && configPage.Id){  
                          if(i==this.state.activeTabIndex){//chi load tab active
                            if(fnList && fnList.updateConfigSearchBar){
                              fnList.updateConfigSearchBar(configPage,configSearchBar)
                            }
                          }                                                
                        }
                      }
                    }}
                  />
                </TabPane>
              );
            } else {
              return (
                <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
                  <div>........</div>
                </TabPane>
              );
            }
          })}
        </TabContent>
      </div>
    );
  };
  render() {
    return (
      <div className="app">
        {this.renderTab()}
        {this.renderContent()}
      </div>
    );
  }
}
// export default PageTab3L5
const mapStateToProps = (state) => {
  return {
    currentProject: state.solidv3.currentProject,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setObjInSolidV3: (obj) => dispatch({ type: "setObjInSolidV3", ...obj }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PageTab3L7);
