import axios from 'axios';
import UI from '../helpers/UI';
import HConsole from '../helpers/Console';
import HConstant from '../helpers/Constant'
import {Error as HError} from '../helpers/Error';
const AUTH_ACCESS_TOKEN_KEY = 'AUTH_ACCESS_TOKEN_KEY';
const API = {
  HConfig: null,
  getHConfig(){
    if(API.HConfig){
      return API.HConfig;
    }
  },
  initApi(Config){
    API.HConfig = Config;
    const HConfig = API.getHConfig();
    if(HConfig){
      API.baseUrl = HConfig.fnList.getConfig('baseUrl');
      if(HConfig.fnList.getConfig('timeout')){
        API.timeout = HConfig.fnList.getConfig('timeout')
      }
    }
  },
  baseUrl: null,
  // baseUrl:'http://devapi.solidapp.vn',
  // baseUrl: 'http://125.253.126.209:10001',
  updateBaseUrl(newBaseUrl){
    this.baseUrl = newBaseUrl;
  },
  timeout: 120000,
  page_size: 10000,
  authroize_key: null,
  buildDataForm(json){
    return Object.keys(json).map(function(key) {
      return encodeURIComponent(key) + '=' +
          encodeURIComponent(json[key]);
    }).join('&');
  },
  getDataList(options={}){
    let _data = {
      PageSize: this.page_size,
      Page: 1,
    };
    if(options.data!=null){
      _data = Object.assign(_data,options.data);
    }
    return _data;
  },
  getHeader(ctype,options){
    var _header = {
      'Accept': 'application/json',
      'Content-Type': ctype,
      // 'dataType': 'json',
      //'Authorization': this.authroize_key,
      //'Access-Control-Allow-Origin':'*',
    }

    if(options!=null && options.customHeader!=null){
      _header = Object.assign(_header,options.customHeader);
      //remove duplicate
      if(_header.ProjectId && _header.projectId){
        delete _header.projectId;
      }      
    }
    HConstant.rc("beforeRequest",[{options,header:_header}]);
    if(this.authroize_key == null){
      let _savedAccessToken = localStorage.getItem(AUTH_ACCESS_TOKEN_KEY);
      if(_savedAccessToken!=null){
        this.authroize_key = _savedAccessToken;
      }
    }
    if(this.authroize_key!=null && this.authroize_key.length>0){
      _header['Authorization'] = this.authroize_key;
    }
    return _header;
  },
  setAuthrozieKey(access_token,token_type){
    console.log('Api setAuthrozieKey:',this.authroize_key);
    if(access_token==null){
      this.authroize_key=null;
    }
    else{
      this.authroize_key = token_type + ' ' + access_token;
    }
  },
  fetchTest(options){
    console.log('fetchTest');
    this.fetch('http://ip.jsontest.com/',{},'get','application/json',30000,options);
  },
  fetch(url,params,method,ctype,timeout,options){
    let _options = {
      method: method,
      headers: this.getHeader(ctype,options),
      url: url,
      timeout: timeout,
    }
    
    if(method=='POST'){
      _options['data'] = params;
    }
    if(options['timeout']!=null){
      _options['timeout'] = options['timeout'];
    }
    if(options['onUploadProgress']!=null){
      _options['onUploadProgress'] = options['onUploadProgress'];
    }
    if(options['onDownloadProgress']!=null){
      _options['onDownloadProgress'] = options['onDownloadProgress'];
    }
    
    console.log('Api fetch options:',_options);
    axios(_options).then(function(response) {
      // console.log('axios response:',response);
      return response.data;
    }).catch(function (error) {
      console.log('axios error:',error);
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      }
      throw error;
    })
    .then(response => {
      this.share_success(options,response);
    })
    .catch((error) => {
      this.share_catchError(options,error);
    });
  },
  beforeFetch(request,options = {}){
    if(options!=null){
      options.type = request.type;
      options.request = request;
      options.url = this.baseUrl + request.url;
      options.timeStartClient = new Date().getTime();
      if(options.isShowHud==true){
        // G.hud.show();
      }
    }
    /*
    customHeader.Project convert to base64 projectId
     */
    if(options.customHeader!= null)
    {
      /*
      Loop all properties, then replace by encodeURIComponent (server dung Uri.UnescapeDataString)
       */
      var header = options.customHeader;
      if(header!= null)
      {
        for(var propt in header){
          if(header[propt]!= null)
          {
            header[propt]= encodeURIComponent(header[propt]);
          }
        }
      }
      /*
      var projectId=  header.ProjectId
      if(projectId != null)
      {
        //projectId = Base64.toByteArray (projectId);
        
        //escape This function encodes special characters, with the exception of: * @ - _ + . /
        //projectId = escape(projectId);// o server parse ko ăn

        projectId = encodeURIComponent(projectId); //server dung Uri.UnescapeDataString
        header.ProjectId = projectId;
      } */     
    }
  },
  afterFetch(options = {},response,error){
    const HConfig = API.getHConfig();    
    if(options.isShowHud==true){
      // G.hud.hide();
    }
    if(options.isHideMsgError!=true){
      // console.log('afterFetch:',response,error);
      if(error!=null){
        var _msg = "Error!";
        var _status = 0;
        if(error.response!=null){
          _status = error.response.status;//401
          if(error.response.data!=null){
            if(error.response.data.Message!=null)
            {_msg = error.response.data.Message;}
            else if(error.response.data.error_description!=null)
            {_msg = error.response.data.error_description;}
          }
        }
        logException({
          AIPUrl: options.url,
          Data: options.data,
          Error: {
            status: _status,
            msg: _msg
          }
        })
        if(_status==401){
          // Auth.logout(true);
          // HConfig && HConfig.fnList.apiUnAuth(true);
          HConstant.rc(HConstant.AppFnList.apiUnAuth,[true]);
        }
        else{
          UI.Toast.showError(_msg);
        }
        
      }
      else if(response!=null && (response.StatusCode!=1 && response.StatusCode!=200) && response.Msg!=null){
        UI.Toast.showError(response.Msg);
      } 
    }
  },
  get(request,options = {}){
    this.beforeFetch(request,options);
    this.fetch(this.baseUrl+request.url,'','GET','application/json',this.timeout,options)
  },
  post(request,options = {}){
    this.beforeFetch(request,options);
    let _data = '';
    if(options!=null && options.data!=null){
      options.data.Url = window.location.href;
      options.data.AppName = HConstant.gc(HConstant.AppConfig.appName);
      options.data.UI_StartAt = new Date().getTime();
      if(document && document.body && document.body.offsetWidth){
        options.data.DocumentWidth = document.body.offsetWidth;
      }
      _data = JSON.stringify(options.data);
    }
    
    this.fetch(this.baseUrl+request.url,_data,'POST','application/json',this.timeout,options)
  },
  postForm(request,options = {}){
    this.beforeFetch(request,options);
    let _data = '';
    if(options!=null && options.data!=null){
      _data = this.buildDataForm(options.data);
    }
    
    this.fetch(this.baseUrl+request.url,_data,'POST','application/x-www-form-urlencoded',this.timeout,options)
  },
  uploadFile(request,options = {}){
    this.beforeFetch(request,options);
    if(options.file!=null){
      let _f = options.file;
      var _formData = new FormData();
      _formData.append("file", _f);
      console.log(_formData);
    }
    else if(options.files!=null){
      let _f = options.files;
      var _formData = new FormData();
      for(let i=0;i<_f.length;i++){
        _formData.append("file[]", _f[i]);
      }
      console.log(_formData);
    }
    
    this.fetch(this.baseUrl+request.url,_formData,'POST','multipart/form-data',this.timeout,options)
  },
  share_success(options = {},response){
    console.log('%c API Success ['+options.type+']:',HConsole.Style.api,response);
    this.afterFetch(options,response,null);
    if(options!=null){
      let _successCallBack = options.successCallBack;
      
      let _errorCallBack = options.errorCallBack;
      let _statusCode = response.StatusCode;
      // console.log('share_success: ',_successCallBack,_errorCallBack,_statusCode);
      if(_statusCode==1 || _statusCode==200 || response.hasOwnProperty("access_token")){//chi can co field access_token
        if(_successCallBack!=null){
          _successCallBack(response);
        }
      }
      else{
        if(_errorCallBack!=null){
          HError.errorApi('Error from api',{
            options: {
              data: options.data,
              type: options.type,
            },
            response: response,
          },{path:"error_api_status"});
          // FHelper.logError()
          _errorCallBack(null,response);
        }
      }
    }
  },
  share_catchError(options,error){
    console.log('%c API Error:',HConsole.Style.alert,options,error);
    this.afterFetch(options,null,error);
    let _errorCallBack = options.errorCallBack;
    if(_errorCallBack!=null){
      _errorCallBack(error,null);
    }
    HError.errorApi(error,{
      options: {
        data: options.data,
        type: options.type,
      },
      error_stack: error!=null?error.stack:'',
      error_message: error!=null?error.message:'',
    });
    // FHelper.logError()
  },
}

function logException({AIPUrl,Data,Error }={}){
  let _url = HConstant.gc("urlForLogException") || `${API.baseUrl}/api/v1/Exception/SaveLog`;
  let _userId = HConstant.rc("getAuthUserId",[]) || ""
  let _userName = HConstant.rc("getAuthUserName",[]) || ""
  let _data = {
    LogBy: `[${_userId}] ${_userName}`,
    AIPUrl: AIPUrl,
    Url: window.location.href,
    Error: Error,
    Data: Data 
  }
  axios({
    method: "POST",
    url: _url,
    data:_data
  }).then(function(response) {
    console.warn('axios response:',response);
  }).catch(function (error) {
    console.warn('axios error:',error);
  })
}
export default API;