import MyComponentTypes from '../MyComponent/Types'
const MyLayoutHelpers = {
  addTypes(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyLayoutHelpers.Types, key, fnUI)
  },
  Types: {
    _empty: MyComponentTypes.Types._empty
  }
}

export default MyLayoutHelpers
