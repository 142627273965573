import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  TabContent,
  TabPane,
  PageExtV2,
  MyPageFromConfig,
  HUtils,
  HLink,
  HConstant,
  HStoreRedux,
  HUI,
} from "../_import";
import "./styles/common.css";
import "../../MyUI/Types/styles/common.css";
import ConfigTabV3 from "../../../model/ConfigTabV3"

class PageTab3Ext extends PageExtV2 {
  constructor(props) {
    super(props);
    this._lastContents = {
      contents: null,
      string: "",
      // activeTabIndex: null,
      // activeTabId: null,
      // loadedTab: [],      
    };
    this.initTab();    
  }
  initTab = () => {
    this.state.activeTabIndex = "0";
    this.state.loadedTab = [this.getTabId({ index: 0 })];
    this._isTabDefault = true;
    this.parseParamsTab();
  };
  parseParamsTab = () => {
    let _levelTabIndex = this.getCurrentLevelTabIndex();
    this._currentTabIdInUrl = HLink.getParamsOfURLWithKey(
      window.location.href,
      `t${_levelTabIndex}`,
      { removeHash: true }
    );
  };
  setParamsTab = (item) => {
    const _needRloadChangeTab = this.getConfigFromPageConfig("needReloadChangeTab");
    if (item && item.Id) {
      ConfigTabV3.keepParams();
      if(_needRloadChangeTab){
        console.warn("needReloadTab:",item,_needRloadChangeTab)
        setTimeout(()=>{
          window.location.reload();
        },100)
        // window.location.reload();
      }
      else{
        ConfigTabV3.updateUrlWhenChangeTab(this.getCurrentLevelTabIndex(),item)
      }
    }
  };
  getHasProject = () => {
    return false;
  };
  getCurrentProject = () => {
    const { currentProject } = this.props;
    if (this.getHasProject()) {
      if (currentProject) {
        return currentProject;
      }
    }
  };
  getCurrentLevelTabIndex = () => {
    const { fnList } = this.props;
    if (fnList && fnList.getParentLevelTabIndex) {
      let _parentTabIndex = fnList.getParentLevelTabIndex();
      // console.warn("getCurrentLevelTabIndex:",_parentTabIndex);
      return _parentTabIndex + 1;
    }
    return 0;
  };
  getTabActive = ({} = {}) => {
    // console.warn("getTabActive")
    const contents = this.getContents();
    const currentProject = this.getCurrentProject();
    if (
      contents &&
      contents.length > 0 &&
      contents[this.state.activeTabIndex] == null
    ) {
      //kiem tra neu danh sach tab ko co hien tai thi chuyen ve tab dau tien
      this.state.activeTabIndex = "0";
      this.changeTab({ index: 0, item: contents[0] });
    }
    // console.warn("getTabActive:",contents.length);
    return this.getTabId({ index: this.state.activeTabIndex, currentProject });
  };
  getTabId = ({ index } = {}) => {
    const currentProject = this.getCurrentProject();
    let _currentLevelTab = this.getCurrentLevelTabIndex();
    if (currentProject) {
      return `tab_lev${_currentLevelTab}_${currentProject.Id}_${index}`;
    }
    return `tab_lev${_currentLevelTab}_${index}`;
  };
  getTabIsLoaded = ({ index } = {}) => {
    const currentProject = this.getCurrentProject();
    let _tabId = this.getTabId({ index, currentProject });
    if (currentProject) {
      if (currentProject.Id != this._lastCurrentProjectId) {
        this.state.loadedTab = [this.getTabActive({ currentProject })];
        this._lastCurrentProjectId = currentProject.Id;
        if (index == this.state.activeTabIndex) {
          return true;
        } else {
          return false;
        }
      }
    }
    if (this.state.loadedTab && this.state.loadedTab.indexOf(_tabId) > -1) {
      return true;
    } else {
      let _activeTabId = this.getTabActive({});
      if (this.state.loadedTab.indexOf(_activeTabId) == -1) {
        //active tab cung ko co thi bo sung
        this.state.loadedTab = [_activeTabId];
        return true;
      }
    }
    return false;
  };
  getTabIsActive = ({ index } = {}) => {
    let _tabId = this.getTabId({ index });
    if (this.getTabActive() == _tabId) {
      return true;
    }
    return false;
  };
  changeTab = ({ index, item }) => {
    // const { currentProject } = this.props;
    const currentProject = this.getCurrentProject();
    let _loadedTab = this.state.loadedTab || [];
    let _newTab = this.getTabId({ index, currentProject });
    console.warn('changeTab:',_newTab,item);
    if (_loadedTab.indexOf(_newTab) == -1) {
      // _loadedTab.push(_newTab);//push moi
      _loadedTab = [_newTab]; //chi cai active
    }
    if(this._beforeChangeTab){
      this._beforeChangeTab(item)
    }
    ConfigTabV3.tabIdNotExistFirst = true;//sau khi change tab thi ko can kiem tra nua
    this.setParamsTab(item);
    this.setState(
      {
        activeTabIndex: `${index}`,
        loadedTab: _loadedTab,
      },
      () => {
        this.responsiveTabContent();
      }
    );
  };
  parseContent = (content) => {
    //set default
    // console.warn("parseContent:",content,this._currentTabIdInUrl)
    if(ConfigTabV3.checkIsChangeContents(this._lastContents,content)){
      // console.warn("content change:",content,this._lastContents)
      this.initTab();
      if (content) {
        if (this._isTabDefault) {
          //chi parse khi la tab default
          if (this._isTabDefault == true && this._currentTabIdInUrl) {
            let _existTabUrlInContent = false;
            for (let i = 0; i < content.length; i++) {
              if (content[i].Id == this._currentTabIdInUrl) {
                this.state.activeTabIndex = `${i}`;
                this.state.loadedTab = [this.getTabId({ index: i })];
                _existTabUrlInContent = true;
                break;
              }
            }
            if(_existTabUrlInContent===false){
              if(ConfigTabV3.isTabIdNotExistInFirst()){
                console.warn("no tab id in content:",content,this._currentTabIdInUrl)
                // HUI.Alert.showMsg("Thông báo",
                // `Đường dẫn trang ${ConfigTabV3.firstUrlWhenLoad} không tồn tại. 
                // Hệ thống sẽ tự động chuyển về trang mặc định`
                // ,null)
              }            
            }
            this._isTabDefault = false;
          }
          if (this._isTabDefault == true) {
            //neu ko phai load tu url hoac local
            for (let i = 0; i < content.length; i++) {
              let _item = content[i];
              if (
                _item.Config &&
                typeof _item.Config == "string" &&
                !_item.hasOwnProperty("ConfigObj")
              ) {
                try {
                  _item.ConfigObj = JSON.parse(_item.Config);
                } catch (error) {
                  console.warn("parse json error:", _item);
                }
              }
              // console.warn("tab ",content[i]);
              if (
                HUtils.Obj.get(
                  _item,
                  `ConfigObj.${HConstant.ConfigPage.isDefaultTab}`
                ) == true
              ) {
                this.state.activeTabIndex = `${i}`;
                this.state.loadedTab = [this.getTabId({ index: i })];
                break;
              }
            }
          }
          this._isTabDefault = false;
        }
      }
    }
  };
  getContents = () => {
    const { configPage } = this.props;
    if (configPage && configPage.Contents) {
      this.parseContent(configPage.Contents);
      return configPage.Contents;
    } else {
      const options = this.getOptions();
      let _contents = HUtils.Obj.get(options, "PageConfig.Contents");
      if (_contents && _contents.length > 0) {
        this.parseContent(_contents);
        return _contents;
      }
    }
    return [];
  };
  getMenuByPosition = (pos, { keyRight, keyIcon } = {}) => {
    // console.warn("getMenuByPosition")
    const content = this.getContents();
    let _menu = [];
    if (content && content.length > 0) {
      switch (pos) {
        case "calendar":
          _menu = content.filter((v) => v.Type == keyIcon);
          break;
        case "left":
          _menu = content.filter((v) => (v.Type !== keyRight && v.Type !== keyIcon));
          break;
        case "right":
          _menu = content.filter((v) => v.Type === keyRight);
          break;
        default:
          break;
      }
    }
    return _menu;
  };
  isMobile = () => {
    if (window.innerWidth <= 1200) {
      return true;
    }
    return false;
  };
  responsiveTabContent = () => {
    let _content = document.getElementById("lv1-content");
    if(_content){
      if (
        this.state.hasOwnProperty("openMenu")
          ? this.state.openMenu
          : HStoreRedux.getState().solidv3.openLeftMenu
      ) {
        // let _scrollbarWidth = window.outerWidth - document.documentElement.clientWidth;
        let _scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        _content.style.width = `calc(100vw - ${225 + _scrollbarWidth}px)`;
        _content.style.float = "right";
      } else {
        _content.style.width = "100%";
      }
    }    
  };
}
export default PageTab3Ext;
