import {HLink, HLocalStorage, HLog } from "@macashipo/mlib"
import HConstant from "../../../helpers/Constant"
const ConfigTabV3 = {
    prefix: "V3a_Last_",
    Key: {
        Project: "Project",
        User: "User",
        Department: "Department",
    },
    getLocalKey(key){
        return `${ConfigTabV3.prefix}${key}`
    },
    getObjLocalByKey(key){//key = "project,user,department"
        let _objLast = HLocalStorage.getObj(`${ConfigTabV3.prefix}${key}`,{});
        //Index, Data, Id    
        return _objLast;
    },
    currentIdByLev: {},
    paramByLevel:{
        1: HConstant.ParramURL.t0,
        3: HConstant.ParramURL.t1,
        5: HConstant.ParramURL.t2,
        7: HConstant.ParramURL.t3,
    },
    checkAndUpdateParamUrlByLev(lev,value){
        let _param = ConfigTabV3.paramByLevel[lev]
        // HLog.logTest("checkAndUpdateParamUrlByLev",lev,value)
        if(_param){
            let _valueOnUrl = HLink.getParameterByName(_param);
            if(_valueOnUrl!=value){
                HLink.updateParamOnUrl(_param,value)
            }
        }
    },
    setTabByLev(levTab,id){
        // HLog.logTest("setTabByLev",levTab,id)
        ConfigTabV3.currentIdByLev[levTab] = id
        // HLog.logTest("ur",window.location.href)
        ConfigTabV3.checkAndUpdateParamUrlByLev(levTab, id)
    },
    getInfoByKey(allParams,key,param,fnSwitch){
        let _info = {
            Data: null,
            Index: "-1"
        }
        let _itemLocal = HLocalStorage.getObj(ConfigTabV3.getObjLocalByKey(key))
        if(_itemLocal && _itemLocal.Id && _itemLocal.Data && _itemLocal.Data.Id==_itemLocal.Id){
            _info.Data = _itemLocal.Data
            _info.Index = _itemLocal.Index || 0
        }
        if(allParams[param]){
            let _itemId = allParams[param];
            if(_info.Data==null || _info.Data.Id!=_itemId){
                _info.Data = {
                    Id: _itemId, ProjectId: _itemId, ProjectName: _itemId, Name: _itemId, IsTemp: true
                }
                _info.Index = 0;
                fnSwitch(_itemId,_info.Data,_info.Index,{justUpdateItem:true})
            }            
        }
        else{
            if(_itemLocal && _info.Index!="-1" && _itemLocal.Id){
                fnSwitch(_itemLocal.Id)
            }
        }
        return _info;
    },
    getInfoFromUrl(){
        if(ConfigTabV3.firstUrlWhenLoad==null){
            ConfigTabV3.firstUrlWhenLoad = window.location.href
        }
        let _allParams = HLink.getAllParamOnUrl()
        let _info = {
            indexProject: "-1",
            currentProject: null,
            indexStaff: "-1",
            currentStaff: null,
            indexDepartment: "-1",
            currentDepartment: null
        };

        //project
        let _infoProject = ConfigTabV3.getInfoByKey(_allParams,"Project",HConstant.ParramURL.pj,ConfigTabV3.switchProject);
        if(_infoProject.Data){
            _info.currentProject = _infoProject.Data
            _info.indexProject = _infoProject.Index
        }

        //staff
        let _infoStaff = ConfigTabV3.getInfoByKey(_allParams,"User",HConstant.ParramURL.ss,ConfigTabV3.switchSolidStaff);
        if(_infoStaff.Data){
            _info.currentStaff = _infoStaff.Data
            _info.indexStaff = _infoStaff.Index
        }
        //department
        let _infoDepartment =  ConfigTabV3.getInfoByKey(_allParams,"Department",HConstant.ParramURL.sd,ConfigTabV3.switchSolidDepartment);
        if(_infoDepartment.Data){
            _info.currentDepartment = _infoDepartment.Data
            _info.indexDepartment = _infoDepartment.Index
        }
        // HLog.logTest("info:",_info)
        return _info
    },
    switchOffOfType(param,keyLocal){
        HLink.clearParamOnUrl([param,"t1","t2","t3","t4"])
        // HLog.logTest("switchOffOfType:",param,keyLocal)
        let _data = HLocalStorage.getObj(keyLocal) || {};
        _data.Index = "-1"
        HLocalStorage.saveObj(keyLocal,_data)        
    },
    switchType(param,keyLocal,itemId,item,index,opts={}){
        // HLog.logTest("switchType:",param,keyLocal)
        if(opts.justUpdateItem!==true){
            HLink.clearParamOnUrl(["t1","t2","t3","t4"])
        }        
        HLink.updateParamOnUrl(param,itemId);
        // console.warn("switchType:",itemId,item,index)
        if(item && item.Id && index!=null){
            HLocalStorage.saveObj(keyLocal,{
                Index: index,
                Data: item,
                Id: item.Id
            })
        }
    },
    switchOffProject(){
        ConfigTabV3.switchOffOfType(HConstant.ParramURL.pj,ConfigTabV3.getLocalKey("Project"))
    },
    switchProject(projectId,projectItem,index,opts){
        ConfigTabV3.switchType(HConstant.ParramURL.pj,ConfigTabV3.getLocalKey("Project"),projectId,projectItem,index,opts)
    },
    switchOffSolidStaff(){
        ConfigTabV3.switchOffOfType(HConstant.ParramURL.ss,ConfigTabV3.getLocalKey("User"))
    },
    switchSolidStaff(staffId,staffItem,index,opts){
        ConfigTabV3.switchType(HConstant.ParramURL.ss,ConfigTabV3.getLocalKey("User"),staffId,staffItem,index,opts)
    },
    switchOffSolidDepartment(){
        ConfigTabV3.switchOffOfType(HConstant.ParramURL.sd,ConfigTabV3.getLocalKey("Department"))
    },
    switchSolidDepartment(dId,dItem,index,opts){
        ConfigTabV3.switchType(HConstant.ParramURL.sd,ConfigTabV3.getLocalKey("Department"),dId,dItem,index,opts)
    },
    keepParams(){
        let _keeps = [HConstant.ParramURL.ss,HConstant.ParramURL.sd,HConstant.ParramURL.t0,HConstant.ParramURL.t1,HConstant.ParramURL.t3,HConstant.ParramURL.t2,HConstant.ParramURL.pj];
        // HLog.logTest("keepParams")
        HLink.keeponlyParams(_keeps)
        // HLog.logTest("keep:",window.location.href)
    },
    parseFromList(list,type,keyItem,keyIndex){
        let _info = this.getInfoFromUrl();
        let _localKey = ConfigTabV3.getLocalKey(type);
        let _currentItem = _info[keyItem];
        let _hasInList = false; 
        let _index = -1;
        if(list && list.length>0){
            for(let i=0;i<list.length;i++){
                let _item = list[i]
                if(_currentItem){
                    if(_item.Id===_currentItem.Id){
                        _hasInList = true;
                        _index = i;
                        _currentItem = _item;
                        if(_info.IsTemp===true){
                            //can update vao localStorage

                        }
                        return {
                            Data: _currentItem,
                            Index: _index
                        }
                    }                    
                }
                else{
                    return {
                        Data: _item,
                        Index: i
                    }
                }
            }
        }
        //if not in list
        if(list && _currentItem && _currentItem.Id){
            list.unshift(_currentItem)
        }
        return {
            Data: _currentItem,
            Index: 0 
        }
    },
    parseProjectList(projectList){
        return ConfigTabV3.parseFromList(projectList,"Project","currentProject","indexProjext")
    },
    parseStaffList(staffList){
        return ConfigTabV3.parseFromList(staffList,"User","currentStaff","indexStaff")
    },
    parseDepartmentList(dList){
        return ConfigTabV3.parseFromList(dList,"Department","currentDepartment","indexDepartment")
    },
    whenProjectIdNotInProjectList(projectList){
        // this.switchOffProject()
        // HLocalStorage.saveString(HConstant.LocalKey.V3_CurrPorfolioIndex,"-1")
        // window.location.reload();
    },
    firstUrlWhenLoad: null,
    tabIdNotExistFirst: false,
    isTabIdNotExistInFirst(){
        if(ConfigTabV3.tabIdNotExistFirst==false){
            ConfigTabV3.tabIdNotExistFirst = true
            return true;
        }
        return false;
    },
    checkUrlWithContent(param,contents){
        if(contents && contents.length>0)
        {
            let _tabId = HLink.getParameterByName(param)
            if(_tabId){
                let _arrId = [];
                for(let i=0;i<contents.length;i++){
                    let _c = contents[i];
                    _arrId.push(_c.Id)
                }
                if(_arrId.indexOf(_tabId)==-1){
                    return false
                }
            }            
        }
        return true;
    },
    getParamWithLevelTab(level){
        return `t${level}`
    },
    updateUrlWhenChangeTab(levTab,pageContent){
        // console.warn("updateUrlWhenChangeTab",levTab,pageContent)
        // HLog.logTest("updateUrlWhenChangeTab",levTab,pageContent)
        if(levTab>=0 && pageContent && pageContent.Id){
            let tabParam = ConfigTabV3.getParamWithLevelTab(levTab);
            let _valueOnUrl = HLink.getParameterByName(tabParam);
            if(_valueOnUrl!=pageContent.Id){
                HLink.updateParamOnUrl(tabParam,pageContent.Id)
            }
            let _tabBelows = []
            for(let i=0;i<5;i++){
                if(i>levTab){
                    _tabBelows.push(ConfigTabV3.getParamWithLevelTab(i))
                }
            }
            // console.warn("updateUrlWhenChangeTab _tabBelows",_tabBelows)
            HLink.clearParamOnUrl(_tabBelows);      
        }
    },
    checkIsChangeContents(lastContentObj,contents){
        let _contentString = "";
        if(contents && contents.length>0){
            for(let i=0;i<contents.length;i++){
                _contentString+=contents[i].Id
            }
        }
        if(lastContentObj && _contentString!=lastContentObj.string){
            lastContentObj.contents = contents;
            lastContentObj.string = _contentString;
            return true;
        }
        return false
    }
}

window.ConfigTabV3 = ConfigTabV3
export default ConfigTabV3