import React, { Component } from 'react';
import classnames from 'classnames';
import { Button, Card, CardBody } from '../../_importComponent';
import HConstant from '../../helpers/Constant';
import HUtils from '../../helpers/Utils';

class HeaderWithExpand extends Component {
  constructor(props) {
    super(props);
    this.state={
      isShowContent: false,
    }

    if(props.defaultShowContent){
      this.state.isShowContent = props.defaultShowContent;
    }
  }

  componentDidMount(){
  }

  reset=()=>{
    if(this.state.isShowContent==true){
      this.setState({
        isShowContent: false
      },()=>{
        this.setState({
          isShowContent: true
        })
      })
    }
  }

  render() {
    const {title,classBody,rightComponent,noExpand, hideTitle, noContent,configHeader,showIcon,fnList,styleTitle,className} = this.props;    
    let _classBody = classBody!=null ? classBody : "dFaiCjcC";
    if(configHeader && configHeader.show==false){
      return (
        <React.Fragment>          
          {
          configHeader.onlyButtonList===true &&
          <div className={"onlyBtnListHeader no-print"}>
            {
              rightComponent!=null &&
              <div className="no-print" style={{marginLeft: "auto",display: "flex",flexDirection: "row"}}>
              {
                typeof rightComponent == "function" ? rightComponent() :
                typeof rightComponent == "object" ? rightComponent : null
              }
              </div>
            }
          </div>
          }
          {
            this.state.isShowContent==true &&
            this.props.children 
          }
        </React.Fragment>
      )
    }
    // bo s_boxshadow
    return(
      <div className={classnames("header-container",className)}>    
        <Card className="">
          <CardBody style={{padding:'2px'}}>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingBottom:'2px'}}>
              <div style={{display:'flex',flexDirection:'row',}}>
                {
                  noExpand!==true &&
                  <Button style={{color:'#44cfae',padding:'0px',width:'30px'}} color="link" onClick={()=>{
                    this.setState({isShowContent:!this.state.isShowContent},()=>{
                      HUtils.runFnList(fnList,HConstant.UIFnPage.fnHeaderChangeExpand,this,[this.state.isShowContent])
                    })
                  }}>
                    <i style={{fontSize:'25px'}} className={`fa ${this.state.isShowContent?'fa-chevron-circle-up':'fa-chevron-circle-down'}`}></i>
                  </Button>
                }
                {
                  showIcon==true &&
                  <div style={{width:'30px'}} className="dFfdRaiCjcC">
                    <i className={`fa fa-list`}></i>
                  </div>
                }
                {
                  hideTitle !=true && 
                  <div style={{display:'flex',alignItems:'center',...styleTitle}} className="s_title_page">
                    {title}
                  </div> 
                }                
              </div>
              {
                rightComponent!=null &&
                <div className="no-print" style={{marginLeft: "auto",display: "flex",flexDirection: "row"}}>
                  {
                    typeof rightComponent == "function" ? rightComponent() :
                    typeof rightComponent == "object" ? rightComponent : null
                  }
                </div>
              }              
            </div>
            {
              noContent!==true &&
              <div className={_classBody} style={{display:this.state.isShowContent?'flex':'none',border:'0px',flexDirection:'column'}}>       
              {
                this.state.isShowContent==true &&
                this.props.children 
              }           
              </div>
            }            
          </CardBody>        
        </Card>      
      </div>
    )
  }
}

export default HeaderWithExpand;