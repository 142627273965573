import React, { Component } from 'react';
import ApiGeneric from '../../services/generic';
import Global from '../Global';
import Text from '../Text';
const MyData = function(obj) {
  obj = obj || {};

  if (obj instanceof MyData) return obj;
  if (!(this instanceof MyData)) return new MyData(obj);
  this._wrapped = obj;
  console.log('this._wrapped:',this._wrapped);
  this._name = obj.name;
  this._config = obj.config;
  if(!this._name){
    console.warn('missing Name in MyData');
  }

  this._options = null;
  this._dataList = null;
  this._dataExtraData = null;
  this._cPage = null;
  this._cTable = null;
};

MyData.prototype = {
  clearAll(){
    this._options = null;
    this._dataList = null;
    this._dataExtraData = null;
    this._cPage = null;
    this._cTable = null;
  },
  setCurrentPage(c){
    this._cPage = c;
  },
  setCurrentTable(c){
    this._cTable = c;
  }, 
  forceUpdatePage(){
    if(this._cPage){
      this._cPage.forceUpdate();
    }
  },
  reloadPage(){
    if(this._cPage){
      this._cPage.forceUpdate();
    }
  },
  getName(){
    return this._name;
  },
  getConfig(){
    return this._config;
  },
  getExtraDataOfColumn(extraData,fieldName){
    if(extraData && fieldName && extraData.Columns && extraData.Columns[fieldName]){
      return extraData.Columns[fieldName];
    }
    return {};
  },
  getCanEditOfColumn(extraData,fieldName){
    let _col = this.getExtraDataOfColumn(extraData,fieldName);
    if(_col.CanEdit!=null){
      return _col.CanEdit;
    }
    return false;
  },
  getCanShowOfColumn(extraData,fieldName){
    let _col = this.getExtraDataOfColumn(extraData,fieldName);
    if(_col.CanShow!=null){
      return _col.CanShow;
    }
    return false;
  },
  getOptions(opts){
    const {cb} = opts;
    let _v = this._options;
    if(_v){
      if(cb){
        cb({
          options: this._options
        });
      }
      return _v;
    }
    else{
      let _opts = {
        cb: function({options}){
          if(cb){
            cb({
              options: options
            });
          }
        },        
      };
      if(opts.requestData){
        _opts.requestData = opts.requestData;
      }
      this.requestOptions(_opts);
    }
  },
  getList(opts){
    const {cb} = opts;
    let _v = this._dataList;
    if(_v && opts.forceRequest!=true){
      console.log('getList:',this._dataList);
      if(cb){
        cb({
          dataList: this._dataList
        });
      }
      return _v;
    }
    else{
      this.requestList({
        ...opts,
        cb: function({dataList,dataExtraData}){
          if(cb){
            cb({
              dataList: dataList,
              dataExtraData, dataExtraData
            });
          }
        }
      });
    }
  },
  removeItemFromDataList(item){
    if(item && item.Id){
      let _data = this._dataList;
      // console.warn("data1",[].concat(_data));
      for(let i=0;i<_data.length;i++){
        if(_data[i].Id == item.Id){
          _data[i]._isDeleted = true;
          _data.splice(i,1);
          break;
        }
      }  
    }
  },
  addItemFromDataList(item){
    if(this._dataList){
      this._dataList.push(
        item
      );
      console.warn('addItemFromDataList:',item,this._dataList);
      this.forceUpdatePage();
    }
  },
  getRequestOptions(){
    return {
      method: 'POST',
      path: this._config.apiPath,
      name: this._config.apiNameOptions,
    }
  },
  requestOptions(opts){
    const {cb} = opts;
    ApiGeneric.generic({
      request:this.getRequestOptions(),
      data: opts.requestData||{},
      successCallBack:(response)=>{
        this._options = response.Data;
        if(cb){
          cb({
            options: this._options
          });
        }
      },
      errorCallBack:(error,response)=>{
        Global.LoadingScreen.showError(response!=null?response.Msg:Text.get('msg_error_get_options'),()=>{
          Global.LoadingScreen.show();
          this.requestOptions(opts);
        })
      }
    })
  },
  getRequestList(){
    return {
      method: 'POST',
      path: this._config.apiPath,
      name: this._config.apiNameList,
    }
  },
  getRequestListData(opts){
    let _queryData = {};
    if(opts && opts.requestData){
      _queryData = opts.requestData;
    }
    if(!_queryData.Url){
      _queryData.Url = window.location.href;
    }
    return _queryData;
  },
  requestList(opts){
    const {cb} = opts;
    ApiGeneric.generic({
      request:this.getRequestList(),
      data: this.getRequestListData(opts),
      successCallBack:(response)=>{
        if(response.Data && response.Data.hasOwnProperty('Data')){
          this._dataList = response.Data.Data;
          this._dataExtraData = response.Data.ExtraData;
        }
        else if(response.Data && response.hasOwnProperty('ExtraData')){ /** Truong hop cua BookingItemAssetForCalendar */
          this._dataList = response.Data;
          this._dataExtraData = response.ExtraData;
        }
        
        // console.warn('requestList: ',this,response);
        if(cb){
          cb({
            dataList: this._dataList,
            dataExtraData: this._dataExtraData,
          });
        }
      },
      errorCallBack:(error,response)=>{
        Global.LoadingScreen.showError(response!=null?response.Msg:'Lỗi lấy List!',()=>{
          Global.LoadingScreen.show();
          this.requestList(opts);
        })
      }
    })
  },
  getRequestUpdateRow(){
    return {
      method: 'POST',
      path: this._config.apiPath,
      name: this._config.apiNameUpdateRow||'UpdateFields',
    }
  },
  updateRowWithIdFromResponse({response,row}={}){
    if(response.Data!=null && response.Data.Id==row.Id){
      if(this._dataList){
        let _data = this._dataList;
        for(let i=0;i<_data.length;i++){
          if(_data[i].Id == response.Data.Id){
            for(let _key of Object.keys(_data[i])){
              _data[i][_key] = response.Data[_key];
            }
            break;
          }
        }
      }        
    }     
  },
  requestUpdateRow(row, cellName, cellValue, opts){
    if(row[cellName]==null && cellValue==""){
      return;
    }
    else if(row[cellName]==cellValue){
      return;
    }
    let _data = {
      ProjectId: row.ProjectId,
      Id: row.Id,
      Values:[{
        FieldName: cellName,
        NewValue: cellValue,
      }]
    };
    ApiGeneric.generic({
      request:this.getRequestUpdateRow(),
      data:_data,
      successCallBack:(response)=>{        
        this.updateRowWithIdFromResponse({response,row});
        Global.Toast.showSuccess(response.Msg);
        Global.TableHelper.Updating.remove(row,cellName);        
        // this.forceUpdate();
        this.forceUpdatePage();
      },
      errorCallBack:(error,response)=>{
      }
    })
  },
  requestAddWithData(opts){
    const {cb,request,requestData} = opts;
    ApiGeneric.generic({
      request:request,
      data:requestData,
      successCallBack:(response)=>{
        if(response.Msg){
          Global.Toast.showSuccess(response.Msg);
        }
        this.addItemFromDataList(response.Data);
        // if(this._dataList){
        //   this._dataList.push(
        //     response.Data
        //   );
        // }
        console.log('requestAddWithData:',response.Data,this._dataList);
        if(cb){
          cb({
            data: response.Data
          });
        }
      },
      errorCallBack:(error,response)=>{
        if(cb){
          cb({
            isError: true,
            error: error,
            response: response,
          });
        }
      }
    })
  },
  requestDelete(row,opts){
    const {cb,request,requestData,isDeleteWithData} = opts;
    let _query = {
      Id: row.Id,
    }
    let _pathName = `Delete/${row.Id}`;
    if(isDeleteWithData==true){
      _pathName = "Delete";
    }
    if(requestData){
      _query = Object.assign(_query,requestData);
    }
    ApiGeneric.generic({
      request:request||{
        method: 'POST',
        path: this._config.apiPath,
        name: _pathName
      },
      data:_query,
      successCallBack:(response)=>{  
        Global.Toast.showSuccess(response.Msg);   
        
        let _item = response.Data;
        if(_item==null || _item.Id==null){
          _item = row;
        }
        this.removeItemFromDataList(_item);
        console.warn('dataList',[].concat(this._dataList));
        this.forceUpdatePage();     
      },
      errorCallBack:(error,response)=>{        
      }
    })
  }
};

const Data =  {
  MyData: MyData,
  CreateNew: function(opts){
    if(opts && opts.name){
      Data[opts.name] = new MyData(opts);
    }
  },
  CRMContact: new MyData({
    name: 'CRMContactInCRMOpty',
    config: {
      apiPath: 'CRMContactInCRMOpty',
      apiNameList: 'ListForCRMOpty',
      apiNameOptions: 'Options',
    },
  }),
  CRMTask: new MyData({
    name: 'CRMTask',
    config: {
      apiPath: 'CRMTask',
      apiNameList: 'ListForCRMOpty',
      apiNameOptions: 'Options',
    },
  }),
  ProjectEVM: new MyData({
    name: 'ProjectEVM',
    config: {
      apiPath: 'ProjectEVMModule',
      apiNameList: 'GetEVMForProject',
      apiNameOptions: 'Options',
    },
  }),
  ProjectEVMCollection: new MyData({
    name: 'ProjectEVMCollection',
    config: {
      apiPath: 'ProjectEVMCollectionModule',
      apiNameList: 'GetEVMForProject',
      apiNameOptions: 'Options',
    },
  }),
  ProjectEVMV2: new MyData({
    name: 'ProjectEVMV2',
    config: {
      apiPath: 'ProjectEVMModule_V2',
      apiNameList: 'GetEVMForProject',
      apiNameOptions: 'Options',
    },
  }),
  ProjectTimeControl: new MyData({
    name: 'ProjectTimeControl',
    config: {
      apiPath: 'ProjectTimeControl',
      apiNameList: 'List',
      apiNameOptions: 'Options',
    },
  }),
  ProjectRequirementDocTimeControl: new MyData({
    name: 'ProjectRequirementDocTimeControl',
    config: {
      apiPath: 'ProjectRequirementDocTimeControl',
      apiNameList: 'List',
      apiNameOptions: 'Options',
    },
  }),
  // ProjectRiskGeneral: new MyData({
  //   name: 'ProjectRiskGeneral',
  //   config: {
  //     apiPath: 'ProjectRiskGeneral',
  //     apiNameList: 'List',
  //     apiNameOptions: 'Options',
  //   },
  // }),
}

export default Data;