import React, { Component } from "react";
import "./styles.css";
import { HFilter } from "../../apps/Helpers";
class MyTableFilterData extends Component {
  constructor(props) {
    super(props);
    this._optsSearch = {
      exactly: true, //search nguyen 1 cum tu hay tach tung tu
      bothWord: true, //trong truong hop exactly = false, co can thiet co mat tat ca cac tu trong cum tu hay ko
      trim: true, //co trim word hay ko
      xoadau: true, //co xoa dau tieng viet hay ko
      ignoreCase: true, //Bo qua hoa thuong
      ignoreFields: null, //Mang field bo qua ko search
      onlyFields: null, //Chi search o trong mang field nay
    };
  }

  componentDidMount() {}

  _onSearchText = (searchText, dataList) => {
    const { configFilter } = this.props;
    if (configFilter && configFilter.optsSearch) {
      this._optsSearch = Object.assign(
        this._optsSearch,
        configFilter.optsSearch
      );
    }
    if(dataList && dataList.length>0 && dataList[0].hasOwnProperty("Text_Filter")){
      this._optsSearch.xoadau = true;
      this._optsSearch.onlyFields = ["Text_Filter"];
    }
    
    let _arrFiltered = null;
    let _dataList = dataList || this.state.dataList;
    if (searchText && _dataList) {
      // let _arrSearch = searchText.trim().toLowerCase().split(/\s+/);
      let _newSearchText = HFilter.buildTextByOptsSearch(searchText,this._optsSearch);
      // console.warn("_onSearchText:",searchText,_newSearchText,this._optsSearch,configFilter);
      _arrFiltered = _dataList.filter((v, i) => {
        // let _res = false;
        // if (v){
        //   let _fields = Object.keys(v);
        //   // _fields.map((v2,i2)=>{
        //   // })
        //   for (let j=0;j<_fields.length;j++){

        //     let _s = v[_fields[j]];
        //     // console.warn('s: ',_s);
        //     for (let k=0;k<_arrSearch.length;k++){
        //       if (typeof _s == 'string' &&_s.toLowerCase().indexOf(_arrSearch[k])>-1){
        //         return true;
        //       }
        //     }
        //   }
        // }
        // return _res;
        return HFilter.hasTextInObj(v, _newSearchText, this._optsSearch);
      });
    }
    if (dataList) {
      return _arrFiltered;
    }
  };
  _onFilterData = (filter, dataList) => {
    // const {data,configFilter,searchText} = this.props;
    //first time: no filter condition0: true
    // console.warn(`onFilterData`,filter,dataList);
    if (filter && dataList) {
      let _dataList = dataList;
      let _arrFiltered = _dataList || [];
      // console.warn('filter: ', filter);
      if (filter && Object.keys(filter).length > 0 && _dataList) {
        let _keysFilter = Object.keys(filter);
        for (let l = 0; l < _keysFilter.length; l++) {
          let _fieldName = _keysFilter[l];
          let _filter = filter[_fieldName];
          if (_filter.value && _filter.isServer !== true && _dataList) {
            let _filterValue = _filter.value;
            if (_filter.type == "ArrayFilter") {
              _arrFiltered = _arrFiltered.filter((v, i) => {
                if (v) {
                  let _s = v[_fieldName];
                  for (let k = 0; k < _filterValue.length; k++) {
                    if (_s == _filterValue[k]) {
                      if(_filter.isFilterHide===true){
                        return false;
                      }
                      return true;
                    }
                  }
                }
                if (!v.hasOwnProperty(_fieldName)) {
                  return true;
                }
                if(_filter.isFilterHide===true){
                  return true;
                }
                return false;
              });
            } else if (_filter.type == "TextFilter") {
              _arrFiltered = _arrFiltered.filter((v, i) => {
                if (v) {
                  // let _s = v[_fieldName];
                  // if(_s){
                  //   let _splitSpace = _filterValue.split(' ');
                  //   for (let k=0;k<_splitSpace.length;k++){
                  //     if (_s.toLowerCase().indexOf(_splitSpace[k].toLowerCase())>-1){
                  //       return true;
                  //     }
                  //   }
                  // }
                  return HFilter.hasTextInObj({ value: v }, _filterValue);
                }
                if (!v.hasOwnProperty(_fieldName)) {
                  return true;
                }
                return false;
              });
            } else if (_filter.type == "SelectFilter") {
              _arrFiltered = _arrFiltered.filter((v, i) => {
                if (v) {
                  let _s = v[_fieldName];
                  if (_s == _filterValue) {
                    if(_filter.isFilterHide===true){
                      return false;
                    }
                    return true;
                  }
                }
                if (!v.hasOwnProperty(_fieldName)) {
                  return true;
                }
                if(_filter.isFilterHide===true){
                  return true;
                }
                return false;
              });
            
            } else if (_filter.type == "RegexFilter") {
              _arrFiltered = _arrFiltered.filter((v, i) => {
                if (v) {
                  let _s = v[_fieldName];
                  if ( new RegExp(_filterValue, 'i').test(_s)) {
                    if(_filter.isFilterHide===true){
                      return false;
                    }
                    return true;
                  }
                }
                if (!v.hasOwnProperty(_fieldName)) {
                  return true;
                }
                if(_filter.isFilterHide===true){
                  return true;
                }
                return false;
              });
            } else if (_filter.type == "RowFilter") {
              _arrFiltered = _arrFiltered.filter((v, i) => {
                if (v) {
                  let _s = v[_fieldName];
                  for (let k = 0; k < _filterValue.length; k++) {
                    if (_s == _filterValue[k]) {
                      return false;
                    }
                  }
                }
                if (!v.hasOwnProperty(_fieldName)) {
                  return true;
                }
                return true;
              });
            } else if (_filter.type == "NumberFilter") {
              _arrFiltered = _arrFiltered.filter((v, i) => {
                if (v) {
                  let _s = v[_fieldName];
                  // _filter.value
                  if (typeof _filter.value == "object") {
                    let _value = _filter.value;
                    if (
                      _value.hasOwnProperty("min") &&
                      _value.hasOwnProperty("max")
                    ) {
                      if (_s >= _value.min && _s <= _value.max) {
                        return true;
                      }
                    }
                    else if(_value.hasOwnProperty("min")){
                      if (_s >= _value.min) {
                        return true;
                      }
                    }
                    else if(_value.hasOwnProperty("max")){
                      if (_s <= _value.max) {
                        return true;
                      }
                    }
                    else{
                      if(_value.hasOwnProperty("comparator") && _value.hasOwnProperty("number")){ // value: {comparator: "> ||....", number: number}
                        switch (_value.comparator) {
                          case ">":
                            if (_s > _value.number) {
                              return true;
                            }
                            break;
                          case "<":
                            if (_s < _value.number) {
                              return true;
                            }
                            break;
                          case ">=":
                            if (_s >= _value.number) {
                              return true;
                            }
                            break;
                          case "<=":
                            if (_s <= _value.number) {
                              return true;
                            }
                            break;
                          case "==":
                            if (_s == _value.number) {
                              return true;
                            }
                            break;
                          case "!=":
                            if (_s != _value.number) {
                              return true;
                            }
                            break;
                          default:
                            break;
                        }
                      }
                    }
                  }
                }
                if (!v.hasOwnProperty(_fieldName)) {
                  return true;
                }
                return false;
              });
            }  
            else if (typeof _filter.value == "object") {
              console.warn("filter CustomFilter1:",_filter);
              if (_filter.value.type == "CustomFilter") {
                if (_filter.value.start && _filter.value.end) {
                  console.warn("filter CustomFilter2:",_filter);
                  _arrFiltered = _arrFiltered.filter((v, i) => {
                    if (v) {
                      let _s = v[_fieldName];
                      // console.warn("check:",_s,_s>= _filter.value.start,_s<=_filter.value.end);
                      if (
                        _s >= _filter.value.start &&
                        _s <= _filter.value.end
                      ) {
                        return true;
                      }
                    }
                    if (!v.hasOwnProperty(_fieldName)) {
                      return true;
                    }
                    return false;
                  });
                }
              }
            }
          }
        }
      } else {
        _arrFiltered = _dataList;
      }
      // console.warn("filterData:",_arrFiltered);
      if (
        filter._SearchText &&
        filter._SearchText.value &&
        filter._SearchText.isServer !== true
      ) {
        _arrFiltered = this._onSearchText(
          filter._SearchText.value,
          _arrFiltered
        );
      }
      return _arrFiltered;
    } else {
      return dataList;
    }
  };

  render() {
    const { configFilter, data, currentFilter } = this.props;
    // console.warn("render MyTableFilterData",configFilter,data);
    let _content = null;
    let _dataFiltered = this._onFilterData(currentFilter, data);
    if (this.props.children && typeof this.props.children === "function") {
      _content = this.props.children({
        dataFiltered: _dataFiltered,
      });
    }
    return _content;
  }
}

export default MyTableFilterData;
