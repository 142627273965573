import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./styles/common.css";
import {
  Button,
  Card,
  CardHeader,
  TabContent,
  TabPane,
  PageExtV2,
  MyPageFromConfig,
  HC,
  HUtils,
  MyUI
} from "../_import";
import Tabv3_ext from "./tabv3_ext";
import Tabv3_SearchBar from "../../MyUI/Types/tabv3_searchbar"
import ConfigTabV3 from "../../../model/ConfigTabV3"
class PageTab3L5 extends Tabv3_ext {
  constructor(props) {
    super(props);
    this.keyRightPositon = "";
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    if (this._isNeedRequestOptions()) {
      this.requestOptions({
        cbSuccess: () => {},
      });
    }
  }
  getHasProject=()=>{
    const {fnList} = this.props;
    if(fnList && fnList.getIsHasProject){
      return fnList.getIsHasProject();
    }
    return false;
  }
  _isNeedRequestOptions = () => {
    const { configPage } = this.props;
    if (configPage && configPage.Contents) {
      return false;
    }
    return true;
  };
  
  renderTab = () => {
    const { fnList, configPage } = this.props;
    const contents = this.getContents();
    return (
      <div className="tab_lv5_wrapper no-print">
        <div className="tab_lv5">
          <div className="title">
            {configPage.Title} <i className="fa fa-chevron-right" />
          </div>
          {contents.map((v, i) => {
            return (
              <Button
                key={i}
                onClick={() => {
                  this.changeTab({ index: i,item: v });
                }}
                className={`btn-tab-lv5 ${
                  this.getTabIsActive({ index: i }) ? "active" : ""
                }`}
              >
                {v.Title}
              </Button>
            );
          })}
        </div>
        <div className="tab_lv5-right">
          <Tabv3_SearchBar ref={r=>{this._cSearchBar=r;}} componentSearchBar={this._componentSearchBar}>          
          </Tabv3_SearchBar>                    
        </div>
      </div>
    );
  };
  renderContent = () => {
    const { fnList, currentProject } = this.props;
    const contents = this.getContents();
    let _levelTabIndex = this.getCurrentLevelTabIndex();
    if(ConfigTabV3.checkUrlWithContent(`t${_levelTabIndex}`,contents)==false){
      return (
        <MyPageFromConfig 
          key="pagenotfound"
          configPage={{
            Id: "notfound",
            UIType: "pagenotfound"
          }}
        />
      )
    }
    let _ui = [];
    return (
      <div className="content_lv5">
        <TabContent
          activeTab={this.getTabActive({ currentProject })}
          style={{ border: "0px", background: "transparent" }}
        >
          {contents.map((v, i) => {
            let _tabId = this.getTabId({
              index: i,
              currentProject: currentProject,
            });
            let _isLoaded = this.getTabIsLoaded({ index: i, currentProject });
            // console.warn("render tablev5:", _tabId, _isLoaded, this.state);
            if (_isLoaded) {
              if(_tabId==this.getTabActive({})){
                ConfigTabV3.setTabByLev(5,v.Id)
              }
              return (
                <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
                  <MyPageFromConfig
                    key={_tabId}
                    configPage={v}
                    fnList={{
                      ...fnList,    
                      ...this._sharedFnList,
                      getParentLevelTabIndex: this.getCurrentLevelTabIndex,                  
                      updateConfigSearchBar:(configPage,configSearchBar)=>{
                        if(configPage && configPage.Id){  
                          if(i==this.state.activeTabIndex){//chi load tab active
                            if(this._cSearchBar){
                              this._cSearchBar.load({
                                configPage,
                                configSearchBar,
                              })
                            }
                          }                                                
                        }
                      }
                    }}
                  />
                </TabPane>
              );
            } else {
              return (
                <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
                  <div>........</div>
                </TabPane>
              );
            }
          })}
        </TabContent>
      </div>
    );
  };
  render() {
    return (
      <div className="app">
        {this.renderTab()}
        {this.renderContent()}
      </div>
    );
  }
}
// export default PageTab3L5
const mapStateToProps = (state) => {
  return {
    currentProject: state.solidv3.currentProject,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setObjInSolidV3: (obj) => dispatch({ type: "setObjInSolidV3", ...obj }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PageTab3L5);
