import { IHModules, IOneModule } from '../interface'
const Modules: IHModules = {
  list: [],
  moduleByKey: {},
  configModules: {},
  initModules(list: IOneModule[], configModules: any, HConfig: any) {
    Modules.list = list
    Modules.configModules = configModules
    if (Modules.list && Modules.list.length > 0) {
      for (let i = 0; i < Modules.list.length; i++) {
        let _oneModule = Modules.list[i]
        if (_oneModule) {
          if (_oneModule.getMyKey) {
            let _key = _oneModule.getMyKey()
            Modules.moduleByKey[_key] = _oneModule
          }
          if (_oneModule.runAfterInit) {
            _oneModule.runAfterInit(HConfig)
          }
        }
      }
    }
  },
  getConfigModuleByKey(key: string) {
    return Modules.configModules[key]
  }
}

export default Modules
