import React, { Component } from 'react';
import HColor from './Color';
const Options = {
  createListDataFromFieldNameOfDataList(fieldName,list,opts={}){
    // console.warn('createListDataFromFieldNameOfDataList:',fieldName,list,opts);
    let _arr = [];
    if(fieldName && list && list.length>0){
      let _arrValue = [];
      let _keyValue = 'Value';
      let _keyText = 'Text';
      if(opts){
        if(opts.keyText){
          _keyText = opts.keyText;
        }
        if(opts.keyValue){
          _keyValue = opts.keyValue;
        }
        if(opts.isSelect2){
          _keyValue = 'value';
          _keyText = 'label';
        }
      }
      for(let i in list){
        let _item = list[i];
        let _value = _item[fieldName];
        let _isValidValue = false;
        if(_value){
          _isValidValue = true;
        }
        if(opts.keepEmpty==true && opts.textEmpty){
          _isValidValue = true;
        }
        if(_arrValue.indexOf(_value)==-1 && _isValidValue){
          let _obj = {};
          _obj[_keyText] = _value || opts.textEmpty || "";
          _obj[_keyValue] = _value;
          if(opts.fnGetText){
            _obj[_keyText] = opts.fnGetText(_value);
          }
          if(_value){
            _arr.push(_obj);
            _arrValue.push(_value);
          }
          else{
            _arr.unshift(_obj);
            _arrValue.unshift(_value);
          }          
        }        
      }
    }
    // console.warn("createListDataFromFieldNameOfDataList:",_arr);
    return _arr;
  },
  createListForSelect({sourceList,itemEmpty,currentValue,currentValueText,isNotAutoAddWhenNotExist}={}){
    let _ui = [];
    if(sourceList!=null){
      if(itemEmpty!=null){
        _ui.push(
          <option key={-1} value={""}>{itemEmpty}</option>
        );
      }
      let _isExistCurrentValue = false;
      for(let i=0;i<sourceList.length;i++){
        if(sourceList[i]!=null){//fix truong hop item source null
          let _value = sourceList[i].Value;
          let _style={};
          let _colorCode = sourceList[i].ColorCode;
          if(_colorCode){
            _style.backgroundColor = _colorCode;          
            let _colorText = HColor.getTextColorBG(_colorCode);          
            if(_colorText){
              _style.color = _colorText;
            }
          }
          if(currentValue!=null && currentValue==_value){
            _isExistCurrentValue = true;
          }
          _ui.push(
            <option key={i} value={_value} style={_style}>{sourceList[i].Text}</option>
          );
        }
        else{
          console.warn("Source item null",sourceList);
        }
      }
      if(_isExistCurrentValue==false && currentValue!=null && currentValue!='' && isNotAutoAddWhenNotExist!=true){
        _ui.push(
          <option key={'c'} value={currentValue}>{currentValueText||currentValue}</option>
        );
      }
    }
    return _ui;
  },
  createOptionsForSelect(options,msgEmpty){
    let _ui = [];
    if(options!=null){
      if(msgEmpty!=null){
        _ui.push(
          <option key={-1} value={""}>{msgEmpty}</option>
        );
      }
      for(let i=0;i<options.length;i++){
        _ui.push(
          <option key={i} value={options[i].Value}>{options[i].Text}</option>
        );
      }
    }
    return _ui;
  },
  convertOptionsApiToSelect2({sourceList,msgEmpty}){
    let _sourceList = [];
    if(msgEmpty!=null){
      _sourceList.push({
        value: "",
        label: msgEmpty,
      });
    }
    if(sourceList!=null && sourceList.length>0){
      _sourceList = sourceList.map((e,i)=>{
        let _obj = {value: e.Value, label: e.Text};
        if(e.ColorCode){
          _obj.ColorCode = e.ColorCode;
        }
        return _obj;
      })
    }
    return _sourceList;
  },
  createOptionsForSelect2(options,msgEmpty){//for Global.Helper.createOptionsForSelect2
    return Options.convertOptionsApiToSelect2({
      sourceList: options,msgEmpty
    })
  },
  updateOptionsSupportSelect2(options){
    if(options && options.length>0){
      for(let i=0;i<options.length;i++){
        let _item = options[i];
        if(_item.value!=null){
          return options;
        }
        _item.value = _item.Value;
        _item.label = _item.Text;
      }
    }
    return options;
  },
  getValuesFromOptions(options){
    let _values = [];
    if(options!=null){
      for(let i=0;i<options.length;i++){
        _values.push(options[i].Value);
      }
    }
    return _values;
  },
  getTextFromOptions(options){
    let _values = [];
    if(options!=null){
      for(let i=0;i<options.length;i++){
        _values.push(options[i].Text);
      }
    }
    return _values;
  },
  getValueAndTextFromOptions(options){
    let _values = [];
    if(options!=null){
      for(let i=0;i<options.length;i++){
        _values.push({
          text: options[i].Text,
          value: options[i].Value
        });
      }
    }
    return _values;
  },
  getTextOfValueFromOptions(options,value){
    let _text = '';
    if(options!=null){
      for(let i=0;i<options.length;i++){
        if(options[i].Value==value){
          _text = options[i].Text;
          break;
        }
      }
    }
    return _text;
  },
  getTextDisplayOfValueFromOptions(options,value){
    let _text = '';
    if(options!=null){
      for(let i=0;i<options.length;i++){
        if(options[i].Value==value){
          _text = options[i].Text_Display || options[i].Text;
          break;
        }
      }
    }
    return _text;
  },
  getItemOfValueFromOptions(options,value){
    let _item = null;
    if(options!=null){
      for(let i=0;i<options.length;i++){
        if(options[i].Value==value){
          _item = options[i];
          break;
        }
      }
    }
    return _item;
  },
  getNextItemOfValueFromOptions(options,value){
    let _item = null;
    if(options!=null){
      for(let i=0;i<options.length;i++){
        // console.warn('nextItem: ', i,options[i],options[i].Value, value);
        if(options[i].Value==value){
          if(i==options.length-1){
            _item=options[0];
          }
          else{
            _item = options[i+1];
          }          
          break;
        }
      }
    
    }

    return _item;
  },
  UpdateOptionsInList(options={},listFieldName,listData,{listFieldTextByFieldOptionsInList}={}){//copy from Global.Options
    let _options = options;
    let _newFieldName = {};
    let _listOnly = {};
    let _listOptions = {};
    for(let i=0;i<listFieldName.length;i++){
      _listOnly[listFieldName[i]]=[];
      _listOptions[listFieldName[i]]=[];
      _newFieldName[listFieldName[i]]= listFieldName[i]+'_InList';
    }
    if(listData!=null){
      for(let i=0;i<listData.length;i++){
        let _item = listData[i];
        for(let j=0;j<listFieldName.length;j++){
          let _fieldName = listFieldName[j];
          let _value = _item[_fieldName];
          if(_listOnly[_fieldName].indexOf(_value)==-1){
            let _text = _value;
            if(listFieldTextByFieldOptionsInList && listFieldTextByFieldOptionsInList.hasOwnProperty(_fieldName)){
              _text = _item[listFieldTextByFieldOptionsInList[_fieldName]] || "";
            }
            _listOptions[_fieldName].push({
              label: _text, Text: _text, 
              value: _value, Value: _value,
            })
            _listOnly[_fieldName].push(_value);
          }
        }
      }
    }
    for(let i=0;i<listFieldName.length;i++){
      _options[_newFieldName[listFieldName[i]]]= _listOptions[listFieldName[i]];
    }
    return _options;
  },
}
export default Options;