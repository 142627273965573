import React from 'react'
import MyLibTypes from '../../../../v1/components2/MyLib/MyLibTypes'
import CSuppense from '../../../../v1/components2/MyCore/CSuspense'

const MLganttchart = React.lazy(() => import('../../../../v1/components2/MyLib/Types/ganttchart'));

const AppMyLibTypes = {
  init(){    
    MyLibTypes.addType('ganttchart',(props)=>{ return CSuppense(props,MLganttchart)  });
  }
}
export default AppMyLibTypes;