import * as React from 'react'
import MyButtonHelpers from 'src/components/MyButton/Helpers'
import MyIcon from 'src/components/MyIcon'
import { isObject, isString, isJsonString, isHexColor } from 'src/helpers/Is'
import { json2Obj } from 'src/helpers/Json'
import HConstant from 'src/helpers/Constants'
import { logNoAnyType } from 'src/helpers/Log'
interface IOneButtonConfig {
  APIName?: string
  Code?: string
  Color?: string
  Color_BG?: string
  ConfirmMsg?: string
  Form?: any
  Icon?: string
  MethodType?: string
  More?: any
  Name?: string
  NeedReload?: string
  NeedReloadList?: string
  OpenMode?: string
  RequestData?: any
  Type?: string
  Url?: string
}

interface IOneButton {
  _configButton: any
}

interface IOneButtonOpts {
  configButton: any
  onePage?: any
}

interface IOnePage {
  getApiController: () => string | any
  getQueryShared: () => any
  getSourceList: () => any
}

class OneButton implements IOneButton {
  _configButton: IOneButtonConfig = {}
  _moreObj: any = {}
  _type: string = ''
  _isLoading: boolean = false
  _onePage?: IOnePage
  _fromComponent: any
  _inDropdown?: boolean = false
  constructor(opts: IOneButtonOpts) {
    if (opts.configButton) {
      this._configButton = opts.configButton
      this.parseMore(this._configButton)
    }
    if (opts.onePage) {
      this._onePage = opts.onePage
    }
  }
  setOnePage(onePage: any) {
    this._onePage = onePage
  }
  setInDropdown(v: boolean) {
    this._inDropdown = v
  }
  setFromComponent(component: any) {
    if (component) {
      this._fromComponent = component
    }
  }
  changeIsLoading(isLoading: any) {
    this._isLoading = isLoading
    this.forceUpdate()
  }
  parseMore(configButton: any) {
    if (configButton && configButton.More) {
      if (isObject(configButton.More)) {
        this._moreObj = {}
        let _keys = Object.keys(configButton.More)
        if (_keys && _keys.length > 0) {
          for (let k of _keys) {
            this._moreObj[k] = configButton.More[k]
            if (isJsonString(this._moreObj[k])) {
              this._moreObj[k] = json2Obj(this._moreObj[k], {})
            }
          }
        }
      } else if (isString(configButton.More)) {
        this._moreObj = json2Obj(configButton.More, {})
      }
    }
  }
  getTitleDisplay() {
    let _configButton = this._configButton || {}
    let _title = _configButton.Name
    let _icon: any = _configButton.Icon
    if (this._isLoading === true) {
      _icon = <MyIcon configIcon='type:loading' />
    } else if (_icon) {
      _icon = <MyIcon configIcon={_icon} />
    }
    return (
      <React.Fragment>
        {_icon}
        {_title}
      </React.Fragment>
    )
  }
  getLinkTarget() {
    let _configButton = this._configButton || {}
    if (_configButton === 'NewWindow') {
      return '_blank'
    } else {
      return '_self'
    }
  }
  getMorePropsButton() {
    let _configButton = this._configButton || {}
    let _type = this.getType()
    let _color =
      _configButton.Color || (_type === 'api' ? 'success' : 'primary')
    let _outline = false
    let _moreProps: any = {
      color: _color,
      outline: _outline,
      style: {}
    }
    if (HConstant.Array.colors.indexOf(_color) > -1) {
      _moreProps.color = _color
    } else {
      _moreProps.color = 'link'
      if (isHexColor(_color)) {
        _moreProps.style['color'] = _color
      }
      if (_configButton.Color_BG && isHexColor(_configButton.Color_BG)) {
        _moreProps.style['background'] = _configButton.Color_BG
      }
    }
    if (this._moreObj) {
      if (this._moreObj.color != null) {
        _moreProps.color = this._moreObj.color
      }
      if (this._moreObj.className != null) {
        _moreProps.className = this._moreObj.className
      }
      if (this._inDropdown === true) {
        if (_moreProps.className) {
          _moreProps.className += ` dropdown-item`
        } else {
          _moreProps.className = `dropdown-item`
        }
      }
      if (this._moreObj.outline != null) {
        _moreProps.outline = this._moreObj.outline
      }
      if (this._moreObj.title) {
        _moreProps['title'] = this._moreObj.title
      }
      if (this._moreObj.style != null) {
        _moreProps.style = Object.assign(_moreProps.style, this._moreObj.style)
      }
      if (this._moreObj.disabled === true) {
        _moreProps['disabled'] = true
      }
    }
    if (this._isLoading) {
      _moreProps['disabled'] = true
    }
    return _moreProps
  }
  getType() {
    let _configButton = this._configButton || {}
    let _type = _configButton.Type
    if (_type == null) {
      //truong hop mac dinh null
      if (_configButton.Form) {
        _type = 'form'
      } else if (_configButton.APIName) {
        _type = 'api'
      } else if (_configButton.Url) {
        _type = 'link'
      } else {
        _type = ''
      }
    }
    if (isString(_type)) {
      _type = _type.toLocaleLowerCase()
    }
    this._type = _type
    // console.warn('getType:', this._type, _configButton)
    return this._type
  }
  getUrlLink() {
    return this._configButton.Url
  }
  getApiRequest() {
    let _configButton = this._configButton || {}
    let _request = {
      method: 'POST'
    }
    if (_configButton.MethodType) {
      _request.method = _configButton.MethodType
    }
    let _apiName = _configButton.APIName
    if (_apiName) {
      if (_apiName.indexOf('/') > -1) {
        _request['url'] = `/api/v1/${_apiName}`
      } else {
        if (this._onePage) {
          let _apiController = this._onePage.getApiController()
          _request['path'] = _apiController
          _request['name'] = _apiName
        }
      }
    }
    return _request
  }
  getApiQuery(customQuery?: any, df: any = {}) {
    let _query = df || {}
    if (this._onePage) {
      _query = { ..._query, ...this._onePage.getQueryShared() }
    }
    if (
      this._configButton.RequestData &&
      isObject(this._configButton.RequestData)
    ) {
      _query = { ..._query, ...this._configButton.RequestData }
    }
    if (customQuery) {
      _query = { ..._query, ...customQuery }
    }
    return _query
  }
  getMyButtonFn() {
    let _type = this.getType()
    // console.warn('getMyButtonFn:', _type)
    if (MyButtonHelpers.Types[_type]) {
      return MyButtonHelpers.Types[_type]
    } else {
      logNoAnyType(`MyButton:${_type}`, this)
    }
    return MyButtonHelpers.Types._empty
  }
  getMyButton(props: any) {
    let _myButtonFn = this.getMyButtonFn()
    return _myButtonFn({ oneButton: this, ...props })
  }
  renderMyButton(props: any) {
    const Component = this.getMyButtonFn()
    return <Component oneButton={this} {...props} />
  }
  forceUpdate() {
    if (this._fromComponent && this._fromComponent.forceUpdate) {
      this._fromComponent.forceUpdate()
    }
  }
  getSourceList() {
    return this._onePage?.getSourceList()
  }

  static createListButtonList(list: OneButton[], fromOnePage?: any) {
    let _arr = []
    if (list) {
      for (let i = 0; i < list.length; i++) {
        let _oneButton = new OneButton({
          configButton: list[i],
          onePage: fromOnePage
        })
        _arr.push(_oneButton)
      }
    }
    return _arr
  }
}

export default OneButton
