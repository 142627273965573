import arraySort from "array-sort";
import moment from "moment";
import React, { Component } from 'react';
import cSVG from './cSVG';

class MilestoneChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                category: {},
                startDate: null,
                endDate: null,
            },
            options: {},
            dfOptions: {
                //Chỉnh style của div bao quanh chart
                style: {
                    width: "100%",//chiều rộng của div
                    height: "auto",//calculate
                    borderStyle: "solid",//border style
                    borderColor: "#cccccc",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    bordeWidth: "1px",
                    marginTop: 10,//margin với chart trước nó
                },
                outer: {
                    width: 0,//calculate
                    height: 0,//calculate
                    face: 0,//calculate
                    main: 0,//calculate
                },
                chart: {
                    width: 0,//calculate
                    height: 0,//calculate
                    paddingX: 20,//canh trái, phải
                    paddingY: 5,//canh trên, dưới
                    line: {
                        tick: {//Tick ngắn được chia đều để chỉ định thời gian
                            height: 8,//chiều cao của tick
                            width: 1,//độ rộng của tick
                            color: "#000000",//màu tick
                        },
                        main: {//Line chính canh vẽ trên dưới
                            height: 8,//độ dày của line
                            color: "#000000",//màu line
                        },
                        step: {//Chiều dài 1 step trên chart
                            width: 0,//calculate
                        },
                    },
                    legend: {
                        rect: {//Hình chữ nhật của legend
                            paddingX: 10,//canh trái so với rect trước nó
                            paddingY: 5,//canh trên so với rect trên nó
                            paddingChart: 20,//canh trên so với chart(đối với rect đầu)
                            width: 80,//chiều rộng của rect
                            height: 20,//chiều cao của rect
                            rx: 10,//border radius x
                            ry: 10,//border radius y
                            strokeWidth: 2,
                        },
                        text: {//Text của legend
                            default: "No text",//default text
                            fill: "black",//màu fill
                            height: 10,//chiều cao so với font-size - cái này chưa xác định được dựa theo font-size nên phải fix
                            fontSize: 12,//font-size
                            fontWeight: "normal",//font weight
                            textAnchor: "left",//canh trái-phải-giữa
                            maxLength: 15,//độ dài tối đa của ký tự - dài quá sẽ được cắt bớt
                            maxWidth: 160,//chiều dài tối đa của text - hiện tại nó được xem như width(chiều dài cố định)
                            marginX: 2,//canh trái so với rect
                        },
                        tooltip: {//Tooltip của legend
                            show: true,//ẩn hiện tooltip
                        },
                        show: true,//ẩn hiện legend
                    },
                    title: {//Title của chart
                        height: 21,//chiều cao của thẻ h5
                    },
                },
                timeTick: {//Dữ liệu biến động
                    line: {
                        vertical: {//Line milestone(line dài thẳng đứng)
                            height: 90,//chiều dài
                            show: false,//ẩn hiện line
                        },
                        horizontal: {//Line màu
                            color: "current",//lấy màu từ vòng tròn hiện tại hoặc trước nó
                        },
                    },
                    path: {
                        show: false,
                    },
                    circle: {//Vòng tròn màu
                        r1: 3,//đường kính của donut
                        r2: 6,//đường kính khuyết
                        fill: "black",//màu được lấy theo màu của data ko có thì màu đen
                        tooltip: {
                            show: true,//ẩn hiện tooltip
                        },
                    },
                    text: {//Task của ngày, 1 ngày có thể có nhiều task
                        height: 16,//chiều cao của text
                        maxLength: 50,//độ dài tối đa của ký tự
                        maxWidth: 200,//chiều dài tối đa của text
                        textAnchor: "middle",//mặc định canh giữa cho toàn bộ. start | middle | end 
                        margin: {
                            line: 10,//khoảng cách so với line
                            prev: 20,//khoảng lệch độ cao so với đối tượng trùng ở trước nó
                            text: 5//khoảng lệch độ cao so với text cùng trên 1 task
                        },
                        show: false,//ẩn hiện text
                    },
                    timeSpan: {//Chú thích thời gian màu xanh dương
                        fill: "blue",//màu chú thích
                        height: 16,//độ cao của chú thích
                        fontSize: 12,//cỡ chữ
                        fontWeight: "bold",//in đậm
                        textAnchor: "middle",//mặc định canh giữa cho toàn bộ. start | middle | end 
                        paddingY: 10,//canh so với line main
                        format: {
                            year: "yyyy",//kiểu hiển thị khi vẽ theo năm
                            day: "DD-MMM",//kiểu hiển thị khi vẽ theo ngày
                            full: "YYYY-MM-DD",
                        },
                        maxWidth: 44,//chiều dài tối đa của text
                    },
                },
                previous: {//Xử lý trùng độ cao
                    text: {//so sánh text của task
                        space: 50,//bước nhảy, đếm theo chart.line.tick --> Sẽ dc tính toán lại
                        repeat: 4,//số lần lập lại tùy chỉnh độ cao - vượt giới hạn trả về vị trí mặc định
                    },
                    timeSpan: {//so sánh chú thích thời gain
                        space: 20,//bước nhảy, đếm theo chart.line.tick --> Sẽ dc tính toán lại
                        repeat: 4,//số lần lập lại tùy chỉnh độ cao - vượt giới hạn trả về vị trí mặc định
                    },
                },
                divide: {
                    fill: "red",
                    fillOpacity: 0.1,
                },
                minorTicks: { number: 1, type: "day" },//Bước nhảy của chart.line.tick, 1 ngày tick 1 cái
            },
            config: {
                graph_id: "MilestoneChart1",
                startDate: null,
                endDate: null,
            },
            html: "",
        }
    }

    reloadChart({ data = {}, options = {}, config = null }) {
        var main = this;
        console.log("%c milestone chart reload", "background:green;color:white;padding:3px", data);
        main.drawChart({ categoryData: data });
    };

    componentDidMount() {
        var main = this;
        var { data, config, options } = main.props;
        if (!data || typeof (data) !== "object" || Object.keys(data).length <= 0) return;
        if (!config) config = main.state.config;
        main.setState({
            data: main.state.data,
            options: options || main.state.dfOptions,
            dfOptions: main.state.dfOptions,
            config: config,
            html: "",
        }, () => {
            var container = document.getElementById(main.state.config.graph_id);
            // console.log(`%c componentDidMount ${main.state.config.graph_id}`, "background:green;color:white;padding:3px", container.clientWidth);
            if (container.clientWidth > 0) main.drawChart({ categoryData: data });
        })
    };

    componentWillReceiveProps(nextProps) {
        var main = this;
        var { data, config, options } = nextProps;
        if (!data || typeof (data) !== "object" || Object.keys(data).length <= 0) return;
        if (!config) config = main.state.config;

        main.setState({
            data: main.state.data,
            options: options || main.state.dfOptions,
            dfOptions: main.state.dfOptions,
            config: config,
            html: "",
        }, () => {
            var container = document.getElementById(main.state.config.graph_id);
            // console.log(`%c componentWillReceiveProps ${main.state.config.graph_id}`, "background:green;color:white;padding:3px", container.clientWidth);
            if (container.clientWidth > 0) main.drawChart({ categoryData: data });
        })
    };

    parseDate(timeSpan) {
        var date = new Date(timeSpan);
        return date;
    };

    getX({ date = null, startDate = null, endDate = null, width = 0, paddingX = 0 }) {
        var dateValue = date.valueOf();
        var start = startDate.valueOf();
        var end = endDate.valueOf();
        var x = ((dateValue - start) / (end - start) * width) + paddingX;
        return x;
    };

    drawChart({ categoryData = {} }) {
        var main = this;
        var category = {
            name: categoryData.name,
            color: categoryData.color,
            legend: categoryData.legend,
            divide: categoryData.divide,
            events: [],
        };
        let options = main.props.options;
        let config = main.state.config;
        var events = categoryData.events || [];
        let startDate, endDate;
        // if (config.startDate) console.log("drawChart.startDate", moment(config.startDate).format(options.timeTick.timeSpan.format.full));
        // if (config.endDate) console.log("drawChart.endDate", moment(config.endDate).format(options.timeTick.timeSpan.format.full));

        for (var e = 0; e < events.length; e++) {
            var event = events[e];
            if (!event.date) continue;
            if (!startDate) startDate = event.date;
            if (!endDate) endDate = event.date;
            if (main.parseDate(event.date) > endDate) endDate = main.parseDate(event.date);
            if (main.parseDate(event.date) < startDate) startDate = main.parseDate(event.date);
            var eOptions = event.options || {};
            var dfStyle = {
                text: {
                    fillColor: "black",
                    fontWeight: "normal",
                    fontSize: 12,
                },
                line: {
                    vertical: {
                        fillColor: "black",
                        strokeWidth: 1,
                    },
                    horizontal: {
                        fillColor: "black",
                        strokeWidth: 5,//height of main line
                    },
                },
                circle: {
                    stroke: "black",
                    fillColor: "black",
                }
            };
            if (event.options && event.options.style) {
                //Handle options style here
                var cStyleText = event.options.style.text || {};
                var cStyleLine = event.options.style.line || {};
                var cStyleLineVertical = cStyleLine.vertical || {};
                var cStyleLineHorizontal = cStyleLine.horizontal || {};

                var cStyleCircle = event.options.style.circle || {};
                dfStyle.text.fillColor = cStyleText.fillColor || dfStyle.text.fillColor;
                dfStyle.text.fontWeight = cStyleText.fontWeight || dfStyle.text.fontWeight;
                dfStyle.text.fontSize = cStyleText.fontSize || dfStyle.text.fontSize;

                dfStyle.line.vertical.fillColor = cStyleLineVertical.fillColor || dfStyle.line.vertical.fillColor;
                dfStyle.line.vertical.strokeWidth = cStyleLineVertical.strokeWidth || dfStyle.line.vertical.strokeWidth;
                dfStyle.line.horizontal.fillColor = cStyleLineHorizontal.fillColor || dfStyle.line.horizontal.fillColor;
                dfStyle.line.horizontal.strokeWidth = cStyleLineHorizontal.strokeWidth || dfStyle.line.horizontal.strokeWidth;

                dfStyle.circle.stroke = cStyleCircle.stroke || dfStyle.circle.stroke;
                dfStyle.circle.fillColor = cStyleCircle.fillColor || dfStyle.circle.fillColor;
            };
            dfStyle.line.strokeWidth = 20;
            eOptions.style = dfStyle;
            var eventData = {
                name: event.name || "No event",
                date: main.parseDate(event.date),
                options: eOptions,
            };
            // console.log(event.date, moment(eventData.date).format(options.timeTick.timeSpan.format.full));
            category.events.push(eventData);
        };
        category.events = arraySort(category.events, "date");

        startDate = moment(startDate).add(-1, "days").toDate();
        endDate = moment(endDate).add(1, "days").toDate();
        if (config.startDate) startDate = moment(config.startDate).add(-1, "days").toDate();
        if (config.endDate) endDate = moment(config.endDate).add(1, "days").toDate();

        main.draw({
            category: category,
            startDate: startDate,
            endDate: endDate,
        });
    };

    renderTitle(title) {
        var main = this;
        var { options } = main.props;
        if (!options) options = main.state.dfOptions;
        title = title || "";
        return (
            <h5 style={{ textAlign: "center", marginBottom: 0, marginTop: options.style.marginTop }} key={title}>{title}</h5>
        );
    };

    handleOptions({ category = {}, startDate = null, endDate = null }) {
        var main = this;
        /****|Chart options chỉ tính cho trường hợp đơn giản nhất|****/
        var { options, config } = main.props;
        if (!options) options = main.state.dfOptions;
        // console.log("handleOptions", options);
        // options = main.state.dfOptions;

        /****|Kích thước chart thay đổi thêm ở phần xử lý data|****/
        var container = document.getElementById(main.state.config.graph_id);
        options.outer.width = container.clientWidth;
        options.outer.face = 0;
        options.outer.main = options.outer.face;
        options.outer.height = options.outer.main + options.outer.face;
        options.chart.width = options.outer.width - (options.chart.paddingX * 2);
        options.chart.height = options.outer.height - (options.chart.paddingY * 2);
        var momentStart = moment(startDate);
        var momentEnd = moment(endDate);
        var duration = moment.duration(momentEnd.diff(momentStart));
        var numOfStick = duration.asDays();//2 ngày start và end đã cộng rồi nên ko cần cộng nữa
        var timeStickWidth = options.outer.width / (numOfStick - 1);///chiều ngang giữa 2 ngày , 2 stick     N sticks thi co N-1 khoảng    
        var textSpace = Math.ceil(options.timeTick.text.maxWidth / timeStickWidth);
        if (textSpace) options.previous.text.space = textSpace;
        var timeSpanSpace = Math.ceil(options.timeTick.timeSpan.maxWidth / timeStickWidth);
        if (timeSpanSpace) options.previous.timeSpan.space = timeSpanSpace;
        options.timeTick.path = options.timeTick.path || { show: false };

        var cfLoop = {
            year: 0,
            month: 0,
            day: 0,
            start: 0,
            stop: 0,
            format: "",
        };
        // console.log("options.outer.face", options.outer.face);
        // console.log("options.outer.main", options.outer.main);
        switch (options.minorTicks.type) {
            case "year":
                cfLoop.month = 0;
                cfLoop.day = 1;
                cfLoop.start = momentStart.year();
                cfLoop.stop = momentEnd.year();
                cfLoop.format = options.timeTick.timeSpan.format.year;
                break;
            case "day":
                cfLoop.year = momentStart.year();
                cfLoop.month = momentStart.month();
                cfLoop.day = momentStart.date();
                cfLoop.start = momentStart.date();
                cfLoop.stop = cfLoop.start + Math.floor(duration.asDays());
                cfLoop.format = options.timeTick.timeSpan.format.day;
                break;
            default:
                break;
        };
        options.chart.line.step.width = options.chart.width / duration.asDays();

        return { options: options, cfLoop: cfLoop };
    };

    handleChartItem({ category = {}, opts = { options: this.state.options, cfLoop: {} }, startDate = null, endDate = null }) {
        var main = this;
        var options = opts.options;
        var cfLoop = opts.cfLoop;
        var momentStart = moment(startDate);
        var momentEnd = moment(endDate);
        var item = {
            chart: {
                line: {
                    tick: [],
                    main: {
                        key: "mainLine",
                        x1: options.chart.paddingX,
                        y1: options.outer.main,
                        x2: options.chart.width + options.chart.paddingX,
                        y2: options.outer.main,
                        stroke: options.chart.line.main.color,
                        strokeWidth: options.chart.line.main.height,
                    },
                    missing: {
                        last: {
                            key: `missingLast`,
                            x1: options.chart.width + options.chart.paddingX - options.chart.line.step.width,
                            y1: options.outer.main,
                            x2: options.chart.width + options.chart.paddingX,
                            y2: options.outer.main,
                            stroke: options.chart.line.main.color,
                            strokeWidth: options.chart.line.main.height,
                        }
                    }
                },
                legend: [],
            },
            timeTick: [],
            previous: {
                x: {
                    tick: [],
                    circle: [],
                },
                y: {
                    timeSpan: {},
                    line: {},
                    text: {},
                },
                color: {
                    circle: [],
                },
            },
            extraHeight: 0,
        };

        //Config chart tick
        for (var i = cfLoop.start; i <= cfLoop.stop; i++) {
            var timeDate = startDate;
            switch (options.minorTicks.type) {
                case "year":
                    timeDate = new Date(i, cfLoop.month, cfLoop.day);
                    break;
                case "day":
                    timeDate = new Date(cfLoop.year, cfLoop.month, i);
                    break;
                default:
                    break;
            };
            var momentDate = moment(timeDate);
            var x = Math.floor(main.getX({ date: timeDate, startDate: startDate, endDate: endDate, width: options.chart.width, paddingX: options.chart.paddingX })) + 0.5;
            var cfTimeTick = {
                key: `tickLine${i}`,
                x1: x,
                y1: options.outer.main,
                x2: x,
                y2: options.outer.main + options.chart.line.tick.height,
                stroke: options.chart.line.tick.color,
                strokeWidth: options.chart.line.tick.width,
                timeDate: parseInt(momentDate.format("x")),
            };
            item.chart.line.tick.push(cfTimeTick);
            item.previous.x.tick.push(`${x}`);
        };

        //Config timeTick
        var previous = item.previous;
        var maxY = {
            label: 0,//Dùng để canh vị trí legend
            timeSpan: 0,//Dùng để canh khi hide line vertical
        };
        var minY = {
            label: 0,//Dùng để chỉnh các tick âm
            timeSpan: 0,//Dùng để canh khi hide line vertical
        };

        for (var [e, event] of category.events.entries()) {
            var eOptions = event.options;
            var eStyle = eOptions.style;
            var x = Math.floor(main.getX({ date: event.date, startDate: startDate, endDate: endDate, width: options.chart.width, paddingX: options.chart.paddingX })) + 0.5;
            var momentDate = moment(event.date);
            if (!previous.x.circle.includes(`${x}`)) {
                //Task đầu tiên của ngày
                var obj = {
                    line: {
                        vertical: {},
                        horizontal: {},
                    },
                    path: [],
                    circle: {},
                    text: [],
                    timeSpan: {},
                };
                var k = previous.x.circle.length % 2;
                var isDrawDown = (k === 1);
                var cal = 1;

                //Config timeTick circle
                if (options.timeTick.circle.r2 === 0) {
                    options.timeTick.circle.r2 = options.timeTick.circle.r1;
                    options.timeTick.circle.r1 = 0;
                };
                var r = options.timeTick.circle.r1 + options.timeTick.circle.r2;
                var circleTitle = options.timeTick.circle.tooltip.show ? event.name : "";
                var cfCircle = {
                    key: `circle${e}`,
                    cx: x,
                    cy: options.outer.main,
                    stroke: eStyle.circle.stroke,
                    strokeWidth: options.timeTick.circle.r1,
                    r: r,
                    fill: eStyle.circle.fillColor,
                    title: circleTitle
                };
                var cfTimeSpan = {
                    key: `timeSpan${e}`,
                    x: x,
                    y: options.outer.main,
                    text: momentDate.format(cfLoop.format),
                    fill: options.timeTick.timeSpan.fill,
                    fontSize: options.timeTick.timeSpan.fontSize,
                    fontWeight: options.timeTick.timeSpan.fontWeight,
                    textAnchor: options.timeTick.timeSpan.textAnchor
                };
                var cfLineVertical = {
                    key: `lineVertical${e}`,
                    x1: x,
                    y1: 0,
                    x2: x,
                    y2: 0,
                    stroke: eStyle.line.vertical.fillColor,
                    strokeWidth: eStyle.line.vertical.strokeWidth,
                };

                var prevCircleX = (previous.x.circle[previous.x.circle.length - 1] || previous.x.tick[0]);
                var cfLineHorizontal = {
                    key: `lineColor${e}`,
                    x1: parseInt(prevCircleX),
                    y1: options.outer.main,
                    x2: x,
                    y2: options.outer.main,
                    stroke: eStyle.circle.fillColor,
                    strokeWidth: eStyle.line.horizontal.strokeWidth,
                };
                var cfLabel = {
                    key: `label${e}`,
                    x: x,
                    y: options.outer.main,
                    text: event.name,//cfTimeSpan.text,
                    fill: eStyle.text.fillColor,
                    fontSize: eStyle.text.fontSize,
                    fontWeight: eStyle.text.fontWeight,
                    textAnchor: options.timeTick.text.textAnchor,
                    isDrawDown: isDrawDown
                };

                //Handle path curve
                let cfPathCurveUpMy = options.outer.main - cfLineHorizontal.strokeWidth / 2;
                let cfPathCurve = {
                    up: {
                        key: `pathCurveUp${e}`,
                        d: ``,
                        dc: {
                            m: {
                                x: x,
                                y: cfPathCurveUpMy,
                            },
                            h: parseInt(prevCircleX),
                            v: options.outer.main,
                            c: {},
                        },
                        fill: eStyle.circle.fillColor,
                    },
                    down: {
                        key: `pathCurveDown${e}`,
                        d: ``,
                        dc: {
                            m: {
                                x: x,
                                y: cfPathCurveUpMy + cfLineHorizontal.strokeWidth,
                            },
                            h: parseInt(prevCircleX),
                            v: options.outer.main,
                            c: {},
                        },
                        fill: eStyle.circle.fillColor,
                    },
                };

                var lineColor = options.timeTick.circle.fill;
                if (lineColor === "stroke") {
                    lineColor = eStyle.circle.stroke;
                    cfLineHorizontal.stroke = lineColor;
                    cfPathCurve.up.fill = lineColor;
                    cfPathCurve.down.fill = lineColor;
                };
                if (options.timeTick.line.horizontal.color === "previous") cfLineHorizontal.stroke = previous.color.circle[previous.color.circle.length - 1] || "black";

                var durationStart = moment.duration(momentDate.diff(momentStart));
                var durationEnd = moment.duration(momentEnd.diff(momentDate));
                if (durationStart.asDays() === 1) cfLabel.textAnchor = "start";
                else if (durationEnd.asDays() === 1) cfLabel.textAnchor = "end";

                if (cfLabel.text.length > options.timeTick.text.maxLength) cfLabel.text = `${cfLabel.text.substring(0, options.timeTick.text.maxLength)}...`;
                previous.x.circle.push(`${x}`);
                previous.color.circle.push(lineColor);

                if (!isDrawDown) {
                    cfTimeSpan.y += options.timeTick.timeSpan.paddingY + options.chart.line.tick.height + r;
                    cfLineVertical.y2 = options.outer.main;
                    cfLineVertical.y1 = cfLineVertical.y2 - options.timeTick.line.vertical.height;
                    cfLabel.y -= options.timeTick.line.vertical.height + options.timeTick.text.margin.line;
                } else {
                    cal = -1;
                    cfTimeSpan.y -= options.timeTick.timeSpan.paddingY + r;
                    cfLineVertical.y1 = options.outer.main;
                    cfLineVertical.y2 = cfLineVertical.y1 + options.timeTick.line.vertical.height;
                    cfLabel.y += options.timeTick.line.vertical.height + options.timeTick.text.height;
                };

                //Handle Previous timeSpan
                var cfTimeSpanY = cfTimeSpan.y;
                var line = 0;
                var index = previous.x.tick.indexOf(`${x}`);
                var n = index - options.previous.timeSpan.space;
                n = (n > 1) ? n : 1;
                for (var j = n; j <= index - 1; j += 1) {
                    var prevX = previous.x.tick[j];
                    var prevY = previous.y.timeSpan[`${prevX}`];
                    if (!prevY || (`${cfTimeSpan.y}` !== `${prevY}`)) continue;
                    line += 1;
                    if (line >= options.previous.timeSpan.repeat) {
                        line = 0;
                        cfTimeSpan.y = cfTimeSpanY;
                        continue;
                    };
                    cfTimeSpan.y += cal * options.timeTick.timeSpan.height;
                };
                previous.y.timeSpan[`${x}`] = `${cfTimeSpan.y}`;
                previous.y.line[`${x}`] = cfLineHorizontal.strokeWidth;
                let previousStrokeWidth = previous.y.line[`${prevCircleX}`] || 0;
                cfPathCurve.up.dc.v = options.outer.main - previousStrokeWidth / 2;
                cfPathCurve.down.dc.v = cfPathCurve.up.dc.v + previousStrokeWidth;
                if (cfPathCurve.up.dc.v > cfPathCurve.up.dc.m.y) {
                    // console.log("change curve", cfPathCurve);
                    let dc = JSON.parse(JSON.stringify(cfPathCurve.up.dc));
                    cfPathCurve.up.dc.m.y -= 2;
                    cfPathCurve.down.dc.m.y += 2;
                    cfPathCurve.up.fill = "#F2F2F2";
                    cfPathCurve.down.fill = "#F2F2F2";
                };

                //Handle Previous text
                var cfLabelY = cfLabel.y;
                var cfLineVerticalY1 = cfLineVertical.y1;
                var cfLineVerticalY2 = cfLineVertical.y2;
                line = 0;
                n = index - options.previous.text.space;
                n = (n > 1) ? n : 1;
                for (var j = n; j <= index - 1; j += 1) {
                    var prevX = previous.x.tick[j];
                    var prevY = previous.y.text[`${prevX}`];
                    if (!prevY || !prevY.includes(`${cfLabel.y}`)) continue;
                    line += 1;
                    if (line >= options.previous.text.repeat) {
                        line = 0;
                        cfLabel.y = cfLabelY;
                        cfLineVertical.y1 = cfLineVerticalY1;
                        cfLineVertical.y2 = cfLineVerticalY2;
                        continue;
                    };
                    var maxPreY = 0;
                    prevY.filter(function (py) {
                        if (Math.abs(py) > Math.abs(maxPreY)) maxPreY = py;
                    });
                    cfLabel.y = maxPreY;//Tính khoảng cách từ vị trí cao nhất của text trước đó
                    // console.log(`%c ${cfTimeSpan.text} - ${cfLabel.text} - ${prevX} -  ${cfLabel.y} - ${line} - ${maxPreY}`, "background:green;color:white;padding:3px", prevY);
                    var z = options.timeTick.text.height + options.timeTick.text.height * 2;
                    cfLabel.y -= cal * z;
                    if (!isDrawDown) {
                        cfLineVertical.y1 = maxPreY
                        cfLineVertical.y1 = cfLabel.y + options.timeTick.text.margin.line;
                    } else {
                        cal = -1;
                        cfLineVertical.y2 = cfLabel.y - options.timeTick.text.height;
                    };
                };
                if (cfLabel.y > maxY.label) maxY.label = cfLabel.y;
                if (cfLabel.y < minY.label) minY.label = cfLabel.y;
                if (cfTimeSpan.y > maxY.timeSpan) maxY.timeSpan = cfTimeSpan.y;
                if (cfTimeSpan.y < minY.timeSpan) minY.timeSpan = cfTimeSpan.y;
                previous.y.text[`${x}`] = [`${cfLabel.y}`];

                obj.timeSpan = cfTimeSpan;
                obj.circle = cfCircle;
                obj.line.vertical = cfLineVertical;
                obj.line.horizontal = cfLineHorizontal;
                obj.path.push(cfPathCurve.up);
                obj.path.push(cfPathCurve.down);
                obj.text.push(cfLabel);
                item.timeTick.push(obj);
            } else {
                //Task 2 trở đi của cùng 1 ngày
                var obj = item.timeTick[item.timeTick.length - 1];
                var prevLabel = obj.text[obj.text.length - 1];
                var cfLabel = {
                    key: `label${e + obj.text.length}`,
                    x: x,
                    y: prevLabel.y,
                    text: event.name,//cfTimeSpan.text,
                    fill: eStyle.text.fillColor,
                    fontSize: eStyle.text.fontSize,
                    fontWeight: eStyle.text.fontWeight,
                    textAnchor: prevLabel.textAnchor,
                    isDrawDown: prevLabel.isDrawDown,
                };
                var z = options.timeTick.text.height;
                if (!isDrawDown) {
                    cfLabel.y -= z;
                } else {
                    cfLabel.y += z;
                };
                if (cfLabel.y > maxY.label) maxY.label = cfLabel.y;
                if (cfLabel.y < minY.label) minY.label = cfLabel.y;
                if (cfLabel.text.length > options.timeTick.text.maxLength) cfLabel.text = `${cfLabel.text.substring(0, options.timeTick.text.maxLength)}...`;
                previous.y.text[`${x}`].push(`${cfLabel.y}`);
                obj.text.push(cfLabel);
            };
        };
        if (maxY.timeSpan > maxY.label) maxY.label = maxY.timeSpan;
        if (minY.timeSpan < minY.label) minY.label = minY.timeSpan;

        var minus = 0;
        if (minY.label < 0) minus = Math.abs(minY.label) + options.chart.paddingY * 2 + options.style.marginTop;
        // console.log(`maxY`, maxY);
        // console.log(`minY`, minY);
        if (!options.timeTick.line.vertical.show) {
            minus -= Math.abs(minY.label) - Math.abs(minY.timeSpan) - options.timeTick.timeSpan.height + options.chart.paddingY * 2;
            maxY.label = Math.abs(maxY.timeSpan) + options.style.marginTop;
        };
        options.outer.face = maxY.label + options.chart.paddingY;
        options.outer.main = maxY.label + options.chart.paddingY;
        options.outer.height = maxY.label + options.chart.paddingY;
        options.chart.height = maxY.label + options.chart.paddingY;

        //Handle hide options
        maxY.label += minus;
        options.outer.face += minus;
        options.outer.main += minus;
        options.outer.height += minus;
        options.chart.height += minus;

        item.chart.line.main.y1 += minus;
        item.chart.line.main.y2 += minus;
        item.chart.line.missing.last.y1 += minus;
        item.chart.line.missing.last.y2 += minus;
        if (item.timeTick.length > 0) {
            let lastTimeTick = item.timeTick[item.timeTick.length - 1];
            item.chart.line.missing.last.x1 = lastTimeTick.circle.cx;
        };

        for (var tick of item.chart.line.tick) {
            tick.y1 += minus;
            tick.y2 += minus;
        };
        for (var timeTick of item.timeTick) {
            timeTick.timeSpan.y += minus;
            timeTick.line.horizontal.y1 += minus;
            timeTick.line.horizontal.y2 += minus;
            timeTick.line.vertical.y1 += minus;
            timeTick.line.vertical.y2 += minus;
            timeTick.circle.cy += minus;

            for (let pathCurve of timeTick.path) {
                let dc = pathCurve.dc;
                dc.m.y += minus;
                dc.v += minus;
                let curve = (dc.m.y - dc.v) / 2;
                dc.c = {
                    x1: dc.h,
                    y1: dc.v,
                    x2: dc.h + curve,
                    y2: dc.m.y - curve,
                    x: dc.m.x,
                    y: dc.m.y,
                };
                let dcCurve = `${dc.c.x1}, ${dc.c.y1}, ${dc.c.x2}, ${dc.c.y2}, ${dc.c.x}, ${dc.c.y}`;
                if (pathCurve.key.indexOf("pathCurveUp") !== -1) {
                    pathCurve.d = `M${dc.m.x} ${dc.m.y + 1}  H ${dc.h} V ${dc.v}`;//C ${dcCurve}
                } else {
                    pathCurve.d = `M${dc.m.x} ${dc.m.y - 1}  H ${dc.h} V ${dc.v}`;//C ${dcCurve}
                };
            };

            for (var lbl of timeTick.text) {
                lbl.y += minus;
            };
        };

        //Config legend
        var legends = category.legend || [];
        if (legends && legends.length) {
            var legendHeight = options.chart.legend.rect.height + options.chart.legend.rect.paddingY;
            var legendWidth = options.chart.legend.rect.width + options.chart.legend.text.maxWidth + options.chart.legend.text.marginX;
            var legendCount = Math.floor(options.chart.width / legendWidth);
            if (options.chart.legend.show) {
                options.outer.face += options.chart.legend.rect.paddingChart + legendHeight;
                options.outer.main += options.chart.legend.rect.paddingChart + legendHeight;
                options.outer.height += options.chart.legend.rect.paddingChart + legendHeight;
                options.chart.height += options.chart.legend.rect.paddingChart + legendHeight;
            };

            var line = 0;
            for (var [l, legend] of legends.entries()) {
                var k = l % legendCount;
                var marginX = k * legendWidth;
                line = Math.floor(l / legendCount);
                var obj = {
                    rect: {},
                };
                var legendName = legend.name;
                var text = "";
                if (Array.isArray(legendName)) {
                    text = legendName.join(" - ");
                } else {
                    text = legend.name || options.chart.legend.text.default;
                };

                var y = maxY.label + options.chart.legend.rect.paddingChart + line * legendHeight;
                var marginY = line * options.chart.legend.rect.paddingY;
                var cfLegendRectTitle = options.chart.legend.tooltip.show ? text : '';
                var cfLegendRect = {
                    key: `legendRect${l}`,
                    x: options.chart.paddingX + marginX,
                    y: y,
                    rx: options.chart.legend.rect.rx,
                    ry: options.chart.legend.rect.ry,
                    width: options.chart.legend.rect.width,
                    height: options.chart.legend.rect.height,
                    strokeWidth: options.chart.legend.rect.strokeWidth,
                    fill: legend.color,
                    title: cfLegendRectTitle
                };
                var cfLegendLabel = {
                    key: `legendLabel${l}`,
                    x: cfLegendRect.x + options.chart.legend.rect.width + options.chart.legend.text.marginX,
                    y: cfLegendRect.y + options.chart.legend.rect.height / 2 + options.chart.legend.text.height / 2,
                    text: text,
                    fill: options.chart.legend.text.fill,
                    fontSize: options.chart.legend.text.fontSize,
                    fontWeight: options.chart.legend.text.fontWeight,
                    textAnchor: options.chart.legend.text.textAnchor,
                };
                if (cfLegendLabel.text.length > options.chart.legend.text.maxLength) {
                    cfLegendLabel.text = `${cfLegendLabel.text.substring(0, options.chart.legend.text.maxLength)}...`;
                };
                item.chart.legend.push({
                    rect: cfLegendRect,
                    label: [cfLegendLabel],
                });
            };

            /*
           - extraHeight dùng để tính lại độ dài của chart trong trường hợp data vượt quá mức quy định
           + repeat text của timetick coder config là 100 lần lập lại(chart rất to)
           + legend từ input của coder nhiều hơn 1 hàng
            */
            item.extraHeight += line * legendHeight;
            if (legendCount <= 1) item.extraHeight += line * legendHeight;
            if (options.chart.legend.show) {
                var legendMinus = item.extraHeight - options.chart.legend.rect.paddingY;
                options.outer.face += legendMinus;
                options.outer.main += legendMinus;
                options.outer.height += legendMinus;
                options.chart.height += legendMinus;
            };
        };
        // console.log("%c options", "background:green;color:white;padding:3px", options);
        // console.log("%c item", "background:green;color:white;padding:3px", item);

        return item;
    };

    draw({ category = {}, startDate = null, endDate = null, }) {
        // console.log("milestone category", category);
        var main = this;
        var { config, onLoadChartFinish } = main.props;
        if (!config) config = main.state.config;
        var opts = main.handleOptions({ startDate: startDate, endDate: endDate });
        var options = opts.options;
        var cfLoop = opts.cfLoop;
        var childs = [];
        var backgroundChilds = [];
        var item = main.handleChartItem({ category: category, opts: opts, startDate: startDate, endDate: endDate });
        // console.log("milestone item", item);

        var lineMain = cSVG.line(item.chart.line.main);
        var lineMissingLast = cSVG.line(item.chart.line.missing.last);
        var ticks = item.chart.line.tick;
        var legends = item.chart.legend;

        //Draw main line
        backgroundChilds.push(lineMissingLast);
        let x = null;
        for (var tick of ticks) {
            //Draw tick
            if (tick.timeDate === category.divide) x = tick.x1;
            var tickLine = cSVG.line(tick);
            backgroundChilds.push(tickLine);
        };

        if (x) {
            backgroundChilds.push(cSVG.rect({
                key: `text_rectangle_div`,
                x: x,
                y: 0,
                width: options.outer.width - x,
                height: options.outer.height,
                fill: options.divide.fill,
                fillOpacity: options.divide.fillOpacity,
            }));
        };

        //Draw item
        for (var [l, legend] of legends.entries()) {
            if (!options.chart.legend.show) continue;
            var legendRect = cSVG.rect(legend.rect);
            var labelGroup = [];
            for (var lbl of legend.label) {
                var legendLabel = cSVG.text(lbl);
                labelGroup.push(legendLabel);
            };
            var legendChilds = [legendRect, labelGroup];
            var legendGroup = cSVG.group({ key: `legendGroup${l}`, childs: legendChilds });
            backgroundChilds.push(legendGroup);
        };
        var background = cSVG.group({ key: "groupBackground", childs: backgroundChilds });
        childs.push(background);

        if (!category || !category.events || !category.events.length) return;
        var timeTicks = item.timeTick;
        var n = timeTicks.length;
        for (var [e, timeTick] of timeTicks.entries()) {
            var itemChildren = [];
            var timeSpan = cSVG.text(timeTick.timeSpan);
            var lineHorizontal = cSVG.line(timeTick.line.horizontal);
            // itemChildren.push(timeSpan);
            itemChildren.push(lineHorizontal);

            if (options.timeTick.line.vertical.show) {
                var lineVertical = cSVG.line(timeTick.line.vertical);
                itemChildren.push(lineVertical);
            };
            if (options.timeTick.text.show) {
                for (var lbl of timeTick.text) {
                    var eventLabel = cSVG.text(lbl);
                    itemChildren.push(eventLabel);
                };
            };
            if (options.timeTick.path.show && e > 0) {
                console.log("options.timeTick.path", timeTick.path);
                for (let pathCurve of timeTick.path) {
                    let curve = cSVG.path(pathCurve);
                    itemChildren.push(curve);
                };

            };
            childs.push(cSVG.group({ key: `event${e}`, childs: itemChildren }));
        };
        for (let [e, timeTick] of timeTicks.entries()) {
            let itemChildren = [];
            let circle = cSVG.circle(timeTick.circle);
            let timeSpan = cSVG.text(timeTick.timeSpan);
            itemChildren.push(circle);
            itemChildren.push(timeSpan);
            childs.push(cSVG.group({ key: `eventCircle${e}`, childs: itemChildren }));
        };
        // options.style.height = options.outer.height;
        var html = [cSVG.svg({ width: options.outer.width, height: options.outer.height, childs: childs })];
        // html = [];
        if (category.name) html.splice(0, 0, main.renderTitle(category.name));

        main.setState({
            data: {
                category: category,
                startDate: startDate,
                endDate: endDate,
            },
            config: config,
            options: options,
            dfOptions: main.state.dfOptions,
            html: html
        }, () => {
            if (onLoadChartFinish) onLoadChartFinish(main.state);
        });
    };

    render() {
        return (
            <div id={this.state.config.graph_id} style={this.state.options.style}>
                {this.state.html}
            </div>
        )
    };
};

export default MilestoneChart;