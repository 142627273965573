import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import {
  Alert,
  Button,
  Card, CardFooter, CardImg, Col,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Nav, NavItem, NavLink, Row,
  TabContent, TabPane
} from '../../_importComponent';
import ModalPhotoViewer from '../../components/ModalPhotoViewer';
import MyUI from '../../components/MyUI/MyUI';
import HPaste from '../../helpers/Paste'
import HUI from '../../helpers/UI'
import HText from '../../helpers/Text'
import HConfig from '../../helpers/Config'
import ApiGeneric from '../../services/generic';
import ApiProjectPhoto from '../../services/project_photo';
// import InputControl from '../../views/Components/InputEditor/CustomControl';
//Mở ra để test
import InputControl from '../../views/Components/InputEditor/CustomControl';


class MyModalPhoto extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      isShow: false,
      error: null,
      isShowLoading: false,
      config: {},
      isLoading: false,
      isUploadingFiles: false,
      isShowAlert: false,
      msgAlert: '',
      activeTab: '1',
      currentRow: {},
      fieldName: '',
      data: {},
      screenCode: null,
      canUpload: true,
    };
  }

  componentDidMount(){
    MyModalPhoto.Helper.component = this;
  }

  getIsShowLoading=()=>{
    return this.state.isShowLoading;
  }

  show=(row,fieldName,screenCode,opts={})=>{
    // console.warn('row: ', row);
    let _canUpload = true;
    if(row && row._CanUpload===false){
      _canUpload = row._CanUpload;
    }
    if(opts && opts.canUpload===false){
      _canUpload = false;
    }    
    this._opts = opts;
    this.setState({
      isShow:true,
      currentRow: row,
      fieldName: fieldName,
      activeTab: _canUpload?'1':'2',
      data: opts.data!=null?opts.data:{},
      screenCode: screenCode,
      canUpload: _canUpload,
      config: opts,
    },()=>{
      if(opts.needRequestPhotos!==false){
        this._onRequestGetPhotos(row.Id,fieldName);
      }
      else{
        this._buildArrPhoto(this.state.data);
      }     
    })
    if(opts.patseAsFile==true){
      HPaste.addEventUploadFileAfterPaste((files)=>{
        this._onDrop(files);
      });HPaste.addEventPaste();
    }    
  }
  _getProjectId=()=>{
    const {config} = this.state;
    let _projectId = null;
    if(config.projectId){
      _projectId = config.projectId;
    }
    return _projectId; 
  }
  _getScreenCode=()=>{
    return this.state.screenCode; 
  }
  _getId=()=>{
    let _valueId = null;
    if (this.state.config && this.state.config.fID){
      _valueId = this.state.currentRow[this.state.config.fID];
    }
    else if (this.state.currentRow.Id){
      _valueId = this.state.currentRow.Id;
    }  
    // console.warn(`_getId:`,_valueId,this.state.currentRow,this.state.config);
    return _valueId;
  }
  _getFieldName=()=>{
    let _fieldName = null;
    if (this.state.fieldName){
      _fieldName = this.state.fieldName;
    } else if (this.state.config && this.state.config.fFN){
      _fieldName = this.state.config.fFN;
    }
    return _fieldName;
  }
  showLoading=()=>{
    this.setState({
      isShowLoading: true
    });
  }
  hideLoading=()=>{
    this.setState({
      isShowLoading: false
    });
  }

  hide=()=>{
    if(this.state.config && this.state.config.patseAsFile==true){
      HPaste.removeEventUploadFileAfterPaste();HPaste.removeEvenPaste();
    }    
    this.setState({
      isShow:false,
      isShowLoading: false,
      config: {},
    })
  }

  _onClickOK=()=>{
    if(this._onOK){
      this._onOK();
    }
  }

  _onDismissAlert=()=>{
    this.setState({ isShowAlert: false, msgAlert: '' });
  }
  _onClickTab=(tabId)=>{
    this.setState({
      activeTab:tabId
    })
  }

  _onRequestGetPhotos=(Id,fieldName)=>{
    console.log('_onRequestGetPhotos:',this);
    let _projectId = this._getProjectId();    
    let _screenCode = this._getScreenCode();
    // if(_projectId!=null){
      this.setState({isLoading:true},()=>{
        // let _valueId = this.state.currentRow.Id;     
        let _valueId = this._getId();        
        let _fieldName = this._getFieldName();
        
        ApiProjectPhoto.get_photos(_projectId,_screenCode,_valueId,_fieldName,{
          successCallBack:(response)=>{
            console.log('successCallBack:',response);
            this.setState({
              data: response.Data,
              error:null,
              isLoading:false
            });
            this._buildArrPhoto(response.Data);
          },
          errorCallBack:(error,response)=>{
            console.log('errorCallBack:',error,response);
            this.setState({error:error!=null?error:response,isLoading:false});
          }
        })
      })
    // }
  }
  _buildArrPhoto=(data)=>{
    let _arrPhoto = [];
    if(data!=null && data.Photos!=null){
      for(let i=0;i<data.Photos.length;i++){
        let _item = data.Photos[i];
        _arrPhoto.push({
          title: _item.FileName,
          url: _item.FileUrl_FullSize,
        })
      }
    }
    // console.warn("_buildArrPhoto",data,_arrPhoto);
    this.arrPhoto = _arrPhoto;
  }

  _onRequestUpdateFieldsFile=(cellName, cellValue,item)=>{
    ApiGeneric.generic({
      request:{
        method: 'POST',
        path: 'ProjectPhoto',
        name: 'UpdateFields_File'
      },
      data:{
        ProjectId: this._getProjectId(),
        Id:item.Id,
        Values: [
          {
            FieldName: cellName,
            NewValue: cellValue
          }
        ]
      },
      successCallBack:(response)=>{
          HUI.Toast.showSuccess(response.Msg);
          if (response.Data && response.Data.CheckQualityColor){
            item.CheckQualityColor = response.Data.CheckQualityColor;
          }
          this.forceUpdate();
      },
      errorCallBack:(error,response)=>{
      }
    })
  }

  _renderAllFile=()=>{
    let _ui = [];
    const {data} = this.state;
    if(data!=null && data.Photos!=null){
      for(let i=0;i<data.Photos.length;i++){
        let _item = data.Photos[i];
        _ui.push(
          <Card className="sp_list_item" key={i}>
            <Button color="link" style={{padding:'0px',minHeight:'180px'}} onClick={()=>{
              console.log('onClick:',this.arrPhoto);
              if(this.arrPhoto && this.arrPhoto.length>i){
                ModalPhotoViewer.Helper.show(this.arrPhoto,i);
              }              
            }}>
              <CardImg top width="100%" height="180px" src={_item.FileUrl} alt="" />
            </Button>
            <CardFooter style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'3px',alignItems:'center'}}>
              <div style={{width:'100%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                <Button color="link" target="_blank" href={_item.FileUrl_Download}>{_item.FileName}</Button>
              </div>
              {
                this.state.canUpload &&
                <div>
                  <Button style={{visibility:_item.IsAvatar ==false?'visible':'hidden'}} color="link" onClick={()=>{
                    HUI.Alert.showMsgQuestion('Thay đổi ảnh đại diện','Bạn có chắc muốn áp dụng file đã chọn?',()=>{
                      console.log('ok');
                      this._onRequestSaveAvatar(_item);
                    },()=>{
                      console.log('dissmiss');
                    })
                  }}><i style={{fontSize:'20px'}} className={"fa fa-check-square-o"}/></Button>
                </div>
              }
              
            </CardFooter>
            <div>
              {
                _item.CheckQualityColor_CanShow==true&&
                // this._renderQuality(_item)
                <MyUI type="check_quality"
                  value = {_item.CheckQualityColor}
                  // canEdit={_item.CheckQualityColor_CanEdit}
                  canEdit={false}
                  fnRequestUpdate={(value)=>{
                    this._onRequestUpdateFieldsFile("CheckQualityColor",value,_item)
                  }}
                />
              }
            </div>
          </Card>
        );
      }
    }
    return (
      <div className="sp_list">
        {
          _ui
        }
      </div>
    )
  }
  _onRequestSaveAvatar=(item)=>{
    const {onReloadTable} = this.props;
    let _screenCode = this._getScreenCode();

    console.log('_onRequestSaveAvatar:',item);
    if(item!=null && item.FileUrl!=null){
      let _projectId = this._getProjectId();
      ApiProjectPhoto.save_avatar(_projectId,_screenCode,this._getId(),this._getFieldName(),item.FileId,{
        onUploadProgress:(progressEvent)=>{
          let _percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          console.log('_percentCompleted:',_percentCompleted);
        },
        successCallBack:(response)=>{
          console.log('_onRequestSaveAvatar:',response);
          let _msg  = response.Msg || HText.get('msg_success_change_avatar');
          HUI.Toast.showSuccess(_msg);
          let _data = this.state.data;
          if(response!=null && response.Data!=null){
            _data = response.Data;
          }
          this.setState({
            data: _data
          },()=>{
            if(onReloadTable!=null){onReloadTable();}
          })
        },
        errorCallBack:(error,response)=>{
          console.log('_onRequestSaveAvatar error:',error,response);
        }
      })
    }
  }
  _onRequestAddLink=({link,name})=>{
    let _screenCode = this._getScreenCode();
    this.setState({
      isAdddingLink: true,
    },()=>{
      ApiGeneric.generic({
        request:{
          method: 'POST',
          path: 'ProjectPhoto',
          name: 'AddFiles',
        },
        // customHeader = {
        // },
        data:{
          ProjectId: this._getProjectId(),
          ScreenCode: _screenCode,
          Id: this._getId(),
          FieldName: this._getFieldName(),
          Files: [{
            Url: link,
            Name: name,
          }],
        },
        successCallBack:(response)=>{
          if(response.Msg){
            HUI.Toast.showSuccess(response.Msg);
          }
          if(this._cInputLink && this._cInputLinkName){
            this._cInputLink.setValue("");
            this._cInputLinkName.setValue("");
          }
          this.setState({
            isAdddingLink: false,
          });
        },
        errorCallBack:(error,response)=>{
          this.setState({
            isAdddingLink: false,
          });
        }
      });
    })
  }
  _onDrop=(files,rejected)=>{
    const {onReloadTable} = this.props;
    let _screenCode = this._getScreenCode();
    
    console.log(files);
    console.log(rejected);
    if(rejected!=null && rejected.length>0){
      if(files!=null && files.length==0){
        HUI.Alert.showMsg(HText.get('msg_error'),HText.get('msg_error_upload_no_support'));
      }
    }
    if(files!=null && files.length>0){
      this.setState({
        isUploadingFiles: true
      },()=>{
        let _f = files[0];
        console.log("File name: " + _f.name);
        console.log("File size: " + _f.size);
        console.log("File type: " + _f.type);
        let _projectId = this._getProjectId();
        let _valueId = this._getId();        
        let _fieldName = this._getFieldName();
        
        ApiProjectPhoto.uploadMultifiles(_projectId,_screenCode,_valueId,_fieldName,files,{
          onUploadProgress:(progressEvent)=>{
            let _percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            console.log('onUploadProgress _percentCompleted:',_percentCompleted,progressEvent.loaded,progressEvent.total);
          },
          onDownloadProgress: (progressEvent)=>{
            let _percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            console.log('onDownloadProgress _percentCompleted:',_percentCompleted,progressEvent.loaded,progressEvent.total);
          },
          successCallBack:(response)=>{
            console.log('_onDrop:',response);
            let _msg  = response.Msg || HText.get('msg_success_upload');
            HUI.Toast.showSuccess(_msg);
            let _data = this.state.data;
            if(response!=null && response.Data!=null){
              _data = response.Data;
              this._buildArrPhoto(response.Data);
            }
            this.setState({
              isUploadingFiles: false,
              data: _data,
            },()=>{
              if(onReloadTable!=null){onReloadTable();}
              if(this._opts && this._opts.cbSuccess){
                this._opts.cbSuccess(response.Data);
              }
            })
          },
          errorCallBack:(error,response)=>{
            console.log('_onDrop error:',error,response);
            this.setState({
              isUploadingFiles: false,
            })
          }
        })
      })
    }
  }

  render() {
    const {isShow, isShowLoading, config} = this.state;
    const {className, title, showFooter, loadingMsg, componentFooter, showSubmit, onClickSubmit} = config;
    // console.warn('MyModal render:',this.props,this.state);
    // console.warn('mymodalphoto');
    return (
      <Modal 
        fade={true} 
        modalTransition={{ timeout: 100 }} 
        backdropTransition={{ timeout: 0 }}
        className={className||'modal-lg'} 
        isOpen={isShow} 
        toggle={()=>{
          this.hide();
        }} backdrop="static">
        <ModalHeader toggle={()=>{
          this.hide();
        }}>            
          {title||"Quản lý file"}
        </ModalHeader>
        <ModalBody style={{backgroundColor:'#f5f6f7'}}>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${this.state.activeTab==1?'active':''} s_cp`}
                  onClick={() => { this._onClickTab('1'); }}>
                  Ảnh đại diện
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${this.state.activeTab==2?'active':''} s_cp`}
                  onClick={() => { this._onClickTab('2'); }}>
                  Tất cả file
                </NavLink>
              </NavItem>
              {
                this.state.canUpload &&
                <NavItem>
                  <NavLink
                    className={`${this.state.activeTab==3?'active':''} s_cp`}
                    onClick={() => { this._onClickTab('3'); }}>
                    Thêm từ Link
                  </NavLink>
                </NavItem>
              }
              {
                this.state.canUpload && 
                <NavItem style={{padding:'0px 10px 0px 10px'}}>
                  <Button
                    className={``}
                    onClick={() => { 
                      console.log('open Dropzone',this._cDropzone);
                      if(this._cDropzone!=null){
                        this._cDropzone.open();
                      }
                    }}>
                    {
                      this.state.isUploadingFiles==true?
                      <i className={"fa fa-circle-o-notch fa-spin"}/>
                      :
                      "Upload file"
                    }
                    <Dropzone ref={(r)=>{this._cDropzone=r;}} 
                      accept={HConfig.fnList.getConfig('fileUploadSupport')}
                      onDrop={this._onDrop} style={{}}>
                    {({getRootProps, getInputProps, isDragActive, open}) => {
                        return (
                          <div
                            {...getRootProps()}
                            className={`dropzone ${isDragActive?'dropzone--isActive':''}`}
                            // style={_styleDropzone}
                          >
                            <input {...getInputProps()}/>
                          </div>
                        )
                      }}
                    </Dropzone>
                  </Button>
                </NavItem>
              }
              {
                this.state.data !=null && this.state.data.FolderUrl !=null && this.state.canUpload &&
                <NavItem>
                  <Button
                    className={``}
                    color="link"
                    href={this.state.data.FolderUrl}
                    target="_blank"
                    // onClick={() => { this._onClickTab('2'); }}
                  >
                    Mở thư mục gốc
                  </Button>
                </NavItem>
              }
            </Nav>  
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                  {
                    this.state.data!=null && this.state.data.AvatarPhoto !=null && this.state.data.AvatarPhoto.FileUrl_FullSize!=null ?
                    <div>
                      <Button style={{position:'absolute',right:'15px'}} target="_blank" href={this.state.data.AvatarPhoto.FileUrl_Download}><i className="fa fa-external-link"></i></Button>
                      <img style={{width:'100%',height:'100%'}} src={this.state.data.AvatarPhoto.FileUrl_FullSize}/>
                      <p>File: {this.state.data.AvatarPhoto.FileName}</p>
                    </div>
                    :
                    <div>
                      <p>Chưa có hình đại diện</p>
                    </div>
                  }
                    
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    {
                      this._renderAllFile()
                    }
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <div>Nhập đường dẫn google file:</div>
                    <InputControl //fix bug không lấy được value của Input => đổi thành InputControl
                      ref={r=>{this._cInputLink=r;}}
                      type={'text'} />
                    <div>Nhập tên:</div>
                    <InputControl
                      ref={r=>{this._cInputLinkName=r;}}
                      type={'text'} />
                    <br />
                    <Button
                      color="primary"
                      onClick={()=>{
                        if(this.state.isAdddingLink!=true && this._cInputLink && this._cInputLinkName){
                          let _link = this._cInputLink.getValue();
                          let _name = this._cInputLinkName.getValue();
                          console.log('link:',_link,_name);
                          this._onRequestAddLink({
                            link: _link,
                            name: _name
                          })
                        }
                      }}>
                      {
                        this.state.isAdddingLink ? 
                        <i className={"fa fa-circle-o-notch fa-spin"}/>
                        :
                        "Thêm"
                      }
                    </Button>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>            
          </div>
          <Alert color="warning" isOpen={this.state.isShowAlert} toggle={this._onDismissAlert}>
            {this.state.msgAlert}
          </Alert>
        </ModalBody>  
        <ModalFooter>
          <Button color="secondary" onClick={()=>{
            this.hide();
          }}>{HText.get('btn_close')}</Button>
        </ModalFooter>      
      </Modal>
    )
  }
}
MyModalPhoto.Helper = {
  component: null,
  currentKey: null,
  show(row,fieldName,screenCode,opts){
    if(MyModalPhoto.Helper.component){
      MyModalPhoto.Helper.component.show(row,fieldName,screenCode,opts);
      if(opts && opts.key){
        MyModalPhoto.Helper.currentKey = opts.key;
      }
    }
  },
  // show(opts){
  //   if(MyModalPhoto.Helper.component){
  //     MyModalPhoto.Helper.component.show(opts);
  //     if(opts && opts.key){
  //       MyModalPhoto.Helper.currentKey = opts.key;
  //     }
  //   }
  // },
  hide(){
    if(MyModalPhoto.Helper.component){
      MyModalPhoto.Helper.component.hide();
      MyModalPhoto.Helper.currentKey = '';
    }
  },
  forceUpdateWithKey(key){
    // console.warn('update: ', key, MyModalPhoto.Helper.component, MyModalPhoto.Helper.currentKey);
    if(MyModalPhoto.Helper.component && key==MyModalPhoto.Helper.currentKey){
      MyModalPhoto.Helper.component.forceUpdate();
    }
  }
}
export default MyModalPhoto;
