import MyPageOne from "./MyPage"
import MyRoutes from './routes'
import HModule from "src/helpers/Module"
import * as MyHelpers from "./helpers"
const ModuleSetting = {
  //========== init ==========
  _key: "setting",
  _fnList: MyHelpers.FnList,
  _config: {},
  _routes: MyRoutes.routes,
  getMyKey: () => {
    return "setting"
  },
  runAfterInit: (HConfig) => {
    HModule.initDefault(ModuleSetting, {MyPageOne})
    // MyHelpers.addOneMenuSetting({title:"General", icon: null, path: "/setting/general", subTitle:"null", onClick:null, configPage: {
    //   Id: "setting_general",
    //   UIUrl: "/setting/setting_general",
    //   UIType: "setting_general",
    // }})
    MyHelpers.addOneMenuSetting({title:"My Profile", icon: null, path: "/my-profile", subTitle:"null", onClick:null, configPage: {
      Id: "setting_myprofile",
      UIUrl: "/setting/my-profile",
      UIType: "setting_myprofile",
    }})
  },
  //---------- end default init ----------//
}

export default ModuleSetting