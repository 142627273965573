import React from "react";
import {MyUIHelpers, CSuppense} from "@macashipo/mlib"

const MyUITablev3 = React.lazy(() => import("./Types/tablev3"));
const MyUITablev3Detail = React.lazy(() => import("./Types/tablev3_detail"));
const MyUIHeaderv3 = React.lazy(() => import("./Types/headerv3"));
const MyUIListCard = React.lazy(() => import("./Types/list_card"));
const MyUIFooter = React.lazy(() => import("./Types/footerv3"));
const MyUITableFilterColumn = React.lazy(() =>
  import("./Types/tablev3_filtercolumn")
);
const MyUIChart = React.lazy(() => import("./Types/chartv3"));
const MyUIFormAddNew = React.lazy(() => import("./Types/form_add_new_v3"));
const MyUIBelowHeader = React.lazy(() => import("./Types/below_header_v3"));
const MyUIBelowFilter = React.lazy(() => import("./Types/below_filter_v3"));
const MyUIFilter = React.lazy(() => import("./Types/filterv3"));
const MyUISignature = React.lazy(() => import("./Types/signaturev3"));
const MyUIModalAccount = React.lazy(() => import("./Types/modal_accountv3"));
const MyUIPopover = React.lazy(() => import("./Types/popover_tippy"));
const MyUITable_listv3_content = React.lazy(() =>
  import("./Types/table_listv3_content")
);
const MyUITable_blockv3_content = React.lazy(() =>
  import("./Types/table_blockv3_content")
);
const MyUIV3_search_on_tablev5 = React.lazy(() =>
  import("./Types/v3_search_on_tablev5")
);

const MyUICardProjectSelect = React.lazy(() =>
  import("./Types/card_project_select")
);
const MyUILogin = React.lazy(() => import("./Types/loginv3"));
const MyUIV3_belowtable_slide = React.lazy(() =>
  import("./Types/v3_belowtable_slide")
);
const MyUIV3_belowtable_mylayout = React.lazy(() =>
  import("./Types/v3_belowtable_mylayout")
);
const MyUIV3BriefFunction = React.lazy(() =>
  import("./Types/v3_brief_function")
);
const MyUIV3BriefEvent = React.lazy(() => import("./Types/v3_brief_event"));
const MyUIV3BriefDesign = React.lazy(() => import("./Types/v3_brief_design"));
const MyUIV3CardFunctionSlide = React.lazy(() =>
  import("./Types/v3_card_funtion_slide")
);
const MyUIV3CardEvent = React.lazy(() => import("./Types/v3_card_event"));
const MyUIV3CardDesign = React.lazy(() => import("./Types/v3_card_design"));
const MyUIV3ChartMilestone = React.lazy(() =>
  import("./Types/v3_chart_milestone")
);
const MyUIV3CustomerPotenial = React.lazy(() =>
  import("./Types/v3_customer_potential")
);
const MyUIV3PotentialLocation = React.lazy(() =>
  import("./Types/v3_card_location")
);
const MYUILayoutDashboardv3Item = React.lazy(() =>
  import("./Types/layout_dashboardv3_item")
);
const MYUISolidv3DashboardProgress = React.lazy(() =>
  import("./Types/solidv3_dashboard_progress")
);
const MYUISolidv3DashboardTable = React.lazy(() =>
  import("./Types/solidv3_dashboard_table")
);
const MYUIFilterv3_dashboard = React.lazy(() =>
  import("./Types/filterv3_dashboard")
);
const MyUIV3DocumentSidebar = React.lazy(() =>
  import("./Types/v3_document_sidebar")
);
const MyUIV3DocumentTable = React.lazy(() =>
  import("./Types/v3_document_table")
);
const MyUIV3GridLayout = React.lazy(() => import("./Types/v3_grid_layout"));
const MyUILayoutPageV3 = React.lazy(() => import("./Types/layout_pagev3"));

// myui from mockup v3
const MyUISidebarFolder = React.lazy(() => import("./Types/sidebar_folder"));
const MyUIGridLayout = React.lazy(() => import("./Types/grid_layout"));
const MyUITableMockup = React.lazy(() => import("./Types/table_mockup"));
const MyUISidebarMockup = React.lazy(() => import("./Types/sidebar_mockup"));
const MyUITableMockupMergeRow = React.lazy(() =>
  import("./Types/table_mockup_merge_row")
);
const MyUIChartMockup = React.lazy(() => import("./Types/chart_mockup"));
const MyUISlide = React.lazy(() => import("./Types/slide"));

const MyUIBannerHomeSupplier = React.lazy(() =>
  import("./Types/rb_banner_home_supplier")
);
const MyUIGroupHomeSupplier = React.lazy(() =>
  import("./Types/rb_group_home_supplier")
);
const MyUIFeatureHomeSupplier = React.lazy(() =>
  import("./Types/rb_feature_home_supplier")
);
const MyUIQAHomeSupplier = React.lazy(() =>
  import("./Types/rb_qa_home_supplier")
);

const MyUICardHomeSupplier = React.lazy(() =>
  import("./Types/rb_card_home_supplier")
);
const MyUICardEventSupplier = React.lazy(() =>
  import("./Types/rb_card_event_supplier")
);
const MyUICardLocation = React.lazy(() => import("./Types/rb_card_location"));
const MyUICardFeatureSupplier = React.lazy(() =>
  import("./Types/rb_card_feature_supplier")
);
const MyUICardCollapse = React.lazy(() => import("./Types/rb_card_collapse"));
const MyUICardBidLevelling = React.lazy(() =>
  import("./Types/rb_card_bid_levelling")
);
const MyUICardDashboard = React.lazy(() => import("./Types/rb_card_dashboard"));
const MyUICardFunctionSlide = React.lazy(() =>
  import("./Types/rb_card_function_slide")
);
const MyUICardEvent = React.lazy(() => import("./Types/rb_card_event"));
const MyUICardDesign = React.lazy(() => import("./Types/rb_card_design"));

const MyUIFormFunction = React.lazy(() => import("./Types/rb_form_function"));

//myui common
const Tooltip = React.lazy(() =>
  import("../../../../v1/components2/MyUI/Types/tooltip")
);
const SliderRange = React.lazy(() => import("./Types/slider_range"));

const MyUISolidv3_dashboard_chart = React.lazy(() =>
  import("./Types/solidv3_dashboard_chart")
);

const Video_player = React.lazy(() =>
  import("../../../../v1/components2/MyUI/Types/video_player")
);

const List = {
  video_player: Video_player,
  tablev3: MyUITablev3,
  tablev3_detail: MyUITablev3Detail,
  headerv3: MyUIHeaderv3,
  list_card: MyUIListCard,
  card_project_select: MyUICardProjectSelect,
  footer: MyUIFooter,
  tablev3_filtercolumn: MyUITableFilterColumn,
  chartv3: MyUIChart,
  form_add_new_v3: MyUIFormAddNew,
  table_listv3_content: MyUITable_listv3_content,
  table_blockv3_content: MyUITable_blockv3_content,
  below_header_v3: MyUIBelowHeader,
  below_filter_v3: MyUIBelowFilter,
  filterv3: MyUIFilter,
  signaturev3: MyUISignature,
  modal_accountv3: MyUIModalAccount,
  popover_tippy: MyUIPopover,
  v3_search_on_tablev5: MyUIV3_search_on_tablev5,
  loginv3: MyUILogin,
  v3_belowtable_slide: MyUIV3_belowtable_slide,
  v3_belowtable_mylayout: MyUIV3_belowtable_mylayout,
  v3_brief_function: MyUIV3BriefFunction,
  v3_brief_event: MyUIV3BriefEvent,
  v3_brief_design: MyUIV3BriefDesign,
  v3_card_function_slide: MyUIV3CardFunctionSlide,
  v3_card_event: MyUIV3CardEvent,
  v3_card_design: MyUIV3CardDesign,
  v3_chart_milestone: MyUIV3ChartMilestone,
  v3_customer_potential: MyUIV3CustomerPotenial,
  v3_card_location: MyUIV3PotentialLocation,
  v3_document_sidebar: MyUIV3DocumentSidebar,
  v3_document_table: MyUIV3DocumentTable,
  v3_grid_layout: MyUIV3GridLayout,
  layout_pagev3: MyUILayoutPageV3,
  sidebar_folder: MyUISidebarFolder,
  grid_layout: MyUIGridLayout,
  table_mockup: MyUITableMockup,
  sidebar_mockup: MyUISidebarMockup,
  table_mockup_merge_row: MyUITableMockupMergeRow,
  rb_chart: MyUIChartMockup,
  slide: MyUISlide,

  rb_banner_home_supplier: MyUIBannerHomeSupplier,
  rb_group_home_supplier: MyUIGroupHomeSupplier,
  rb_feature_home_supplier: MyUIFeatureHomeSupplier,
  rb_qa_home_supplier: MyUIQAHomeSupplier,

  rb_card_home_supplier: MyUICardHomeSupplier,
  rb_card_event_supplier: MyUICardEventSupplier,
  rb_card_location: MyUICardLocation,
  rb_card_feature_supplier: MyUICardFeatureSupplier,
  rb_card_collapse: MyUICardCollapse,
  rb_card_bid_levelling: MyUICardBidLevelling,
  rb_card_dashboard: MyUICardDashboard,
  rb_card_function_slide: MyUICardFunctionSlide,
  rb_card_event: MyUICardEvent,
  rb_card_design: MyUICardDesign,

  rb_form_function: MyUIFormFunction,
  tooltip: Tooltip,
  slider_range: SliderRange,

  //dashboar
  solidv3_dashboard_chart: MyUISolidv3_dashboard_chart,
  layout_dashboardv3_item: MYUILayoutDashboardv3Item,
  solidv3_dashboard_progress: MYUISolidv3DashboardProgress,
  solidv3_dashboard_table: MYUISolidv3DashboardTable,
  filterv3_dashboard: MYUIFilterv3_dashboard,
};
const AppMyUI = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
    console.warn("init nintint int,",MyUIHelpers.Types)
  },
};
export default AppMyUI;
