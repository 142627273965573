import MyComponentTypes from '../MyComponent/Types'
const MyButtonHelpers = {
  addTypes(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyButtonHelpers.Types, key, fnUI)
  },
  addTypesFromListLazy(listWithKey: any) {
    MyComponentTypes.addTypesGenericFromListLazy(
      MyButtonHelpers.Types,
      listWithKey
    )
  },
  addTypesWrap(key: string, fnUI: any) {
    MyComponentTypes.addTypesGeneric(MyButtonHelpers.TypesWrap, key, fnUI)
  },
  Types: {
    _empty: MyComponentTypes.Types._empty
  },
  TypesWrap: {
    _empty: MyComponentTypes.TypesWrap._empty
  }
}

export default MyButtonHelpers
