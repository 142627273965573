import { isObject } from '../helpers/Is'
import OneFilter from './OneFilter'
interface IOneFilters {
  listFilter?: OneFilter[]
  needRequestServerAfterFilter?: boolean
  needRequestLast?: boolean //dung de kiem tra truoc do co request server hay khong, neu co thi lan nay du co isServer hay khong cung can request server
  afterChangeFilter: () => void
  afterChangeSort: () => void
}

class OneFilters implements IOneFilters {
  listFilter?: OneFilter[]
  listSort?: any[]
  needRequestServerAfterFilter?: boolean = false
  needRequestLast?: boolean = false
  afterChangeFilter: () => void
  afterChangeSort: () => void
  constructor() {
    this.afterChangeFilter = () => {}
    this.afterChangeSort = () => {}
  }
  updateCurrentSort(sortField?: string, order?: string) {
    this.listSort = [
      {
        Field: sortField,
        Dir: order
      }
    ]
    this.afterChangeSort()
  }
  updateCurrentFiters(currentFilters?: any) {
    this.listFilter = []
    if (currentFilters && isObject(currentFilters)) {
      this.needRequestServerAfterFilter = false
      for (let k of Object.keys(currentFilters)) {
        let _oneFilter = new OneFilter(k, currentFilters[k])
        if (_oneFilter.isServer === true) {
          this.needRequestServerAfterFilter = true
        }
        this.listFilter.push(_oneFilter)
      }

      //check last de quyet dinh lan nay co can request server hay khong
      if (this.needRequestServerAfterFilter === true) {
        this.needRequestLast = true
      } else {
        if (this.needRequestLast === true) {
          this.needRequestServerAfterFilter = true
          this.needRequestLast = false
        }
      }
    }
    this.afterChangeFilter()
  }
  getQueryFilter() {
    let _query = {}
    if (this.listFilter) {
      for (let i = 0; i < this.listFilter.length; i++) {
        let _oneFilter = this.listFilter[i]
        _query = Object.assign(_query, _oneFilter.getQueryFilter())
      }
    }
    return _query
  }
  getQueryFilterClients() {
    let _query = {}
    if (this.listFilter) {
      for (let i = 0; i < this.listFilter.length; i++) {
        let _oneFilter = this.listFilter[i]
        _query = Object.assign(_query, _oneFilter.getQueryFilterClient())
      }
    }
    return _query
  }
  getQuerySort() {
    let _query = {}
    if (this.listSort && this.listSort.length > 0) {
      _query['SortQuery'] = this.listSort
    }
    return _query
  }
  getDataWithCurrentFilter(listData?: any[]) {
    if (listData && this.listFilter) {
      let _listDataFilterd = null
      if (this.listFilter.length > 0) {
        for (let i = 0; i < this.listFilter.length; i++) {
          let _oneFilter = this.listFilter[i]
          if (_oneFilter.isServer !== true) {
            //chi filter client
            _listDataFilterd = _oneFilter.getDataByThisFilter(
              _listDataFilterd || listData
            )
          }
        }
      }
      if (_listDataFilterd) {
        return _listDataFilterd
      }
      return listData
    }
    return listData
  }
}

export default OneFilters
