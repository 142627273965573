import React from 'react';
import { Link } from 'react-router-dom';
import HColor from '../../helpers/Color'
import M from '../../libs/M';
import HConfig from '../../helpers/Config';
import HUtils from '../../helpers/Utils'

// import CellTypeApi from '../../components/CellType/Types/api';
// import CellTypeArrayEditableModal from '../../components/CellType/Types/array_editable_modal';
// import CellTypeChart from '../../components/CellType/Types/chart';
// import CellChartMilestone from '../../components/CellType/Types/chart_milestone';
// import CellTypeCheckBox from '../../components/CellType/Types/checkbox';
// import CellTypeCheckboxStyled from '../../components/CellType/Types/checkbox_styled';
// import CellTypeChromeColor from '../../components/CellType/Types/chromecolor';
// import CellTypeCsCheckQualityFiles from '../../components/CellType/Types/cs_check_quality_files';
// import CellTypeCsEmailTpAction from '../../components/CellType/Types/cs_email_tp_action/cs_email_tp_action';
// import CellTypeCurrency from '../../components/CellType/Types/currency';
// import CellTypeDate from '../../components/CellType/Types/date';
// import CellTypeDatePicker from '../../components/CellType/Types/datepicker';
// import CellTypeDateTime from '../../components/CellType/Types/datetime';
// import CellTypeDelete from '../../components/CellType/Types/delete';
// import CellTypeEditForm from '../../components/CellType/Types/editform';
// import CellTypeExpandMulti from '../../components/CellType/Types/expand_multi';
// import CellTypeExpandWithInput from '../../components/CellType/Types/expand_with_input';
// import CellTypeFiles from '../../components/CellType/Types/files';
// import CellTypeForm from '../../components/CellType/Types/form';
// import CellTypeHtmlEditor from '../../components/CellType/Types/html_editor';
// import CellTypeJsonEditor from '../../components/CellType/Types/json_editor';
// import CellTypeLink from '../../components/CellType/Types/link';
// import CellTypeListBtn from '../../components/CellType/Types/list_btn';
// import CellTypeListCheckBox from '../../components/CellType/Types/list_checkbox';
// import CellTypeListDate from '../../components/CellType/Types/list_date';
// import CellTypeListInput from '../../components/CellType/Types/list_input';
// import CellTypeListText from '../../components/CellType/Types/list_text';
// import CellTypeMergeRow from '../../components/CellType/Types/mergerow';
// import CellTypeModal from '../../components/CellType/Types/modal';
// import CellTypeModalScore from '../../components/CellType/Types/modal_score';
// import CellTypeModalTable from '../../components/CellType/Types/modal_table';
// import CellTypeModalTableExtra from '../../components/CellType/Types/modal_table_extra';
// import CellTypeNumber from '../../components/CellType/Types/number';
// import CellTypeNumberDecimal from '../../components/CellType/Types/number_decimal';
// import CellPercent from '../../components/CellType/Types/percent';
// import CellTypePickColor from '../../components/CellType/Types/pickcolor';
// import CellTypePopNumber from '../../components/CellType/Types/pop_number';
// import CellTypePopNumberScore from '../../components/CellType/Types/pop_number_score';
// import CellPosPrintBill from '../../components/CellType/Types/pos_printbill';
// import CellTypeQuality from '../../components/CellType/Types/quality';
// import CellTypeRate from '../../components/CellType/Types/rate';
// import CellTypeReadonly from '../../components/CellType/Types/readonly';
// import CellTypeReadonlyAutoLink from '../../components/CellType/Types/readonly_autolink';
// import CellTypeReadonlyColor from '../../components/CellType/Types/readonly_color';
// import CellTypeReadonlyCurrency from '../../components/CellType/Types/readonly_currency';
// import CellTypeReadonlyDate from '../../components/CellType/Types/readonly_date';
// import CellTypeReadonlyHtml from '../../components/CellType/Types/readonly_html';
// import CellTypeReadonlyNowwrap from '../../components/CellType/Types/readonly_nowrap';
// import CellTypeReadonlyNumber from '../../components/CellType/Types/readonly_number';
// import CellTypeReadonlyPercent from '../../components/CellType/Types/readonly_percent';
// import CellTypeReadonlyStt from '../../components/CellType/Types/readonly_stt';
// import CellTypeReadonlyTooltip from '../../components/CellType/Types/readonly_tooltip';
// import CellTypeReadonlyWrap from '../../components/CellType/Types/readonly_wrap';
// import CellTypeSelect from '../../components/CellType/Types/select';
// import CellTypeSelect2Addable from '../../components/CellType/Types/select2_addable';
// import CellTypeSelect2Modal from '../../components/CellType/Types/select2_modal';
// import CellTypeSelect2Popover from '../../components/CellType/Types/select2_popover';
// import CellTypeSelectAddable from '../../components/CellType/Types/select_addable';
// import CellTypeSelectIcon from '../../components/CellType/Types/select_icon';
// import CellTypeSurveyAnswer from '../../components/CellType/Types/survey_answer';
// import CellTypeText from '../../components/CellType/Types/text';
// import CellTypeTextArea from '../../components/CellType/Types/textarea';
// import CellTypeTextAreaFull from '../../components/CellType/Types/textareafull';
// import CellUploadAsset from '../../components/CellType/Types/upload_asset';
// import CellAction from '../../components/CellType/Types/action';
// import CellQualityPopover from '../../components/CellType/Types/quality_popover';

//New
// import CellTypeInputFormatNumeral from '../../components2/MyTableCell/Types/input_format_numeral';
// import CellTypeInputFormatNumeralMoney from '../../components2/MyTableCell/Types/input_format_numeral_money';
// import With_MyUI from '../../components2/MyTableCell/Types/with_myui';
// import Cell_solid_projectscopeevm_3datetime from '../../components2/MyTableCell/TypesSolid/solid_projectscopeevm_3datetime';
// import Cell_solid_timetracking_startend from '../../components2/MyTableCell/TypesSolid/solid_timetracking_startend';

// import InputEditor from '../../views/Components/InputEditor/index2';
// import * as CellCustom from './CellCustom';
// import * as CellCustomPage from './CellCustomPage';
// import MyTableCellHelpers from '../../components2/MyTableCell/MyTableCellHelpers';

/* more:
screenCode: string (files)
otF: string, field Option Text
lockF: string, field lock, overide canEdit
linkTitle: string, title for type link
isColumn: bool, for type quality
- files
showPhoto: bool
maxHeight: string
- select_addable
apiPath, apiNameAdd, fName, 
- array_editable
apiPath,apiNameAdd,apiNameRemove,fID,fList,fIDRemove,canToggle,fProjectId
- link
showPhoto: bool
fPhoto: link to show
urlPhoto: link to show
linkTitle: string
*/

const CellHelper = {
  getMoreInType({extra,row}){
    let _more = {};
    // console.log("getMoreInType:",extra,extra.Type);
    if(extra!=null){
      let _type = extra.Type;
      try {
        if(_type!=null && _type.more){
          _more = JSON.parse(_type.more);
        }
      } catch (error) { console.warn('Parser json more in Type error:',error,_type);}
    }
    return _more;
  },
  getFnList({extra,row}){
    let _fnList = {};
    if(extra!=null){
      _fnList = extra.fnList||{};
    }
    return _fnList;
  },
  getFormatInMore({extra,row}){
    let _more = this.getMoreInType({extra,row});
    if(_more!=null){
      return _more.format;
    }
  },
  getOptionTextFieldInMore({extra,row}){
    let _more = this.getMoreInType({extra,row});
    if(_more!=null){
      return _more.otF;
    }
  },
  getCanEdit({extra,row,defaultValue=true}){
    let _type = extra.Type;
    // console.log('getCanEdit:',extra,row);
    let _canEdit = defaultValue;
    if(_type!=null && _type.more!=null){
      let _more = CellHelper.getMoreInType({extra:extra,row:row});
      // console.log('getCanEdit more:',_more);
      if(typeof _more.lockF === 'boolean'){
        _canEdit = _more.lockF;
      }
      else if(typeof _more.lockF === 'string'){
        if(_more.lockF.length>0){          
          if(row[_more.lockF]!=null){
            // console.log('getCanEdit more:',_more,row[_more.lockF]);
            _canEdit = !row[_more.lockF];
          }
          else if(_more.lockF.indexOf(',')>-1){
            let _arrLockF = _more.lockF.split(',');
            if(_arrLockF && _arrLockF.length>0){
              for(let f of _arrLockF){
                if(row[f]==true){//only need one lock -> lock
                  _canEdit = false;
                  break;                  
                }
              }
            }
          }
        }        
      }

      if(_canEdit==true){
        if(typeof _more.canEditF == 'boolean'){
          _canEdit = _more.canEditF;
        }
        else if(typeof _more.canEditF == 'string'){
          if(_more.canEditF.length>0){          
            if(row[_more.canEditF]!=null){
              _canEdit = row[_more.canEditF];
            }
            else if(_more.canEditF.indexOf(',')>-1){
              let _arrcanEditF = _more.canEditF.split(',');
              if(_arrcanEditF && _arrcanEditF.length>0){
                for(let f of _arrcanEditF){
                  if(row[f]==false){//only need one cannot edit -> cannot edit
                    _canEdit = false;
                    break;                  
                  }
                }
              }
            }
          }        
        }
      }    
    }

    if(row && row.hasOwnProperty('IsLocked')){
      if(_canEdit==true && row.IsLocked===true){
        _canEdit = false;
      }
    }
    // console.log('getCanEdit rs:',_canEdit);
    return _canEdit;
  },
  getExtraClassName({extra,row,more}){
    let _className = "";
    // console.log('getExtraClassName',extra);
    if(extra!=null){
      if(extra.ClassName){
        return extra.ClassName;
      }
    }
    if(more && more.className){
      return more.className;
    }
    return _className;
  },
  getExtraTooltip({extra,row}){
    let _more = this.getMoreInType({extra,row});
    if(_more!=null){
      if(_more.tooltip){
        return _more.tooltip;
      }
      if(_more.tooltipF && row[_more.tooltipF]){
        return row[_more.tooltipF];
      }
    }
  },
  getExtraStyle({extra,row}){
    let _style = {};
    if(extra!=null){
      let _styleEx = extra.Style;
      if(_styleEx!=null){
        if(M.isString(_styleEx)==true){
          try {
            _style = Object.assign(_style,JSON.parse(_styleEx));
          } catch (error) {
            console.log('getExtraStyle error:',_style,error);
          }
        }
        else if(M.isObject(_styleEx)==true){
          _style = Object.assign(_style,_styleEx);
        }
      }
      if(extra.Type!=null && extra.Type.bgF!=null && row!=null){
        if(row[extra.Type.bgF]!=null){
          let _color = HColor.getTextWB(row[extra.Type.bgF]);
          _style = Object.assign(_style,{backgroundColor:row[extra.Type.bgF],color:_color});
        }
        else if(extra.Type.bgF.startsWith('#')){
          let _color = HColor.getTextWB(extra.Type.bgF);
          _style = Object.assign(_style,{backgroundColor:extra.Type.bgF,color:_color});
        }
      }
      let _more = CellHelper.getMoreInType({extra,row});
      // console.log('_more:',_more);
      if(_more && _more.style){
        try {
          let _styleInMore = _more.style || {};
          if(_styleInMore){
            _style = Object.assign(_style,_styleInMore);
          }
        } catch (error) {
          
        }
      }
    }
    // console.log('getExtraStyle:',extra,_style);
    if(row && row.StyleRow && typeof row.StyleRow == 'object'){
      _style = Object.assign(_style,row.StyleRow);
    }
    if(row && row.UI_StyleRow){
      if(typeof row.UI_StyleRow == 'object'){
        _style = Object.assign(_style,row.UI_StyleRow);
      }
      else if(typeof row.UI_StyleRow == 'string'){
        try {
          let _styleRow = JSON.parse(row.UI_StyleRow);
          if(_styleRow){
            _style = Object.assign(_style,_styleRow);
          }
        } catch (error) {
          console.warn('Error parse UI_StyleRow',error);
        }
      }      
    }
    // console.log('getExtraStyle:',row.StyleRow,extra,_style);
    return _style;
  },
  getSourceSelect({extra,row}){
    let _sourceOption = [];let _type = extra.Type;
    // console.log('getSourceSelect:',row,extra);

    //Check source have .
    if(_type.source!=null && _type.source.indexOf('.')>0){
      let _f1 = _type.source.split('.')[0];
      let _f2 = _type.source.split('.')[1];
      let _sf = _type.sourceField;
      let _valueSourceField = row[_sf];

      if(extra.sourceList[_f1]!=null  && extra.sourceList[_type.source]==null){//&& extra.sourceList[_f2]!=null
        let _obj = M.arrayObj2ObjWithKey(extra.sourceList[_f1],'Value');
        if(_obj!=null)
        {
          let _keys = Object.keys(_obj);
          let _newObj = {};
          for(let i of _keys){
            _newObj[i] = _obj[i][_f2];
          }
          extra.sourceList[_type.source] = _newObj;
          console.log('getSourceSelect:',extra);
        }
      }
      if(extra.sourceList[_type.source]!=null && _valueSourceField!=null){
        _sourceOption = extra.sourceList[_type.source][_valueSourceField];
        // console.log('getSourceSelect option 1:',_sourceOption);
      }
    }
    else if(_type.source!=null && extra.sourceList!=null){
      _sourceOption = extra.sourceList[_type.source] || [];
    }

    if(_type.type=='select2color' || _type.type=='pickcolor'){
      let _s = extra.sourceList[_type.source];
      let _newFieldSource = _type.source+'_Select2Color';
      if(extra.sourceList[_newFieldSource]!=null){
        _sourceOption = extra.sourceList[_newFieldSource];
      }
      else if(_s!=null){
        extra.sourceList[_newFieldSource] = _s.map((e,i)=>{
          return {
            value: e.ColorCode,
            label: e.Name,
          }
        });
        _sourceOption = extra.sourceList[_newFieldSource];
      }
    }

    if(_type.sourceList!=null){
      try {
        extra.Type.sourceListParser = JSON.parse(_type.sourceList);
        _sourceOption = extra.Type.sourceListParser;
      } catch (error) { console.warn('parser json type source list error:',error);}
    }
    // console.log('getSourceSelect _sourceOption:',extra.sourceList,_sourceOption);
    return _sourceOption;
  },
  Type:{
    readonly: 'readonly',
    text: 'text',
  },
  addCustomDataFormat(key,format){
    if(CellHelper.DataFormat[key]==null){
      CellHelper.DataFormat[key] = format;
    }
    else{
      console.warn('CustomFormat exist:',key)
    }
  },
  DataFormat:{
    // invisible:(cell,row,extra,index)=>{
    //   return <div></div>
    // },
    // // readonly:(cell,row,extra,index)=>{
    // //   return(
    // //     <CellType.readonly cell={cell} row={row} extra={extra}/>
    // //   )
    // // },
    // readonly:(cell,row,extra,index)=>{
    //   // let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    //   // if(typeof cell == 'object' && cell!=null){
    //   //   return <div className="idiv-ro has-wrap" style={_style}>{"[object]"}</div> 
    //   // }
    //   // return <div className={classnames("idiv-ro has-wrap",CellHelper.getExtraClassName({extra:extra,row:row}))} style={_style}>{cell}</div>
    //   return (
    //     <CellTypeReadonly cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // // readonly_nowrap:(cell,row,extra,index)=>{
    // //   return(
    // //     <CellType.readonly_nowrap cell={cell} row={row} extra={extra}/>
    // //   )
    // // },
    // readonly_nowrap:(cell,row,extra,index)=>{
    //   // let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    //   // _style = Object.assign(_style,{width:'100%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'});
    //   // if(typeof cell == 'object' && cell!=null){
    //   //   return <div className="idiv-ro has-wrap" style={_style}>{"[object]"}</div> 
    //   // }
    //   // return <div title={cell} className={classnames("idiv-ro has-wrap",CellHelper.getExtraClassName({extra:extra,row:row}))} style={_style}>{cell}</div>
    //   return (
    //     <CellTypeReadonlyNowwrap cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // readonly_wrap:(cell,row,extra,index)=>{
    //   // let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    //   // _style = Object.assign(_style,{width:'100%',whiteSpace:'pre-line',overflow:'hidden',textOverflow:'ellipsis'});
    //   // if(typeof cell == 'object' && cell!=null){
    //   //   return <div className="idiv-ro has-wrap" style={_style}>{"[object]"}</div> 
    //   // }
    //   // return <div title={cell} className={classnames("idiv-ro has-wrap",CellHelper.getExtraClassName({extra:extra,row:row}))} style={_style}>{cell}</div>
    //   return (
    //     <CellTypeReadonlyWrap cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // readonly_date:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyDate cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // readonly_datetime:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyDate cell={cell} row={row} extra={extra} custom={{defaultFormat:HConfig.fnList.getConfig("datetimeFormat")}}/>
    //   )
    // },
    // readonly_datetime_timeonly:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyDate cell={cell} row={row} extra={extra} custom={{defaultFormat:HConfig.fnList.getConfig('timeFormat')}}/>
    //   )
    // },
    // readonly_currency:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyCurrency cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // readonly_percent:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyPercent cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // readonly_tooltip:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyTooltip cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // readonly_array:(cell,row,extra,index)=>{ //source for select add new, sourceField for field display in array, more: {"apiPath":"JobTitle","apiNameAdd":"AddTask","apiNameRemove":"RemoveTask","fID":"JobTitleId","fList":"ConfigTaskIds","fIDRemove":"ConfigTaskId","canToggle":false}
    //   let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    //   return (
    //     <CellCustom.UICustomArrayReadonly style={_style} cell={cell} row={row} extra={extra}/>
    //   )
    //   // return(
    //   //   <CellTypeReadonlyArray cell={cell} row={row} extra={extra}/>
    //   // )
    // },
    // array_editable:(cell,row,extra,index)=>{ //source for select add new, sourceField for field display in array, more: {"apiPath":"JobTitle","apiNameAdd":"AddTask","apiNameRemove":"RemoveTask","fID":"JobTitleId","fList":"ConfigTaskIds","fIDRemove":"ConfigTaskId","canToggle":false}
    //   let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    //   let _canEdit = CellHelper.getCanEdit({extra,row,defaultValue:extra.CanEdit});
    //   if(_canEdit==false){
    //     return CellHelper.DataFormat.readonly_array(cell,row,extra,index);
    //   }      
    //   return (
    //     <CellCustom.UICustomArrayEditable style={_style} cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // array_editable_popup:(cell,row,extra,index)=>{ //source for select add new, sourceField for field display in array, more: {"apiPath":"JobTitle","apiNameAdd":"AddTask","apiNameRemove":"RemoveTask","fID":"JobTitleId","fList":"ConfigTaskIds","fIDRemove":"ConfigTaskId","canToggle":false}
    //   let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    //   let _canEdit = CellHelper.getCanEdit({extra,row,defaultValue:extra.CanEdit});
    //   if(_canEdit==false){
    //     return CellHelper.DataFormat.readonly_array(cell,row,extra,index);
    //   }      
    //   return (
    //     <CellCustom.UICustomArrayEditableInPopup style={_style} cell={cell} row={row} extra={extra}/>
    //   )
    // },    
    // ro_nowrap:(cell,row,extra,index)=>{
    //   let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    //   return <div className="idiv-ro-ellipsis" style={_style}>{cell}</div>
    // },
    // expand: (cell,row,extra,index)=>{
    //   return (
    //     <CellCustom.UICustomExpand cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // link:(cell,row,extra,index)=>{
    //   // return(
    //   //   <CellCustom.UICustomLink cell={cell} row={row} extra={extra} index={index}/>
    //   // )
    //   return(
    //     <CellTypeLink cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // link_icon:(cell,row,extra,index)=>{
    //   let _style = CellHelper.getExtraStyle({extra:extra,row:row});
    //   let _link = extra.Type!=null?extra.Type.link:'';
    //   if(_link && _link.indexOf('[')>-1){
    //     let _extract = HUtils.SquareBracket.extract(_link);
    //     if(_extract!=null && _extract.length>0){
    //       for(let _e of _extract){
    //         if(row[_e]!=null){
    //           let _rg = new RegExp('\\['+_e+'\\]','g');
    //           // console.log(_rg,_link,row[_e]);
    //           _link = _link.replace(_rg, row[_e]);
    //         }
    //       }
    //     }
    //   }
    //   // console.log('link:',_link);
    //   return <div className="idiv-ro has-wrap dFaiCjcC" style={_style}>
    //     <Link to={_link} target='_blank'>
    //       <i className="fa fa-location-arrow"/>
    //     </Link>
    //   </div>
    // },
    // input:({cell,row,extra,index,type,inputProps})=>{
    //   // console.log('DataFormat input :',type,extra);
    //   let _type = extra.Type;
    //   var moreProps = {extra:extra,styleControl:CellHelper.getExtraStyle({extra:extra,row:row}),moreProps:{}};
    //   // var controlProps = {};
    //   let _more = CellHelper.getMoreInType({extra:extra,row:row});

    //   if(inputProps!=null){
    //     moreProps = Object.assign(moreProps,inputProps);
    //   }
    //   if(_type!=null && _type.multipleLine!=null){
    //     moreProps.multipleLine = _type.multipleLine;
    //   }
    //   let _canEdit = extra.CanEdit;
    //   if(_canEdit==true){
    //     _canEdit = CellHelper.getCanEdit({extra:extra,row:row});
    //   }
    //   if(type=='select'){
    //     let _showRemoved = _more.showRemoved;
    //     if(_showRemoved==true){
    //       moreProps.showRemoved=true;
    //     }
    //   }
    //   else if(type=='text'){
    //     let _mask = _more.mask;
    //     if(_mask!=null){
    //       // controlProps.mask = _mask;
    //       moreProps.moreProps.mask = _mask;
    //     }
    //     if(_more.regexDelete!=null){
    //       moreProps.moreProps.regexDelete = _more.regexDelete;
    //     }
    //   }
    //   if(_more && _more.disableField){
    //     let _isDisable = row[_more.disableField];
    //     if(_isDisable==true){
    //       let _value = row[extra.fieldName];
    //       if(_more.showField){
    //         _value = row[_more.showField];
    //       }
    //       console.log("disableField: ",_more);
    //       return <div className="idiv-ro has-wrap">{_value}</div>
    //     }
    //   }
    //   if(_more && _more.prefixId){
    //     moreProps.prefixId = _more.prefixId;
    //   }
    //   //numberRows: số dòng của textarea
    //   // let _numberRows = null;
    //   if (_more && _more.numberRows){
    //     // _numberRows = _more.numberRows;
    //     moreProps.rows = _more.numberRows;
    //   }
    //   return <InputEditor row={row} fnRequestUpdate={extra.fnRequestUpdate} extraData={extra.extraData} canEdit={_canEdit}
    //   type={type} fieldName={extra.fieldName} {...moreProps}/>
    // },
    // text:(cell,row,extra,index)=>{
    //   // return CellHelper.DataFormat.input({cell:cell,row:row,extra:extra,index:index,type:'text'});
    //   return (
    //     <CellTypeText cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // datetime:(cell,row,extra,index)=>{
    //   // return CellHelper.DataFormat.input({cell:cell,row:row,extra:extra,index:index,type:'date'});
    //   return (
    //     <CellTypeDateTime cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // number:(cell,row,extra,index)=>{
    //   // return CellHelper.DataFormat.input({cell:cell,row:row,extra:extra,index:index,type:'number'});
    //   return(
    //     <CellTypeNumber cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // number_decimal:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeNumberDecimal cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // checkbox:(cell,row,extra,index)=>{
    //   // return CellHelper.DataFormat.input({cell:cell,row:row,extra:extra,index:index,type:'checkbox'});
    //   return(
    //     <CellTypeCheckBox cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // textarea:(cell,row,extra,index)=>{
    //   // return CellHelper.DataFormat.input({cell:cell,row:row,extra:extra,index:index,type:'textarea'});
    //   return(
    //     <CellTypeTextArea cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // textareafull:(cell,row,extra,index)=>{
    //   // let _inputProps = {textareafull:true};
    //   // if(extra && extra.Type){
    //   //   extra.Type.multipleLine = true;
    //   // }
    //   // return CellHelper.DataFormat.input({cell:cell,row:row,extra:extra,index:index,type:'textarea',inputProps:_inputProps});
    //   return(
    //     <CellTypeTextAreaFull cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // textarea_autoresize:(cell,row,extra,index)=>{      
    //   return(
    //     <CellTypeTextAreaFull cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // date:(cell,row,extra,index)=>{
    //   // return CellHelper.DataFormat.input({cell:cell,row:row,extra:extra,index:index,type:'date'});
    //   return(
    //     <CellTypeDate key={MyTableCellHelpers.getKeyOfCell({cell,row,extra})} cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // select:(cell,row,extra,index)=>{
    //   // let _inputProps = {};
    //   // _inputProps.options = CellHelper.getSourceSelect({extra: extra,row:row});
    //   // // console.warn('select options:',_inputProps.options);
    //   // _inputProps.optionText = CellHelper.getOptionTextFieldInMore?row[CellHelper.getOptionTextFieldInMore]:null;
    //   // return CellHelper.DataFormat.input({cell:cell,row:row,extra:extra,index:index,type:'select',inputProps:_inputProps});
    //   return(
    //     <CellTypeSelect cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // selectlevel:(cell,row,extra,index)=>{
    //   let _inputProps = {};
    //   _inputProps.options = CellHelper.getSourceSelect({extra: extra,row:row})
    //   return CellHelper.DataFormat.input({cell:cell,row:row,extra:extra,index:index,type:'select',inputProps:_inputProps});
    // },
    // select2color:(cell,row,extra,index)=>{
    //   let _inputProps = {};
    //   _inputProps.options = CellHelper.getSourceSelect({extra: extra,row:row})
    //   return CellHelper.DataFormat.input({cell:cell,row:row,extra:extra,index:index,type:'select2color',inputProps:_inputProps});
    //   // return(
    //   //   <CellTypeSelect2Color cell={cell} row={row} extra={extra}/>
    //   // )
    // },
    // percent:(cell,row,extra,index)=>{
    //   return (
    //     <CellPercent cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // files:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeFiles cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // // files_old:(cell,row,extra,index)=>{//Ko xai
    // //   return(
    // //     <CellCustom.UICustomFiles cell={cell} row={row} extra={extra}/>
    // //   )
    // // },
    // chromecolor:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeChromeColor cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // pickcolor:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypePickColor cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // select2:(cell,row,extra,index)=>{
    //   return(
    //     <CellCustom.UICustomSelect2 cell={cell} row={row} extra={extra}/>
    //   )
    //   // return(
    //   //   <CellTypeSelect2 cell={cell} row={row} extra={extra}/>
    //   // )
    // },
    // delete:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeDelete cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // // An di vi ko thay xai o dau
    // // action:(cell,row,extra,index)=>{
    // //   const {fnList}=extra;
    // //   // console.log('delete:',extra);
    // //   return (
    // //     <div className="col-action">
    // //       <Button color="primary" style={{border:'0px'}} onClick={()=>{
    // //         if(fnList!=null && fnList.fnEdit!=null){
    // //           fnList.fnEdit({row});
    // //         }
    // //       }}><i className="fa fa-edit"/></Button>
    // //       <Popover placement="topRight" title={'Xóa'}
    // //         overlay={<div>Bạn có chắc muốn xóa?</div>} trigger="click" showButton okText="Đồng ý" cancelText="Đóng" 
    // //         onOk={()=>{
    // //           if(fnList!=null && fnList.fnDelete!=null){
    // //             fnList.fnDelete({row});
    // //           }
    // //         }} onCancel={()=>{console.log('Cancel')}}>
    // //         <Button color="danger" style={{border:'0px'}}><i className="fa fa-trash"/></Button>
    // //       </Popover>
    // //     </div>
    // //   );
    // // },
    // select_addable:(cell,row,extra,index)=>{
    //   return (
    //     <CellTypeSelectAddable cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // // select_addable_old:(cell,row,extra,index)=>{
    // //   return (
    // //     <CellCustom.UICustomSelectAddable cell={cell} row={row} extra={extra}/>
    // //   )
    // // },
    // select2_addable:(cell,row,extra,index)=>{
    //   // return (
    //   //   <CellCustom.UICustomSelect2Addable cell={cell} row={row} extra={extra}/>
    //   // )
    //   return (
    //     <CellTypeSelect2Addable cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // currency:(cell,row,extra,index)=>{
    //   // return(
    //   //   <CellCustom.UICustomCurrency cell={cell} row={row} extra={extra}/>
    //   // )
    //   return(
    //     <CellTypeCurrency cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // stt:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyStt cell={cell} row={row} extra={extra}/>
    //   ) 
    // },
    // quality:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeQuality cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // rate:(cell,row,extra,index)=>{
    //   return (
    //     <CellTypeRate cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // // custom_action:(cell,row,extra,index)=>{
    // //   return(
    // //     <CellCustom.UICustomAction cell={cell} row={row} extra={extra}/>
    // //   )
    // // },
    // api:(cell,row,extra,index)=>{//more: url, needReload, method
    //   return(
    //     <CellTypeApi cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // array_editable_modal:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeArrayEditableModal cell={cell} row={row} extra={extra}/>
    //   )
    // }, 
    // custom_evm_filecheck:(cell,row,extra,index)=>{
    //   return(
    //     <CellCustomPage.UICustomEVMFilesWithCheck cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // expand_api:(cell,row,extra,index)=>{
    //   return (
    //     <CellTypeExpandMulti cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // expand_multi:(cell,row,extra,index)=>{
    //   return (
    //     <CellTypeExpandMulti cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // editform:(cell,row,extra,index)=>{
    //   return (
    //     <CellTypeEditForm cell={cell} row={row} extra={extra}/>
    //   )
    // },    
    // checkbox_styled:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeCheckboxStyled cell={cell} row={row} extra={extra}/>
    //   )
    // }, 
    // list_checkbox:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeListCheckBox cell={cell} row={row} extra={extra}/>
    //   )
    // }, 
    // readonly_color:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyColor cell={cell} row={row} extra={extra}/>
    //   )
    // }, 
    // list_text:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeListText cell={cell} row={row} extra={extra}/>
    //   )
    // }, 
    // readonly_stt:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyStt cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // readonly_number:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyNumber cell={cell} row={row} extra={extra}/>
    //   )
    // }, 
    // list_btn:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeListBtn cell={cell} row={row} extra={extra}/>
    //   )
    // }, 
    // list_input:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeListInput cell={cell} row={row} extra={extra}/>
    //   )
    // }, 
    // upload_asset:(cell,row,extra,index)=>{
    //   return(
    //     <CellUploadAsset cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // select2_modal:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeSelect2Modal cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // select2_popover:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeSelect2Popover cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // datepicker:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeDatePicker cell={cell} row={row} extra={extra}/>
    //   )
    // },
    // select_icon:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeSelectIcon cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // chart_milestone:(cell,row,extra,index)=>{
    //   return(
    //     <CellChartMilestone cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // cs_check_quality_files:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeCsCheckQualityFiles cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // form:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeForm cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // html_editor:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeHtmlEditor cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // cs_email_tp_action:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeCsEmailTpAction cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // pop_number:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypePopNumber cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // modal_table:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeModalTable cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // modal_table_extra:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeModalTableExtra cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // list_date:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeListDate cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // expand_with_input:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeExpandWithInput cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // json_editor:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeJsonEditor cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // pop_number_score:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypePopNumberScore cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // chart:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeChart cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // modal_score:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeModalScore cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // survey_answer:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeSurveyAnswer cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // modal:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeModal cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // pos_printbill:(cell,row,extra,index)=>{
    //   return(
    //     <CellPosPrintBill cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // readonly_html:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyHtml cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // readonly_autolink:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeReadonlyAutoLink cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // mergerow:(cell,row,extra,index)=>{
    //   return(
    //     <CellTypeMergeRow cell={cell} row={row} extra={extra} index={index}/>
    //   )
    // },
    // input_format_numeral:(cell,row,extra,index)=>{ return( <CellTypeInputFormatNumeral cell={cell} row={row} extra={extra} index={index}/> ) },
    // input_format_numeral_money:(cell,row,extra,index)=>{ return( <CellTypeInputFormatNumeralMoney cell={cell} row={row} extra={extra} index={index}/> ) },
    // solid_timetracking_startend:(cell,row,extra,index)=>{ return( <Cell_solid_timetracking_startend cell={cell} row={row} extra={extra} index={index}/> ) },
    // solid_projectscopeevm_3datetime:(cell,row,extra,index)=>{ return( <Cell_solid_projectscopeevm_3datetime cell={cell} row={row} extra={extra} index={index}/> ) },
    // with_myui:(cell,row,extra,index)=>{ return( <With_MyUI cell={cell} row={row} extra={extra} index={index}/> ) },
    // action:(cell,row,extra,index)=>{ return( <CellAction cell={cell} row={row} extra={extra} index={index}/> ) },
    // quality_popover:(cell,row,extra,index)=>{ return( <CellQualityPopover cell={cell} row={row} extra={extra} index={index}/> ) },
  }, 
}
window.MyTableCellTypes = CellHelper.DataFormat; 
if(window._M){
  window._M.MyTableCellTypesV1 = CellHelper.DataFormat;
}
export default CellHelper;